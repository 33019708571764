import React from 'react';
import $ from 'jquery';

import './other.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import SmartEditor from '../../components/smarteditor';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, copyToClipboard, formatDate, formatDate2, formatTime, formatNum } from '../../libs/utils';
import {requestPost, requestFile} from '../../libs/request';
import Datepicker from '../../components/datepicker';
import Timepicker from '../../components/datepicker/timepicker';

class DetailEvent extends React.Component {
    editorref = null;
    constructor() {
        super();
        this.state = {
            eventId:     0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            
            infinite_time: 'Y',
            title:      '', 
            contents:   '', 
            description: '', 
            url:        '', 
            st_time: new Date(),
            ed_time: new Date(),
            status: 1,
            st_sel: 1,
            ed_sel: 1,
            file_url: '',
            landing_dv: 1,
            expo:       1
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const eventId  = searchParams.get("id");
        // 
        this.getData(eventId);
    }

    getData = (eventId) => {
        window.scrollTo(0, 0);
        this.setState({eventId});
    
        if(eventId > 0) {
            requestPost('getEventDetail', {eventId}, (res) => {
                if(Boolean(res)) {
                    const { title
                        , contents
                        , description
                        , expo
                        , hits
                        , file_url
                        , url
                        , status
                        , landing_dv
                        , st_time, ed_time
                        , reg_time
                        , email
                        , user_name } = res;
        
                    this.setState({ 
                        title
                        , contents
                        , description
                        , status
                        , landing_dv
                        , st_time
                        , ed_time
                        , file_url
                        , expo
                        , url
                        , hits
                        , reg_time
                        , email
                        , user_name
                        , stdate: Boolean(res.st_time) ? new Date( res.st_time ) : new Date(),
                            eddate: Boolean(res.ed_time) ? new Date( res.ed_time ) : new Date(), 
                            sttime: Boolean(res.st_time) ? new Date( res.st_time ) : new Date(), 
                            edtime: Boolean(res.ed_time) ? new Date( res.ed_time ) : new Date(),
                            infinite_time: Boolean(res.st_time) && res.st_time.startsWith('1899') && Boolean(res.ed_time) && res.ed_time.startsWith('9999') ? 'Y' : 'N',
                    });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }

    handleUploadFileChange = (event, type) => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;
        this.setState({[type]: file_path});
      });
    }

    handleFileChange = (event) => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;

		let imgHTML = `<img src="${consts.resourcUrl + file_path}" style="width: 100%">`;
		this.editorref.addContext(imgHTML);
      });
    }
    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    update = () => {
        const {infinite_time, eventId, title, description, expo, status, st_sel, ed_sel, stdate, eddate, sttime, edtime, landing_dv, url, file_url } = this.state;
        let contents;
        
        if (landing_dv === 2) {
            contents = this.editorref.getContext();
        }
        
        
        if(!title) {
            return toastMsg('제목을 입력하세요.');
        }
        

        if (landing_dv === 1) {
            if (!url) {
                return toastMsg('URL을 입력하세요.');
            }
        } else if (landing_dv === 2) {
            if(!contents || contents == '<p><br></p>') { 
                return toastMsg('내용을 입력하세요.');
            }
        }

        if (!file_url) {
            return toastMsg('이벤트 배너 배경을 등록하세요.');
        }

        let st_time = infinite_time === 'Y' ? '1900-01-01 00:00:00' : st_sel == 1 ? formatDate2(stdate) + ' ' + formatTime(sttime) + ':00' : null;
        let ed_time = infinite_time === 'Y' ? '9999-12-31 00:00:00' : ed_sel == 1 ? formatDate2(eddate) + ' ' + formatTime(edtime) + ':00' : null;
        
        if(!st_time && !ed_time && st_time > ed_time) {
            return toastMsg('종료 일시는 시작 일시 이후로 설정하세요.');
        }

        const params = {
            eventId, title, contents, description, expo, status, st_time, ed_time, file_url, url, landing_dv
        }

        console.log(params);
        requestPost('updateEvent', params, (res) => {
            if(Boolean(res)) {
                if(!eventId) {
                  setTimeout(() => {
                    this.gotoPage(-1);
                  }, 100);
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }
    onConfirm = () => {
        const {eventId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        requestPost('deleteEvent', {eventId}, (res) => {
            if(Boolean(res)) {
                this.gotoPage(-1);
            }
            else {
                toastMsg('삭제에 실패하였습니다.');
            }
        });
    }

    copyLink = () => {
        const { eventId } = this.state;
        const url = consts.domain + 'event?id=' + eventId
        copyToClipboard(url);
        toastMsg('복사되었습니다.', 'success');
    }

    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        this.setState({[name]: value});

        if (name === 'infinite_time' && value === 'N') {
            this.setState({stdate: new Date(), sttime: new Date(), eddate: new Date(), edtime: new Date()})
        }
    }

    render() {
        const { file_url, infinite_time, stdate, eddate, sttime, edtime, st_sel, ed_sel, url, landing_dv, eventId, confirmTitle, confirmTxt, modalIdx, status,
            title, contents, description, expo, hits, reg_time, email, user_name } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailJobpaper'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{!Boolean(eventId) ? "이벤트 등록" : "이벤트 상세"}</div>
                            <div className='fralign_center' style={{ gap: 10 }}>
                                {Boolean(eventId) || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)} >취소</div>}
                                {Boolean(eventId) || <div className='btn_normal' onClick={this.update}>등록</div>}
                                {Boolean(eventId) && <div className='btn_normal' onClick={this.update}>저장</div>}
                            </div>
                        </div>
                        <div className='card_body'>
                            {Boolean(eventId) > 0 && (<table className='alignleft'>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{eventId}</td>
                                        <th>조회수</th>
                                        <td>{formatNum(hits)}</td>
                                    </tr>
                                    <tr>
                                        <th>등록자</th>
                                        <td>{user_name} ({email})</td>
                                        <th>등록일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                </tbody>
                            </table>)}
                            {Boolean(eventId) && (<div className='card_row'>
                                <label className='field_label'>페이지 URL</label>
                                <div className='card_row_val'>
                                    <label>페이지 URL</label>
                                    <div className='btn_normal' onClick={this.copyLink}>링크 복사</div>
                                </div>
                            </div>)}
                            <div className='card_row'>
                                <label className='field_label'>제목</label>
                                <div className='card_row_val'>
                                    <input type="text" className='input_box' placeholder='제목을 입력하세요.' style={{ width: 400 }} value={title} onChange={({target}) => this.setState({title: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>부제</label>
                                <div className='card_row_val'>
                                    <input type={'text'} style={{width:'100%'}} placeholder="한 줄 설명을 입력하세요." value={description ? description : ''} onChange={({target}) => this.setState({description: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>게시상태</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({ status: 1 }) }><input type="radio" name="status" checked={status == 1} onChange={() => { }} />노출</label>
                                    <label className='radio_label' onClick={() => this.setState({ status: 2 }) }><input type="radio" name="status" checked={status == 2} onChange={() => { }} />비노출</label>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>랜딩구분</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({ landing_dv: 1 }) }><input type="radio" name="landing_dv" checked={landing_dv == 1} onChange={() => { }} />URL</label>
                                    <label className='radio_label' onClick={() => this.setState({ landing_dv: 2 }) }><input type="radio" name="landing_dv" checked={landing_dv == 2} onChange={() => { }} />에디터</label>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>게시 기간 설정</label>
                                <div className='card_row_val'>
                                    <select value={infinite_time} onChange={this.handleInputChange} name="infinite_time">
                                        <option value={'Y'}>무제한</option>
                                        <option value={'N'}>게시일 지정</option>
                                    </select>
                                </div>
                            </div>
                            {
                                infinite_time === 'N' ?
                                <div className='card_row'>
                                    <div className='card_row_val'>
                                        <div className='fcjust_center' style={{gap:10}}>
                                            <div className='fralign_center' style={{gap:10}}>
                                                <select value={st_sel} onChange={this.handleInputChange} name="st_sel">
                                                    <option value={1}>시작일시 지정</option>
                                                    <option value={2}>게시 즉시</option>
                                                </select>
                                                {st_sel == 1 &&
                                                <>
                                                <Datepicker selected={stdate} onChange={(date) => this.setState({ stdate: date })} maxDate={eddate} />
                                                <Timepicker selected={sttime} onChange={(val) => this.setState({ sttime: val })} />
                                                </>}
                                            </div>
                                            <div className='fralign_center' style={{gap:10}}>
                                                <select value={ed_sel} onChange={this.handleInputChange} name="ed_sel">
                                                
                                                <>
                                                <option value={1}>종료일시 지정</option>
                                                <option value={2}>무제한</option>
                                                </>
                                                    
                                                </select>
                                                {(ed_sel == 1) &&
                                                <>
                                                <Datepicker selected={eddate} onChange={(date) => this.setState({ eddate: date })} 
                                                    minDate={stdate} 
                                                    // maxDate={type == 'P' ? prod_expire_time : null} 
                                                />
                                                <Timepicker selected={edtime} onChange={(val) => this.setState({ edtime: val })} />
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : 
                                null
                            }
                            {
                                landing_dv === 1 ?
                                <>
                                <div className='card_row'>
                                    <label className='field_label'>URL</label>
                                    <div className='card_row_val'>
                                        <input type={'text'} style={{width:'100%'}} placeholder="URL을 입력하세요." value={url ? url : ''} onChange={({target}) => this.setState({url: target.value})} />
                                    </div>
                                </div>
                                </>
                                : 
                                <>
                                    <div className='card_row'>
                                        <label className='field_label'>내용</label>
                                        <div className='card_row_val'>
                                            <div className='btn_normal' onClick={() => $('input[name=thumb]').trigger('click')}>이미지 추가</div>
                                            <span className='gray-text fs-11 ms-2 mt--2'>png, jpg 파일, 1280px X 672px 권장</span>
                                            <input type="file" name="thumb" onChange={(e) => this.handleFileChange(e)} className="d-none" accept="image/*" />
                                        </div>
                                    </div>
                                    <div className='fralign_center w-100' >
                                        <SmartEditor ref={(val) => this.editorref = val} editorid={"class_intro"} value={contents ? contents : ''} 
                                            onChange={(val) => {}} 
                                        />
                                    </div>
                                </>
                            }

                            <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box', gap:20 }}>
                                <div className='fralign_center' style={{gap:20}}>
                                    <div className='btn_normal' onClick={() => $('input[name=file_url]').trigger('click')}>PC용 이미지 등록</div>
                                    <span className='gray-text fs-11 mx-2 mt--2'>png, jpg 파일, 1280px X 300px 권장</span>
                                    <input type="file" name="file_url" onChange={(e) => this.handleUploadFileChange(e, 'file_url')} className="d-none" accept="image/*" />
                                </div>
                                {Boolean(file_url) &&
                                    <div>
                                        <label className='font_D400_14'>이미지 미리보기</label>
                                        <br />
                                        <img src={consts.resourcUrl + file_url} className='pc_img_bg' />
                                    </div>}
                            </div>
                            {Boolean(eventId) && (<div className='fr_c_end w-100' style={{ padding: '20px 0px' }}>
                                <div className='btn_remove' onClick={() => this.setState({confirmTitle: "게시물 삭제", confirmTxt: "게시물을 삭제하시겠습니까?", modalIdx: 1})}>게시물 삭제</div>
                            </div>)}
                        </div>
                    </div>
                </div>
                {modalIdx == 1 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailEvent);