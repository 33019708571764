import './crm.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailReviewer extends React.Component {
    constructor() {
        super();
        this.state = {
            surveyId:    0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        const surveyId  = searchParams.get("id");
        // 
        this.getData(surveyId);
    }

    getData = (surveyId) => {
        window.scrollTo(0, 0);
        this.setState({surveyId});
    
        if(surveyId > 0) {
            requestPost('getSurveyDetail', {surveyId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, type, user_name, phone, user_phone, email, review, mark, answer, status, reg_time, nickname, user_email } = res;
        
                    this.setState({ user_id, type, user_name, phone, user_phone, email, review, mark, answer, status, reg_time, nickname, user_email });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }

    onConfirm = () => {
        const {modalIdx, surveyId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        const status = modalIdx == 10 ? 2 : 1
        const params = {
            surveyId, status
        }
        requestPost('updateSurvey', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.setState({status})
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { surveyId, confirmTitle, confirmTxt, modalIdx,
            user_id, type, user_name, phone, user_phone, email, review, mark, answer, status, reg_time, nickname, user_email } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailreviewer'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>평가 및 리뷰 상세</div>
                            <div className='fralign_center' style={{ gap: 20 }}>
                                {status == 1 && <div className='btn_normal' onClick={() => this.setState({confirmTitle: "평가 및 리뷰 상세", confirmTxt: "답변완료 상태로 변경하시겠습니까?", modalIdx: 10})}>답변완료로 처리</div>}
                                {status == 2 && <div className='btn_normal' onClick={() => this.setState({confirmTitle: "평가 및 리뷰 상세", confirmTxt: "미답변 상태로 변경하시겠습니까?", modalIdx: 11})}>미답변으로 처리</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{ padding: 10 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{surveyId}</td>
                                    </tr>
                                    <tr>
                                        <th>평가 구분</th>
                                        <td>{type == 1 ? '서비스평가' : '추천평가'}</td>
                                    </tr>
                                    {Boolean(user_id) ?
                                    <tr>
                                        <th>작성자</th>
                                        <td className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{nickname}</td>
                                    </tr>
                                    :
                                    <tr>
                                        <th>이름</th>
                                        <td >{user_name}</td>
                                    </tr>}
                                    <tr>
                                        <th>휴대전화번호</th>
                                        <td>{phone ? phone : user_phone}</td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>{email ? email : user_email}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>답변여부</th>
                                        <td>{status == 2 ? '답변완료' : '미답변'}</td>
                                    </tr>
                                    <tr>
                                        <th>평가</th>
                                        <td>{mark}</td>
                                    </tr>
                                    <tr>
                                        <th>리뷰</th>
                                        <td className='pre-wrap'>{review}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailReviewer);