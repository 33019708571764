import './report.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import NotoSansKRBold from '../../assets/NotoSansKR-Bold.otf';
import NotoSansKRRegular from '../../assets/NotoSansKR-Regular.otf';

Font.register({ family: "NotoSansKR_Blod", src: NotoSansKRBold });
Font.register({ family: "NotoSansKR_Regular", src: NotoSansKRRegular });
export const UserData = [
    { id: 1, year: 2016, userGain: 80000, userLost: 823 }, { id: 2, year: 2017, userGain: 45677, userLost: 345 }, { id: 3, year: 2018, userGain: 78888, userLost: 555 }, { id: 4, year: 2019, userGain: 90000, userLost: 4555 }, { id: 5, year: 2020, userGain: 4300, userLost: 234 },
    { id: 6, year: 2016, userGain: 80000, userLost: 823 }, { id: 7, year: 2017, userGain: 45677, userLost: 345 }, { id: 8, year: 2018, userGain: 78888, userLost: 555 }, { id: 9, year: 2019, userGain: 90000, userLost: 4555 }, { id: 10, year: 2020, userGain: 4300, userLost: 234 },
    { id: 11, year: 2016, userGain: 80000, userLost: 823 }, { id: 12, year: 2017, userGain: 45677, userLost: 345 }, { id: 13, year: 2018, userGain: 78888, userLost: 555 }, { id: 14, year: 2019, userGain: 90000, userLost: 4555 }, { id: 15, year: 2020, userGain: 4300, userLost: 234 },
    { id: 16, year: 2016, userGain: 80000, userLost: 823 }, { id: 17, year: 2017, userGain: 45677, userLost: 345 }, { id: 18, year: 2018, userGain: 78888, userLost: 555 }, { id: 19, year: 2019, userGain: 90000, userLost: 4555 }, { id: 20, year: 2020, userGain: 4300, userLost: 234 },
    { id: 21, year: 2016, userGain: 80000, userLost: 823 }, { id: 22, year: 2017, userGain: 45677, userLost: 345 }, { id: 23, year: 2018, userGain: 78888, userLost: 555 }, { id: 24, year: 2019, userGain: 90000, userLost: 4555 }, { id: 25, year: 2020, userGain: 4300, userLost: 234 },
    { id: 26, year: 2016, userGain: 80000, userLost: 823 }, { id: 27, year: 2017, userGain: 45677, userLost: 345 }, { id: 28, year: 2018, userGain: 78888, userLost: 555 }, { id: 29, year: 2019, userGain: 90000, userLost: 4555 }, { id: 30, year: 2020, userGain: 4300, userLost: 234 },
    { id: 31, year: 2016, userGain: 80000, userLost: 823 }, { id: 32, year: 2017, userGain: 45677, userLost: 345 }, { id: 33, year: 2018, userGain: 78888, userLost: 555 }, { id: 34, year: 2019, userGain: 90000, userLost: 4555 }, { id: 35, year: 2020, userGain: 4300, userLost: 234 },
]

class PdfViewAdvert extends React.Component {
    constructor() {
        super();
        this.state = {
            curPage: 0,
            totalPage: 16,
        };
    }

    componentDidMount() {
        // this.initChart();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    setCurPage = (val) => {
        this.setState({ curPage: val })
    }

    initChart = () => {
        var t_x_data = UserData.map((item) => item.id);
        var t_pick_data = UserData.map((item) => item.userLost);
        var t_user_data = UserData.map((item) => item.userGain);
        var t_spline_data = UserData.map((item) => item.year);
        window.Highcharts.chart('pick_chart', {
            title: { text: '' },
            subtitle: { text: '' },
            xAxis: {
                categories: t_x_data,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: { text: '' }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.3,
                    borderWidth: 0,
                    borderRadius: 4,
                },
                series: {
                    states: {
                        hover: {
                            color: '#22BDC2',
                        }
                    }
                }
            },
            series: [{
                type: 'column',
                name: '',
                showInLegend: false,
                data: t_pick_data
            },
            {
                type: 'column',
                name: '',
                showInLegend: false,
                data: t_user_data
            }, {
                type: 'spline',
                name: '',
                showInLegend: false,
                data: t_spline_data,
                marker: {
                    lineWidth: 2,
                    lineColor: 'red',
                    fillColor: 'white'
                }
            }
            ]
        });
    }

    render() {
        // const { searchParams } = this.props.router;
        // var viewid = searchParams.get("viewid");
        const { curPage, totalPage } = this.state;
        var f_page_num = curPage - curPage % 10;
        var l_page_num = f_page_num + 10;
        if (l_page_num >= totalPage) {
            l_page_num = totalPage;
        }
        return (
            <Leftmenu>
                <div className='page_contents detailMain' style={{height:'calc(100vh - 100px)'}}>
                    <PDFViewer style={{width:'100%', flex:1}}>
                        <Document>
                            <Page size="A4" orientation="landscape" style={styles.page}>
                                <View style={styles.card}>
                                    <View style={styles.card_head}>
                                        <Text style={styles.card_head_title}>광고 성과 리포트 상세</Text>
                                    </View>
                                    <View style={styles.card_body}>
                                        <View style={styles.body_head}>
                                            <Text style={styles.body_head_title}>기본 정보</Text>
                                            <Text style={styles.body_text}>2023-02-03 00:00 기준</Text>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {width:100, alignItems:'flex-start'}]}>
                                                    <Text >ID</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text >00000000</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {width:100}]}>
                                                    <Text>배너/상품명</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>제목</Text>
                                                </View>
                                                <View style={[styles.th, {width:100}]}>
                                                    <Text>게시상태</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>게시중</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {width:100}]}>
                                                    <Text>광고 유형</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>광고 배너</Text>
                                                </View>
                                                <View style={[styles.th, {width:100}]}>
                                                    <Text>게시위치</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>영역1, 영역3</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {width:100}]}>
                                                    <Text>게시 기간</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>2023-01-31 ~ 무제한</Text>
                                                </View>
                                                <View style={[styles.th, {width:100}]}><Text>실 게시일시</Text></View>
                                                <View style={[styles.td, {flex:1}]}><Text>23일 12시 22분</Text></View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.card_body}>
                                        <View style={styles.body_head}>
                                            <Text style={styles.body_head_title}>누적 통계</Text>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>노출 수</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>클릭 수</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>클릭율</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>30,000</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>6,000</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text>20%</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={[styles.card_body, {gap:10}]}>
                                        <View style={styles.body_head}>
                                            <Text style={styles.body_head_title}>유입 사용자 통계</Text>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {width:100, textAlign:'center'}]}>
                                                    <Text>나이대</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>15 미만</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>15-19</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>20-24</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>25-29</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>30-34</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>35-39</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>40-44</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>45-49</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>50-54</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>55-59</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>60-64</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>65 이상</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:100}]}>
                                                    <Text>노출수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:100}]}>
                                                    <Text>클릭수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:100}]}>
                                                    <Text>클릭율(노출대비클릭, %)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:100, }]}>
                                                    <Text>전체 클릭수 중 비율(%)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>성별</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>여성</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>남성</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>노출수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>클릭수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>클릭율(노출대비클릭, %)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>전체 클릭수 중 비율(%)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>고객 유형</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>재직자</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>구직자</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, textAlign:'center'}]}>
                                                    <Text>학생</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>노출수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>클릭수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>클릭율(노출대비클릭, %)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text>전체 클릭수 중 비율(%)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.table}>
                                            <View style={styles.table_row}>
                                                <View style={[styles.th, {width:70, }]}>
                                                    <Text>직무</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>프론트엔드</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>백엔드</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>풀스택</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>웹퍼블리셔</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>안드로이드 개발</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>iOS 개발</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>데이터 엔지니어</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>데이터 사이언티스트</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>데이터 분석가</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>데이터 베이스 관리자(DBA)</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>UIUX 디자이너</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>서비스기획</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>디지털 마케터</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1,}]}>
                                                    <Text>IoT/로봇 엔지니어</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>클라우드 엔지니어</Text>
                                                </View>
                                                <View style={[styles.th, {flex:1, }]}>
                                                    <Text>AR/VR 엔지니어</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:70, }]}>
                                                    <Text>노출수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:70, }]}>
                                                    <Text>클릭수</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1,}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1,}]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:70, }]}>
                                                    <Text>클릭율(노출대비클릭, %)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                            <View style={styles.table_row}>
                                                <View style={[styles.td, {width:70, }]}>
                                                    <Text>전체 클릭수 중 비율(%)</Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1, }]}>
                                                    <Text></Text>
                                                </View>
                                                <View style={[styles.td, {flex:1}]}>
                                                    <Text></Text>
                                                </View>
                                            </View>
                                        </View>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>학습방식</th>
                                                    <th>실시간 온라인 교육</th>
                                                    <th>오프라인 교육</th>
                                                    <th>이러닝 교육</th>
                                                    <th>블렌디드 교육</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr>
                                                    <td>노출수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭율(노출대비클릭, %)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>전체 클릭수 중 비율(%)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>관심정보</th>
                                                    <th>취업지원</th>
                                                    <th>현업멘토링/특강</th>
                                                    <th>코딩 지원</th>
                                                    <th>프로젝트 중심</th>
                                                    <th>자료제공</th>
                                                    <th>훈련비/포상지원</th>
                                                    <th>팀스터디</th>
                                                    <th>국비지원</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr>
                                                    <td>노출수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭율(노출대비클릭, %)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>전체 클릭수 중 비율(%)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>교육 난이도</th>
                                                    <th>입문</th>
                                                    <th>초급</th>
                                                    <th>중고급</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr>
                                                    <td>노출수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭율(노출대비클릭, %)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>전체 클릭수 중 비율(%)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>지역</th>
                                                    <th>서울</th>
                                                    <th>부산</th>
                                                    <th>대구</th>
                                                    <th>인천</th>
                                                    <th>광주</th>
                                                    <th>대전</th>
                                                    <th>울산</th>
                                                    <th>세종</th>
                                                    <th>경기</th>
                                                    <th>강원</th>
                                                    <th>충북</th>
                                                    <th>충남</th>
                                                    <th>전북</th>
                                                    <th>전남</th>
                                                    <th>경북</th>
                                                    <th>경남</th>
                                                    <th>제주</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr>
                                                    <td>노출수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭수</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>클릭율(노출대비클릭, %)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>전체 클릭수 중 비율(%)</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </View>
                                    <View style={styles.card_body}>
                                        <View style={styles.body_head}>
                                            <Text style={styles.body_head_title}>일별 그래프</Text>
                                        </View>
                                        <div style={{ height: 300, width: '100%' }} id="pick_chart"></div>
                                    </View>
                                    <View style={styles.card_body}>
                                        <View style={styles.body_head}>
                                            <Text style={styles.body_head_title}>일별 통계 상세</Text>
                                        </View>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>기준일</th>
                                                    <th>게시시간</th>
                                                    <th>노출수</th>
                                                    <th>클릭수</th>
                                                    <th>클릭율</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2023-01-31</td>
                                                    <td>24:00:00</td>
                                                    <td>1,234</td>
                                                    <td>522</td>
                                                    <td>42%</td>
                                                </tr>
                                                <tr>
                                                    <td>2023-01-31</td>
                                                    <td>24:00:00</td>
                                                    <td>1,234</td>
                                                    <td>522</td>
                                                    <td>42%</td>
                                                </tr>
                                                <tr>
                                                    <td>2023-01-31</td>
                                                    <td>24:00:00</td>
                                                    <td>1,234</td>
                                                    <td>522</td>
                                                    <td>42%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='pagenation'>
                                            <div className='page_nav_btn page_first_btn' onClick={() => { this.setCurPage(0) }} />
                                            <div className='page_nav_btn page_prev_btn' onClick={() => { if (curPage > 0) { this.setCurPage(curPage - 1) } }} />
                                            {[...Array(10)].map((item, index) => f_page_num + index < l_page_num ? (<div key={"page_" + index} className={f_page_num + index === curPage ? 'page_act_btn' : 'page_nav_btn'} onClick={() => { this.setCurPage(f_page_num + index) }}>{f_page_num + index + 1}</div>) : (<></>))}
                                            <div className='page_nav_btn page_next_btn' onClick={() => { if (curPage < totalPage - 1) { this.setCurPage(curPage + 1) } }} />
                                            <div className='page_nav_btn page_last_btn' onClick={() => { this.setCurPage(totalPage - 1) }} />
                                        </div>
                                    </View>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                </div>
            </Leftmenu>
        );
    }
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        width:'100%',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    card: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 20,
        gap: 20,
        width: '100%',
        backgroundColor: '#FFFFFF',
    },
    card_head: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
        width: '100%',
    },
    card_head_title:{
        fontFamily: 'NotoSansKR_Blod',
        fontSize: 18,
        textAlign: 'center',
        color: '#262327',
    },
    card_body:{
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid #EEEEEE', 
        padding: 10, 
        gap:10
    },
    body_head:{
        width: '100%', 
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body_head_title:{
        fontFamily: 'NotoSansKR_Blod',
        fontSize: 14,
        color: '#262327',
    },
    body_text:{
        fontFamily: 'NotoSansKR_Regular',
        fontSize: 12,
        color: '#262327',
    },
    table:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        border:'0.5px solid #666666',
    },
    table_row:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        alignItems:'stretch',
    },
    th:{
        border:'0.5px solid #666666',
        padding:5,
        backgroundColor:'#F1F1F1',
        color: '#666666',
        fontFamily: 'NotoSansKR_Regular',
        fontSize: 8,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        flexWrap:'wrap'
    },
    td:{
        border:'0.5px solid #666666',
        padding: 5,
        fontFamily: 'NotoSansKR_Regular',
        fontSize: 8,
        color: '#262327',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        flexWrap:'wrap'
    }
});

export default withRouter(PdfViewAdvert);