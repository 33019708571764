import React from 'react'
import { connect } from "react-redux";
import './home.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';
import { formatNum, updateAdminPerm, updateMyAlarm } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {}, 
      chartData1: [], 
      chartData2: [], 
    };
  }

  componentDidMount(){
    setTimeout(() => {
      this.initDatas();
    }, 300);  
    // const { authacc, setauthinf } = this.props;
  }
  initDatas = () => {
    updateAdminPerm();
    updateMyAlarm();

    requestPost('getDashboard', {}, (res) => {
      if(Boolean(res)) {
        const {data, chartData1, chartData2} = res;
        this.setState({data, chartData1, chartData2});
        // 
        this.initChart(chartData1, chartData2);
      }
    });
  }
  initChart = (chartData1, chartData2) => {
    var t_x_data = chartData1.map((item) => item.label);
    var t_y_data = chartData1.map((item) => item.value);
    window.Highcharts.chart('pick_chart', {
      chart: {
        type: 'column'
      },
      title: { text: '' },
      subtitle: { text: '' },
      xAxis: {
        categories: t_x_data,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: { text: '' }
      },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0,
          borderRadius: 4,
        },
        series: {
          states: {
            hover: {
              color: '#22BDC2',
            }
          }
        }
      },
      series: [{
        name: '',
        showInLegend: false,
        data: t_y_data
      }
      ]
    });

    var t_x_data = chartData2.map((item) => item.label);
    var t_y_data = chartData2.map((item) => item.value);
    window.Highcharts.chart('new_join_chart', {
      chart: {
        type: 'column'
      },
      title: { text: '' },
      subtitle: { text: '' },
      xAxis: {
        categories: t_x_data,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: { text: '' }
      },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0,
          borderRadius: 4,
        },
        series: {
          states: {
            hover: {
              color: '#22BDC2',
            }
          }
        }
      },
      series: [{
        name: '',
        showInLegend: false,
        data: t_y_data
      }
      ]
    });
  }

  render() {
    const { pick_cnt1, user_cnt1, prod_cnt1, visit_cnt1, page_cnt1, pick_cnt2, user_cnt2, prod_cnt2, visit_cnt2, page_cnt2, pick_cnt3, user_cnt3, prod_cnt3, visit_cnt3, page_cnt3, 
      active_prod_cnt, partner_cnt1, partner_cnt2, banner_cnt1, banner_cnt2 } = this.state.data || {};

    return (
      <Leftmenu>
        <div className='page_contents dashboard'>
          <div className='card'>
            <table>
              <tbody>
                <tr>
                  <th></th>
                  <td>PICK</td>
                  <td>신규 가입</td>
                  <td>교육 상품 등록</td>
                  <td>방문(visit)</td>
                  <td>페이지뷰(pageview)</td>
                </tr>
                <tr>
                  <th>오늘</th>
                  <td>{formatNum(pick_cnt1)}</td>
                  <td>{formatNum(user_cnt1)}</td>
                  <td>{formatNum(prod_cnt1)}</td>
                  <td>{formatNum(visit_cnt1)}</td>
                  <td>{formatNum(page_cnt1)}</td>
                </tr>
                <tr>
                  <th>전일</th>
                  <td>{formatNum(pick_cnt2)}</td>
                  <td>{formatNum(user_cnt2)}</td>
                  <td>{formatNum(prod_cnt2)}</td>
                  <td>{formatNum(visit_cnt2)}</td>
                  <td>{formatNum(page_cnt2)}</td>
                </tr>
                <tr>
                  <th>당월 누적</th>
                  <td>{formatNum(pick_cnt3)}</td>
                  <td>{formatNum(user_cnt3)}</td>
                  <td>{formatNum(prod_cnt3)}</td>
                  <td>{formatNum(visit_cnt3)}</td>
                  <td>{formatNum(page_cnt3)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='card'>
            <table>
              <thead>
                <tr>
                  <th>모집 중인 교육</th>
                  <th>전체 파트너사</th>
                  <th>신청 파트너사</th>
                  <th>활성 광고 배너</th>
                  <th>활성 광고 상품</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>{formatNum(active_prod_cnt)}</td>
                <td>{formatNum(partner_cnt1)}</td>
                <td>{formatNum(partner_cnt2)}</td>
                <td>{formatNum(banner_cnt1)}</td>
                <td>{formatNum(banner_cnt2)}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className='card'>
            <div className='card_head'>
              <div className='chart_title'>최근 3개월간 일별 PICK</div>
            </div>
            <div className='card_body'>
              <div style={{ height: 300, width: '100%' }} id="pick_chart"></div>
            </div>
          </div>
          <div className='card'>
            <div className='card_head'>
              <div className='chart_title'>최근 3개월간 신규 가입자수</div>
            </div>
            <div className='card_body'>
              <div style={{ height: 300, width: '100%' }} id="new_join_chart"></div>
            </div>
          </div>
        </div>
      </Leftmenu>
    );
  }
}

function mapStateToProps(state) {
  return {
    authacc: state.authacc
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    // setauthinf: (val) => dispatch(setauthinf(val)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));