import './datepicker.css'
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko)

function Timepicker({ selected, onChange, disabled=false }) {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input type="text" className='input_Time' readOnly placeholder='HH:mm' value={value} onClick={onClick} ref={ref} />
    ));
    return (
        <div style={{ width: 'min-content' }} className={disabled ? 'disabled' : ''}>
            <DatePicker
                disabled={disabled}
                selected={selected}
                onChange={onChange}
                customInput={<ExampleCustomInput />}
                dateFormat="HH:mm"
                locale="ko"
                showTimeSelect
                showTimeSelectOnly
                // timeIntervals={15}
                timeCaption="시간"
            />
        </div>
    );
}

export default Timepicker;