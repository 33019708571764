import './other.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';
import consts from '../../libs/consts';

class OtherEvent extends React.Component {
  constructor() {
    super();
    this.state = {
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, status } = this.state;
    const params = { keyword, status, currentPage, type: 'A' }
    requestPost('getEventList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  gotoPage = (link)=>{
    const { router } = this.props;
    router.navigate(link);
  }

  render() {
    const { totalCnt, currentPage, dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents othjobpaper'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>이벤트</div>
            </div>
            <div className='card_head'>
              <div />
              <div className='btn_normal' onClick={()=> {this.gotoPage('/other/event/detail')}}>신규 이벤트 등록</div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>게시상태</th>
                  <th>랜딩구분</th>
                  <th>제목</th>
                  <th>부제</th>
                  <th>이미지</th>
                  <th>작성일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={() => this.gotoPage('/other/event/detail?id='+item.id)} className='pointer'>
                    <td>{item.id}</td>
                    <td>{item.status == 1 ? '게시' : '미게시'}</td>
                    <td>{item.landing_dv == 1 ? 'URL' : '에디터'}</td>
                    <td>{item.title}</td>
                    <td>{item.description}</td>
                    <td>
                      <img src={consts.resourcUrl + item.file_url} className='banner_img' />
                    </td>
                    <td>{formatDate(item.reg_time)}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(OtherEvent);