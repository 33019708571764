import React from 'react';
import './modal.css'
import Modal from 'react-modal';
import { useState } from "react";

import {requestPost} from '../../libs/request';
import withRouter from '../withRouter';
import Pagination from '../Pagination';
import Nodata from '../../components/Nodata';
import { formatDate, formatNum, toastMsg, renderProductStatus } from '../../libs/utils';

Modal.setAppElement('#root');
class SelectProdModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId:    this.props.productId,
      keyword:      '',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
      product: {}
    };
  }

  componentDidMount = () => {
    // 
    this.getData();
  }
  
  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword } = this.state;
    const params = { keyword, per_page: 8, currentPage }
    requestPost('getProductList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }
  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }

  render() {
    const { productId, keyword, totalCnt, currentPage, dataList, product } = this.state;

    return (
        <Modal
            isOpen={true}
            // onAfterOpen={this.props.afterOpenModal}
            onRequestClose={this.props.closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 1000, height: '80vh', padding: 0 }
            }}
        >
            <div className='card edukeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>상품 입력</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={this.props.closeModal} />
                    </div>
                </div>
                <div className='search_input' style={{ width: 600 }}>
                    <input type="text" placeholder='상품ID, 상품명, 교육사명으로 검색' 
                        value={keyword} 
                        onKeyUp={this.handleKeyPress} 
                        onChange={e => this.setState({keyword: e.target.value}) } 
                    />
                    <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
                <div className='card_body'>
                    <table>
                        <thead>
                            <tr>
                                <th>상품 ID</th>
                                <th>교육상품명</th>
                                <th>교육사명</th>
                                <th>모집마감일시</th>
                                <th>모집상태</th>
                                <th>노출상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                            dataList.length > 0 ? dataList.map((item, index) =>
                            <tr key={index} onClick={() => this.setState({productId: item.id, product: item})} className={productId == item.id ? 'sel_item' : ''}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.user_name}</td>
                                <td>{formatDate(item.expire_time)}</td>
                                <td>{item.collect ? '모집중' : '모집종료'}</td>
                                <td>{renderProductStatus(item.status)}</td>
                            </tr>
                            )
                            :
                            <Nodata colSpan={6} />
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={this.props.closeModal}>취소</div>
                    <div className='btn_normal' onClick={() => {
                        if(!productId) {
                            return toastMsg('상품을 선택하세요');
                        }
                        this.props.selectDatas && this.props.selectDatas(product);
                        this.props.closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
        );
    }
}

export default withRouter(SelectProdModal);