import './crm.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum, renderReportReason } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailReport extends React.Component {
    constructor() {
        super();
        this.state = {
            reportId:     0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            blind_txt1:   '',
            user_status:  'N',
            blind:        0,
            stop_day:     0,
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const reportId  = searchParams.get("id");
        // 
        this.getData(reportId);
    }

    getData = (reportId) => {
        window.scrollTo(0, 0);
        this.setState({reportId});
    
        if(reportId > 0) {
            requestPost('getReportDetail', {reportId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, admin_id, target_id, cmt_id, cmt_type, type, reason, reason_txt, memo, status, blind, blind_txt, update_time, reg_time, nickname, nickname2, user_name, email, user_status, title, content, stop_day } = res;
        
                    this.setState({ user_id, admin_id, target_id, cmt_id, cmt_type, type, reason, reason_txt, memo, status, blind, blind_txt, update_time, reg_time, nickname, nickname2, user_name, email, user_status, title, content, stop_day });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }

    onConfirm = () => {
        const {modalIdx, reportId, blind, blind_txt1, user_status, stop_day, user_id, target_id, cmt_id, cmt_type, type} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        if(modalIdx == 11 && user_status == 'T' && stop_day == 0) {  // 계정 이용 정지
            return toastMsg('일시정지일을 입력하세요.');
        }

        const params = {
            modalIdx, reportId, blind, blind_txt: blind_txt1, user_status, stop_day, user_id, target_id, cmt_id, cmt_type, type
        }
        requestPost('updateReport', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.getData(reportId);
                this.setState({blind_txt1: ''})
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { reportId, confirmTitle, confirmTxt, modalIdx,
            user_id, target_id, type, reason, reason_txt, memo, status, blind, blind_txt, blind_txt1, update_time, reg_time, nickname, nickname2, user_name, email, user_status, title, content, stop_day } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailreport'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>신고 상세</div>
                        </div>
                        {type != 1 && (<div className='card_body' style={{ padding: 10 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td colSpan={3}>{reportId}</td>
                                    </tr>
                                    <tr>
                                        <th>신고 유형</th>
                                        <td colSpan={3}>{type == 2 ? '게시글 신고' : '댓글/대댓글'}</td>
                                    </tr>
                                    <tr>
                                        <th>신고자</th>
                                        <td colSpan={3} className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>신고사유</th>
                                        <td colSpan={3}>{renderReportReason(reason, type, reason_txt)}</td>
                                    </tr>
                                    <tr>
                                        <th>신고일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                        <th>처리일시</th>
                                        <td>{formatDate(update_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>처리상태</th>
                                        <td>{status == 1 ? '처리전' : '처리완료'}</td>
                                        <th>처리 담당자</th>
                                        <td>{Boolean(user_name) ? `${user_name}(${email})` : '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>블라인드 여부</th>
                                        <td colSpan={3}>블라인드 {blind == 0 ? '미' : ''}처리</td>
                                    </tr>
                                    <tr>
                                        <th>블라인드 사유</th>
                                        <td className='pre-wrap' colSpan={3}>{blind_txt}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>게시글ID</th>
                                        <td className='cursor_pointer' onClick={() => this.gotoPage('/community/list/detail?id=' + target_id)}>{target_id}</td>
                                    </tr>
                                    <tr>
                                        <th>제목</th>
                                        <td>{title}</td>
                                    </tr>
                                    <tr>
                                        <th>글 내용</th>
                                        <td>{content}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='fr_c_end' style={{ width: '100%', padding: '20px 0px 10px 0px' }}>
                                <div className='btn_normal' style={{ width: 150 }} onClick={() => this.setState({confirmTitle: "블라인드 처리", confirmTxt: "신고 정보를 처리 완료하시겠습니까?", modalIdx: 10})}>처리 완료로 변경</div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>블라인드 처리</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({blind:0})}><input type="radio" name="blind" checked={blind == 0} onChange={()=>{}}/>적용하지 않음</label>
                                    <label className='radio_label' onClick={() => this.setState({blind:1})}><input type="radio" name="blind" checked={blind == 1} onChange={()=>{}}/>게시물 블라인드 처리</label>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>사유 작성</label>
                                <div className='card_row_val'>
                                    <textarea style={{ width: '100%', height: 100 }} placeholder={"사유를 입력하세요."} value={blind_txt1} onChange={({target}) => this.setState({blind_txt1: target.value})} />
                                </div>
                            </div>
                        </div>)}
                        {type == 1 && (<div className='card_body' style={{ padding: 10 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td colSpan={3}>{reportId}</td>
                                    </tr>
                                    <tr>
                                        <th>신고 유형</th>
                                        <td colSpan={3}>사용자</td>
                                    </tr>
                                    <tr>
                                        <th>신고자</th>
                                        <td colSpan={3} className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>피신고자</th>
                                        <td colSpan={3} className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + target_id)}>{nickname2}</td>
                                    </tr>
                                    <tr>
                                        <th>신고사유</th>
                                        <td colSpan={3}>{renderReportReason(reason, type, reason_txt)}</td>
                                    </tr>
                                    <tr>
                                        <th>신고일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                        <th>처리일시</th>
                                        <td>{formatDate(update_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>처리상태</th>
                                        <td>{status == 1 ? '처리전' : '처리완료'}</td>
                                        <th>처리 담당자</th>
                                        <td>{Boolean(user_name) ? `${user_name}(${email})` : '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>계정 이용 정지</th>
                                        <td colSpan={3}>{user_status == 'T' ? `일시 정지 (${stop_day})` : (user_status == 'S' ? '영구정지' : '') } </td>
                                    </tr>
                                    <tr>
                                        <th>사유</th>
                                        <td className='pre-wrap' colSpan={3}>{blind_txt}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='fr_c_end' style={{ width: '100%', padding: '20px 0px 10px 0px' }}>
                                <div className='btn_normal' style={{ width: 150 }} onClick={() => this.setState({confirmTitle: "계정 이용 정지", confirmTxt: "신고 정보를 처리 완료하시겠습니까?", modalIdx: 11})}>처리 완료로 변경</div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>계정 이용 정지</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({user_status:''})}><input type="radio" name="user_status" checked={user_status != 'T' && user_status != 'S'} onChange={()=>{}}/>적용하지 않음</label>
                                    <label className='radio_label' onClick={() => this.setState({user_status:'T'})}><input type="radio" name="user_status" checked={user_status == 'T'} onChange={()=>{}}/>일시정지</label>
                                    <div className='fralign_center' >
                                        <input type={"text"} style={{width:100}} value={stop_day} 
                                            onChange={(e) => {
                                                const check = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
                                                if (e.nativeEvent.data && check.test(e.nativeEvent.data)) {
                                                    return null
                                                }
                                                this.setState({stop_day: e.target.value})
                                            }}
                                            disabled={user_status == 'S'} />
                                        <label style={{marginLeft:5}}>일</label>
                                    </div>
                                    <label className='radio_label' onClick={()=>{this.setState({user_status:'S'})}}><input type="radio" name="user_status" checked={user_status == 'S'} onChange={()=>{}}/>영구정지</label>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>사유 작성</label>
                                <div className='card_row_val'>
                                    <textarea style={{ width: '100%', height: 100 }} placeholder={"사유를 입력하세요."} value={blind_txt1} onChange={({target}) => this.setState({blind_txt1: target.value})} />
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailReport);