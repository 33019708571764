import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { fileDownload, requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';
import moment from 'moment';
import consts from '../../libs/consts';
import { nonAuthedReqPost } from '../../libs/request';
import Pagination from '../Pagination';

Modal.setAppElement('#root');
function EduCampModal({ closeModal,type, confirm }) {

  const [eduProductList, setEduProductList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    fetchData();
  }, [page])

  const fetchData = (curPage) => {
    const params = { keyword, currentPage: curPage || page }
    requestPost('getProductList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        setEduProductList(res);
        setTotalCount(totalCnt)
      }
    });

    if (curPage) {
      setPage(curPage);
    }
  }

  const confirmData = (eduProduct) => {
    confirm(eduProduct);
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      fetchData(1);
    }
  }

    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 1000, height: 600, padding:0 }
            }}
        >
            <div className='card diffkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>교육상품 선택</div>
                    <div className='card_head_tool'>
                      <div className='search_input'>
                          <input type="text" placeholder='상품명, 상품ID, 교육사명으로 검색' 
                            value={keyword} 
                            onKeyUp={handleKeyPress} 
                            onChange={e => setKeyword(e.target.value) } 
                          />
                          <div className='search_btn' onClick={() => {fetchData(1)}} />
                        </div>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='card_body' style={{border: 0}}>
                  <table>
                          <tbody>
                            <tr>
                              <th>교육기관명</th>
                              <th>교육명</th>
                            </tr>
                            {
                              eduProductList?.map((item, index) => (
                                <tr style={{cursor: 'pointer'}} onClick={() => confirmData(item)}>
                                  <td>{item.user_name}</td>
                                  <td>{item.title}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                         
                  </table>
                </div>

                <Pagination totalCnt={totalCount} onClick={(pageIdx) => setPage(pageIdx)} currentPage={page} perPage={5} />
                
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>닫기</div>
                </div>
            </div>
        </Modal>
    );
}

export default EduCampModal;