import React from 'react'
import withRouter from '../../components/withRouter';

import './mypage.css';
import { useState } from "react";
import Leftmenu from '../../layout/leftmenu';

import {requestPost} from '../../libs/request';
import { toastMsg, updateAdminPerm, updateMyAlarm } from '../../libs/utils';

class EditMyInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      user_name:    '', 
      department:   '', 
      phone:        '', 
      position:     ''
    };
  }

  componentDidMount(){
    
    updateAdminPerm();
    updateMyAlarm();

    requestPost('getMyInfo', {}, (res) => {
      if(Boolean(res)) {
        const {type, perm_name, email, user_name, department, phone, position, status, reg_time } = res;
        this.setState({type, perm_name, email, user_name, department, phone, position, status, reg_time });
        // 
      }
    });
  }
  
  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  handleInputChange = (event) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({[name]: value});
    // 
  }
  // 
  update = () => {
    const { user_name, department, phone, position } = this.state;
    // 
    if(!user_name) {
        return toastMsg('이름을 입력하세요.');
    }
    if(!phone) {
        return toastMsg('휴대전화번호를 입력하세요.');
    }

    const params = {
      user_name, department, phone, position
    }
    requestPost('updateMyInfo', params, (res) => {
        if(Boolean(res)) {
          toastMsg('저장되었습니다.', 'success');
        }
        else {
          toastMsg('저장에 실패하였습니다.');
        }
    });
  }

  render() {
    const { email, user_name, department, phone, position } = this.state;

    return (
    <Leftmenu>
      <div className='page_contents'>
        <div className='card'>
          <div className='card_head'>
            <div className='card_head_title'>내 정보 수정</div>
            <div className='card_head_tool'>
              <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>
              <div className='btn_normal' onClick={this.update}>확인</div>
            </div>
          </div>
          <div className='card_body'>
            <div className='frjustify_between' style={{width:'100%'}}>
              <div style={{flex:1}}></div>
              <label className='field_label'><span>*</span> 필수 입력 항목입니다.</label>
            </div>
            <div className='card_row'>
              <label className='field_label'>이메일(로그인ID)</label>
              <div className='card_row_val'>{email}</div>
              <div className='card_row_val'>
                <div value={'position'} type="text" className='input_box' style={{visibility: 'hidden'}} />
              </div>
            </div>
            <div className='card_row'>
              <label className='field_label'>이름 <span>*</span></label>
              <div className='card_row_val'>
                <input onChange={this.handleInputChange} value={user_name} name='user_name' type="text" className='input_box' placeholder='이름을 입력하세요.' style={{width:200}} />
              </div>
              <label className='field_label'>휴대전화번호 <span>*</span></label>
              <div className='card_row_val'>
                <input onChange={this.handleInputChange} value={phone} name='phone' type="tel" className='input_box' placeholder='휴대전화번호를 입력하세요.' style={{width:200}}/>
              </div>
            </div>
            <div className='card_row'>
              <label className='field_label'>소속부서</label>
              <div className='card_row_val'>
                <input onChange={this.handleInputChange} value={department} name='department' type="text" className='input_box' placeholder='소속부서를 입력하세요.' style={{width:200}}/>
              </div>
              <label className='field_label'>직책</label>
              <div className='card_row_val'>
                <input onChange={this.handleInputChange} value={position} name='position' type="text" className='input_box' placeholder='직책을 입력하세요.' style={{width:200}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Leftmenu>
    );
  }
}

export default (withRouter(EditMyInfo));