import './partner.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';
import { utils, writeFileXLSX } from 'xlsx';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';

class PartnerCompany extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      account_pub:  0,
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
      checkAll:     false,
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, account_pub } = this.state;
    const params = { keyword, account_pub, currentPage, type: 'company' }
    requestPost('getEduCompanyList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeAccountPub = (account_pub) => {
    this.setState({account_pub})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  checkAll = () => {
    let { dataList, checkAll } = this.state;
    dataList = dataList.map(dt => {
      dt.checked = !checkAll;
      return dt;
    } );
    this.setState({checkAll: !checkAll, dataList})
  }

  allDownloadExcel = () => {
    requestPost('getEduCompanyList', {type: 'all'}, (res) => {
      if(Boolean(res) && res.length > 0) {
        this.downloadExcel(res);
      }
      else {
        toastMsg("데이터가 없습니다.");
      }
    });
  }
  selDownloadExcel = () => {
    const { dataList } = this.state;
    const selectedList = dataList.filter(dt => dt.checked);
    if(selectedList.length == 0) {
      return toastMsg('항목을 선택하세요.');
    }
    this.downloadExcel(selectedList);
  }
  downloadExcel = (dataList) => {
    
    const data = [];
    dataList.map(item => {
      data.push({ "파트너사 ID": item.id, "파트너사명": item.user_name, "생성일시": formatDate(item.reg_time), "계정발급여부": (item.account_status > 0) ? '발급' : '미발급' })
    });

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `파트너사_${getCurrentTime()}.xlsx`);
  }

  render() {
    const { keyword, account_pub, totalCnt, currentPage, dataList, checkAll } = this.state;
    
    return (
      <Leftmenu>
        <div className='page_contents partcompany'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>파트너사</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='파트너사명, 파트너사ID로 검색' 
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
                <div className='btn_normal' onClick={() => this.gotoPage('/partner/company/detail')}>신규 파트너사 등록</div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
                <span>{formatNum(totalCnt)}</span>개의 파트너사
              </div>
              <div className='fralign_center' style={{ gap: 60 }}>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='btn_link' onClick={this.selDownloadExcel}>선택 정보 다운로드</div>
                  <div className='btn_link' onClick={this.allDownloadExcel}>전체 다운로드</div>
                </div>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>계정발급여부</div>
                  <label className='radio_label' onClick={() => this.changeAccountPub(0)}><input type="radio" name="account_pub" checked={account_pub === 0} onChange={()=>{}} />전체</label>
                  <label className='radio_label' onClick={() => this.changeAccountPub(1)}><input type="radio" name="account_pub" checked={account_pub === 1} onChange={()=>{}} />발급</label>
                  <label className='radio_label' onClick={() => this.changeAccountPub(2)}><input type="radio" name="account_pub" checked={account_pub === 2} onChange={()=>{}} />미발급</label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>
                    <input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} />
                  </th>
                  <th>파트너사 ID</th>
                  <th>파트너사명</th>
                  <th>생성일시</th>
                  <th>계정발급여부</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index}>
                    <td>
                      <input type='checkbox' onChange={() => {}} onClick={() => {
                        let tmpList = dataList;
                        tmpList[index].checked = !item.checked;
                        // 
                        let checkedCnt = tmpList.filter(dt => dt.checked).length
                        this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})
                      }} checked={Boolean(item.checked)} />
                    </td>
                    <td>{item.id}</td>
                    <td className='cursor_pointer' onClick={()=>{this.gotoPage('/partner/company/detail?id='+item.id)}}>{item.user_name}</td>
                    <td>{formatDate(item.reg_time)}</td>
                    <td>{(item.account_status > 0) ? '발급' : '미발급' }</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={5}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(PartnerCompany);