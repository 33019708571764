import './menugroup.css'
import { useNavigate, useLocation } from 'react-router-dom';

function Menugroup({children, title, linkurl, baseurl}) {
    let navigate = useNavigate();
    let url = useLocation();
    return (
        <div className={url.pathname.indexOf(baseurl) === 0?(Boolean(children)?"menu_group_open":"menu_group_open_self"):"menu_group_close"}>
            <div className='menu_g_item' onClick={()=>{ navigate(linkurl); }}>
                {title}
            </div>
            {url.pathname.indexOf(baseurl) === 0 && children}
        </div>
    );
}

export default Menugroup;