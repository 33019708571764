export const authaccActionType = {
    setauthinf: 'setauthinf',
    clearauthinf: 'clearauthinf',
    updatePerm: 'updatePerm',
    updateAlarm: 'updateAlarm',
};

export const setauthinf = (value) => ({ type: authaccActionType.setauthinf, value });
export const clearauth = () => ({ type: authaccActionType.clearauthinf });
export const updatePerm = (perms) => ({ type: authaccActionType.updatePerm, perms });
export const updateAlarm = (alarm) => ({ type: authaccActionType.updateAlarm, alarm });