import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { fileDownload, requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';
import moment from 'moment';
import consts from '../../libs/consts';

Modal.setAppElement('#root');
function ReviewModal({ review, closeModal,type, confirm }) {

  const [currentReview, setCurrentReview] = useState(review);
  const [period, setPeriod] = useState(review.period || '');

  const updateCert = (cert_st) => {
    // updateReviewCertSt
    if (type === 'P') {
      requestPost('updatePremiumReviewCertSt', {review_id: review.review_id, cert_st: cert_st}, (res) => {
        if(Boolean(res)) {
            toastMsg('저장되었습니다.', 'success');
            setCurrentReview({...review, cert_st: cert_st})
        } else {
            toastMsg('저장에 실패하였습니다.', 'warning');
        }
      });
    } else {
      requestPost('updateReviewCertSt', {review_id: review.review_id, cert_st: cert_st}, (res) => {
        if(Boolean(res)) {
            toastMsg('저장되었습니다.', 'success');
            setCurrentReview({...review, cert_st: cert_st})
        } else {
            toastMsg('저장에 실패하였습니다.', 'warning');
        }
      });
    }
  }

  const updateReviewSt = (review_st) => {
    // updateReviewCertSt
    if (type === 'P') {
      requestPost('updatePremiumReviewCertSt', {review_id: review.review_id, review_st: review_st, period: period}, (res) => {
        if(Boolean(res)) {
            toastMsg('저장되었습니다.', 'success');
            setCurrentReview({...review, review_st: review_st})
        } else {
            toastMsg('저장에 실패하였습니다.', 'warning');
        }
      });
    } else {
      requestPost('updateReviewCertSt', {review_id: review.review_id, review_st: review_st, period: period}, (res) => {
        if(Boolean(res)) {
            toastMsg('저장되었습니다.', 'success');
            setCurrentReview({...review, review_st: review_st})
        } else {
            toastMsg('저장에 실패하였습니다.', 'warning');
        }
      });
    }
  }

    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 700, height: type === 'P' ? 750 : 650, padding:0 }
            }}
        >
            <div className='card diffkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>{type === 'P' ? '프리미엄' : ''} 리뷰상세</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='card_body' style={{border: 0}}>
                  {
                    type === 'P' ?
                    <table>
                        <tbody>
                           <tr>
                            <th style={{width: '15%'}}>교육기관</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>{currentReview.partner_name}</td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>교육상품명</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>{currentReview.edu_name}</td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>수강후기 상태</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>
                              {
                                currentReview.review_st === 1 ? '승인' : '승인대기'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>총점</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>
                              {currentReview.score_total}
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>작성자</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>
                              {
                                currentReview.user_nickname + '(' + currentReview.email + ')'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>작성일시</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>
                              {
                                moment(currentReview.reg_dtm).format('YYYY-MM-DD HH:mm')
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>기수</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>
                              {
                                currentReview.period || '-'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>성별</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.sex === 'M' ? '남성' : '여성'
                              }
                            </td>
                            <th style={{width: '15%'}}>연령</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.age
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>교육경험</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.similar_yn === 'Y' ? '있음' : '없음'
                              }
                            </td>
                            <th style={{width: '15%'}}>재직여부</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.on_job_yn === 'Y' ? '재직자' : '구직자'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>수강상태</th>
                            <td colSpan={7} style={{textAlign: 'left'}}>
                              {
                                currentReview.edu_st === 1 ?
                                '면접통과'
                                : currentReview.edu_st === 2 ?
                                '수강중'
                                : currentReview.edu_st === 3 ?
                                '수강중단'
                                :
                                '수료'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>인증상태</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.cert_st === 1 ? <div className='btn_normal cancel' onClick={() => updateCert(2)}>인증철회</div> : <div className='btn_normal' onClick={() => updateCert(1)}>인증하기</div>
                              }
                            </td>
                            <th style={{width: '15%'}}>수료증</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.file_url ? <div className="btn_normal" onClick={() => fileDownload(consts.resourcUrl +currentReview.file_url)}>다운로드</div> : '없음'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>커리큘럼 총점</th>
                            <td style={{textAlign: 'left'}}>
                              {
                                currentReview.score_curri
                              }
                            </td>
                            <th style={{width: '15%'}}>내용</th>
                            <td colSpan={5} style={{textAlign: 'left'}}>
                              {
                                currentReview.text_curri
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>강사 총점</th>
                            <td style={{textAlign: 'left'}}>
                              {
                                currentReview.score_teacher
                              }
                            </td>
                            <th style={{width: '15%'}}>내용</th>
                            <td  colSpan={5} style={{textAlign: 'left'}}>
                              {
                                currentReview.text_teacher
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>환경 총점</th>
                            <td style={{textAlign: 'left'}}>
                              {
                                currentReview.score_env
                              }
                            </td>
                            <th style={{width: '15%'}}>내용</th>
                            <td  colSpan={5} style={{textAlign: 'left'}}>
                              {
                                currentReview.text_env
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>운영관리 총점</th>
                            <td style={{textAlign: 'left'}}>
                              {
                                currentReview.score_manage
                              }
                            </td>
                            <th style={{width: '15%'}}>내용</th>
                            <td  colSpan={5} style={{textAlign: 'left'}}>
                              {
                                currentReview.text_manage
                              }
                            </td>
                          </tr> 
                        </tbody>
                    </table>
                    : 
                    <table>
                        <tbody>
                           <tr>
                            <th style={{width: '15%'}}>교육기관</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>{currentReview.partner_name}</td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>교육상품명</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>{currentReview.edu_name}</td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>수강후기 상태</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.review_st === 1 ? '승인' : '승인대기'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>총점</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {currentReview.score}
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>작성자</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.user_nickname + '(' + currentReview.email + ')'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>작성일시</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                moment(currentReview.reg_dtm).format('YYYY-MM-DD HH:mm')
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>기수</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.period || '-'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>수강상태</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.edu_st === 1 ?
                                '면접통과'
                                : currentReview.edu_st === 2 ?
                                '수강중'
                                : currentReview.edu_st === 3 ?
                                '수강중단'
                                :
                                '수료'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>인증상태</th>
                            <td style={{width: '35%', textAlign: 'left'}}>
                              {
                                currentReview.cert_st === 1 ? <div className='btn_normal cancel' onClick={() => updateCert(2)}>인증철회</div> : <div className='btn_normal' onClick={() => updateCert(1)}>인증하기</div>
                              }
                            </td>
                            <th style={{width: '15%'}}>수료증</th>
                            <td style={{width: '35%', textAlign: 'left'}}>
                              {
                                currentReview.file_url ? <div className="btn_normal" onClick={() => fileDownload(consts.resourcUrl +currentReview.file_url)}>다운로드</div> : '없음'
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>좋은점</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.pros
                              }
                            </td>
                          </tr> 
                          <tr>
                            <th style={{width: '15%'}}>아쉬운점</th>
                            <td colSpan={3} style={{textAlign: 'left'}}>
                              {
                                currentReview.cons
                              }
                            </td>
                          </tr> 
                        </tbody>
                    </table>
                  }
                    
                </div>
                
                <div className='modal_bottom'>
                    <div className='font_D700_16'>기수 변경</div>
                    <input type="text" className="w-50" value={period} onChange={(e) => setPeriod(e.target.value)} placeholder='변경하려면 입력 후 승인을 눌러주세요.'/>
                    <div className={currentReview.review_st === 1 ? 'btn_normal cancel' : 'btn_normal'} onClick={()=>{
                        updateReviewSt(currentReview.review_st === 1 ? 2 : 1);
                        confirm();
                    }}>{currentReview.review_st === 1 ? '승인철회' : '승인하기'}</div>
                    <div className='btn_normal cancel' onClick={closeModal}>닫기</div>
                </div>
            </div>
        </Modal>
    );
}

export default ReviewModal;