import React from 'react';
import $ from 'jquery';
import ModalImage from "react-modal-image";

import './other.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import JobKeyword from '../../components/modal/job_keyword';
import TechKeyword from '../../components/modal/tech_keyword';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, isEmptyObj, formatDate, formatDate2, formatTime } from '../../libs/utils';
import {requestPost, requestFile} from '../../libs/request';

class DetailJobpaper extends React.Component {
    editorref = null;
    fileref = null;
    constructor() {
        super();
        this.state = {
            whitePaperId: 0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',

            group_tech:  '', 
            jobtype:     '', 
            description: '', 
            intro:  '', 
            thumb:  '', 
            expo:   1, 
            techs:  '', 
            sortIdx:   '', 
            reg_time:  '', 
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const whitePaperId  = searchParams.get("id");
        const sortIdx  = searchParams.get("sort");
        this.setState({sortIdx})
        // 
        this.getData(whitePaperId);
    }

    getData = (whitePaperId) => {
        window.scrollTo(0, 0);
        this.setState({whitePaperId});
    
        if(whitePaperId > 0) {
            requestPost('getWhitePaperDetail', {whitePaperId}, (res) => {
                if(Boolean(res)) {
                    const { jobtype, description, intro, thumb, expo, techs, sort, reg_time } = res;
        
                    this.setState({ jobtype, description, intro, thumb, expo, sort, reg_time,
                        techs: Boolean(techs) ? techs : '' });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }
    handleFileChange = async (event, type) => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      // get dementions
      let img = new Image();
      img.src = URL.createObjectURL(file);
      await img.decode();
      let width = img.width;
      let height = img.height;
      
      if(width != 500 || height != 500) {
        toastMsg('이미지를 500px X 500px로 권장 드립니다.');
      }

      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;
        this.setState({[type]: file_path});
      });
    }
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
    
        this.setState({[name]: value});
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    // 
    onConfirm = () => {
        const {whitePaperId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        requestPost('deleteWhitePaper', {whitePaperId}, (res) => {
            if(Boolean(res)) {
                this.gotoPage(-1);
            }
            else {
                toastMsg('삭제에 실패하였습니다.');
            }
        });
    }
    update = () => {
        const {whitePaperId, jobtype, description, intro, thumb, expo, techs} = this.state;

        if(!jobtype) {
            return toastMsg('직무를 선택하세요.');
        }
        if((!thumb)) {
            return toastMsg('이미지를 첨부하세요.');
        }
        if((!techs)) {
            return toastMsg('기술 스택 키워드를 추가하세요.');
        }
        // 
        const params = {
            whitePaperId, jobtype, description, intro, thumb, expo, techs: Boolean(techs) ? JSON.stringify(techs) : ''
        }
        requestPost('updateWhitePaper', params, (res) => {
            if(Boolean(res)) {
                if(!whitePaperId) {
                  setTimeout(() => {
                    this.gotoPage(-1);
                  }, 100);
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    render() {
        const { whitePaperId, confirmTitle, confirmTxt, modalIdx, group_tech,
            jobtype, description, intro, thumb, expo, techs, sortIdx } = this.state;

        let techStackList = [];
        try {
            techStackList = Boolean(techs) ? JSON.parse(techs) : [];
        } catch (error) {
            console.error(error)
        }
        
        return (
            <Leftmenu>
                <div className='page_contents detailJobpaper'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{!Boolean(whitePaperId) ? "직무백서 등록" : "직무백서 상세"}</div>
                            <div className='fralign_center' style={{ gap: 10 }}>
                                {Boolean(whitePaperId) || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)} >취소</div>}
                                {Boolean(whitePaperId) || <div className='btn_normal' onClick={this.update} >등록</div>}
                                {Boolean(whitePaperId) && <div className='btn_normal' onClick={this.update}>저장</div>}
                            </div>
                        </div>
                        <div className='card_body'>
                            {whitePaperId > 0 && (<table className='alignleft'>
                                <tbody>
                                    <tr>
                                        <th>게시물ID</th>
                                        <td>{whitePaperId}</td>
                                    </tr>
                                    <tr>
                                        <th>게시순서</th>
                                        <td>{sortIdx}</td>
                                    </tr>
                                </tbody>
                            </table>)}
                            <div className='card_row'>
                                <label className='field_label'>직무명</label>
                                <div className='card_row_val'>
                                    <input type="text" className='input_box' placeholder='직무를 선택하세요.' style={{ width: 400 }} readOnly value={jobtype} />
                                    <div className='btn_normal' onClick={() => this.setState({modalIdx: 10})}>{whitePaperId > 0 ? "직무 수정" : "직무 선택"}</div>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>한 줄 설명</label>
                                <div className='card_row_val'>
                                    <input type={'text'} style={{width:'100%'}} placeholder="한 줄 설명을 입력하세요." value={description} onChange={({target}) => this.setState({description: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>노출여부</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({ expo: 1 }) }><input type="radio" name="expo" checked={expo == 1} onChange={() => {}} />노출</label>
                                    <label className='radio_label' onClick={() => this.setState({ expo: 2 }) }><input type="radio" name="expo" checked={expo == 2} onChange={() => {}} />비노출</label>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>소개</label>
                                <div className='card_row_val'>
                                    <textarea style={{width:'100%', height:100}} placeholder="소개글을 입력하세요." value={intro ? intro : ''} onChange={({target}) => this.setState({intro: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>이미지 첨부 <br /><span className='gray-text fs-11 mt-2'>500px X 500px 권장</span></label>
                                <div className='card_row_val'>
                                    <div className='btn_normal' onClick={() => $('input[name=thumb]').trigger('click')}>파일 찾기</div>
                                    <input type="file" name="thumb" onChange={(e) => this.handleFileChange(e, 'thumb')} className="d-none" accept="image/*" />
                                    {/* {Boolean(thumb) && <img src={consts.resourcUrl + thumb} className='addtion_img' />} */}
                                    {Boolean(thumb) && 
                                    <ModalImage
                                        className='addtion_img'
                                        small={consts.resourcUrl + thumb}
                                        large={consts.resourcUrl + thumb}
                                        hideDownload
                                    />}
                                </div>
                            </div>
                            <div className='frjustify_between splite_block' style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}>
                                <div className='font_D700_16'>기술 스택 키워드 입력</div>
                            </div>
                            <div className='fcjust_center' style={{ width: '100%', gap: 10 }}>
                                {
                                    techStackList.map((item, index) =>
                                    <div className='query_block' key={index}>
                                        <div className='fr_c_end' style={{ width: '100%' }}>
                                            <div className='close_btn' 
                                                onClick={() => {
                                                    let tmpList = techStackList;
                                                    tmpList.splice(index, 1);
                                                    this.setState({techs: JSON.stringify(tmpList)});
                                                }}
                                            />
                                        </div>
                                        <div className='fralign_center' style={{ gap: 20, width: '100%' }}>
                                            <input type="text" className='flex-1' placeholder="그룹명을 입력하세요." value={item.title} onChange={(e) => {
                                                item.title = e.target.value;
                                                techStackList[index] = item;
                                                this.setState({techs: JSON.stringify(techStackList)})
                                            }} />
                                        </div>
                                        <div className='fralign_start' style={{ width: '100%' }}>
                                            <div className='card_row_val' style={{ flexWrap: 'wrap', padding: '10px 0px' }}>
                                                <div className='btn_edit' onClick={() => this.setState({modalIdx: 1000 + index, group_tech: item.data})}></div>
                                                {Boolean(item.data) && item.data.split(',').map((tech, idx) => <div key={idx} className='btn_option c-default'>{tech}</div>)}
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                                <div className='add_query' onClick={() => {
                                    techStackList.push({"title":"","data":""});
                                    this.setState({techs: JSON.stringify(techStackList)});
                                }}><div className='plus_btn' />그룹 추가</div>
                            </div>
                            {whitePaperId > 0 && 
                            <div className='fr_c_end w-100' style={{ padding: '20px 0px' }}>
                                <div className='btn_remove' onClick={() => this.setState({confirmTitle: "게시물 삭제", confirmTxt: "게시물을 삭제하시겠습니까?", modalIdx: 1})}>게시물 삭제</div>
                            </div>}
                        </div>
                    </div>
                </div>
                {modalIdx == 1 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
                {modalIdx == 10 && <JobKeyword category={jobtype} closeModal={() => this.setState({modalIdx: 0})} onlyOne selectDatas={(jobtype) => this.setState({jobtype})} />}
                {modalIdx >= 1000 && <TechKeyword category={group_tech} closeModal={() => this.setState({modalIdx: 0})} 
                    selectDatas={(tech_stack) => {
                        techStackList[modalIdx - 1000].data = tech_stack;
                        this.setState({techs: JSON.stringify(techStackList)})
                    }} 
                />}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailJobpaper);