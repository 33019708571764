import './modal.css'
import Modal from 'react-modal';

Modal.setAppElement('#root');
function QueryPreView({ curriclumList, afterOpenModal, closeModal, selectDatas }) {
    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 500, height: 500, padding:0 }
            }}
        >
            <div className='card querypreview'>
                <div className='card_head'>
                    <div className='card_head_title'>커리큘럼 미리보기</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='card_body'>
                    <table>
                        <tbody>
                            {
                                curriclumList.map((item, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <th>{item.title}</th>
                                                <th>{item.time}</th>
                                            </tr>
                                            {
                                                item.data?.map((data, idx) => {
                                                        return (
                                                            // (data.title && data.time) &&
                                                            <tr key={index +'' + idx}>
                                                                <td>{data.title}</td>
                                                                <td>{data.time}</td>
                                                            </tr>
                                                        )
                                                    }
                                                )
                                            }
                                        </>
                                    )
                                }
                                ) 
                            }
                        </tbody>
                    </table>
                </div>
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>닫기</div>
                </div>
            </div>
        </Modal>
    );
}

export default QueryPreView;