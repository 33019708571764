import './modal.css'
import Modal from 'react-modal';
Modal.setAppElement('#root');
function ConformModal({ afterOpenModal, closeModal, onConfirm, title, children }) {
    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', minWidth: 300, padding:0 }
            }}
        >
            <div className='card removedlg'>
                <div className='card_head'>
                    <div className='card_head_title'>{title}</div>
                    <div style={{flex:1}}></div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='message_txt pre-wrap'>{children}</div>
                <div className='modal_bottom'>
                    <div className='btn_normal' onClick={()=>{
                        onConfirm && onConfirm();
                        closeModal();
                    }}>{title?.indexOf('삭제') >= 0 ? '삭제' : '확인'}</div>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                </div>
            </div>
        </Modal>
    );
}

export default ConformModal;