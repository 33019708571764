import './layout.css';
import { useSelector } from "react-redux";
import Top from '../components/top';
import Foot from '../components/foot';
import Menu from '../components/menu';
function Leftmenu({children}) {
    const leftmenu = useSelector(state => state.leftmenu);
    return (<>
        <Top />
        <div className='leftmenu_contents'>
            {leftmenu && (<Menu />)}
            {children}
        </div>
        <Foot />
    </>
    );
}

export default Leftmenu;