
const dev = false;

const baseUrl    = dev ? 'http://192.168.1.6' : 'https://pkit.kr';
const apiUrl     = baseUrl + ':3501/';
const domain     = dev ? 'http://localhost:3000/' : 'https://pkit.kr/';
const adminUrl   = dev ? 'http://localhost:3001/' : 'https://pkit.kr/admin/';
const resourcUrl = dev ? baseUrl + '/uploads/' : 'https://pkit.kr/uploads/';

export default {
  dev,
  baseUrl,
  apiBasicUrl:  apiUrl,
  resourcUrl,
  domain,
  adminUrl,
  showMaxLen: 40,

  googleCSEKey: 'AIzaSyB5rcYa2r3VZtzutlSLRV1zKPry-EV4DLM',
  googleCSEcx:  '95e37276f62e80304',

};
