import './report.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';

class ReportAdvert extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      type:         '',
      status:       0,
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, status, type } = this.state;
    const params = { keyword, status, currentPage, type }
    requestPost('getBannerList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeStatus = (status) => {
    this.setState({status})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }
  changeType = (type) => {
    this.setState({type});
    setTimeout(() => {
      this.getData(1);
    }, 100);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  openDetail = (id) => {
    const { router } = this.props;
    router.navigate('/report/advert/detail?id='+id);
  }

  render() {
    const { keyword, type, status, totalCnt, currentPage, dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents cmtlist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>광고 성과 리포트</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='ID, 배너/상품명, 광고주명으로 검색'
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
                <span>{formatNum(totalCnt)}</span>개의 배너
              </div>
              <div className='fralign_center' style={{ gap: 60 }}>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>광고유형</div>
                  <label className='radio_label' onClick={() => this.changeType('')}><input type="radio" name="type" checked={type == ''} onChange={()=>{}}/>전체</label>
                  <label className='radio_label' onClick={() => this.changeType('M')}><input type="radio" name="type" checked={type == 'M'} onChange={()=>{}}/>메인배너</label>
                  <label className='radio_label' onClick={() => this.changeType('A')}><input type="radio" name="type" checked={type == 'A'} onChange={()=>{}}/>광고배너</label>
                  <label className='radio_label' onClick={() => this.changeType('P')}><input type="radio" name="type" checked={type == 'P'} onChange={()=>{}}/>광고상품</label>
                </div>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>게시상태</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0)}><input type="radio" name="status" checked={status == 0} onChange={()=>{}}/>전체</label>
                  <label className='radio_label' onClick={() => this.changeStatus(1)}><input type="radio" name="status" checked={status == 1} onChange={()=>{}}/>게시중</label>
                  <label className='radio_label' onClick={() => this.changeStatus(2)}><input type="radio" name="status" checked={status == 2} onChange={()=>{}}/>게시종료</label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>광고유형</th>
                  <th>배너/상품명</th>
                  <th>게시일시</th>
                  <th>게시상태</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={() => this.openDetail(item.id)}>
                    <td>{item.id}</td>
                    <td>{item.type == 'M' ? '메인배너' : (item.type == 'A' ? '광고배너' : '광고상품') }</td>
                    <td>{item.type == 'P' ? item.product_title : item.title}</td>
                    <td>{Boolean(item.st_time) ? formatDate(item.st_time) : '게시 즉시'} ~ {Boolean(item.ed_time) ? formatDate(item.ed_time) : '무제한'}</td>
                    <td>{item.enable ? '게시중' : (item.disable ? '게시종료' : '') }</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(ReportAdvert);