import React from 'react'
import withRouter from '../../components/withRouter';

import './mypage.css';
import Leftmenu from '../../layout/leftmenu';

import {requestPost} from '../../libs/request';
import { formatDate, toastMsg, updateAdminPerm, updateMyAlarm } from '../../libs/utils';

class Mypage extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      user_name:    '', 
      department:   '', 
      phone:        '', 
      position:     '',
      alarm_partner_talk: 'Y',
      alarm_review: 'Y',
      alarm_premium_review: 'Y',
      alarm_unmatched_review: 'Y',
      alarm_qna: 'Y',
      id: 0
    };
  }

  componentDidMount(){
    
    updateAdminPerm();
    updateMyAlarm();

    requestPost('getMyInfo', {}, (res) => {
      if(Boolean(res)) {
        const {type, perm_name, email, user_name, department, phone, position, status, reg_time, alarm_review, alarm_premium_review, alarm_unmatched_review, alarm_qna, alarm_partner_talk, id} = res;
        this.setState({type, perm_name, email, user_name, department, phone, position, status, reg_time, alarm_review, alarm_premium_review, alarm_unmatched_review, alarm_qna, alarm_partner_talk, id });
        // 
      }
    });
  }
  
  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  updateAlarmSettings = () => {
    const { alarm_review, alarm_premium_review, alarm_qna, alarm_partner_talk, alarm_unmatched_review, id} = this.state;

    const params = {
      alarm_review, alarm_premium_review, alarm_qna, alarm_partner_talk, alarm_unmatched_review, id
    }

    requestPost('updateAlarmSettings', params, (res) => {
      if (Boolean(res)) {
        toastMsg('알람설정이 저장되었습니다.', 'success');
      }
    })
  }
  
  render() {
    const { type, perm_name, email, user_name, department, phone, position, status, reg_time,  alarm_review, alarm_premium_review, alarm_unmatched_review, alarm_qna, alarm_partner_talk } = this.state;

  return (
    <Leftmenu>
      <div className='page_contents mypage'>
        <div className='card'>
          <div className='card_head'>
            <div className='card_head_title'>마이페이지</div>
            <div className='card_head_tool'>
              <div className='btn_normal' onClick={() => this.gotoPage('/mypage/editmyinf')}>내 정보 수정</div>
              <div className='btn_normal' onClick={() => this.gotoPage('/mypage/changepassword')}>비밀번호 변경</div>
            </div>
          </div>
          <table>
            <tbody>
            <tr>
              <th className='f_G6_4_14'>계정유형</th>
              <td>{type == 2 ? '슈퍼 관리자' : '일반 관리자'}</td>
              <th>권한그룹</th>
              <td>{perm_name}</td>
            </tr>
            <tr>
              <th>이메일(로그인ID)</th>
              <td>{email}</td>
              <th>계정생성일시</th>
              <td>{formatDate(reg_time)}</td>
            </tr>
            <tr>
              <th>이름</th>
              <td>{user_name}</td>
              <th>휴대전화번호</th>
              <td>{phone}</td>
            </tr>
            <tr>
              <th>소속부서</th>
              <td>{department}</td>
              <th>직책</th>
              <td>{position}</td>
            </tr>
            </tbody>
          </table>
          <div className='card_row'>
            <label className='field_label'>새로운 수강후기</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_review: 'Y' }) }><input type="radio" name="alarm_review" checked={alarm_review == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_review: 'N' }) }><input type="radio" name="alarm_review" checked={alarm_review == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>새로운 프리미엄 수강후기</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_premium_review: 'Y' }) }><input type="radio" name="alarm_premium_review" checked={alarm_premium_review == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_premium_review: 'N' }) }><input type="radio" name="alarm_premium_review" checked={alarm_premium_review == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>매칭 필요한 수강후기</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_unmatched_review: 'Y' }) }><input type="radio" name="alarm_unmatched_review" checked={alarm_unmatched_review == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_unmatched_review: 'N' }) }><input type="radio" name="alarm_unmatched_review" checked={alarm_unmatched_review == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>새로운 교육상품 문의</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_qna: 'Y' }) }><input type="radio" name="alarm_qna" checked={alarm_qna == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_qna: 'N' }) }><input type="radio" name="alarm_qna" checked={alarm_qna == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_row'>
            <label className='field_label'>교육 기관 한줄톡</label>
            <div className='card_row_val'>
              <label className='radio_label' onClick={() => this.setState({ alarm_partner_talk: 'Y' }) }><input type="radio" name="alarm_partner_talk" checked={alarm_partner_talk == 'Y'} onChange={() => { }} />알림받기</label>
              <label className='radio_label' onClick={() => this.setState({ alarm_partner_talk: 'N' }) }><input type="radio" name="alarm_partner_talk" checked={alarm_partner_talk == 'N'} onChange={() => { }} />알림없음</label>
            </div>
          </div>
          <div className='card_head'>
            <div className='fralign_center' style={{ gap: 10 }}>
              <div className='btn_normal' onClick={this.updateAlarmSettings}>알림상태저장</div>
            </div>
          </div>
        </div>
      </div>
    </Leftmenu>
    );
  }
}

export default (withRouter(Mypage));