import './manager.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';

class ManagerPermission extends React.Component {
  constructor() {
    super();
    this.state = {
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, status, type } = this.state;
    const params = { keyword, status, type, currentPage }
    requestPost('getPermList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  openDetail = (id) => {
    const { router } = this.props;
    router.navigate('/manager/permission/detail?id='+id);
  }

  render() {
    const { totalCnt, currentPage, dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents mnglist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>권한 그룹 관리</div>
              <div className='card_head_tool'>
                <div className='btn_normal' onClick={()=>{this.openDetail('')}}>신규 그룹 등록</div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
              <span>{formatNum(totalCnt)}</span>개의 그룹
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>권한그룹명</th>
                  <th>생성일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={() => this.openDetail(item.id)}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{formatDate(item.reg_time)}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={3}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(ManagerPermission);