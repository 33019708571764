import React from 'react';

const Nodata = ({str='데이터가 없습니다.', colSpan=1}) => {
	
	return (
        <tr><td className='py-4 text-center c-default' colSpan={colSpan}>{str}</td></tr>
	);
};


export default Nodata;