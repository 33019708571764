import './other.css';
import Leftmenu from '../../layout/leftmenu';
import $ from 'jquery';

import React from 'react';
import withRouter from '../../components/withRouter';
import SmartEditor from '../../components/smarteditor';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, copyToClipboard, formatDate, formatDate2, formatTime, formatNum } from '../../libs/utils';
import {requestPost, requestFile} from '../../libs/request';

class DetailNotice extends React.Component {
    editorref = null;
    constructor() {
        super();
        this.state = {
            noticeId:     0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            
            title:      '', 
            contents:   '', 
            description: '', 
            file_name:  '', 
            file_path:  '', 
            url:        '', 
            expo:       1
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const noticeId  = searchParams.get("id");
        // 
        this.getData(noticeId);
    }

    getData = (noticeId) => {
        window.scrollTo(0, 0);
        this.setState({noticeId});
    
        if(noticeId > 0) {
            requestPost('getNoticeDetail', {noticeId}, (res) => {
                if(Boolean(res)) {
                    const { title, contents, expo, hits, file_path, file_name, reg_time, email, user_name } = res;
        
                    this.setState({ 
                        title, contents, expo, hits, reg_time, email, user_name, file_path, file_name
                    });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }
    handleFileChange = (event) => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;
        this.setState({file_path, file_name: originalname});
      });
    }
    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    update = () => {
        const {noticeId, title, expo, file_path, file_name} = this.state;
        const contents = this.editorref.getContext();
        
        if(!title) {
            return toastMsg('제목을 입력하세요.');
        }
        if(!contents || contents == '<p><br></p>') { 
            return toastMsg('내용을 입력하세요.');
        }
        const params = {
            noticeId, title, contents, expo, file_path, file_name
        }
        requestPost('updateNotice', params, (res) => {
            if(Boolean(res)) {
                if(!noticeId) {
                  setTimeout(() => {
                    this.gotoPage(-1);
                  }, 100);
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }
    onConfirm = () => {
        const {noticeId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        requestPost('deleteNotice', {noticeId}, (res) => {
            if(Boolean(res)) {
                this.gotoPage(-1);
            }
            else {
                toastMsg('삭제에 실패하였습니다.');
            }
        });
    }
    share = () => {
        const {noticeId} = this.state;
        copyToClipboard(consts.domain + 'notice-detail?id=' + noticeId);
        toastMsg('복사되었습니다.');
    }

    render() {
        const { noticeId, confirmTitle, confirmTxt, modalIdx, 
            title, contents, expo, hits, reg_time, email, user_name, file_path, file_name } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailJobpaper'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{!Boolean(noticeId) ? "공지사항 등록" : "공지사항 상세"}</div>
                            <div className='fralign_center' style={{ gap: 10 }}>
                                {Boolean(noticeId) || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)} >취소</div>}
                                {Boolean(noticeId) || <div className='btn_normal' onClick={this.update}>등록</div>}
                                {Boolean(noticeId) && <div className='btn_normal cancel' onClick={this.share}>공유</div>}
                                {Boolean(noticeId) && <div className='btn_normal' onClick={this.update}>저장</div>}
                            </div>
                        </div>
                        <div className='card_body'>
                            {Boolean(noticeId) > 0 && (<table className='alignleft'>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td>{noticeId}</td>
                                        <th>조회수</th>
                                        <td>{formatNum(hits)}</td>
                                    </tr>
                                    <tr>
                                        <th>등록자</th>
                                        <td>{user_name} ({email})</td>
                                        <th>등록일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                </tbody>
                            </table>)}
                            <div className='card_row'>
                                <label className='field_label'>제목</label>
                                <div className='card_row_val'>
                                    <input type="text" className='input_box' placeholder='제목을 입력하세요.' style={{ width: 400 }} value={title} onChange={({target}) => this.setState({title: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>노출여부</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({ expo: 1 }) }><input type="radio" name="expo" checked={expo == 1} onChange={() => { }} />노출</label>
                                    <label className='radio_label' onClick={() => this.setState({ expo: 2 }) }><input type="radio" name="expo" checked={expo == 2} onChange={() => { }} />비노출</label>
                                </div>
                            </div>
                            <label className='field_label'>내용</label>
                            <div className='fralign_center' style={{width:'100%'}}>
                                <SmartEditor ref={(val) => this.editorref = val} editorid={"class_intro"} value={contents ? contents : ''} 
                                    onChange={(val) => {}} 
                                />
                            </div>
                            <div className='card_row mt-3'>
                                <label className='field_label'>파일첨부</label>
                                <div className='card_row_val'>
                                    <div className='btn_normal' onClick={() => $('input[name=file]').trigger('click')}>파일첨부</div>
                                    {Boolean(file_path) &&
                                    <span>
                                        <span className='mx-2 pointer underline mt--4' onClick={() => window.open(consts.resourcUrl + file_path)}>{file_name}</span>
                                        <span className='pointer' onClick={() => this.setState({file_path: '', file_name: ''})}><img src='/images/ico_x.png' className='w-20px' /></span>
                                    </span>}
                                    <input type="file" name="file" onChange={(e) => this.handleFileChange(e)} className="d-none" />
                                </div>
                            </div>
                            {Boolean(noticeId) > 0 && (<div className='fr_c_end' style={{ width: '100%', padding: '20px 0px' }}>
                                <div className='btn_remove' onClick={() => this.setState({confirmTitle: "게시물 삭제", confirmTxt: "게시물을 삭제하시겠습니까?", modalIdx: 1})}>게시물 삭제</div>
                            </div>)}
                        </div>
                    </div>
                </div>
                {modalIdx == 1 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailNotice);