
import React from 'react';
import './education.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import {nonAuthedReqPost, requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import Nodata from '../../components/Nodata';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, renderProductStatus } from '../../libs/utils';
import moment from 'moment';

class Curation extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      set_state:    0,
      visible_state:0,
      totalCnt:     0,
      currentPage:  1,
      dataList:     []
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, set_state, visible_state } = this.state;
    const params = { keyword, set_state, visible_state, currentPage }
    nonAuthedReqPost('getCurationList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeCollect = (set_state) => {
    this.setState({set_state})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }
  changeStatus = (visible_state) => {
    this.setState({visible_state})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoDetail = (id) => {
    const { router } = this.props;
    router.navigate('/education/curation/detail?id=' + id);
  }
  render() {
    const { keyword, set_state, visible_state, totalCnt, currentPage, dataList } = this.state;
    
    return (
      <Leftmenu>
        <div className='page_contents eduleasson'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>큐레이션</div>
              <div className='card_head_tool'>
                <div className='btn_normal' onClick={() => this.gotoDetail('')}>신규 큐레이션 등록</div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>노출여부</th>
                  <th>게시위치</th>
                  <th>큐레이션명</th>
                  <th>부트캠프수</th>
                  <th>게시기간</th>
                  <th>생성일</th>
                  <th>등록자</th>
                </tr>
              </thead>
              <tbody>
                { 
                  dataList.length > 0 ? dataList.map((item, index) =>
                  <tr key={index} onClick={() => this.gotoDetail(item.id)}>
                    <td>{item.id}</td>
                    <td>{item.status == 1 ? '노출' : '비노출'}</td>
                    <td>{item.position}</td>
                    <td>{item.title}</td>
                    <td>{item.bootcamps.length}</td>
                    <td>{Boolean(item.st_time) && item.st_time.startsWith('1899') && Boolean(item.ed_time) && item.ed_time.startsWith('9999') ? '무제한' : `${moment(item.st_time).format('YYYY-MM-DD HH:mm')}~${moment(item.ed_time).format('YYYY-MM-DD HH:mm')}`}</td>
                    <td>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{item.reg_nm + '(' + item.email + ')'}</td>
                  </tr>
                  )
                  :
                  <Nodata colSpan={8} />
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(Curation);