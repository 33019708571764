import './edukeyword.css';
import React from 'react';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import { requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';

class JobKeyList extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIdx:     0,
      confirmTitle: '',
      confirmTxt:   '',
      jobList:     [],
      jobtypeId:    -1,
      title:        '',
      data:         '',
    };
  }

    componentDidMount = () => {
        // 
        this.getData();
    }
    getData = () => {
        requestPost('getJobtypeByKeyword', {keyword: ''}, (res) => {
            if(Boolean(res)) {
                this.setState({jobList: res})
            }
        });
    }
  
    swapGroup = (indexA, indexB) =>  {
        let {jobList} = this.state;
        let temp = jobList[indexA];
        jobList[indexA] = jobList[indexB];
        jobList[indexB] = temp;
        // 
        this.setState({jobList})
        requestPost('updateJobtype', {jobList}, (res) => { });
    }
    swapData = (indexA, indexB) =>  {
        let {data} = this.state;
        let dataList = [];
        try {
            dataList = Boolean(data) ? JSON.parse(data) : [];
        } catch (error) { }

        let temp = dataList[indexA];
        dataList[indexA] = dataList[indexB];
        dataList[indexB] = temp;
        // 
        this.setState({data: JSON.stringify(dataList)});
    }

    deleteJobtype = () => {
        let {data, modalIdx} = this.state;
        let dataList = [];
        try {
            dataList = Boolean(data) ? JSON.parse(data) : [];
        } catch (error) { }

        dataList.splice(modalIdx - 1000, 1);
        // 
        this.setState({data: JSON.stringify(dataList)});
        this.setState({modalIdx: 0, confirmTxt: ''});
    }
    
    onConfirm = () => {
        const {modalIdx} = this.state;

        if(modalIdx == 1) {  // 그룹 삭제
            this.deleteGroup();
        }
        else if(modalIdx > 1) {  // 직무 삭제
            this.deleteJobtype();
        }
    }
    
    deleteGroup = () => {
        const { jobtypeId } = this.state;

        requestPost('deleteJobtypeById', {jobtypeId}, (res) => {
            if(Boolean(res)) {
                this.getData();
                if(jobtypeId > 0) {
                    toastMsg('삭제되었습니다.', 'success');
                    this.setState({modalIdx: 0, confirmTxt: '', jobtypeId: -1});
                }
            }
        });
    }
    update = () => {
        const { jobtypeId, title, data } = this.state;

        if(!title) {
            return toastMsg('그룹명을 입력하세요.');
        }
        const params = {
            jobtypeId, title, data
        }

        console.log(params);

        requestPost('updateJobtypeById', params, (res) => {
            if(Boolean(res)) {
                this.getData();
                if(jobtypeId > 0) {
                    toastMsg('저장되었습니다.', 'success');
                }
                else {
                    toastMsg('추가되었습니다.', 'success');
                    this.setState({jobtypeId: -1, title: "", data: ''});
                }
            }
        });
    }

    render() {
    const { modalIdx, confirmTxt, jobList, jobtypeId, title, data } = this.state;

    let dataList = [];
    if(jobtypeId >= 0) {
        try {
            dataList = Boolean(data) ? JSON.parse(data) : [];
        } 
        catch (error) { }
    }
    return (
    <div className='jobkey_list'>
        <div className='job_grouplist'>
            <table>
                <thead>
                    <tr>
                        <th>순서변경</th>
                        <th>그룹명</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobList.map((item, index) =>
                            <tr key={index}>
                                <td >
                                    <div className='sortrow'>
                                        <div className='up' onClick={() => this.swapGroup(index, index==0 ? jobList.length-1 : index-1)} />
                                        <div className='down' onClick={() => this.swapGroup(index, index==jobList.length-1 ? 0 : index+1)} />
                                    </div>
                                </td>
                                <td className='joggroupname' onClick={() => this.setState({jobtypeId: item.id, data: item.data, title: item.title})}>{item.title}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <div className='btn_normal mb-3' onClick={() => this.setState({jobtypeId: 0, title: "", data: ''})}>그룹  추가</div>
        </div>
        {jobtypeId >= 0 &&
        <div className='job_detail'>
            <div className='job_detail_head'>
                <div className='detail_label'>그룹 {jobtypeId == 0 ? '등록' : '수정'}</div>
                <div className='detail_tool'>
                    {jobtypeId > 0 && <div className='btn_normal cancel' onClick={() => this.setState({confirmTxt: "그룹을 삭제하시겠습니까? \n그룹에 등록된 모든 키워드가 삭제되며, \n키워드가 적용된 모든 영역에서 \n해당 값이 삭제됩니다.", modalIdx: 1})}>삭제</div>}
                    <div className='btn_normal' onClick={() => this.update()}>저장</div>
                </div>
            </div>
            <div className='job_detail_row'>
                <div className='job_row_label'>그룹명</div>
                <input type="text" style={{width:300}} value={title} onChange={({target}) => this.setState({title: target.value})} />
            </div>
            <div className='job_detail_row'>
                <div className='job_row_label'>직무</div>
                <div style={{flex:1}} />
                <div className='btn_normal' onClick={() => {
                  dataList.push({"title":"","descript":""});
                  this.setState({data: JSON.stringify(dataList)});
                }}>직무 추가</div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>순서</th>
                        <th style={{width: '20%'}}>직무명</th>
                        <th style={{width: '40%'}}>직무 설명</th>
                        <th style={{width: '5%'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataList.map((item, index) => 
                        <tr key={index}>
                            <td>
                                <div className='sortrow'>
                                    <div className='up' onClick={() => this.swapData(index, index==0 ? dataList.length-1 : index-1)} />
                                    <div className='down' onClick={() => this.swapData(index, index==dataList.length-1 ? 0 : index+1)} />
                                </div>
                            </td>
                            <td>
                                <input type="text" className='' value={item.title} onChange={(e) => {
                                    item.title = e.target.value;
                                    dataList[index] = item;
                                    this.setState({data: JSON.stringify(dataList)})
                                }} />
                            </td>
                            <td>
                                <input type="text" className='' value={item.descript} onChange={(e) => {
                                    item.descript = e.target.value;
                                    dataList[index] = item;
                                    this.setState({data: JSON.stringify(dataList)})
                                }} 
                                />
                                <input type="text" className='' value={item.keywords} onChange={(e) => {
                                    item.keywords = e.target.value;
                                    dataList[index] = item;
                                    this.setState({data: JSON.stringify(dataList)})
                                }} 
                                />
                            </td>
                            <td><div className='rmvtxtbtn' onClick={() => this.setState({confirmTxt: "직무를 삭제하시겠습니까? \n키워드가 적용된 모든 영역에서 \n해당 값이 삭제됩니다.", modalIdx: index + 1000})}>삭제</div></td>
                        </tr>
                        )
                    }
                </tbody>
            </table>
        </div>}

        {modalIdx >= 1 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={"삭제하기"}>{confirmTxt}</ConformModal>}
    </div>
    );
  }
}

export default withRouter(JobKeyList);