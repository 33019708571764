import React from 'react';
import ModalImage from "react-modal-image";

import './community.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import EditAnswer from '../../components/modal/edit_answer';

import consts from '../../libs/consts';
import Pagination from '../../components/Pagination';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum, renderPostCategory, renderPostOpen } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailComment extends React.Component {
    constructor() {
        super();
        this.state = {
            cmtId:    0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            totalCnt:     0,
            currentPage:  1,
            dataList:     [],
            dataList2:    [],
            checkAll:     false,
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const cmtId  = searchParams.get("id");
        // 
        window.scrollTo(0, 0);
        this.getData(cmtId);
    }

    getData = (cmtId) => {
        this.setState({cmtId});
    
        if(cmtId > 0) {
            requestPost('getCommentDetail', {cmtId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, target_id, comment, thumb, memo, status, reg_time, nickname, like_cnt, recmt_cnt, report_cnt } = res;
        
                    this.setState({ user_id, target_id, comment, thumb, memo, status, reg_time, nickname, like_cnt, recmt_cnt, report_cnt });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
            // 
            this.getReComments(1, cmtId);
        }
    }
    getReComments = (currentPage=this.state.currentPage, cmtId) => {
      this.setState({currentPage});
  
      const params = { cmtId, currentPage }
      requestPost('getCommentsByCmtId', params, (res) => {
        if(Boolean(res)) {
          let totalCnt = 0;
          if(Boolean(res[0])) {
            totalCnt = res[0].total_cnt;
          }
          this.setState({dataList: res, totalCnt});
        }
      });
    }
    updateMemo = (memo) => {
        const { cmtId, modalIdx, dataList } = this.state;
        this.setState({modalIdx: 0});

        if(!memo) {
            return toastMsg('사유를 입력하세요.');
        }

        const selectedList = dataList.filter(dt => Boolean(dt.checked)).map(dt => dt.id);
        if(modalIdx == 2 && selectedList.length == 0) {
            return toastMsg('대댓글 목록을 선택하세요.');
        }

        const params = {
            cmtId, memo, modalIdx, selectedList
        }
        requestPost('updateComment', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.getData(cmtId);
                this.setState({checkAll: false});
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    checkAll = () => {
        let { dataList, checkAll } = this.state;
        dataList = dataList.map(dt => {
          dt.checked = !checkAll;
          return dt;
        } );
        this.setState({checkAll: !checkAll, dataList})
    }

    render() {
        const { cmtId, modalIdx, totalCnt, currentPage, dataList, 
            user_id, target_id, comment, thumb, memo, status, reg_time, nickname, like_cnt, recmt_cnt, report_cnt, checkAll } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailcomment'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>댓글 상세</div>
                            <div className='fralign_center' style={{ gap: 20 }}>
                                <div className='btn_normal' onClick={() => this.setState({modalIdx: 1})}>블라인드 처리</div>
                            </div>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                            </div>
                            <table className='alignleft'>
                                <tbody>
                                    <tr>
                                        <th>원게시글ID</th>
                                        <td colSpan={3} className="cursor_pointer" onClick={() => this.gotoPage('/community/list/detail?id=' + target_id)}>{target_id}</td>
                                    </tr>
                                    <tr>
                                        <th>댓글ID</th>
                                        <td colSpan={3}>{cmtId}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자</th>
                                        <td colSpan={3} className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td colSpan={3}>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td className='pre-wrap' colSpan={3}>{comment}</td>
                                    </tr>
                                    <tr>
                                        <th>첨부이미지</th>
                                        <td colSpan={3}>
                                            <div className='fralign_start' style={{gap:10, flexWrap:'wrap'}}>
                                                {
                                                    Boolean(thumb) && thumb.split(',').map((item, index) => 
                                                        // <img key={index} src={consts.resourcUrl + item} className='imgbg' />
                                                        <ModalImage
                                                            key={index}
                                                            className='imgbg'
                                                            small={consts.resourcUrl + item}
                                                            large={consts.resourcUrl + item}
                                                            hideDownload
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>노출상태</th>
                                        <td>{status == 1 ? '공개' : (status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                                        <th>블라인드 사유</th>
                                        <td className='pre-wrap'>{memo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>공감</th>
                                        <th>대댓글</th>
                                        <th>신고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatNum(like_cnt)}</td>
                                        <td>{formatNum(recmt_cnt)}</td>
                                        <td className='cursor_pointer' onClick={() => this.gotoPage('/crm/report')}>{formatNum(report_cnt)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>대댓글 목록</div>
                                <div className='btn_normal' onClick={() => this.setState({modalIdx: 2})}>선택 글 블라인드 처리</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th><input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} /></th>
                                        <th>대댓글ID</th>
                                        <th>내용</th>
                                        <th>작성자</th>
                                        <th>노출상태</th>
                                        <th>작성일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList.length > 0 ?
                                    dataList.map((item, index) =>
                                    <tr key={index}>
                                        <td>
                                            <input type='checkbox' onChange={() => {}} onClick={() => {
                                                let tmpList = dataList;
                                                tmpList[index].checked = !item.checked;
                                                // 
                                                let checkedCnt = tmpList.filter(dt => dt.checked).length
                                                this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})
                                            }} checked={Boolean(item.checked)} />
                                        </td>
                                        <td>{item.id}</td>
                                        <td className='pre-wrap'>{item.comment}</td>
                                        <td>{item.nickname}</td>
                                        <td>{item.status == 1 ? '공개' : (item.status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                                        <td>{formatDate(item.reg_time)}</td>
                                    </tr>
                                    )
                                    :
                                    <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} perPage={7} />
                        </div>
                    </div>
                </div>
                {modalIdx >= 1 && <EditAnswer closeModal={() => this.setState({modalIdx: 0})} title={"사유 작성"} text={memo} selectDatas={this.updateMemo} />}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailComment);