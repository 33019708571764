import './edukeyword.css';
import React from 'react';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import { requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';

class KeywordList extends React.Component {
    constructor() {
        super();
        this.state = {
            modalIdx:     0,
            confirmTxt:   '',
            dataList:     [],
            data:         '',
        };
    }

    componentDidMount = () => {
        // 
        this.getData();
    }
    getData = () => {
        requestPost('getProductKeywords', {type: this.props.type}, (res) => {
          if(Boolean(res)) {
            let data = res.data
            let dataList = Boolean(data) ? JSON.parse(data) : []
            this.setState({dataList, data})
          }
        });
    }

    swapData = (indexA, indexB) =>  {
        let {data} = this.state;
        let dataList = [];
        try {
            dataList = Boolean(data) ? JSON.parse(data) : [];
        } catch (error) { }

        let temp = dataList[indexA];
        dataList[indexA] = dataList[indexB];
        dataList[indexB] = temp;
        // 
        this.setState({data: JSON.stringify(dataList)});
    }

    deleteKeyword = () => {
        let {data, modalIdx} = this.state;
        let dataList = [];
        try {
            dataList = Boolean(data) ? JSON.parse(data) : [];
        } catch (error) { }

        dataList.splice(modalIdx - 1000, 1);
        // 
        this.setState({data: JSON.stringify(dataList)});
        this.setState({modalIdx: 0});
    }
    
    update = () => {
        const { data } = this.state;
        const { type } = this.props;

        requestPost('updateKeywordById', {data, type}, (res) => {
            if(Boolean(res)) {
                this.getData();
                toastMsg('저장되었습니다.', 'success');
            }
        });
    }

    render() {
    const { modalIdx, data } = this.state;
    const { type } = this.props;

    let dataList = [];
    try {
        dataList = Boolean(data) ? JSON.parse(data) : [];
    } 
    catch (error) { }

    return (
    <div className='jobkey_list'>
        <div className='job_detail'>
            <div className='job_detail_head'>
                <div className='detail_label'>{type == 'D' ? '난이도' : (type == 'I' ? '관심정보' : '고객유형')}</div>
                <div className='detail_tool'>
                    <div className='btn_normal' onClick={() => this.update()}>저장</div>
                    <div className='btn_link' onClick={() => {
                        if(type == 'C' && dataList.length == 3) {
                            return toastMsg('고객유형은 3개까지 등록가능합니다.');
                        }
                        dataList.push({"title":"","descript":""});
                        this.setState({data: JSON.stringify(dataList)});
                    }}>항목 추가</div>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>순서</th>
                        <th style={{width: '20%'}}>항목명</th>
                        <th style={{width: '40%'}}>설명</th>
                        <th style={{width: '5%'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataList.map((item, index) =>
                        <tr key={index}>
                            <td>
                                <div className='sortrow'>
                                    <div className='up' onClick={() => this.swapData(index, index==0 ? dataList.length-1 : index-1)} />
                                    <div className='down' onClick={() => this.swapData(index, index==dataList.length-1 ? 0 : index+1)} />
                                </div>
                            </td>
                            <td>
                                <input type="text" className='' value={item.title} onChange={(e) => {
                                    item.title = e.target.value;
                                    dataList[index] = item;
                                    this.setState({data: JSON.stringify(dataList)})
                                }} />
                            </td>
                            <td>
                                <input type="text" className='' value={item.descript} onChange={(e) => {
                                    item.descript = e.target.value;
                                    dataList[index] = item;
                                    this.setState({data: JSON.stringify(dataList)})
                                }} 
                                />
                            </td>
                            <td><div className='rmvtxtbtn' onClick={() => this.setState({modalIdx: index + 1000})}>삭제</div></td>
                        </tr>
                        )
                    }
                </tbody>
            </table>
        </div>

        {modalIdx > 1 && <ConformModal closeModal={() => this.setState({modalIdx: 0})} onConfirm={this.deleteKeyword} title={"삭제하기"}>{'항목을 삭제하시겠습니까?\n키워드가 적용된 모든 영역에서 \n해당 값이 삭제됩니다'}</ConformModal>}
    </div>
    );
  }
}

export default withRouter(KeywordList);