import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const itemKey = {
  token:     'token',
  email:     'email',
  userId:    'userId',
  user_name: 'user_name',
  profile:   'profile',
};

export const setItem = (key, value) => {
  // let maxAge = 86400; // a day
  let maxAge = 986400;
  try {
    cookies.set(key, typeof value !== 'string' ? String(value) : value, {path: '/', maxAge});
  } catch (error) {
    throw error;
  }
}

export const getItem = key => {
  try {
    const value = cookies.get(key)
    return value
  } catch(error) {
    // error reading value
    throw error;
  }
}

export const removeItem = key => {
  cookies.remove(key, { path: '/' });
};