import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import consts from "../libs/consts";

const PrivateComponent = ({ element }) => {
    const authacc = useSelector(state => state.authacc);
    return authacc.user_id > 0 ? element : <Navigate to="/login" />;
    // return authacc.user_id > 0 ? element : window.location.href = consts.adminUrl + '/login';
};

export default PrivateComponent;