import React from 'react';
import './login.css';
import { connect } from "react-redux";
import queryString from 'query-string';

import withRouter from '../../components/withRouter';
import { setauthinf } from '../../redux/authacc/AuthaccActions';
import {toastMsg, verifyPassword} from '../../libs/utils';
import {nonAuthedReqPost} from '../../libs/request';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      password:   "",
      pass_msg:   '',
      repassword: "",
      repass_msg: '',
      token:      '',
      enable_token: 0
    };
  }

  componentDidMount = () => {
    // 
    const parsed = queryString.parse(window.location.search);
    const token  = parsed.t ? parsed.t : '';
    this.checkToken(token);
    this.setState({token});
  }
  
  checkToken = (token) => {
    nonAuthedReqPost('checkToken', {token, type: 'admin'}, (res) => {
      if(!res) {  // 토큰 만료
        return this.setState({enable_token: 2}); 
      }
      else {
        return this.setState({enable_token: 1}); 
      }
    });
    
  }

  onClickLogin = ()=>{
    const {repassword, password} = this.state;

    let pass_msg = '';
    switch(verifyPassword(password)) {
      case -10:  pass_msg = '비밀번호를 입력해주세요.'; break;
      case -1: pass_msg = '8~16자리 영문,숫자,특수문자 혼합으로 입력해주세요.'; break;
      case -2: pass_msg = '숫자와 영문자를 혼용하여야 합니다.'; break;
      case -3: pass_msg = '같은 문자를 4번 이상 사용하실 수 없습니다.'; break;
    }
    if(Boolean(pass_msg)) {
      this.setState({pass_msg});
      return;
    }
    else {
      this.setState({pass_msg: ''});
    }
    
    if(password != repassword) {
      this.setState({repass_msg: '비밀번호가 일치하지 않습니다.'});
      return;
    }
    else {
      this.setState({repass_msg: ''});
    }

    this.fnChangePw();
    
  }
  fnChangePw = () => {
    const {token, password} = this.state;
    let param = {token, password, type: 'admin'}
    nonAuthedReqPost('updatePassByToken', param, (res) => {
      if(res) {
        return this.setState({enable_token: 3});
      }
      else {
        toastMsg('비밀번호 변경이 실패하였습니다.', 'warning')
      }
    });
    
  }

  render() {
    const { repassword, password, repass_msg, pass_msg, enable_token } = this.state;
    return (
      <div className="loginpage">
        {enable_token == 1 ?
        <div className='loginform'>
          <div className='formtitle'>비밀번호 재설정</div>
          <div className='login_inp_area'>
            <input type="password" className='input_box mt-3' placeholder='영문, 숫자, 특수문자 포함 8~16자' onChange={(e) => this.setState({password:e.target.value}) } value={password} />
            {Boolean(pass_msg) && <div className="invalid-feedback d-block">{pass_msg}</div>}
            <input type="password" className='input_box mt-3' placeholder='비밀번호 확인' onChange={(e) => this.setState({repassword:e.target.value}) } value={repassword} />
            {Boolean(repass_msg) && <div className="invalid-feedback d-block">{repass_msg}</div>}
          </div>
          <div className='login_tool'>
            <div className='btn_rectangle' onClick={() => this.onClickLogin()}>비밀번호 변경</div>
          </div>
        </div>
        :
        <div className='loginform'>
          <div className='findpagedes pre-wrap text-center'>{enable_token == 2 ? '비밀번호가 이미 변경되었거나 \n토큰이 만료되었습니다.' : '비밀번호가 변경되었습니다.'}</div>
          <div className='login_tool'>
            <div className='btn_rectangle' onClick={() => this.props.router.navigate("/login")}>로그인 페이지로 이동</div>
          </div>
        </div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authacc:state.authacc,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    setauthinf: (val) => dispatch(setauthinf(val)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));