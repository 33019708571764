import './member.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import MemberPause from '../../components/modal/member_pause';

import ConformModal from '../../components/modal/ConformModal';
import Pagination from '../../components/Pagination';
import { toastMsg, renderProductStatus, renderMemberStatus, formatDate, formatNum, renderMethod } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailMember extends React.Component {
    constructor() {
        super();
        this.state = {
            memberId:     0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            alermtab:     0,
            
            totalCnt:     0,
            currentPage:  1,
            dataList:     [],

            totalCnt2:    0,
            currentPage2: 1,
            dataList2:    [],

            reg_type:   '', 
            email:      '', 
            nickname:   '', 
            phone:      '', 
            birthday:   '', 
            gender:     '', 
            marketing:  '', 
            kakao_agree: '', 
            experience:  '', 
            holder:      '', 
            jobtype:    '', 
            tech_stack: '', 
            method:     '', 
            interest:   '', 
            difficulty: '', 
            memo:       '', 
            status:     '', 
            stop_day:   '', 
            exit_time:  '', 
            exit_txt:   '', 
            reg_time:   '', 
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        const memberId  = searchParams.get("id");
        
        this.getData(memberId);
    }

    getData = (memberId) => {
        // window.scrollTo(0, 0);
        this.setState({memberId});
    
        requestPost('getMemberDetail', {memberId}, (res) => {
            if(Boolean(res)) {
                const { reg_type, email, nickname, phone, birthday, gender, marketing, kakao_agree, experience, holder, jobtype, tech_stack, method, interest, difficulty, 
                    area_name, sigungu, area_name2, sigungu2, area_no, memo, auth_token, status, stop_day, exit_time, exit_txt, reg_time,
                    pick_cnt, product_alarm_cnt, campany_alarm_cnt, comment_cnt, post_cnt, prod_req_cnt, question_cnt, survey_cnt, post_report_cnt, acc_report_cnt } = res;
    
                this.setState({ reg_type, email, nickname, phone, birthday, gender, marketing, kakao_agree, experience, holder, jobtype, tech_stack, method, interest, difficulty, 
                    area_name, sigungu, area_name2, sigungu2, area_no, memo, auth_token, status, stop_day, exit_time, exit_txt, reg_time,
                    pick_cnt, product_alarm_cnt, campany_alarm_cnt, comment_cnt, post_cnt, prod_req_cnt, question_cnt, survey_cnt, post_report_cnt, acc_report_cnt });
                // 
                this.getPickData(1, memberId);
                this.getMemberAlarms(1, 0, memberId);
            }
            else {
                alert('삭제된 내역입니다.');
                this.gotoPage(-1);
            }
        });
    }
    getPickData = (currentPage=this.state.currentPage, memberId=this.state.memberId) => {
      this.setState({currentPage});

      const params = { memberId, currentPage }
      requestPost('getPickDataByMemberId', params, (res) => {
        if(Boolean(res)) {
          let totalCnt = 0;
          if(Boolean(res[0])) {
            totalCnt = res[0].total_cnt;
          }
          this.setState({dataList: res, totalCnt});
        }
      });
    }
    getMemberAlarms = (currentPage2=this.state.currentPage2, alermtab=0, memberId=this.state.memberId) => {
      this.setState({currentPage2, alermtab});

      const params = { memberId, currentPage: currentPage2, alermtab }
      requestPost('getMemberAlarms', params, (res) => {
        if(Boolean(res)) {
            let totalCnt2 = 0;
            if(Boolean(res[0])) {
              totalCnt2 = res[0].total_cnt;
            }
            this.setState({dataList2: res, totalCnt2});
        }
      });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    onConfirm = () => {
        const {modalIdx, memberId, email} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
    
        if(modalIdx == 10) {  // 계정 이용 정지 해제
            requestPost('updateMemberStatus', {memberId, status: 'N', type: 'status'}, (res) => {
                if(Boolean(res)) {
                    toastMsg('처리되었습니다.', 'success');
                    this.setState({status: 'N'});
                }
            });
        }
        else if(modalIdx == 11) {  // 해당 회원 메일로 비밀번호 재설정 메일 전송
            requestPost('sendMemberPwReset', {email, memberId}, (res) => {
                if(Boolean(res)) {
                    toastMsg('전송되었습니다.', 'success');
                }
            });
        }
    }
    onSaveMemo = () => {
        const {memberId, memo} = this.state;
        if(!memo) {
            return toastMsg('메모를 작성하세요.');
        }
        requestPost('updateMemberStatus', {memberId, memo, type: 'memo'}, (res) => {
            if(Boolean(res)) {
                toastMsg('저장되었습니다.', 'success');
            }
        });
    }

    render() {
        const { memberId, modalIdx, confirmTitle, confirmTxt, totalCnt, currentPage, dataList, totalCnt2, currentPage2, dataList2, alermtab,
            reg_type, email, nickname, phone, birthday, gender, marketing, kakao_agree, experience, holder, jobtype, tech_stack, method, interest, difficulty, 
            area_name, sigungu, area_name2, sigungu2, area_no, memo, auth_token, status, stop_day, exit_time, exit_txt, reg_time,
            pick_cnt, product_alarm_cnt, campany_alarm_cnt, comment_cnt, post_cnt, prod_req_cnt, question_cnt, survey_cnt, post_report_cnt, acc_report_cnt } = this.state;
            
        const jobtypeList   = Boolean(jobtype) ? jobtype.split(',') : [];
        const techStackList = Boolean(jobtype) ? jobtype.split(',') : [];
        const interestList  = Boolean(jobtype) ? jobtype.split(',') : [];

        return (
            <Leftmenu>
                <div className='page_contents detailmember'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>회원 상세</div>
                            <div className='fralign_center' style={{gap:20}}>
                                <div className='btn_normal' onClick={() => this.setState({confirmTitle: "메일 전송", confirmTxt: "회원의 이메일 주소로 비밀번호 재설정 메일이 전송됩니다. \n전송하시겠습니까?", modalIdx: 11})}>비밀번호 재설정 메일 전송</div>
                            </div>
                        </div>
                        <div className='card_body' style={{ border: 'none' }}>
                            <div className='card_row' style={{ gap: 20, alignItems: 'flex-start', border: 'none' }}>
                                <div style={{ flex: 1, border: '1px solid #EEEEEE', padding: 10 }}>
                                    <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                        <div className='font_D700_16'>기본 정보</div>
                                    </div>
                                    <table className='alignleft'>
                                        <tbody>
                                            <tr>
                                                <th>회원ID</th>
                                                <td>{memberId}</td>
                                            </tr>
                                            <tr>
                                                <th>가입유형</th>
                                                <td>{reg_type == 'E' ? '이메일' : '카카오'}</td>
                                            </tr>
                                            <tr>
                                                <th>닉네임</th>
                                                <td>{nickname}</td>
                                            </tr>
                                            <tr>
                                                <th>휴대폰번호</th>
                                                <td>{(status == 'E' ? '0' : '') + phone}</td>
                                            </tr>
                                            <tr>
                                                <th>이메일주소</th>
                                                <td>{email}</td>
                                            </tr>
                                            <tr>
                                                <th>가입일시</th>
                                                <td>{formatDate(reg_time)}</td>
                                            </tr>
                                            <tr>
                                                <th>계정 상태</th>
                                                <td>{renderMemberStatus(status)}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className='alignleft' style={{marginTop:20}}>
                                        <tbody>
                                            <tr>
                                                <th>생년월일</th>
                                                <td>{birthday}</td>
                                            </tr>
                                            <tr>
                                                <th>성별</th>
                                                <td>{gender == 'M' ? '남성' : '여성'}</td>
                                            </tr>
                                            <tr>
                                                <th>이벤트 정보 수신 동의 여부</th>
                                                <td>{marketing == 1 ? '수신 동의' : '수신 비동의'}</td>
                                            </tr>
                                            <tr>
                                                <th>카카오톡 채널 추가 여부</th>
                                                <td>{kakao_agree == 1 ? '추가' : '미추가'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ flex: 1, border: '1px solid #EEEEEE', padding: 10}}>
                                    <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                        <div className='font_D700_16'>CRM</div>
                                    </div>
                                    <table >
                                        <thead>
                                            <tr>
                                                <th>Pick</th>
                                                <th>알림 신청 (상품/교육사)</th>
                                                <th>게시글</th>
                                                <th>댓글/대댓글</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{pick_cnt}</td>
                                                <td>{product_alarm_cnt}/{campany_alarm_cnt}</td>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/community/list?key='+nickname)}>{post_cnt}</td>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/community/comment?key='+nickname)}>{comment_cnt}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{marginTop:20}}>
                                        <thead>
                                            <tr>
                                                <th>상품문의</th>
                                                <th>서비스의견</th>
                                                <th>평가 및 리뷰</th>
                                                <th>신고된 게시글</th>
                                                <th>계정이 신고된 수</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/crm/queryprod?key='+nickname)}>{prod_req_cnt}</td>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/crm/querysvc?key='+email)}>{question_cnt}</td>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/crm/reviewer?key='+memberId)}>{survey_cnt}</td>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/crm/report?type=2&key='+nickname)}>{post_report_cnt}</td>
                                                <td className='cursor_pointer' onClick={() => this.gotoPage('/crm/report?type=1&key='+nickname)}>{acc_report_cnt}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <textarea style={{marginTop:20, width:'100%', height:200}} maxLength={2000} placeholder="회원에 대한 메모 작성" value={memo ? memo : ''} onChange={({target}) => this.setState({memo: target.value}) } />
                                    <div className='btn_normal' style={{float:'right', marginTop:10}} onClick={this.onSaveMemo}>저장</div>
                                </div>
                            </div>
                        </div>
                        <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>유저 특성 정보</div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>교육경험여부</label>
                                <div className='card_row_val'>
                                    {experience > 0 && <div className='btn_option'>{experience == 1 ? '왕초보' : '유경험자'}</div>}
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>재직여부</label>
                                <div className='card_row_val'>
                                    {Boolean(holder) && <div className='btn_option'>{holder}</div>}
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>직무</label>
                                <div className='card_row_val'>
                                    {
                                        jobtypeList.map((item, index) => <div className='btn_option' key={index}>{item}</div>)
                                    }
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>기술스택</label>
                                <div className='card_row_val'>
                                    {
                                        techStackList.map((item, index) => <div className='btn_option' key={index}>{item}</div>)
                                    }
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>학습방식</label>
                                {Boolean(method) &&
                                <div className='card_row_val'>
                                    {method > 0 && <div className='btn_option'>{renderMethod(method)}</div>}
                                </div>}
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>관심정보</label>
                                <div className='card_row_val'>
                                    {
                                        interestList.map((item, index) => <div className='btn_option' key={index}>{item}</div>)
                                    }
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>난이도</label>
                                <div className='card_row_val'>
                                    {Boolean(difficulty) && <div className='btn_option'>{difficulty}</div>}
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>지역</label>
                                {(Boolean(area_name) || Boolean(area_name2)) &&
                                <div className='card_row_val'>
                                    {Boolean(area_name) && <div className='btn_option'>{`${area_name} ${Boolean(sigungu) ? sigungu : ''}`}</div>}
                                    {Boolean(area_name2) && <div className='btn_option'>{`${area_name2} ${Boolean(sigungu2) ? sigungu2 : ''}`}</div>}
                                </div>}
                                {area_no > 0 &&
                                <div className='card_row_val'>
                                    <div className='btn_option'>관계없음</div>
                                </div>}
                            </div>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>Pick</div>
                                <div className='field_label'>{formatNum(totalCnt)} 개의 교육</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>상품 ID</th>
                                        <th>교육상품명</th>
                                        <th>교육사명</th>
                                        <th>모집마감일시</th>
                                        <th>모집상태</th>
                                        <th>노출상태</th>
                                        <th>저장일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList.length > 0 ?
                                        dataList.map((item, index) =>
                                        <tr className='cursor_pointer' onClick={() => this.gotoPage('/education/leasson/detail?id=' + item.id)} key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.title}</td>
                                            <td>{Boolean(item.user_name) ? item.user_name : `관리자(${item.manager_name})`}</td>
                                            <td>{formatDate(item.expire_time)}</td>
                                            <td>{item.collect ? '모집중' : '모집종료'}</td>
                                            <td>{renderProductStatus(item.status)}</td>
                                            <td>{formatDate(item.reg_time)}</td>
                                        </tr>
                                        )
                                        :
                                        <tr><td colSpan={7}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getPickData(pageIdx)} currentPage={currentPage} perPage={7} />
                        </div>
                        <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>알림 신청 내역</div>
                            </div>
                            <div className='fralign_center' style={{width:'100%'}}>
                                <div className={alermtab === 0?'tab_act':'tab_nor'} onClick={() => this.getMemberAlarms(1, 0)}>상품</div>
                                <div className={alermtab === 1?'tab_act':'tab_nor'} onClick={() => this.getMemberAlarms(1, 1)}>교육사</div>
                                <div style={{flex:1}}></div>
                                <div className='field_label' style={{padding:0}}>{formatNum(totalCnt2)} 개의 교육</div>
                            </div>
                            {alermtab === 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <th>상품 ID</th>
                                        <th>교육상품명</th>
                                        <th>교육사명</th>
                                        <th>모집마감일시</th>
                                        <th>모집상태</th>
                                        <th>신청일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList2.length > 0 ?
                                        dataList2.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.target_id}</td>
                                            <td>{item.title}</td>
                                            <td>{Boolean(item.user_name) ? item.user_name : `관리자(${item.manager_name})`}</td>
                                            <td>{formatDate(item.expire_time)}</td>
                                            <td>{item.collect ? '모집중' : '모집종료'}</td>
                                            <td>{formatDate(item.reg_time)}</td>
                                        </tr>
                                        )
                                        :
                                        <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            :
                            <table>
                                <thead>
                                    <tr>
                                        <th>파트너계정 ID</th>
                                        <th>교육사명</th>
                                        <th>생성일시</th>
                                        <th>활성상태</th>
                                        <th>신청일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList2.length > 0 ?
                                        dataList2.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.target_id}</td>
                                            <td>{item.user_name}</td>
                                            <td>{formatDate(item.create_time)}</td>
                                            <td>{item.status == 1 ? '활성' : '비활성'}</td>
                                            <td>{formatDate(item.reg_time)}</td>
                                        </tr>
                                        )
                                        :
                                        <tr><td colSpan={5}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>}
                            <Pagination totalCnt={totalCnt2} onClick={(pageIdx) => this.getMemberAlarms(pageIdx)} currentPage={currentPage} perPage={7} />
                        </div>
                        {status != 'E' &&
                        <div className='fr_c_end w-100' style={{ padding: '20px 0px', gap:10 }}>
                            {status == 'N' ? <div className='btn_remove' style={{ width: 150 }} onClick={() => this.setState({modalIdx: 1})}>계정 이용 정지</div>
                                :
                                <div className='btn_remove' onClick={() => this.setState({confirmTitle: "계정 정지", confirmTxt: "계정 정지를 해제하시겠습니까?", modalIdx: 10})}>계정 이용 정지 해제</div>}
                        </div>}
                    </div>
                </div>
                {modalIdx == 1 && <MemberPause closeModal={() => this.setState({modalIdx: 0})} memberId={memberId} status={status} onConfirm={(status) => this.setState({status})} />}
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailMember);