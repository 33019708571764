import './report.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';
import { utils, writeFileXLSX } from 'xlsx';
import RTSelect  from 'react-select';
import DateBtnpicker from '../../components/datepicker/btnpicker';
import "react-datepicker/dist/react-datepicker.css";

import {requestPost} from '../../libs/request';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, getCurrentTime } from '../../libs/utils';

const pageLabel = ['', '회원가입자', 'Pick수', 'Pick수(2개 이상)', '방문수', '페이지뷰', '교육 상품 등록 수', '파트너 등록 수', '활성 광고 배너 수', '활성 광고 상품 수'];
const group_1 = [
  { value: 1, label: '회원가입자' },
  { value: 2, label: 'Pick수' },
  { value: 3, label: 'Pick수(2개 이상)' },
];

const group_2 = [
  { value: 4, label: '방문수' },
  { value: 5, label: '페이지뷰' },
];

const group_3 = [
  { value: 6, label: '교육 상품 등록 수' },
  { value: 7, label: '파트너 등록 수' },
];

const group_4 = [
  { value: 8, label: '활성 광고 배너 수' },
  { value: 7, label: '활성 광고 상품 수' },
];

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontFamily: 'Noto Sans KR',
      fontWeight: 400,
      fontSize: 14,
    };
  }
};

const groupedOptions = [
  {
    label: 'g_1',
    options: group_1,
  },
  {
    label: 'g_2',
    options: group_2,
  },
  {
    label: 'g_3',
    options: group_3,
  },
  {
    label: 'g_4',
    options: group_4,
  }
];

const groupStyles = {
  borderTop:'2px solid #CCCCCC',
  // height:15,
};

const GroupHeading = ( props ) => (
  <div style={groupStyles} />
);


class ReportService extends React.Component {
  constructor() {
    super();
    this.state = {
      public_state:0,
      viewtype:1,
      datetype:0,
      seldate:new Date(),
      chartData: [],

      dataList:     []
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }
  getData = (viewtype=1) => {
    window.scrollTo(0, 0);

    const { datetype, seldate } = this.state;
    const params = { viewtype, datetype, seldate: formatDate(seldate) }
    requestPost('getServiceUseStats', params, (res) => {
      if(Boolean(res)) {
        this.setState({chartData: res});
        this.initChart(res);
      }
    });
    // 
    if(viewtype == 3) {   // 2개 이상 픽
      this.getTwoPick();
    }
  }
  getTwoPick = () => {

    const { datetype, seldate } = this.state;
    const params = { datetype, seldate: formatDate(seldate) }
    requestPost('getTwoPickList', params, (res) => {
      if(Boolean(res)) {
        this.setState({dataList: res});
      }
      else {
        this.setState({dataList: []});
      }
    });
  }

  allDownloadExcel = () => {
    const { datetype, seldate, viewtype, chartData } = this.state;
    let prefix = '';
    
    switch (datetype) {
      case 0: prefix = formatDate(seldate).substr(0, 10) + ' '; break;  // 일
      case 1: prefix = formatDate(seldate).substr(0, 7) + '-'; break;  // 월
      case 2: prefix = formatDate(seldate).substr(0, 4) + '-'; break;  // 년
    }

    let value = `당${['일', '월', '년'][datetype]} 누적 계`;
    let data = [];
    const totalVal = formatNum(chartData.reduce((n, {value}) => n + value, 0));
    if(viewtype == 1) {
      data.push({ "일자": value, '회원가입자': totalVal })
    }
    else if(viewtype == 2) {
      data.push({ "일자": value, 'Pick수': totalVal })
    }
    else if(viewtype == 3) {
      data.push({ "일자": value, 'Pick수(2개 이상)': totalVal })
    }
    else if(viewtype == 4) {
      data.push({ "일자": value, '방문수': totalVal })
    }
    else if(viewtype == 5) {
      data.push({ "일자": value, '페이지뷰': totalVal })
    }
    else if(viewtype == 6) {
      data.push({ "일자": value, '교육 상품 등록 수': totalVal })
    }
    else if(viewtype == 7) {
      data.push({ "일자": value, '파트너 등록 수': totalVal })
    }
    else if(viewtype == 8) {
      data.push({ "일자": value, '활성 광고 배너 수': totalVal })
    }
    else if(viewtype == 9) {
      data.push({ "일자": value, '활성 광고 상품 수': totalVal })
    }
    // 
    const pageType = pageLabel[viewtype];
    chartData.map(item => {
      if(viewtype == 1) {
        data.push({ "일자": prefix + item.label, '회원가입자': item.value })
      }
      else if(viewtype == 2) {
        data.push({ "일자": prefix + item.label, 'Pick수': item.value })
      }
      else if(viewtype == 3) {
        data.push({ "일자": prefix + item.label, 'Pick수(2개 이상)': item.value })
      }
      else if(viewtype == 4) {
        data.push({ "일자": prefix + item.label, '방문수': item.value })
      }
      else if(viewtype == 5) {
        data.push({ "일자": prefix + item.label, '페이지뷰': item.value })
      }
      else if(viewtype == 6) {
        data.push({ "일자": prefix + item.label, '교육 상품 등록 수': item.value })
      }
      else if(viewtype == 7) {
        data.push({ "일자": prefix + item.label, '파트너 등록 수': item.value })
      }
      else if(viewtype == 8) {
        data.push({ "일자": prefix + item.label, '활성 광고 배너 수': item.value })
      }
      else if(viewtype == 9) {
        data.push({ "일자": prefix + item.label, '활성 광고 상품 수': item.value })
      }
    });

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `${pageType}_${getCurrentTime()}.xlsx`);
  }

  initChart = (chartData) => {
    var t_x_data = chartData.map((item) => item.label);
    var t_y_data = chartData.map((item) => item.value);
    window.Highcharts.chart('pick_chart', {
      chart: {
        type: 'column'
      },
      title: { text: '' },
      subtitle: { text: '' },
      xAxis: {
        categories: t_x_data,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: { text: '' }
      },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0,
          borderRadius: 4,
        },
        series: {
          states: {
            hover: {
              color: '#22BDC2',
            }
          }
        }
      },
      series: [{
        name: '',
        showInLegend: false,
        data: t_y_data
      }
      ]
    });
  }

  changeDate  = (type) => {
    const { datetype, seldate, viewtype } = this.state;
    let date = new Date(seldate);
    if(datetype == 0) {   // day
      date.setDate(date.getDate() + (type == 1 ? 1 : -1));
    }
    else if(datetype == 1) {   // month
      date.setMonth(date.getMonth() + (type == 1 ? 1 : -1));
    }
    else if(datetype == 2) {   // year
      date.setFullYear(date.getFullYear() + (type == 1 ? 1 : -1));
    }
    this.setState({seldate: date});
    // 
    setTimeout(() => {
      this.getData(viewtype)
    }, 10);
  }
  changeDateType  = (datetype) => {
    const { viewtype } = this.state;
    this.setState({datetype});
    // 
    setTimeout(() => {
      this.getData(viewtype)
    }, 10);
  }

  render() {
    const { datetype, seldate, viewtype, chartData, dataList } = this.state;
    let prefix = '';
    
    switch (datetype) {
      case 0: prefix = formatDate(seldate).substr(0, 10) + ' '; break;  // 일
      case 1: prefix = formatDate(seldate).substr(0, 7) + '-'; break;  // 월
      case 2: prefix = formatDate(seldate).substr(0, 4) + '-'; break;  // 년
    }

    return (
      <Leftmenu>
        <div className='page_contents reportservice'>
          <div className='card' style={{marginBottom:60}}>
            <div className='card_head'>
              <div className='card_head_title'>서비스 이용 통계</div>
              <div className='card_head_tool'>
                <div className='btn_normal' onClick={this.allDownloadExcel}>엑셀 파일로 저장</div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
              <RTSelect
                placeholder=""
                className="rtselect"
                classNamePrefix="rtselect"
                separator={false}
                defaultValue={group_1[0]}
                onChange={(val) => {
                  this.setState({viewtype: val.value});
                  this.getData(val.value);
                }}
                options={groupedOptions}
                styles={colourStyles}
                components={{ GroupHeading }}
              />
              </div>
              <div className='fralign_center' style={{ gap: 40 }}>
                <div className='fralign_center' style={{ gap: 10 }}>
                  <div className={datetype === 0 ? 'btn_normal' : 'btn_link'} onClick={() => this.changeDateType(0)}>일</div>
                  <div className={datetype === 1 ? 'btn_normal' : 'btn_link'} onClick={() => this.changeDateType(1)}>월</div>
                  <div className={datetype === 2 ? 'btn_normal' : 'btn_link'} onClick={() => this.changeDateType(2)}>년</div>
                </div>
                <div className='viewdate_area'>
                  <div className='prev_date' onClick={() => this.changeDate(0)} />
                    <DateBtnpicker selected={seldate} onChange={(date) => {
                      this.setState({ seldate: date });
                      setTimeout(() => {
                        this.getData(viewtype)
                      }, 10);
                    }} format={datetype}/>
                  <div className='next_date' onClick={() => this.changeDate(1)} />
                </div>
              </div>
            </div>
            <div style={{ height: 300, width: '100%' }} id="pick_chart"></div>

            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>{pageLabel[viewtype]}</th>
                </tr>
                <tr>
                  <th>당{['일', '월', '년'][datetype]} 누적 계</th>
                  <td>{formatNum(chartData.reduce((n, {value}) => n + value, 0))}</td>
                </tr>
              </thead>
              <tbody>
                {
                  chartData.map((item, index) =>
                  <tr key={index}>
                    <td>{prefix}{item.label}</td>
                    <td>{formatNum(item.value)}</td>
                  </tr>
                  )
                }
              </tbody>
            </table>
            
            {viewtype == 3 &&
            <table className='mt-4'>
              <thead>
                <tr>
                  <th colSpan={2}>Pick수(2개 이상)</th>
                </tr>
                <tr>
                  <th>아이디</th>
                  <th>닉네임</th>
                </tr>
              </thead>
              <tbody>
                {
                  dataList.map((item, index) =>
                  <tr key={index} className='pointer' onClick={() => window.open('/member/list/detail?id=' + item.id)}>
                    <td>{item.nickname}</td>
                    <td>{item.email}</td>
                  </tr>
                  )
                }
              </tbody>
            </table>}
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(ReportService);