import './education.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';

class NewLeasson extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }
  render() {
    return (
    <Leftmenu>
      
    </Leftmenu>
    );
  }
}

export default withRouter(NewLeasson);