import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { requestPost } from '../../libs/request';
import { replaceSpaceAll, toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';

Modal.setAppElement('#root');

function JobKeyword({ category, afterOpenModal, closeModal, selectDatas, onlyOne=false }) {
	useEffect( () => {
		getData();
        category = Boolean(category) ? category.split(',') : []
        setDataArr(category);
	}, []);
  
  	const [dataArr, setDataArr] = useState([]);       // 상품에 등록된 직무
  	const [keyword, setKeyword] = useState('');
  	const [dataList, setDataList] = useState([]);

  	const getData = () => {
		requestPost('getJobtypeByKeyword', {keyword}, (res) => {
		  if(Boolean(res)) {
			setDataList(res)
		  }
		});
	}
	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			getData();
		}
	}

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 500, height: '70vh', padding:0 }
            }}
        >
            <div className='card jobkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>직무</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='search_input' style={{width:'100%'}}>
                    <input type="text" placeholder='그룹명, 직무명 입력' 
                        value={keyword}
						onKeyUp={handleKeyPress} 
						onChange={e => setKeyword(e.target.value) } 
                    />
                    <div className='search_btn' onClick={getData}></div>
                </div>
                <div className='card_body'>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>ID</th>
                                <th>그룹명</th>
                                <th>직무명</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            dataList.length > 0 ? dataList.map((item, index) => {
                                let arrData = Boolean(item.data) ? JSON.parse(item.data) : [];
                                return (
                                    arrData.map((data, idx) => {
                                        let checked = dataArr?.find((dt) => dt == data.title);
                                        let show = true;
                                        if(Boolean(keyword) && replaceSpaceAll(data?.title?.toUpperCase()).indexOf(replaceSpaceAll(keyword).toUpperCase()) == -1) {
                                            show = false;
                                        }
                                        return !show ? null : 
                                        (
                                            <tr key={idx}>
                                                <td>
                                                    <input type="checkbox" 
                                                        value={data.title} 
                                                        checked={Boolean(checked)} 
                                                        onChange={() => {
                                                            if(onlyOne) {
                                                                setDataArr([data.title]);
                                                            }
                                                            else {
                                                                let dataArr_ = dataArr;
                                                                if(checked) {
                                                                    dataArr_ = dataArr?.filter((dt) => dt != data.title);
                                                                }
                                                                else {
                                                                    dataArr_.push(data.title);
                                                                }
                                                                setDataArr([...dataArr_]);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>{(index + 1) + '' + (idx + 1)}</td>
                                                <td>{item.title}</td>
                                                <td>{data.title}</td>
                                            </tr>
                                        )
                                    })
                                )
                            })
                            :
                            <Nodata colSpan={4} />
                        }
                        </tbody>
                    </table>
                </div>
                <div className='fralign_center' style={{gap:10, flexWrap:'wrap'}}>
                    {
                        dataArr.map((item, index) => <div key={index} className='key_button'>{item}</div>)
                    }
                </div>
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={()=>{
						if(dataArr.length == 0) {
							return toastMsg('직무를 선택하세요.', 'warning');
						}
                        selectDatas && selectDatas(dataArr.join());
                        closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
}

export default JobKeyword;