import './other.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime, renderMemberStatus } from '../../libs/utils';
import consts from '../../libs/consts';

class OtherJobpaper extends React.Component {
  constructor() {
    super();
    this.state = {
      editsort:false,
      dataList: [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = () => {
    window.scrollTo(0, 0);

    requestPost('getWhitePapers', {}, (res) => {
      if(Boolean(res)) {
        this.setState({dataList: res});
      }
    });
  }

  changeOrder = () => {
    const { editsort, dataList } = this.state;
    this.setState({editsort:!editsort});
    
    if(editsort) {  // 변경 완료
      const listIds = dataList.map(dt => dt.id);
      requestPost('updateWhitePaperSort', {listIds}, (res) => {
        if(Boolean(res)) {
            this.getData();
            toastMsg('저장되었습니다.', 'success');
        }
    });
    }
  }
  swapData = (indexA, indexB) =>  {
    let {dataList} = this.state;
    let temp = dataList[indexA];
    dataList[indexA] = dataList[indexB];
    dataList[indexB] = temp;
    // 
    this.setState({dataList});
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  openDetail = (id)=>{
    const { router } = this.props;
    router.navigate('/other/jobpaper/detail?id='+id);
  }

  render() {
    const { editsort, dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents othjobpaper'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>직무백서</div>
            </div>
            <div className='card_head'>
              <div className='btn_normal' onClick={() => this.changeOrder()}>{editsort ? "변경 완료" : "게시 순서 변경"}</div>
              <div className='btn_normal' onClick={() => this.gotoPage('/other/jobpaper/detail')}>신규 게시물 등록</div>
            </div>
            <table>
              <thead>
                <tr>
                  {editsort && (<th></th>)}
                  <th>ID</th>
                  <th>게시 순서</th>
                  <th>직무명</th>
                  <th>노출여부</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index}>
                    {editsort && (<td>
                      <div className='sortrow'>
                        <div className='up' onClick={() => this.swapData(index, index==0 ? dataList.length-1 : index-1)} />
                        <div className='down' onClick={() => this.swapData(index, index==dataList.length-1 ? 0 : index+1)} />
                      </div>
                    </td>)}
                    <td>{item.id}</td>
                    <td>{index + 1}</td>
                    <td className='cursor_pointer' onClick={()=>{this.gotoPage('/other/jobpaper/detail?id=' + item.id + '&sort=' + (index + 1))}}>{item.jobtype}</td>
                    <td>{item.expo == 1 ? '노출' : '비노출'}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={4}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(OtherJobpaper);