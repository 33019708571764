import './report.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import { utils, writeFileXLSX } from 'xlsx';
import ReactToPrint from 'react-to-print';

import Pagination from '../../components/Pagination';
import { toastMsg, isEmptyObj, formatDate, formatDate2, formatTime, getCurrentTime, secondsTwoDayToMHHMMSS, formatNum } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailAdvert extends React.Component {
    constructor() {
        super();
        this.pdfRef = null;
        this.state = {
            bannerId:       0,
            baseDate:       '',

            bannerData:     {},
            reportList:     [],
            jobtypeList:    [],
            keywordList:    [],
            areaList: 	    [],
            totalCnt:       0,
            currentPage:    1,
            chartList:      [],
            chartList2:     [],
            dataList:       [],

            // table datas
            ageDatas:       [],
            genderDatas:    [],
            customerDatas:  [],
            jobDatas:       [],
            methodDatas:    [],
            interestDatas:  [],
            difficultDatas: [],
            areaDatas:      [],
            customerList:   [],
            interestList:   [],
            difficultList:  [],
            jobtypes:       [],
        };
    }

    componentDidMount() {
        const { searchParams } = this.props.router;
        const bannerId  = searchParams.get("id");
        let baseDate = new Date();
        baseDate = baseDate.setDate(baseDate.getDate() - 1);
        this.setState({baseDate: formatDate2(baseDate) + ' 00:00 기준'});
        // 
        this.getData(bannerId);
    }

    getData = (bannerId) => {
        window.scrollTo(0, 0);
        this.setState({bannerId});
    
        requestPost('getAdReportDetail', {bannerId}, (res) => {
            if(Boolean(res)) {
                const { bannerData } = res;
                const { expo, st_time, ed_time, reg_time } = bannerData;

                try {
                    this.initDatas(res);
                    // 
                    let st_date = formatDate2(st_time);
                    let ed_date = formatDate2(ed_time);
                    if(!st_time) {
                        st_date = formatDate2(reg_time);
                    }
                    if(formatDate2(ed_time) > formatDate2(new Date())) {        // 마감기간 안됬음
                        ed_date = formatDate2(new Date());
                    }
                    // 
                    this.getChartData(bannerId, st_date, ed_date);
                } catch (error) {
                    
                }
            }
            else {
                alert('삭제된 내역입니다.');
                this.gotoPage(-1);
            }
        });
    }
    // init datas
    initDatas = (res) => {
        const { bannerData, reportList, jobtypeList, keywordList, areaList } = res;
        this.setState({ bannerData, reportList, jobtypeList, keywordList, areaList });

        let customer  = keywordList?.find(dt => dt.type == 'C') || [];
        let interest  = keywordList?.find(dt => dt.type == 'I') || [];
        let difficult = keywordList?.find(dt => dt.type == 'D') || [];
        const customerList  = Boolean(customer.data) ? JSON.parse(customer.data) : [];
        const interestList  = Boolean(interest.data) ? JSON.parse(interest.data) : [];
        const difficultList = Boolean(difficult.data) ? JSON.parse(difficult.data) : [];
        // 
        let jobtypes = [];
        if(jobtypeList.length > 0) {
            for(let i in jobtypeList) {
                let tmp = jobtypeList[i];
                let tmpList = Boolean(tmp.data) ? JSON.parse(tmp.data) : [];
                jobtypes.push(...tmpList);
            }
        }
        // 
        let ageDatas = [], 
            genderDatas = [{expo: 0, click: 0}, {expo: 0, click: 0}, {expo: 0, click: 0}], 
            customerDatas = [], 
            jobDatas = [], 
            methodDatas = [], 
            interestDatas = [], 
            difficultDatas = [], 
            areaDatas = [];

        // 
        [...new Array(13).fill(0)].map(dt => ageDatas.push({expo: 0, click: 0}));
        [...new Array(5).fill(0)].map(dt => methodDatas.push({expo: 0, click: 0}));

        [...new Array(customerList.length + 1).fill(0)].map(dt => customerDatas.push({expo: 0, click: 0}));
        [...new Array(interestList.length + 1).fill(0)].map(dt => interestDatas.push({expo: 0, click: 0}));
        [...new Array(difficultList.length + 1).fill(0)].map(dt => difficultDatas.push({expo: 0, click: 0}));
        
        [...new Array(jobtypes.length + 1).fill(0)].map(dt => jobDatas.push({expo: 0, click: 0}));
        [...new Array(areaList.length + 1).fill(0)].map(dt => areaDatas.push({expo: 0, click: 0}));

        const setAgeDatas = (idx, type) => {
            if(type == 1)
                ageDatas[idx].click += 1;
            else ageDatas[idx].expo += 1;
        }
        const setCustomerDatas = (idx, type) => {
            if(type == 1)
                customerDatas[idx].click += 1;
            else customerDatas[idx].expo += 1;
        }
        const setjobtypeDatas = (idx, type) => {
            if(type == 1)
                jobDatas[idx].click += 1;
            else jobDatas[idx].expo += 1;
        }
        const setMethodDatas = (idx, type) => {
            if(type == 1)
                methodDatas[idx].click += 1;
            else methodDatas[idx].expo += 1;
        }
        const setInterestDatas = (idx, type) => {
            if(type == 1)
                interestDatas[idx].click += 1;
            else interestDatas[idx].expo += 1;
        }
        const setDifficultDatas = (idx, type) => {
            if(type == 1)
                difficultDatas[idx].click += 1;
            else difficultDatas[idx].expo += 1;
        }
        const setAreaDatas = (idx, type) => {
            if(type == 1)
                areaDatas[idx].click += 1;
            else areaDatas[idx].expo += 1;
        }
        
        for(let i in reportList) {
            let data = reportList[i];
            const {user_id, age, type, gender, holder, jobtype, method, interest, difficulty, area1, area3} = data;
            
            // 유입 사용자 통계
            if(age > 0 && age < 15) {
                setAgeDatas(0, type);
            }
            else if(age >= 15 && age <= 19) {
                setAgeDatas(1, type);
            }
            else if(age >= 20 && age <= 24) {
                setAgeDatas(2, type);
            }
            else if(age >= 25 && age <= 29) {
                setAgeDatas(3, type);
            }
            else if(age >= 30 && age <= 34) {
                setAgeDatas(4, type);
            }
            else if(age >= 35 && age <= 39) {
                setAgeDatas(5, type);
            }
            else if(age >= 40 && age <= 44) {
                setAgeDatas(6, type);
            }
            else if(age >= 45 && age <= 49) {
                setAgeDatas(7, type);
            }
            else if(age >= 50 && age <= 54) {
                setAgeDatas(8, type);
            }
            else if(age >= 55 && age <= 59) {
                setAgeDatas(9, type);
            }
            else if(age >= 60 && age <= 64) {
                setAgeDatas(10, type);
            }
            else if(age >= 65) {
                setAgeDatas(11, type);
            }
            else if(!age) {
                setAgeDatas(12, type);
            }

            // 성별
            if(gender == "M") {
                if(type == 1)
                    genderDatas[0].click += 1;
                else genderDatas[0].expo += 1;
            }
            else if(gender == "W") {
                if(type == 1)
                    genderDatas[1].click += 1;
                else genderDatas[1].expo += 1;
            }
            else if(!gender) {  // 비회원
                if(type == 1)
                    genderDatas[2].click += 1;
                else genderDatas[2].expo += 1;
            }

            // 고객 유형
            customerList.map((dt, idx) =>
                holder == dt.title && setCustomerDatas(idx, type)
            );
            if(!holder) {   // 비회원
                setCustomerDatas(customerList.length, type)
            }
            
            // 직무
            jobtypes.map((dt, idx) =>
                jobtype == dt.title && setjobtypeDatas(idx, type)
            );
            if(!jobtype) {   // 비회원
                setjobtypeDatas(jobtypes.length, type)
            }
            
            // 학습방식
            [1,2,3,4].map((dt, idx) =>
                method == dt && setMethodDatas(idx, type)
            );
            if(!method) {   // 비회원
                setMethodDatas(4, type)
            }
            
            // 관심정보
            interestList.map((dt, idx) =>
                interest == dt.title && setInterestDatas(idx, type)
            );
            if(!interest) {   // 비회원
                setInterestDatas(interestList.length, type)
            }
            
            // 교육 난이도
            difficultList.map((dt, idx) =>
                difficulty == dt.title && setDifficultDatas(idx, type)
            );
            if(!difficulty) {   // 비회원
                setDifficultDatas(difficultList.length, type)
            }
            
            // 지역
            areaList.map((dt, idx) =>
                (area1 == dt.id || area3 == dt.id) && setAreaDatas(idx, type)
            );
            if(!area1 && !user_id) {   // 비회원
                setAreaDatas(areaList.length, type)
            }
        }

        this.setState({ageDatas, genderDatas, customerDatas, jobDatas, methodDatas, interestDatas, difficultDatas, areaDatas, customerList, interestList, difficultList, jobtypes});
    }
    // 
    getChartData = (bannerId, st_date, ed_date) => {
    
        requestPost('getAdReportChartData', {bannerId, st_date, ed_date}, (res) => {
            if(Boolean(res)) {
                this.initChart(res);
                this.setState({ chartList: res, chartList2: res.sort().reverse() });
                setTimeout(() => {
                    this.changeStatsPage(1);
                }, 100);
            }
        });
    }
    initChart = (chartList) => {

        var labels = chartList.map((item) => item.label);
        var expo_cnt = chartList.map((item) => item.expo_cnt);
        var click_cnt = chartList.map((item) => item.click_cnt);
        var ratings = chartList.map((item) => item.expo_cnt == 0 ? 0 : parseFloat( item.click_cnt / item.expo_cnt * 100 ));

        window.Highcharts.chart('pick_chart', {
            //   chart: {
            //     type: 'column'
            //   },
            title: { text: '' },
            subtitle: { text: '' },
            xAxis: {
                categories: labels,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: { text: '' }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.3,
                    borderWidth: 0,
                    borderRadius: 4,
                },
                series: {
                    states: {
                        hover: {
                            color: '#22BDC2',
                        }
                    },
                }
            },
            series: [
            {
                type: 'column',
                name: '노출수',
                showInLegend: true,
                data: expo_cnt,
                tooltip: {
                    pointFormatter: function () { return '노출수 ' + formatNum(this.y);  }
                }
            },
            {
                type: 'column',
                name: '클릭수',
                showInLegend: true,
                data: click_cnt
            },
            {
                type: 'spline',
                name: '클릭율',
                showInLegend: true,
                data: ratings,
                marker: {
                    lineWidth: 2,
                    lineColor: 'red',
                    fillColor: 'white'
                }
            }]
        });
    }
    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    allDownloadExcel = () => {
        const { chartList2 } = this.state;

        let data = [];
        chartList2.map(item => {
          data.push({ 
            "기준일": item.label, "게시시간": "24:00:00", "노출수": formatNum(item.expo_cnt), "클릭수": formatNum(item.click_cnt), 
            "클릭율": (item.expo_cnt > 0 ? (item.click_cnt / item.expo_cnt * 100).toFixed(1) : 0) + "%" 
            })
        });
    
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, `광고 성과 리포트_${getCurrentTime()}.xlsx`);
      }

    onPdfViewer = ()=>{
        const { router } = this.props;
        const { searchParams } = this.props.router;
        var id = searchParams.get("id");
        router.navigate('/report/advert/pdfview?id='+id);
    }

    getBannerPos = (position) => {
        if(!position) {
          return '';
        }
        const posList = ['교육찾기', '교육상품상세', '직무백서', '검색결과', '마이페이지'];
        
        let positions = [];
        for(let i in posList) {
          let pos = posList[i];
          position.split(',').map(idx => {
            if(i == idx) {  // 
                positions.push(pos);
            }
          })
        }
      
        return positions.join();
    }
    getRealDate = () => {
        const { reg_time, expo, st_time, ed_time } = this.state.bannerData;
        
        let st_date = null;
        let ed_date = null;
        if(!st_time) {
            st_date = reg_time;
        }
        else {
            st_date = st_time;
        }
        if(!ed_time || new Date(ed_time) > new Date()) {
            ed_date = new Date();
        }
        else {
            ed_date = ed_time;
        }
      
        let date = secondsTwoDayToMHHMMSS(st_date, ed_date);
        return date;
    }

    changeStatsPage = (currentPage) => {
        const { chartList2 } = this.state;
        const perPage = 7;
        let dataList = chartList2.slice((currentPage-1) * perPage, perPage * currentPage);

        this.setState({currentPage, dataList, });
    }

    render() {
        const { bannerId, baseDate, bannerData, areaList, currentPage, chartList, dataList,
            ageDatas, genderDatas, customerDatas, jobDatas, methodDatas, interestDatas, difficultDatas, areaDatas, customerList, interestList, difficultList, jobtypes } = this.state;
        const { type, title, expo, position, reg_time, enable, disable, st_time, ed_time, click_cnt, expo_cnt } = bannerData;

        return (
            <Leftmenu>
                <div className='page_contents detailAdvert'>
                    <div className='card d-block'>
                        <div className='card_head mb-3'>
                            <div className='card_head_title'>광고 성과 리포트 상세</div>
                            <ReactToPrint
                                trigger={() => {
                                    return <div className='btn_normal'>PDF 다운로드</div>;
                                }}
                                content={() => this.pdfRef}
                            />
                        </div>

                        <div ref={(ref) => this.pdfRef = ref}>
                            <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                                <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                    <div className='font_D700_16'>기본 정보</div>
                                    <label>{baseDate}</label>
                                </div>
                                <table className='alignleft'>
                                    <tbody >
                                        <tr>
                                            <th>ID</th>
                                            <td colSpan={3}>{bannerId}</td>
                                        </tr>
                                        <tr>
                                            <th>배너/상품명</th>
                                            <td className='cursor_pointer' onClick={() => this.gotoPage('/banner/main/detail?id=' + bannerId)}>{title}</td>
                                            <th>게시상태</th>
                                            <td>{enable ? '게시중' : (disable ? '게시종료' : '') }</td>
                                        </tr>
                                        <tr>
                                            <th>광고 유형</th>
                                            <td>{type == 'M' ? '메인 배너' : (type == 'A' ? '광고 배너' : '광고 상품')}</td>
                                            <th>게시위치</th>
                                            <td>{this.getBannerPos(position)}</td>
                                        </tr>
                                        <tr>
                                            <th>게시 기간</th>
                                            <td>{Boolean(st_time) ? formatDate(st_time) : '게시 즉시'} ~ {Boolean(ed_time) ? formatDate(ed_time) : '무제한'}</td>
                                            <th>실 게시일시</th>
                                            <td>{this.getRealDate()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                                <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                    <div className='font_D700_16'>누적 통계</div>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>노출 수</th>
                                            <th>클릭 수</th>
                                            <th>클릭율</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td>{formatNum(expo_cnt)}</td>
                                            <td>{formatNum(click_cnt)}</td>
                                            <td>{expo_cnt == 0 ? 0 : formatNum((click_cnt/expo_cnt * 100).toFixed(1))}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                                <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                    <div className='font_D700_16'>유입 사용자 통계</div>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>나이대</th>
                                            <th>15 미만</th>
                                            <th>15-19</th>
                                            <th>20-24</th>
                                            <th>25-29</th>
                                            <th>30-34</th>
                                            <th>35-39</th>
                                            <th>40-44</th>
                                            <th>45-49</th>
                                            <th>50-54</th>
                                            <th>55-59</th>
                                            <th>60-64</th>
                                            <th>65 이상</th>
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                ageDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                ageDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                ageDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                ageDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>성별</th>
                                            <th>여성</th>
                                            <th>남성</th>
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                genderDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                genderDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                genderDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                genderDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>고객 유형</th>
                                            {customerList.map((item, index) => <th key={index}>{item.title}</th>)}
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                customerDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                customerDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                customerDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                customerDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>직무</th>
                                            {jobtypes.map((item, index) => <th key={index}>{item.title}</th>)}
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                jobDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                jobDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                jobDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                jobDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>학습방식</th>
                                            <th>실시간 온라인 교육</th>
                                            <th>오프라인 교육</th>
                                            <th>이러닝 교육</th>
                                            <th>블렌디드 교육</th>
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                methodDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                methodDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                methodDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                methodDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>관심정보</th>
                                            {interestList.map((item, index) => <th key={index}>{item.title}</th>)}
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                interestDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                interestDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                interestDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                interestDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>교육 난이도</th>
                                            {difficultList.map((item, index) => <th key={index}>{item.title}</th>)}
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                difficultDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                difficultDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                difficultDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                difficultDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='fieldhead'>지역</th>
                                            { areaList.map((item, index) => <th key={index}>{item.short_name}</th>) }
                                            <th>비회원</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td className='fieldhead'>노출수</td>
                                            {
                                                areaDatas.map((item, index) => <td key={index}>{item.expo > 0 ? formatNum(item.expo) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭수</td>
                                            {
                                                areaDatas.map((item, index) => <td key={index}>{item.click > 0 ? formatNum(item.click) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>클릭율(노출대비클릭, %)</td>
                                            {
                                                areaDatas.map((item, index) => <td key={index}>{item.expo > 0 ? (item.click / item.expo * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                        <tr>
                                            <td className='fieldhead'>전체 클릭수 중 비율(%)</td>
                                            {
                                                areaDatas.map((item, index) => <td key={index}>{(item.click / click_cnt * 100) > 0 && click_cnt > 0 ? (item.click / click_cnt * 100).toFixed(1) : ''}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>일별 그래프</div>
                            </div>
                            <div style={{ height: 300, width: '100%' }} id="pick_chart"></div>
                        </div>
                        <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>일별 통계 상세</div>
                                <div className='btn_normal' onClick={this.allDownloadExcel}>엑셀 다운로드</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>기준일</th>
                                        <th>게시시간</th>
                                        <th>노출수</th>
                                        <th>클릭수</th>
                                        <th>클릭율</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataList.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.label}</td>
                                            <td>24:00:00</td>
                                            <td>{formatNum(item.expo_cnt)}</td>
                                            <td>{formatNum(item.click_cnt)}</td>
                                            <td>{item.expo_cnt > 0 ? (item.click_cnt / item.expo_cnt * 100).toFixed(1) : 0}%</td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <Pagination totalCnt={chartList.length} onClick={(pageIdx) => this.changeStatsPage(pageIdx)} currentPage={currentPage} perPage={7} />
                        </div>
                    </div>
                </div>
            </Leftmenu>
        );
    }
}

// const styles = StyleSheet.create({
//     page: {
//         flexDirection: 'row',
//         backgroundColor: '#E4E4E4'
//     },
//     section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1
//     }
// });

export default withRouter(DetailAdvert);