import React from 'react';

import './education.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import UsePrompt from '../../components/UsePrompt';
import Datepicker from '../../components/datepicker';
import Timepicker from '../../components/datepicker/timepicker';
import EduKeyword from '../../components/modal/edu_keyword';
import JobKeyword from '../../components/modal/job_keyword';
import TechKeyword from '../../components/modal/tech_keyword';
import KeywordsModal from '../../components/modal/KeywordsModal';
import QueryPreView from '../../components/modal/query_prevView';
import ConformModal from '../../components/modal/ConformModal';
import EditAnswer from '../../components/modal/edit_answer';
import SmartEditor from '../../components/smarteditor';

import {requestPost, requestFile, nonAuthedReqPost} from '../../libs/request';
import { formatDate2, getEduDays, toastMsg, getCurrentTime, formatTime, isEmptyObj } from '../../libs/utils';
import consts from '../../libs/consts';
import LessonReviewModal from '../../components/modal/LessonReviewModal';

import moment from 'moment';
import ReviewModal from '../../components/modal/ReviewModal';
import ReviewListModal from '../../components/modal/ReviewListModal';
import Pagination from '../../components/Pagination';
import EduCampModal from '../../components/modal/EduCampModal';

// ToDo
// 모달 3개 만들어야함
// 리스트에서 선택 : edu_id 없는 리뷰 목록
// 승인 대기리뷰목록
// 리뷰상세

let org_state = {};
let saved = false;
class CurationDetail extends React.Component {
  editorref = null;
  fileref = null;
  constructor() {
    super();
    this.state = {
      dummy: 0,
      support_price: 0,
      modalIdx:     0,
      checkEdit:    0,
      set_status:   6,
      areaList:     [],
      areaSubList:  [],
      confirmTitle: '',
      confirmTxt:   '',
      hiData:  {},
      jobTitle: '프론트엔드',

      // detail
      partner_id: '',
      user_name:  '', 
      title: '', 
      thumb: '', 
      st_date: new Date(), 
      ed_date: new Date(), 
      expire_date: new Date(), 
      expire_time: new Date(), 
      no_limit_time1: 0, 
      no_limit_time2: 0, 
      price_type: 1, 
      price:      '', 
      cond_txt: '', 
      support_price: -1, 
      method: 1, 
      area1: 1, 
      area2: 1, 
      schedule: '', 
      req_url: '', 
      comment: '', 
      note: '', 
      jobtype: '', 
      tech_stack: '', 
      difficulty: '', 
      interest: '', 
      customer_type: '', 
      // curriclum: '[{"title":"","time":"","data":[{"title":"","time":""}]}]', 
      curriclum: '', 
      curriclum_time: '', 
      lesson_review: '', 
      faq: '', 
      intro: '', 
      return_txt: '', 
      updated: '', 
      reg_time: '', 

      review_input: false,
      review_title: '',
      review_link:  '', 

      reviewPage: 1,
      premiumReviewPage: 1,
      totalReviewCnt: 1,
      totalPremiumReviewCnt: 1,
      id: 0,
      subtitle: '',
      subtitle_yn: 'N',
      status: 1,
      st_sel:  1,
      ed_sel:  1,
      infinite_time: 'Y',
      position: [3, 4, 5],
      bc1: {},
      bc2: {},
      bc3: {},
      bc4: {},
      bc5: {},
      bc6: {},
      bc7: {},
      bc8: {},
    };
  }

  componentDidMount() {

    const { searchParams } = this.props.router;
    let id = searchParams.get("id");   // 파트너에서 정보 수정 > 관리자가 "수정 요청 확인" 했을 때
    id = Boolean(id) ? id : '';
    // 
    org_state = {};
    saved = false;
    org_state = this.state;
    // 
    this.getData(id);
    
    
  }

  getArea = (area_id=1, area2) => {
    nonAuthedReqPost('getAreas', {}, (res) => {
      if(Boolean(res)) {
        this.setState({areaList: res});
        setTimeout(() => {
          this.resetState();
        }, 10);
      }
    });
    nonAuthedReqPost('getSubAreaInfo', {area_id: area_id ? area_id : 1}, (res) => {
      if(Boolean(res)) {
        this.setState({areaSubList: res, area2: (area2 ? area2 : res[0]?.id)});
        setTimeout(() => {
          this.resetState();
        }, 10);
      }
    });
  }

  getReviews(productId, page = 1) {
    console.log(page);
    let { reviewPage } = this.state;
    nonAuthedReqPost('getReviewList', {edu_id: productId, offset: 5, page: page, review_st: 1}, (res) => {
      if (Boolean(res)) {
        console.log(res);
        this.setState({normal_review_list: res.rows, totalReviewCnt: res.totalCount});
      }
    })
  }

  getPremiumReviews(productId, page = 1) {
    let { premiumReviewPage } = this.state;
    nonAuthedReqPost('getPremiumReviewList', {edu_id: productId, offset: 5, page: page, review_st: 1}, (res) => {
      if (Boolean(res)) {
        console.log(res);
        this.setState({premium_review_list: res.rows, totalPremiumReviewCnt: res.totalCount});
      }
    })
  }

  refetchData = () => {
    const { searchParams } = this.props.router;
    let id = searchParams.get("id");  // 파트너에서 정보 수정 > 관리자가 "수정 요청 확인" 했을 때
    id = Boolean(id) ? id : '';
    this.getData(id);
  }

  getData = (id) => {
    window.scrollTo(0, 0);
    this.setState({id: id});
    console.log(id);

    if (id < 1) {

    } else {
      nonAuthedReqPost('getCurationDetail', {id}, (res) => {
        if (Boolean(res)) {
          this.setState({
            id: res.id,
            title: res.title,
            subtitle_yn: res.subtitle_yn,
            subtitle: res.subtitle,
            status: res.status,
            stdate: Boolean(res.st_time) ? new Date( res.st_time ) : new Date(),
            eddate: Boolean(res.ed_time) ? new Date( res.ed_time ) : new Date(), 
            sttime: Boolean(res.st_time) ? new Date( res.st_time ) : new Date(), 
            edtime: Boolean(res.ed_time) ? new Date( res.ed_time ) : new Date(),
            infinite_time: Boolean(res.st_time) && res.st_time.startsWith('1899') && Boolean(res.ed_time) && res.ed_time.startsWith('9999') ? 'Y' : 'N',
            position: res.position?.split(','),
            bc1: {
              id: res.bc1,
              title: res.bc1_title
            },
            bc2: {
              id: res.bc2,
              title: res.bc2_title
            },
            bc3: {
              id: res.bc3,
              title: res.bc3_title
            },
            bc4: {
              id: res.bc4,
              title: res.bc4_title
            },
            bc5: {
              id: res.bc5,
              title: res.bc5_title
            },
            bc6: {
              id: res.bc6,
              title: res.bc6_title
            },
            bc7: {
              id: res.bc7,
              title: res.bc7_title
            },
            bc8: {
              id: res.bc8,
              title: res.bc8_title
            }
          })
        }
      })
    }
    // checkEdit 값이 설정되었을 때 updated된 값을 비교하여 하이라이트 및 수정 되어야 함.
  }

  handleInputChange = (event) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({[name]: value});
    // 
    if(name == 'price_type' && value != 3) {  //가격 유형 유료가 아니면
      this.setState({price: ''});
    }
    if(name == 'area1') {  // 지역 변경
      nonAuthedReqPost('getSubAreaInfo', {area_id: value}, (res) => {
        if(Boolean(res)) {
          this.setState({areaSubList: res, area2: res[0]?.id});
        }
      });
    }

    if (name === 'infinite_time' && value === 'N') {
      this.setState({stdate: new Date(), sttime: new Date(), eddate: new Date(), edtime: new Date()})
    }
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }
  


  update = () => {
    const { id, title, subtitle_yn,subtitle, status, position, bc1, bc2, bc3, jobTitle} = this.state;
       const { searchParams } = this.props.router;
        // const managerId  = searchParams.get("id");
        const managerId  = 100001;

    let cnt = 0;
    let bcs = [];
    if (bc1.id) {
      bcs[cnt] = bc1.id;
      cnt ++;
    }
    if (bc2.id) {
      bcs[cnt] = bc2.id;
      cnt ++;
    }
    if (bc3.id) {
      bcs[cnt] = bc3.id;
      cnt ++;
    }

    if (cnt !== 3) {
      return toastMsg('부트캠프 3개 모두 선택하세요.', 'warning');
    }

    let params = {
      jobTitle, managerId, bcs
    };


    requestPost('upsertJobtypeBoot', params, (res) => {
      if (Boolean(res)) {
        this.gotoPage('/education/camp')
      } else {
        toastMsg('저장에 실패했습니다.')
      }
    })

  }


  updatePos = (idx) => {
    let { position } = this.state;

    if (idx < 0) {
        if (position.length < 3) {
            position = [3, 4, 5]
        } else {
            position = [];
        }

        this.setState({position})
    } else {
        let checked = false;
        for (let i in position) {
            let tmp = position[i];
            if(tmp == idx) {
                position.splice(i, 1);
                checked = true;
                break;
            }
        }
        if(!checked) {
            position.push(idx);
        }
        this.setState({position});
    }
  }

  checkPos = (pos) => {
    let { position } = this.state;
    if (pos < 0) {
        if (position?.length === 3) {
            return true;
        } else {
            return false;
        }
    } else {
        if(!position) {
        return false;
        }
        for(let i in position) {
        let data = position[i];
        if(data == pos) {  // checked
            return true;
        }
        }
    
        return false;
    }
  }

  setBootcamp = (bootcamp, id) => {
    switch(id) {
      case 1:
        this.setState({bc1: bootcamp, modalIdx: 0})
      break;
      case 2:
        this.setState({bc2: bootcamp, modalIdx: 0})
      break;
      case 3:
        this.setState({bc3: bootcamp, modalIdx: 0})
      break;
      case 4:
        this.setState({bc4: bootcamp, modalIdx: 0})
      break;
      case 5:
        this.setState({bc5: bootcamp, modalIdx: 0})
      break;
      case 6:
        this.setState({bc6: bootcamp, modalIdx: 0})
      break;
      case 7:
        this.setState({bc7: bootcamp, modalIdx: 0})
      break;
      case 8: 
        this.setState({bc8: bootcamp, modalIdx: 0})
      break;
      default: break;
    }
  }

  render() {
    const { productId, expire_date, expire_time, set_status, modalIdx, areaList, areaSubList, confirmTitle, confirmTxt, hiData, checkEdit, review_input, review_title, review_link, subtitle_yn, subtitle, jobTitle } = this.state;
    const { partner_id, user_name, title, thumb, no_limit_time1, no_limit_time2, price_type, price, cond_txt, support_price, method, area1, area2, schedule, req_url, comment, 
      note, jobtype, tech_stack, difficulty, interest, customer_type, curriclum, curriclum_time, lesson_review, faq, intro, return_txt, status, period_yn, confirm_st, normal_review_list, premium_review_list, premium_confirm_st, selectedReview, reviewType, matchingYn, totalReviewCnt, totalPremiumReviewCnt, reviewPage, premiumReviewPage } = this.state;

    const { bc1, bc2, bc3, bc4, bc5, bc6, bc7, bc8, currentBootcamp, infinite_time , st_date, st_time, ed_date, ed_time, stdate, eddate, edtime, sttime, st_sel, ed_sel }  = this.state;

    return (
      <Leftmenu>
        <div className='page_contents detailleasson'>
          {/* <UsePrompt isDirty={JSON.stringify(org_state) != JSON.stringify(state1) && !saved} /> */}
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>{jobTitle} {Boolean(productId) ? '상세' : '등록'}</div>
              <div className='fralign_center' style={{ gap: 10 }}>
                {Boolean(productId) || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>}
                {Boolean(productId) || <div className='btn_normal' onClick={() => this.update()}>등록</div>}
              </div>
            </div>
            <div className='card_body'>
              <div className='frjustify_between splite_head' style={{ width: '100%' }}>
                <div className='font_D700_16'>기본 정보</div>
                <div className='field_label'><span>*</span> 필수 입력 항목입니다.</div>
              </div>
              {/* {
              <>
              <div className={`card_row`} style={{ alignItems: 'stretch' }}>
                <div style={{ flex: 1 }}>
                  {productId > 0 && (<div className={`card_row`} style={{ borderTop: 0 }}>
                    <label className='field_label'>상품ID</label>
                    <div className='card_row_val'>{productId}</div>
                  </div>)}
                  <div className={`card_row ${hiData.title ? 'hilight' : ''}`}>
                    <label className='field_label'>큐레이션명<span>*</span></label>
                    <div className='card_row_val'>
                      <input type="text" className='input_box' placeholder='상품명을 입력하세요.' value={title} style={{ width: 330 }} onChange={this.handleInputChange} name='title' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='card_row'>
                  <label className='field_label'>부가설명</label>
                  <div className='card_row_val'>
                      <label className='radio_label' onClick={() => this.setState({subtitle_yn:'Y'})}><input type="radio" name="subtitle_yn" checked={subtitle_yn == 'Y'} onChange={()=>{}}/>사용</label>
                      <label className='radio_label' onClick={() => this.setState({subtitle_yn:'N'})}><input type="radio" name="subtitle_yn" checked={subtitle_yn == 'N'} onChange={()=>{}}/>사용안함</label>
                  </div>
              </div>
              {
                subtitle_yn === 'Y' ?
                <>
                  <div className='card_row'>
                    <div className='card_row_val'>
                      <input style={{width: '100%'}} type={"text"} placeholder={"부가설명을 입력하세요 (최대 64자)"} value={subtitle} onChange={({target}) => this.setState({subtitle: target.value})} maxLength={64} />  
                    </div>
                  </div>
                  </>
                  :
                  null
              }
              <div className='card_row'>
                  <label className='field_label'>게시상태</label>
                  <div className='card_row_val'>
                      <label className='radio_label' onClick={() => this.setState({status:1})}><input type="radio" name="status" checked={status == 1} onChange={()=>{}}/>게시</label>
                      <label className='radio_label' onClick={() => this.setState({status:2})}><input type="radio" name="status" checked={status == 2} onChange={()=>{}}/>미게시</label>
                  </div>
              </div>
              </>} */}
              {/* <div className='card_row'>
                <label className='field_label'>게시 위치 설정</label>
                <div className='card_row_val'>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(-1)} onChange={() => {}} onClick={() => this.updatePos(-1)} />전체</label>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(0)} onChange={() => {}} onClick={() => this.updatePos(0)} />영역1(교육찾기)</label>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(1)} onChange={() => {}} onClick={() => this.updatePos(1)} />영역2(교육상품상세)</label>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(2)} onChange={() => {}} onClick={() => this.updatePos(2)} />영역3(직무백서)</label>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(5)} onChange={() => {}} onClick={() => this.updatePos(5)} />홈</label>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(4)} onChange={() => {}} onClick={() => this.updatePos(4)} />마이페이지</label>
                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(3)} onChange={() => {}} onClick={() => this.updatePos(3)} />부트캠프 탐색 홈</label>
                    
                </div>
            </div> */}
              {/* <div className='card_row'>
                <label className='field_label'>게시 기간 설정</label>
                <div className='card_row_val'>
                    <select value={infinite_time} onChange={this.handleInputChange} name="infinite_time">
                        <option value={'Y'}>무제한</option>
                        <option value={'N'}>게시일 지정</option>
                    </select>
                </div>
                </div>
                {
                    infinite_time === 'N' ?
                    <div className='card_row'>
                        <div className='card_row_val'>
                            <div className='fcjust_center' style={{gap:10}}>
                                <div className='fralign_center' style={{gap:10}}>
                                    <select value={st_sel} onChange={this.handleInputChange} name="st_sel">
                                        <option value={1}>시작일시 지정</option>
                                        <option value={2}>게시 즉시</option>
                                    </select>
                                    {st_sel == 1 &&
                                    <>
                                    <Datepicker selected={stdate} onChange={(date) => this.setState({ stdate: date })} maxDate={eddate} />
                                    <Timepicker selected={sttime} onChange={(val) => this.setState({ sttime: val })} />
                                    </>}
                                </div>
                                <div className='fralign_center' style={{gap:10}}>
                                    <select value={ed_sel} onChange={this.handleInputChange} name="ed_sel">
                                       
                                      <>
                                      <option value={1}>종료일시 지정</option>
                                      <option value={2}>무제한</option>
                                      </>
                                        
                                    </select>
                                    {(ed_sel == 1) &&
                                    <>
                                    <Datepicker selected={eddate} onChange={(date) => this.setState({ eddate: date })} 
                                        minDate={stdate} 
                                        // maxDate={type == 'P' ? prod_expire_time : null} 
                                    />
                                    <Timepicker selected={edtime} onChange={(val) => this.setState({ edtime: val })} />
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    null
                } */}

                <div className='card_row'>
                  <label className='field_label'>부트캠프1</label>
                  <div className='card_row_val'>
                    <input type="text" disabled style={{width: '100%'}} value={bc1.id ? bc1.title : ''}/>
                    {
                      bc1.id ?
                      <div className="btn_normal cancel" onClick={() => this.setState({bc1: {}})}>제거</div>
                      :
                      <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentBootcamp: 1})}>선택</div>
                    }
                  </div>
                </div>
                <div className='card_row'>
                  <label className='field_label'>부트캠프2</label>
                  <div className='card_row_val'>
                    <input type="text" disabled style={{width: '100%'}} value={bc2.id ? bc2.title : ''}/>
                    {
                      bc2.id ?
                      <div className="btn_normal cancel" onClick={() => this.setState({bc2: {}})}>제거</div>
                      :
                      <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentBootcamp: 2})}>선택</div>
                    }
                  </div>
                </div>
                <div className='card_row'>
                  <label className='field_label'>부트캠프3</label>
                  <div className='card_row_val'>
                    <input type="text" disabled style={{width: '100%'}} value={bc3.id ? bc3.title : ''}/>
                    {
                      bc3.id ?
                      <div className="btn_normal cancel" onClick={() => this.setState({bc3: {}})}>제거</div>
                      :
                      <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentBootcamp: 3})}>선택</div>
                    }
                  </div>
                </div>
            </div>
          </div>
        </div>
        {modalIdx == 1 && <EduCampModal closeModal={() => this.setState({modalIdx: 0, currentBootcamp: 0})} confirm={(item) => this.setBootcamp(item, currentBootcamp)}/>}
      </Leftmenu>
    );
  }
}

export default withRouter(CurationDetail);