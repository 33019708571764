import './crm.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import ReviewModal from '../../components/modal/ReviewModal'
import consts from '../../libs/consts';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum, renderReportReason } from '../../libs/utils';
import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import Nodata from '../../components/Nodata';
import { nonAuthedReqPost } from '../../libs/request';
import moment from 'moment';
import ReviewMatchingModal from '../../components/modal/ReviewMatchingModal';

class UnmatchedReviews extends React.Component {
    constructor() {
        super();
        this.state = {
            totalCnt:     0,
            currentPage:  1,
            keyword:      '',
            dataList:     [],
            edu_st: 0,
            checkAll: false,
            type: 'N'
        };
    }
    componentDidMount() {
        // 
        this.getData(1);
    }

    getData = (currentPage=this.state.currentPage, type=this.state.type) => {
        window.scrollTo(0, 0);
        this.setState({currentPage});
        this.setState({checkAll: false});

        const { keyword, edu_st } = this.state;
        const params = { keyword, edu_st, page: currentPage, offset: 10 }
        if (type === 'P') {
            nonAuthedReqPost('getUnmatchedPremiumReviews', params, (res) => {
                if(Boolean(res)) {
                    console.log(res);
                    this.setState({dataList: res.rows, totalCnt: res.totalCount});
                }
            });
        } else {
            nonAuthedReqPost('getUnmatchedReviews', params, (res) => {
                if(Boolean(res)) {
                    console.log(res);
                    this.setState({dataList: res.rows, totalCnt: res.totalCount});
                }
            });
        }
        
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
        this.getData(1);
        }
    }

    changeEduSt = (edu_st) => {
        this.setState({edu_st})
        setTimeout(() => {
            this.getData(1);
        }, 10);
    }

     changeReviewSt = (review_st) => {
        this.setState({review_st})
        setTimeout(() => {
            this.getData(1);
        }, 10);
    }

    checkAll = () => {
        let { dataList, checkAll } = this.state;
        dataList = dataList.map(dt => {
        dt.checked = !checkAll;
        return dt;
        } );
        this.setState({checkAll: !checkAll, dataList})
    }

    matchData = () => {
        console.log("1111")
        this.setState({modalIdx: -2})
    }


    render() {
        const { reportId, confirmTitle, confirmTxt, modalIdx,keyword, totalCnt, edu_st, review_st, dataList, currentPage, selectedReview, checkAll, type } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailreport edukeyword'>
                    <div className='card'>
                        <div className='tab_row'>
                            <div className={type === 'N' ?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({type:'N'}); this.getData(1, 'N')}}>일반후기</div>
                            <div className={type ===  'P' ?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({type:'P'}); this.getData(1, 'P')}}>프리미엄후기</div>
                        </div>
                        <div className='card_head'>
                            <div className='card_head_title'>매칭필요 수강후기 목록</div>
                            <div className='card_head_tool'>
                                <div className='search_input'>
                                <input type="text" placeholder='교육명, 파트너사명, 닉네임, 이메일로 검색' 
                                    value={keyword} 
                                    onKeyUp={this.handleKeyPress} 
                                    onChange={e => this.setState({keyword: e.target.value}) } 
                                />
                                <div className='search_btn' onClick={() => this.getData(1)} />
                                </div>
                            </div>
                        </div>
                        <div className='card_head'>
                            <div className='table_total'><span>{formatNum(totalCnt)}</span>개의 후기</div>
                            <div className='fralign_center' style={{ gap: 60 }}>
                                <div className='fralign_center' style={{ gap: 20 }}>
                                <div className='font_D700_14'>수강상태</div>
                                <label className='radio_label' onClick={() => this.changeEduSt(0)}><input type="radio" name="edu_st" checked={edu_st === 0} onChange={()=>{}}/>전체</label>
                                <label className='radio_label' onClick={() => this.changeEduSt(1)}><input type="radio" name="edu_st" checked={edu_st === 1} onChange={()=>{}}/>면접통과</label>
                                <label className='radio_label' onClick={() => this.changeEduSt(2)}><input type="radio" name="edu_st" checked={edu_st === 2} onChange={()=>{}}/>수강중</label>
                                <label className='radio_label' onClick={() => this.changeEduSt(3)}><input type="radio" name="edu_st" checked={edu_st === 3} onChange={()=>{}}/>수강중단</label>
                                <label className='radio_label' onClick={() => this.changeEduSt(4)}><input type="radio" name="edu_st" checked={edu_st === 4} onChange={()=>{}}/>수료</label>
                                </div>
                            </div>
                        </div>
                        <table>
                            {
                                type === 'P' ?
                                <>
                                    <tr>
                                        <th>
                                            <input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} />
                                        </th>
                                        <th>총점</th>
                                        <th>기수</th>
                                        <th>수강상태</th>
                                        <th>교육기관</th>
                                        <th>교육상품명</th>
                                        <th>종합평가</th>
                                        <th>성별</th>
                                        <th>연령</th>
                                        <th>교육경험</th>
                                        <th>재직여부</th>
                                        <th>작성자</th>
                                        <th>작성일</th>
                                        <th>승인상태</th>
                                        <th>인증상태</th>
                                    </tr>
                                    <tbody style={{textAlign: 'center'}}>
                                        { 
                                        dataList.length > 0 ? dataList.map((item, index) =>
                                        <tr  style={{cursor: 'pointer'}} 
                                            // onClick={() => this.setState({modalIdx: -1, selectedReview: item, reviewType: 'N'})}
                                            >
                                            <td onClick={(e) => e.stopPropagation()}>
                                                <input type='checkbox' onChange={() => {}} onClick={() => {
                                                    let tmpList = dataList;
                                                    tmpList[index].checked = !item.checked;
                                                    // 
                                                    let checkedCnt = tmpList.filter(dt => dt.checked).length
                                                    this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})
                                                }} checked={Boolean(item.checked)} />
                                                </td>
                                            <td style={{fontSize: '12px'}}>{item.score}</td>
                                            <td style={{fontSize: '12px'}}>{item.period || '-'}</td>
                                            <td style={{fontSize: '12px'}}>{item.edu_st === 1 ? '면접통과' : item.edu_st === 2 ? '수강중' : item.edu_st === 3 ? '수강중단' : '수료'}</td>
                                            <td style={{fontSize: '12px'}}>{item.partner_name}</td>
                                            <td style={{fontSize: '12px'}}>{item.edu_name}</td>
                                            <td style={{textAlign: 'left', fontSize: '12px'}}>{item.text_total}</td>
                                            <td>{item.sex === 'M' ? '남' : '여'}</td>
                                            <td>{item.age}</td>
                                            <td>{item.similar_yn}</td>
                                            <td>{item.on_job_yn}</td>
                                            <td style={{fontSize: '12px'}}>{item.user_nickname + '(' + item.email + ')'}</td>
                                            <td style={{fontSize: '12px'}}>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                                            <td style={{fontSize: '12px'}}>{item.review_st === 1 ? '승인' : '승인대기'}</td>
                                            <td style={{fontSize: '12px'}}>{item.cert_st === 1 ? '인증' : '미인증'}</td>
                                        </tr>
                                        )
                                        :
                                        <Nodata colSpan={15} />
                                        }
                                    </tbody>
                                </>
                                :
                                <>
                                <tr>
                                    <th>
                                        <input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} />
                                    </th>
                                    <th>총점</th>
                                    <th>기수</th>
                                    <th>수강상태</th>
                                    <th>교육기관</th>
                                    <th>교육상품명</th>
                                    <th>좋은점</th>
                                    <th>아쉬운점</th>
                                    <th>작성자</th>
                                    <th>작성일</th>
                                    <th>승인상태</th>
                                    <th>인증상태</th>
                                </tr>
                                <tbody style={{textAlign: 'center'}}>
                                    { 
                                    dataList.length > 0 ? dataList.map((item, index) =>
                                    <tr  style={{cursor: 'pointer'}} 
                                        // onClick={() => this.setState({modalIdx: -1, selectedReview: item, reviewType: 'N'})}
                                    >
                                        <td onClick={(e) => e.stopPropagation()}>
                                            <input type='checkbox' onChange={() => {}} onClick={() => {
                                                let tmpList = dataList;
                                                tmpList[index].checked = !item.checked;
                                                // 
                                                let checkedCnt = tmpList.filter(dt => dt.checked).length
                                                this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})
                                            }} checked={Boolean(item.checked)} />
                                            </td>
                                        <td style={{fontSize: '12px'}}>{item.score}</td>
                                        <td style={{fontSize: '12px'}}>{item.period || '-'}</td>
                                        <td style={{fontSize: '12px'}}>{item.edu_st === 1 ? '면접통과' : item.edu_st === 2 ? '수강중' : item.edu_st === 3 ? '수강중단' : '수료'}</td>
                                        <td style={{fontSize: '12px'}}>{item.partner_name}</td>
                                        <td style={{fontSize: '12px'}}>{item.edu_name}</td>
                                        <td style={{textAlign: 'left', fontSize: '12px'}}>{item.pros}</td>
                                        <td style={{textAlign: 'left', fontSize: '12px'}}>{item.cons}</td>
                                        <td style={{fontSize: '12px'}}>{item.user_nickname + '(' + item.email + ')'}</td>
                                        <td style={{fontSize: '12px'}}>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                                        <td style={{fontSize: '12px'}}>{item.review_st === 1 ? '승인' : '승인대기'}</td>
                                        <td style={{fontSize: '12px'}}>{item.cert_st === 1 ? '인증' : '미인증'}</td>
                                    </tr>
                                    )
                                    :
                                    <Nodata colSpan={12} />
                                    }
                                </tbody>
                                </>
                            }
                            
                        </table>
                            <div className='btn_normal' style={{margin: '0 0 0 auto'}} onClick={() => this.matchData()}>매칭</div>
                        
                    </div>
                    
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} perPage={10}/>
                </div>
                {/* 여기에 EduList를 잡을 수 있는 Popup이 새로 들어가야 함. */}
                {modalIdx == -2 && <ReviewMatchingModal reviews={dataList.filter(item => item.checked === true)} type={type} confirm={() => {this.setState({modalIdx: 0, matchingYn: ''}); this.getData(1)}} closeModal={() => {this.setState({modalIdx: 0, selectedReview: null});}} />}
                {modalIdx == -1 && <ReviewModal confirm={() => {this.setState({modalIdx: 0, matchingYn: '', reviewType: ''}); this.getData(1)}} closeModal={() => {this.setState({modalIdx: 0, selectedReview: null, type: ''});}} review={selectedReview} type={type}/>}
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(UnmatchedReviews);