
import React from 'react';
import './education.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import Nodata from '../../components/Nodata';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, renderProductStatus } from '../../libs/utils';

class EduLeasson extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      set_state:    0,
      visible_state:0,
      totalCnt:     0,
      currentPage:  1,
      dataList:     []
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, set_state, visible_state } = this.state;
    const params = { keyword, set_state, visible_state, currentPage }
    requestPost('getProductList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeCollect = (set_state) => {
    this.setState({set_state})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }
  changeStatus = (visible_state) => {
    this.setState({visible_state})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoDetail = (id) => {
    const { router } = this.props;
    router.navigate('/education/leasson/detail?id=' + id);
  }

  render() {
    const { keyword, set_state, visible_state, totalCnt, currentPage, dataList } = this.state;
    
    return (
      <Leftmenu>
        <div className='page_contents eduleasson'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>교육상품</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='상품명, 상품ID, 교육사명으로 검색' 
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
                <div className='btn_normal' onClick={() => this.gotoDetail('')}>신규 교육 등록</div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'><span>{formatNum(totalCnt)}</span>개의 상품</div>
              <div className='fralign_center' style={{ gap: 60 }}>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>모집상태</div>
                  <label className='radio_label' onClick={() => this.changeCollect(0)}><input type="radio" name="set_state" checked={set_state === 0} onChange={()=>{}}/>전체</label>
                  <label className='radio_label' onClick={() => this.changeCollect(1)}><input type="radio" name="set_state" checked={set_state === 1} onChange={()=>{}}/>모집중</label>
                  <label className='radio_label' onClick={() => this.changeCollect(2)}><input type="radio" name="set_state" checked={set_state === 2} onChange={()=>{}}/>모집종료</label>
                </div>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>노출상태</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0)}><input type="radio" name="visible_state" checked={visible_state === 0} onChange={()=>{}}/>전체</label>
                  <label className='radio_label' onClick={() => this.changeStatus(2)}><input type="radio" name="visible_state" checked={visible_state === 2} onChange={()=>{}}/>노출</label>
                  <label className='radio_label' onClick={() => this.changeStatus(1)}><input type="radio" name="visible_state" checked={visible_state === 1} onChange={()=>{}}/>승인대기</label>
                  <label className='radio_label' onClick={() => this.changeStatus(4)}><input type="radio" name="visible_state" checked={visible_state === 4} onChange={()=>{}}/>수정요청</label>
                  <label className='radio_label' onClick={() => this.changeStatus(3)}><input type="radio" name="visible_state" checked={visible_state === 3} onChange={()=>{}}/>비노출</label>
                  <label className='radio_label' onClick={() => this.changeStatus(6)}><input type="radio" name="visible_state" checked={visible_state === 6} onChange={()=>{}}/>임시저장</label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>상품 ID</th>
                  <th>교육상품명</th>
                  <th>파트너사명</th>
                  <th>모집마감일시</th>
                  <th>모집상태</th>
                  <th>노출상태</th>
                  <th>등록자</th>
                </tr>
              </thead>
              <tbody>
                { 
                  dataList.length > 0 ? dataList.map((item, index) =>
                  <tr key={index} onClick={() => this.gotoDetail(item.id)}>
                    <td>{item.id}</td>
                    <td>{item.title}</td>
                    <td>{item.user_name}</td>
                    <td>{item.no_limit_time2==1 ? '상시' : formatDate(item.expire_time)}</td>
                    <td>{item.collect ? '모집중' : '모집종료'}</td>
                    <td>{renderProductStatus(item.status)}</td>
                    <td>{item.manager_id > 0 ? `관리자(${item.manager_name})` : `파트너사(${item.email})`}</td>
                  </tr>
                  )
                  :
                  <Nodata colSpan={7} />
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(EduLeasson);