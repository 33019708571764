import React from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import parse from 'html-react-parser';
import './modal.css'

import Pagination from '../Pagination';
import consts from '../../libs/consts';
import { formatDate2, replaceQuoteAll } from '../../libs/utils';

Modal.setAppElement('#root');
class LessonReviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAll:     false,
      keyword:      this.props.title + ' 후기',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
      selectedList: this.props.selectedList || []
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = (currentPage=this.state.currentPage) => {
    this.setState({currentPage, checkAll: false});
    // 
    const { keyword } = this.state;
    const url = `https://www.googleapis.com/customsearch/v1?key=${consts.googleCSEKey}&cx=${consts.googleCSEcx}&q=${keyword}&start=${(((currentPage-1) * 10) + 1)}`
    axios({
        url,
        method: "GET",
    }).then(response => {
        const {status, data} = response;
        if(status == 200) {
            const {items, searchInformation} = data;
            this.setState({ dataList: [], totalCnt: 0 });
            const totalCnt = parseInt(searchInformation.totalResults) / 10;
            this.setState({
                dataList: items,
                totalCnt: totalCnt > 100 ? 100 : totalCnt
            });
        }
    });
  }

  checkAll = () => {
    let { dataList, selectedList, checkAll } = this.state;
    dataList = dataList.map(dt => {
      dt.checked = !checkAll;
      return dt;
    } );

    const filterArray = (arr1, arr2) => {
        return arr1.filter(item1 => !arr2.some(item2 => item1.title === replaceQuoteAll(item2.title)));
    }
    
    let selectedList_ = filterArray(selectedList, dataList);
    
    if(!checkAll) {
        dataList.map(item => {
            selectedList_.push({
                title:  replaceQuoteAll(item.title), 
                link:   item?.formattedUrl, 
                img:    item?.pagemap?.cse_thumbnail ? item?.pagemap?.cse_thumbnail[0]?.src : '',
                contents: replaceQuoteAll(item.snippet)
            });
        })
    }

    this.setState({checkAll: !checkAll, selectedList: selectedList_})
  }
    
  render() {
    const { keyword, totalCnt, currentPage, dataList, selectedList, checkAll } = this.state;
    const {afterOpenModal, closeModal, selectDatas} = this.props;

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: '80vw', height: '80vh', padding:0 }
            }}
        >
            <div className='card jobkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>수강후기 선택</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='search_input' style={{width:'100%'}}>
                    <input type="text" placeholder='검색어를 입력하세요.' value={keyword} 
                        onChange={({target}) => this.setState({keyword: target.value})} 
                        onKeyUp={(event) => {
                            if(event.key === 'Enter'){
                              this.getData(1);
                            }
                          }} 
                    />
                    <div className='search_btn' onClick={() => this.getData()} />
                </div>
                <div className='card_body'>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} />
                                </th>
                                <th style={{width: '45%'}}>제목</th>
                                <th style={{width: '30%'}}>URL</th>
                                <th>등록일</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList.length > 0 &&
                            dataList.map((item, index) => {
                                let checked = selectedList?.find((dt) => dt.title == replaceQuoteAll(item.title));
                                return (
                                <tr key={index}>
                                    <td>
                                        <input type="checkbox" 
                                            value={item.title} 
                                            checked={Boolean(checked)} 
                                            onChange={() => {
                                                let selectedList_ = selectedList;
                                                if(checked) {
                                                    selectedList_ = selectedList?.filter((dt) => dt.title != replaceQuoteAll(item.title));
                                                }
                                                else {
                                                    selectedList_.push({
                                                        title:  replaceQuoteAll(item?.title), 
                                                        link:   item?.formattedUrl, 
                                                        img:    item?.pagemap?.cse_thumbnail[0]?.src,
                                                        contents: item?.snippet
                                                    });
                                                }
                                                // check all
                                                let tmpList = dataList;
                                                tmpList[index].checked = !item.checked;
                                                // 
                                                let checkedCnt = tmpList.filter(dt => dt.checked).length
                                                this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})

                                                this.setState({selectedList: [...selectedList_]});
                                            }}
                                        />
                                    </td>
                                    <td className='text-left'>{Boolean(item.htmlTitle) && parse(item.htmlTitle)}</td>
                                    <td className='text-left'><span>{item?.formattedUrl?.length > 50 ? item.formattedUrl + '...' : item.formattedUrl}</span></td>
                                    <td>{formatDate2(item?.pagemap?.metatags[0]['article:published_time'])}</td>
                                    <td><div className='btn_link' onClick={() => window.open(item?.formattedUrl)}>새창으로 보기</div></td>
                                </tr>
                                )
                            }
                            )}
                        </tbody>
                    </table>
                </div>
                <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} showLast={false} perPage={10} />
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={()=>{
                        selectDatas && selectDatas(selectedList);
                        closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
    }
}

export default LessonReviewModal;