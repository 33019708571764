import './partner.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import EduKeyword from '../../components/modal/edu_keyword';
import ConformModal from '../../components/modal/ConformModal';

import { checkPerm, formatDate, toastMsg } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailManager extends React.Component {
    constructor() {
        super();
        this.state = {
            modalIdx:   0,
            partnerId:  0,
            partner_id: 0,      // 교육사 선택창에서 선택된 교육사 아이디
            email:      '', 
            user_name:  '', 
            status:     1, 
            admin_accept: 0,
            reg_time:   '',
            perm:       '0,1,2,3,4,5,6',
            partner_name:''
            //
            // [  partner perm
            //    0: '교육사 정보 및 교육 상품 정보 열람',
            //    1: '교육사 정보 수정',
            //    2: '교육 상품 등록',
            //    3: '교육 상품 정보 수정',
            //    4: '교육 상품 정보 삭제',
            //    5: '한줄톡 수정',
            //    6: '상품문의 답변'
            // ]
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        const partnerId  = searchParams.get("id");
        // 
        this.getData(partnerId);

    }

    getData = (partnerId) => {
        this.setState({partnerId});
    
        if(partnerId > 0) {
            requestPost('getPartnerAccountDetail', {managerId: partnerId}, (res) => {
                console.log(res);
                if(Boolean(res)) {
                    const { email, user_name, status, perm, reg_time,partner_name } = res;
        
                    this.setState({ email, user_name, status, perm, reg_time , partner_name });
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    openCompany = () => {
        const { router } = this.props;
        const { partnerId } = this.state;
        router.navigate('/partner/company/detail?id=' + partnerId);
    }

    changePerm = (idx) => {
        const { perm } = this.state;
        const permList = Boolean(perm) ? perm.split(',') : [];

        let checked = false;
        for (let i in permList) {
            let tmp = permList[i];
            if(tmp == idx) {
                permList.splice(i, 1);
                checked = true;
                break;
            }
        }
        if(!checked) {
            permList.push(idx);
        }
        this.setState({perm: permList.join()});
    }
    
    // 교육사 삭제
    deleteCompany = () => {
        const { partnerId } = this.state;

        this.setState({confirmTxt: '', confirmTitle: '', modalIdx: 0});
        requestPost('deletePartnerAccount', {partnerId}, (res) => {
            if(Boolean(res)) {
                this.gotoPage('/partner/manager');
            }
        });
    }
    // 
    update = () => {
        const { partnerId, email, partner_id, accountName } = this.state;
        // 
        if(!(partnerId > 0) && !partner_id) {
            return toastMsg('교육사를 선택하세요.');
        }
        if(!(partnerId > 0) && !email) {
            return toastMsg('이메일을 입력하세요.');
        }
        if(!(partnerId > 0) && !accountName) {
            return toastMsg('담당자명을 입력하세요.');
        }

        if(partnerId) {
            this.fnUpdate();
        }
        else {
            requestPost('ckPartnerEmail', {email}, (res) => {
                if(Boolean(res)) {
                    toastMsg('사용중인 이메일 입니다.');
                }
                else {
                    this.fnUpdate();
                }
            });
        }
    }
    fnUpdate = () => {
        const { partnerId, email, perm, status, partner_id, admin_accept, accountName } = this.state;
        // 
        if(!(partnerId > 0) && !partner_id) {
            return toastMsg('교육사를 선택하세요.');
        }
        if(!(partnerId > 0) && !email) {
            return toastMsg('이메일을 입력하세요.');
        }
        if(!(partnerId > 0) && !accountName) {
            return toastMsg('담당자명을 입력하세요.');
        }

        if(!perm) {
            return toastMsg('권한 정보를 선택하세요.');
        }

        const params = {
            partnerId, email, perm, status, partner_id, admin_accept, accountName
        }

        console.log(params);
        
        requestPost('updatePartnerAcc', params, (res) => {
            if(Boolean(res)) {
                if(!partnerId) {
                    this.props.router.navigate('/partner/manager');
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    render() {
        const { modalIdx, partnerId, email, user_name, status, perm, reg_time, partner_id, accountName , partner_name } = this.state;
        
        return (
            <Leftmenu>
                <div className='page_contents detailmanager'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{partnerId === "0" ? "신규 파트너 계정 등록" : "파트너 계정 상세"}</div>
                            <div className='fralign_center' style={{gap:20}}>
                                {partnerId > 0 && <div className='btn_remove' onClick={() => this.setState({modalIdx: 10})}>파트너 계정 삭제</div>}
                                {partnerId > 0 && <div className='btn_normal' onClick={this.update}>저장</div>}
                                {partnerId > 0 || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>}
                                {partnerId > 0 || <div className='btn_normal' onClick={this.update}>등록</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{gap:10}}>
                            {partnerId > 0 && <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                            </div>}
                            {partnerId > 0 && (<table>
                                <tbody>
                                    <tr>
                                        <th>파트너계정번호</th>
                                        <td>{partnerId}</td>
                                    </tr>
                                    <tr>
                                        <th>이메일(ID)</th>
                                        <td>{email}</td>
                                    </tr>
                                    <tr>
                                        <th>교육사명</th>
                                        <td className='cursor_pointer' onClick={this.openCompany}>{partner_name}</td>
                                    </tr>
                                    <tr>
                                        <th>생성일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                </tbody>
                            </table>)}
                            {partnerId > 0 || <div className='card_row'>
                                <label className='field_label'>교육사</label>
                                <div className='card_row_val'>
                                    <input type="text" readOnly value={user_name} />
                                    <div className='btn_normal' onClick={() => this.setState({modalIdx: 1})}>교육사 검색</div>
                                </div>
                            </div>}
                            {partnerId > 0 || <div className='card_row'>
                                <label className='field_label'>이메일(ID)</label>
                                <div className='card_row_val'>
                                    <input type="text" value={email} onChange={({target}) => this.setState({email: target.value})} />
                                </div>
                            </div>}

                            {partnerId > 0 || <div className='card_row'>
                                <label className='field_label'>담당자명</label>
                                <div className='card_row_val'>
                                    <input type="text" value={accountName} onChange={({target}) => this.setState({accountName: target.value})} />
                                </div>
                            </div>}

                            <div className='card_row'>
                                <label className='field_label'>계정 활성 상태</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({ status: 1 }) }><input type="radio" name="status" checked={status == 1} onChange={() => {}} />활성(로그인가능)</label>
                                    <label className='radio_label' onClick={() => this.setState({ status: 2 }) }><input type="radio" name="status" checked={status == 2} onChange={() => {}} />비활성(로그인불가)</label>
                                </div>
                            </div>
                            <div className='card_row' style={{alignItems:'flex-start'}}>
                                <label className='field_label'>권한 정보</label>
                                <div className='card_row_val'>
                                    <div className='fcjust_center w-100' style={{alignItems:'flex-start'}}>
                                        <label className='radio_label'><input type="checkbox" checked={checkPerm(perm, '0')} onChange={() => {}} onClick={() => this.changePerm(0)} />교육사 정보 및 교육 상품 정보 열람</label>
                                        <div className='fcjust_center w-100' style={{alignItems:'flex-start'}}>
                                            <div className='fralign_center' style={{gap:20}}>
                                                <label className='radio_label' style={{width:150}}><input type="checkbox" checked={checkPerm(perm, '1')} onChange={() => {}} onClick={() => this.changePerm(1)} />교육사 정보 수정</label>
                                            </div>
                                            <div className='fralign_center' style={{gap:20}}>
                                                <label className='radio_label' style={{width:150}}><input type="checkbox" checked={checkPerm(perm, '2')} onChange={() => {}} onClick={() => this.changePerm(2)} />교육 상품 등록</label>
                                                <label className='radio_label' style={{width:150}}><input type="checkbox" checked={checkPerm(perm, '3')} onChange={() => {}} onClick={() => this.changePerm(3)} />교육 상품 정보 수정</label>
                                                <label className='radio_label' style={{width:150}}><input type="checkbox" checked={checkPerm(perm, '4')} onChange={() => {}} onClick={() => this.changePerm(4)} />교육 상품 정보 삭제</label>
                                            </div>
                                            <div className='fralign_center' style={{gap:20}}>
                                                <label className='radio_label' style={{width:150}}><input type="checkbox" checked={checkPerm(perm, '5')} onChange={() => {}} onClick={() => this.changePerm(5)} />한줄톡 수정</label>
                                                <label className='radio_label' style={{width:150}}><input type="checkbox" checked={checkPerm(perm, '6')} onChange={() => {}} onClick={() => this.changePerm(6)} />상품문의 답변</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modalIdx == 1 && <EduKeyword closeModal={() => this.setState({modalIdx: 0})} selectDatas={({id, user_name, p_email, m_email, admin_accept}) => this.setState({partner_id: id, user_name, email: p_email ? p_email : m_email, admin_accept})} partnerId={partner_id} user_name={user_name} status={4} />}
                {modalIdx == 10 && <ConformModal closeModal={() => this.setState({modalIdx: 0})} onConfirm={this.deleteCompany} title={"삭제하기"}>{"교육사 정보를 삭제하시겠습니까? \n해당 교육사 정보 및 등록된 모든 교육 상품 정보가 삭제됩니다."}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailManager);