import { otherActionType } from './OtherActions';

const initOther = {
    count: 0,
};

const other = (state = initOther, action) => {
    switch (action.type) {
        case otherActionType.setotherinf:
            return {
                ...state,
                ...action.value,
            };
        default:
            return state;
    }
};

export default other;