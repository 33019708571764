
import React from 'react';
import $ from 'jquery';

import './partner.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import UsePrompt from '../../components/UsePrompt';
import ConformModal from '../../components/modal/ConformModal';
import Pagination from '../../components/Pagination';
import consts from '../../libs/consts';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum } from '../../libs/utils';
import {requestPost, requestFile, fileDownload} from '../../libs/request';

let org_state = {};
let saved = false;
class DetailCompany extends React.Component {
    constructor() {
        super();
        this.state = {
            dummy: 0,
            partnerId:    0,
            partner_id:   0,        // 파트너사 신청 상세에서 '신규 교육사 생성'으로 넘어왔을 때
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            hiData:  {},
            
            totalCnt:     0,
            currentPage:  1,
            eduList:      [],

            totalCnt2:     0,
            currentPage2:  1,
            oneTalkList:   [],
            // 
            user_name:  '', 
            homepage:   '', 
            intro:      '', 
            thumb:      '', 
            expo:       'E', 
            buss_num:   '', 
            company:    '', 
            owner:      '', 
            service:    '', 
            buss_file:  '', 
            communit_file:  '', 
            brand_file: '', 
            buss_path:  '', 
            communit_path:  '', 
            brand_path: '', 
            manager_name: '', 
            phone:      '', 
            m_email:    '', 
            status:     3, 
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        const partnerId  = searchParams.get("id");
        const partner_id = searchParams.get("partner_id");
        // 
        org_state = {};
        saved = false;
        org_state = this.state;
        // 
        this.getData(partnerId);
        // 
        if(Boolean(partner_id)) {   // 파트너사 신청 상세에서 '신규 교육사 생성'으로 넘어왔을 때
            this.getCompanyData(partner_id);
        }
    }

    getData = (partnerId) => {
        window.scrollTo(0, 0);
        this.setState({partnerId});
    
        if(partnerId > 0) {
            requestPost('getEduCompanyDetail', {partnerId}, (res) => {
                if(Boolean(res)) {
                    const { email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                        brand_path, manager_name, phone, m_email, status, accep_time, reg_time, account_list } = res;
        
                    this.setState({ email, user_name, homepage, intro, thumb: brand_path, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                        brand_path, manager_name, phone, m_email, status, accep_time, reg_time, account_list });
                    // 
                    setTimeout(() => {
                        this.resetState();
                    }, 100);
                    setTimeout(() => {
                        this.resetState();
                    }, 200);
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
            //   
            this.getEduData(1, partnerId);
            this.getOneTalkData(1, partnerId);
        }
        else {
            // 
            setTimeout(() => {
                this.resetState();
            }, 100);
            setTimeout(() => {
                this.resetState();
            }, 200);
        }
    }
    resetState = () => {
      org_state = this.state;
      this.setState({dummy: 1});
      this.setState({dummy: 0});
    }

    getCompanyData = (partner_id) => {
        this.setState({partner_id});
    
        requestPost('getEduCompanyDetail', {partnerId: partner_id}, (res) => {
            if(Boolean(res)) {
                const { homepage, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                    brand_path, manager_name, phone, m_email } = res;
        
                this.setState({ homepage, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                    brand_path, manager_name, phone, m_email, thumb: brand_path, user_name: company });
                // 
                setTimeout(() => {
                    this.resetState();
                }, 100);
            }
            else {
                alert('삭제된 내역입니다.');
                this.gotoPage(-1);
            }
        });
    }
    getEduData = (currentPage=this.state.currentPage, partnerId=this.state.partnerId) => {
      this.setState({currentPage});

      const params = { partnerId, currentPage }
      requestPost('getEduProductsByPartnerId', params, (res) => {
        if(Boolean(res)) {
          let totalCnt = 0;
          if(Boolean(res[0])) {
            totalCnt = res[0].total_cnt;
          }
          this.setState({eduList: res, totalCnt});
          setTimeout(() => {
              this.resetState();
          }, 100);
        }
      });
    }
    getOneTalkData = (currentPage2=this.state.currentPage2, partnerId=this.state.partnerId) => {
      this.setState({currentPage2});

      const params = { partnerId, currentPage: currentPage2 }
      requestPost('getOneTalkByPartnerId', params, (res) => {
        if(Boolean(res)) {
            let totalCnt2 = 0;
            if(Boolean(res[0])) {
              totalCnt2 = res[0].total_cnt;
            }
            this.setState({oneTalkList: res, totalCnt2});
            setTimeout(() => {
                this.resetState();
            }, 100);
        }
      });
    }
    
    handleFileChange = (event, type, org='') => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;
        this.setState({[type]: file_path});
        if(org) {
            this.setState({[org]: originalname});
        }

        if (type === 'brand_path') {
            this.setState({thumb: file_path})
        }
      });
    }
    handleInputChange = (event) => {
      let target = event.target;
      let value = target.value;
      let name = target.name;
  
      this.setState({[name]: value});
      // 
    }
    onConfirm = () => {
      const {modalIdx} = this.state;
      this.setState({modalIdx: 0, confirmTxt: ''});
  
      if(modalIdx == 10) {  // 교육사 삭제
        this.deleteCompany();
      }
      else if(modalIdx == 11) {  // 수정 사항을 승인
        this.update();
      }
    }
    // 교육사 삭제
    deleteCompany = () => {
        const { partnerId } = this.state;

        this.setState({confirmTxt: '', confirmTitle: '', modalIdx: 0});
        requestPost('updateCompanyStatus', {partnerId, type: 'del' }, (res) => {
            if(Boolean(res)) {
                this.gotoPage('/partner/company');
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    openReqUrl = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }
    // 
    update = () => {
        const { partnerId, 
            user_name, homepage, intro, thumb, expo, buss_num, company, buss_file, brand_file, buss_path, brand_path, manager_name, phone, m_email } = this.state;

        let hiData = {};
        if(!user_name) {
            hiData.user_name = 1;
        }
        if(!thumb) {
            hiData.thumb = 1;
        }
        // 
        this.setState({hiData});
        if(!isEmptyObj(hiData)) {  // 필수 항목 미 입력
            return toastMsg('필수 입력 항목들을 입력하세요.');
        }
        // 
        const params = {
            partnerId, 
            data: {
                user_name, homepage, intro, thumb, expo, buss_num, company, buss_file, brand_file, buss_path, brand_path, manager_name, phone, m_email
            }
        }
        requestPost('updateCompanyDetail', params, (res) => {
            if(Boolean(res)) {
                saved = true;
                this.setState({dummy: 1});
                
                if(!partnerId) {
                    setTimeout(() => {
                        this.props.router.navigate('/partner/company');
                    }, 10);
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }
    // 
    fileDownload = (buss_path, buss_file) => {
        window.open(buss_path, '_blank')
    }

    render() {
        const { partnerId, hiData, totalCnt, currentPage, eduList, totalCnt2, currentPage2, oneTalkList, confirmTitle, confirmTxt, modalIdx,  } = this.state;
        const { email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, brand_path, manager_name, phone, m_email, status, accep_time, reg_time, account_list } = this.state;
        console.log(thumb);

        console.log("ACC :", account_list)
        
        let state1 = JSON.parse(JSON.stringify(this.state));

        return (
            <Leftmenu>
                <div className='page_contents detailcompany'>
                    {/* <UsePrompt isDirty={JSON.stringify(org_state) != JSON.stringify(state1) && !saved} /> */}
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{!partnerId ? "신규 교육사 등록" : "교육사 상세"}</div>
                            <div className='fralign_center' style={{gap:20}}>
                                {partnerId > 0 && <div className='btn_normal' onClick={this.update}>저장</div>}
                                {partnerId > 0 || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>}
                                {partnerId > 0 || <div className='btn_normal' onClick={this.update}>등록</div>}
                            </div>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                                <div className='field_label'><span>*</span> 필수 입력 항목입니다.</div>
                            </div>
                            <div className='card_row' style={{ alignItems: 'stretch' }}>
                                <div style={{ flex: 1 }}>
                                    <div className={`card_row ${hiData.user_name ? 'hilight' : ''}`}>
                                        <label className='field_label'>교육사명<span>*</span></label>
                                        <div className='card_row_val'>
                                            <input type="text" className='input_box' placeholder='교육사명을 입력하세요' style={{ width: 330 }} value={user_name} onChange={this.handleInputChange} name='user_name'  />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.homepage ? 'hilight' : ''}`}>
                                        <label className='field_label'>교육사 홈페이지</label>
                                        <div className='card_row_val'>
                                            <input type="text" className='input_box w-100' placeholder='홈페이지 URL을 입력하세요' value={homepage} onChange={this.handleInputChange} name='homepage' />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.intro ? 'hilight' : ''}`}>
                                        <label className='field_label'>교육사 소개</label>
                                        <div className='card_row_val'>
                                            <textarea style={{ width: '100%', height: 100 }} placeholder={"교육사 소개글을 입력하세요"} value={intro ? intro : ''} onChange={this.handleInputChange} name='intro' />
                                        </div>
                                    </div>
                                    {partnerId > 0 && (<div className='card_row'>
                                        <label className='field_label'>노출상태</label>
                                        <div className='card_row_val'>
                                            <label className='radio_label' onClick={() => this.setState({ expo: 'E'}) }><input type="radio" name="expo" checked={expo == 'E'} onChange={() => {}} />노출</label>
                                            <label className='radio_label' onClick={() => this.setState({ expo: 'N' })}><input type="radio" name="expo" checked={expo == 'N'} onChange={() => {}} />비노출</label>
                                        </div>
                                    </div>)}
                                </div>
                                <div className='fcjust_center' style={{ width: 200, alignItems: 'center', borderLeft: '1px solid #EEEEEE', padding: 10, gap: 6, boxSizing: 'border-box' }}>
                                    <label className={`field_label ${hiData.brand_path ? 'hilight' : ''} w-100`}>회사 이미지(또는 로고)<span>*</span> <br /><span className='gray-text fs-11 mt--2'>png, jpg 파일, 500px X 500px 권장</span></label>
                                    <input type="file" name="thumb" onChange={(e) => this.handleFileChange(e, 'brand_path', 'brand_file')} className="d-none" accept="image/*" />
                                    {Boolean(brand_path) && <img src={consts.resourcUrl + brand_path} className='prod_img_bg' />}
                                    <div className='btn_normal' onClick={() => $('input[name=brand_file]').trigger('click')}>이미지등록</div>
                                </div>
                            </div>
                        </div>
                        <div className='card_body b-none'>
                            <div className='card_row' style={{ gap: 20, alignItems: 'flex-start', border: 'none' }}>
                                <div style={{ flex: 1, border: '1px solid #EEEEEE', padding: 10 }}>
                                    <div className='frjustify_between w-100 py-2'>
                                        <div className='font_D700_16'>회사 정보</div>
                                    </div>
                                    <div className={`card_row ${hiData.buss_num ? 'hilight' : ''}`}>
                                        <label className='field_label'>사업자등록번호</label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={buss_num} name='buss_num' 
                                                onChange={(e) => {
                                                    const check = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
                                                    if (e.nativeEvent.data && check.test(e.nativeEvent.data)) {
                                                        return null
                                                    }
                                                    this.setState(({buss_num: e.target.value.replace(/[^0-9]/g, '')}))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.company ? 'hilight' : ''}`}>
                                        <label className='field_label'>회사(법인)명</label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={company} onChange={this.handleInputChange} name='company' />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.buss_file ? 'hilight' : ''}`}>
                                        <label className='field_label'>사업자등록증</label>
                                        <div className='card_row_val'>
                                            {Boolean(buss_file) && <label className='field_label flex-1'>{buss_file}</label>}
                                            <div className='btn_normal' onClick={() => $('input[name=buss_file]').trigger('click')}>{Boolean(buss_file) ? '재업로드' : '파일선택'}</div>
                                            {Boolean(buss_file) && <div className='btn_normal cancel' onClick={() => this.openReqUrl(consts.resourcUrl + buss_path)}>보기</div>}
                                            {Boolean(buss_file) && <div className='btn_link' onClick={() => this.fileDownload(consts.resourcUrl + buss_path, buss_file)}>다운로드</div>}
                                            <input type="file" name="buss_file" onChange={(e) => this.handleFileChange(e, 'buss_path', 'buss_file')} className="d-none" accept="application/pdf, image/*" />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.brand_file ? 'hilight' : ''}`}>
                                        <label className='field_label'>브랜드 로고</label>
                                        <div className='card_row_val'>
                                            {Boolean(brand_file) && <label className='field_label flex-1'>{brand_file}</label>}
                                            <div className='btn_normal' onClick={() => $('input[name=brand_file]').trigger('click')}>{Boolean(brand_file) ? '재업로드' : '파일선택'}</div>
                                            {Boolean(brand_file) && <div className='btn_normal cancel' onClick={() => this.openReqUrl(consts.resourcUrl + brand_path)}>보기</div>}
                                            {Boolean(brand_file) && <div className='btn_link' onClick={() => this.fileDownload(consts.resourcUrl + brand_path, brand_file)}>다운로드</div>}
                                            <input type="file" name="brand_file" onChange={(e) => this.handleFileChange(e, 'brand_path', 'brand_file')} className="d-none" accept="image/*" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1, border: '1px solid #EEEEEE', padding: 10 }}>
                                    <div className='frjustify_between w-100 py-2' >
                                        <div className='font_D700_16'>담당자 정보</div>
                                    </div>
                                    <div className={`card_row ${hiData.manager_name ? 'hilight' : ''}`}>
                                        <label className='field_label'>운영 담당자명</label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={manager_name} onChange={this.handleInputChange} name='manager_name' />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.phone ? 'hilight' : ''}`}>
                                        <label className='field_label'>운영 담당자 휴대전화번호</label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={phone} onChange={this.handleInputChange} name='phone' />
                                        </div>
                                    </div>
                                    <div className={`card_row ${hiData.m_email ? 'hilight' : ''}`}>
                                        <label className='field_label'>운영 담당자 이메일</label>
                                        <div className='card_row_val'>
                                            <input type="text" style={{ flex: 1 }} value={m_email} onChange={this.handleInputChange} name='m_email' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {partnerId > 0 && (<div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>파트너 계정 정보</div>
                                <div className='btn_normal' onClick={() => this.gotoPage('/partner/manager/detail')}>계정 추가</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th><th>생성일시</th><th>활성상태</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {account_list?.length > 0 &&
                                        account_list.map((item) => (
                                            (item.status == 1 || item.status == 2) &&
                                                <tr className='cursor_pointer' onClick={() => this.gotoPage('/partner/manager/detail?id='+item.id)}>
                                                    <td >{item.email}</td><td>{formatDate(item.reg_time)}</td><td>{item.status == 1 ? '활성' : '비활성'}</td>
                                                </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>)}
                        {partnerId > 0 && (<div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>등록된 교육</div>
                                <div className='field_label'>{formatNum(totalCnt)} 개의 교육</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>상품 ID</th>
                                        <th>교육상품명</th>
                                        <th>모집마감일시</th>
                                        <th>모집상태</th>
                                        <th>노출상태</th>
                                        <th>등록자</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eduList.length > 0 ?
                                        eduList.map((item, index) =>
                                        <tr className='cursor_pointer' onClick={() => this.gotoPage('/education/leasson/detail?id=' + item.id)} key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.title}</td>
                                            <td>{formatDate(item.reg_time)}</td>
                                            <td>{item.collect ? '모집중' : '모집종료'}</td>
                                            <td>{renderProductStatus(item.status)}</td>
                                            <td>{item.reg_id > 0 ? `관리자(${item.manager_name})` : `파트너사(${item.email})`}</td>
                                        </tr>
                                        )
                                        :
                                        <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getEduData(pageIdx)} currentPage={currentPage} perPage={7} />
                        </div>)}
                        {partnerId > 0 && (<div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>한줄톡</div>
                                <div className='field_label'>{formatNum(totalCnt2)} 개의 게시글</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>한줄톡 ID</th>
                                        <th>글내용</th>
                                        <th>작성자</th>
                                        <th>노출상태</th>
                                        <th>작성일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oneTalkList.length > 0 ?
                                        oneTalkList.map((item, index) =>
                                        <tr className='cursor_pointer' onClick={() => this.gotoPage('/partner/linetok/detail?id=' + item.id)} key={index}>
                                            <td>{item.id}</td>
                                            <td className='ellipsis1'>{item.contents}</td>
                                            <td>{item.nickname}</td>
                                            <td>{item.expo == 'E' ? '노출' : '비노출'}</td>
                                            <td>{formatDate(item.reg_time)}</td>
                                        </tr>
                                        )
                                        :
                                        <tr><td colSpan={5}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination totalCnt={totalCnt2} onClick={(pageIdx) => this.getOneTalkData(pageIdx)} currentPage={currentPage2} perPage={7} />
                        </div>)}
                        {partnerId > 0 && 
                        <div className='fr_c_end w-100 py-3'>
                            <div className='btn_remove' style={{ width: 120 }} onClick={() => this.setState({confirmTitle: "삭제하기", confirmTxt: "교육사 정보를 삭제하시겠습니까? \n해당 교육사 정보 및 등록된 모든 교육 상품 정보가 삭제됩니다.", modalIdx: 10})}>교육사 삭제</div>
                        </div>}
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailCompany);