import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';

Modal.setAppElement('#root');
function TechKeyword({ category, afterOpenModal, closeModal, selectDatas }) {
	useEffect( () => {
		getData();
        category = Boolean(category) ? category.split(',') : []
        setDataArr(category);
	}, []);
  
  	const [dataArr, setDataArr] = useState([]);       // tbl_tech data
  	const [keyword, setKeyword] = useState('');
  	const [groupList, setGroupList] = useState([]);
  	const [techStackList, setTechStackList] = useState([]);

  	const getData = () => {
		requestPost('getTechStackByKeyword', {keyword}, (res) => {
		    if(Boolean(res)) {
                const {groupList, groupAllList, techStackList} = res;
			    setGroupList(groupAllList);
			    setTechStackList(techStackList);
		    }
		});
	}
	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			getData();
		}
	}

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 800, height: '80vh', padding:0 }
            }}
        >
            <div className='card techkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>기술스택</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='search_input' style={{width:'100%'}}>
                    <input type="text" placeholder='그룹명, 기술스택명 입력' 
                        value={keyword}
						onKeyUp={handleKeyPress} 
						onChange={e => setKeyword(e.target.value) } 
                    />
                    <div className='search_btn' onClick={getData}></div>
                </div>
                <div className='card_body'>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>ID</th>
                                <th>대그룹</th>
                                <th>소그룹</th>
                                <th>기술스택명</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            groupList.length > 0 ? groupList.map((group, index) => {
                                
                                return (
                                    techStackList.map((tech, idx) => {
                                        if(group.id != tech.group_id) {
                                            return null;
                                        }
                                        let arrData = Boolean(tech.data) ? JSON.parse(tech.data) : [];
                                        return (
                                            arrData.map((data, idx) => {
                                                let checked = dataArr?.find((dt) => dt == data.title);
                                                let show = true;
                                                if(Boolean(keyword) && data?.title?.toUpperCase().indexOf(keyword.toUpperCase()) == -1) {
                                                    show = false;
                                                }
                                                if(Boolean(keyword) && tech?.group_name?.toUpperCase().indexOf(keyword.toUpperCase()) >= 0) {
                                                    show = true;
                                                }
                                                return !show ? null : 
                                                (
                                                    <tr key={idx}>
                                                        <td>
                                                            <input type="checkbox" 
                                                                value={data.title} 
                                                                checked={Boolean(checked)} 
                                                                onChange={() => {
                                                                    let dataArr_ = dataArr;
                                                                    if(checked) {
                                                                        dataArr_ = dataArr?.filter((dt) => dt != data.title);
                                                                    }
                                                                    else {
                                                                        dataArr_.push(data.title);
                                                                    }
                                                                    setDataArr([...dataArr_]);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{(index + 1) + '' + (idx + 1)}</td>
                                                        <td>{group.group_name}</td>
                                                        <td>{tech.group_name}</td>
                                                        <td>{data.title}</td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    })
                                )
                            })
                            :
                            <Nodata colSpan={5} />
                        }
                        </tbody>
                    </table>
                </div>
                <div className='fralign_center' style={{gap:10, flexWrap:'wrap'}}>
                    {
                        dataArr.map((item, index) => <div key={index} className='key_button'>{item}</div>)
                    }
                </div>
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={()=>{
						// if(dataArr.length == 0) {
						// 	return toastMsg('기술스택을 선택하세요.', 'warning');
						// }
                        selectDatas && selectDatas(dataArr.join());
                        closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
}

export default TechKeyword;