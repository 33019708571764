import React from 'react';

import './education.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import UsePrompt from '../../components/UsePrompt';
import Datepicker from '../../components/datepicker';
import Timepicker from '../../components/datepicker/timepicker';
import EduKeyword from '../../components/modal/edu_keyword';
import JobKeyword from '../../components/modal/job_keyword';
import TechKeyword from '../../components/modal/tech_keyword';
import KeywordsModal from '../../components/modal/KeywordsModal';
import QueryPreView from '../../components/modal/query_prevView';
import ConformModal from '../../components/modal/ConformModal';
import EditAnswer from '../../components/modal/edit_answer';
import SmartEditor from '../../components/smarteditor';

import { requestPost, requestFile, nonAuthedReqPost } from '../../libs/request';
import {
  formatDate2,
  getEduDays,
  toastMsg,
  getCurrentTime,
  formatTime,
  isEmptyObj,
} from '../../libs/utils';
import consts from '../../libs/consts';
import LessonReviewModal from '../../components/modal/LessonReviewModal';

import moment from 'moment';
import ReviewModal from '../../components/modal/ReviewModal';
import ReviewListModal from '../../components/modal/ReviewListModal';
import Pagination from '../../components/Pagination';
import $ from 'jquery';


// ToDo
// 모달 3개 만들어야함
// 리스트에서 선택 : edu_id 없는 리뷰 목록
// 승인 대기리뷰목록
// 리뷰상세

let org_state = {};
let saved = false;
class DetailLeasson extends React.Component {
  editorref = null;
  fileref = null;
  constructor() {
    super();
    this.state = {
      dummy: 0,
      support_price: 0,
      modalIdx: 0,
      checkEdit: 0,
      set_status: 6,
      areaList: [],
      areaSubList: [],
      confirmTitle: '',
      confirmTxt: '',
      hiData: {},

      // detail
      partner_id: '',
      user_name: '',
      title: '',
      thumb: '',
      st_date: new Date(),
      ed_date: new Date(),
      expire_date: new Date(),
      expire_time: new Date(),
      no_limit_time1: 0,
      no_limit_time2: 0,
      price_type: 1,
      price: '0',
      cond_txt: '',
      support_price: -1,
      method: 1,
      area1: 1,
      area2: 1,
      schedule: '',
      req_url: '',
      comment: '',
      note: '',
      jobtype: '',
      tech_stack: '',
      difficulty: '',
      interest: '',
      customer_type: '',
      // curriclum: '[{"title":"","time":"","data":[{"title":"","time":""}]}]',
      curriclum: '[]',
      curriclum_time: '',
      lesson_review: '',
      faq: '',
      intro: '',
      return_txt: '',
      updated: '',
      status: 6,
      reg_time: '',

      review_input: false,
      review_title: '',
      review_link: '',

      reviewPage: 1,
      premiumReviewPage: 1,
      totalReviewCnt: 1,
      totalPremiumReviewCnt: 1,
      period_yn: 'N',
      confirm_st: 1,
      premium_confirm_st: 1,
    };
  }

  componentDidMount() {
    const { searchParams } = this.props.router;
    let productId = searchParams.get('id');
    let checkEdit = searchParams.get('check'); // 파트너에서 정보 수정 > 관리자가 "수정 요청 확인" 했을 때
    productId = Boolean(productId) ? productId : '';
    checkEdit = Boolean(checkEdit) ? checkEdit : '';
    //
    org_state = {};
    saved = false;
    org_state = this.state;
    //
    this.getData(productId, checkEdit);
  }

  getArea = (area_id = 1, area2) => {
    nonAuthedReqPost('getAreas', {}, (res) => {
      if (Boolean(res)) {
        this.setState({ areaList: res });
        setTimeout(() => {
          this.resetState();
        }, 10);
      }
    });
    nonAuthedReqPost(
      'getSubAreaInfo',
      { area_id: area_id ? area_id : 1 },
      (res) => {
        if (Boolean(res)) {
          this.setState({
            areaSubList: res,
            area2: area2 ? area2 : res[0]?.id,
          });
          setTimeout(() => {
            this.resetState();
          }, 10);
        }
      }
    );
  };

  getReviews(productId, page = 1) {
    console.log(page);
    let { reviewPage } = this.state;
    nonAuthedReqPost(
      'getReviewList',
      { edu_id: productId, offset: 5, page: page, review_st: 1 },
      (res) => {
        if (Boolean(res)) {
          console.log(res);
          this.setState({
            normal_review_list: res.rows,
            totalReviewCnt: res.totalCount,
          });
        }
      }
    );
  }

  getPremiumReviews(productId, page = 1) {
    let { premiumReviewPage } = this.state;
    nonAuthedReqPost(
      'getPremiumReviewList',
      { edu_id: productId, offset: 5, page: page, review_st: 1 },
      (res) => {
        if (Boolean(res)) {
          console.log(res);
          this.setState({
            premium_review_list: res.rows,
            totalPremiumReviewCnt: res.totalCount,
          });
        }
      }
    );
  }

  refetchData = () => {
    const { searchParams } = this.props.router;
    let productId = searchParams.get('id');
    let checkEdit = searchParams.get('check'); // 파트너에서 정보 수정 > 관리자가 "수정 요청 확인" 했을 때
    productId = Boolean(productId) ? productId : '';
    checkEdit = Boolean(checkEdit) ? checkEdit : '';
    this.getData(productId, checkEdit);
  };

  getData = (productId, checkEdit) => {
    window.scrollTo(0, 0);
    this.setState({ productId, checkEdit });

    // checkEdit 값이 설정되었을 때 updated된 값을 비교하여 하이라이트 및 수정 되어야 함.

    const initDatas = (res) => {
      const {
        partner_id,
        user_name,
        title,
        thumb,
        st_date,
        ed_date,
        expire_time,
        no_limit_time1,
        no_limit_time2,
        price_type,
        price,
        cond_txt,
        support_price,
        method,
        area1,
        area2,
        schedule,
        req_url,
        comment,
        note,
        jobtype,
        tech_stack,
        difficulty,
        interest,
        customer_type,
        curriclum,
        curriclum_time,
        lesson_review,
        faq,
        intro,
        return_txt,
        status,
        reg_time,
        period_yn,
        confirm_st,
        premium_confirm_st,
      } = res;

      this.setState({
        partner_id,
        user_name,
        title,
        thumb,
        no_limit_time1,
        no_limit_time2,
        price_type,
        price,
        cond_txt,
        support_price,
        method,
        area1,
        area2,
        schedule,
        req_url,
        jobtype,
        tech_stack,
        difficulty,
        interest,
        customer_type,
        curriclum_time,
        status,
        reg_time,
        set_status: status,
        intro: Boolean(intro) ? intro : '',
        return_txt: Boolean(return_txt) ? return_txt : '',
        comment: Boolean(comment) ? comment : '',
        note: Boolean(note) ? note : '',
        curriclum: Boolean(curriclum) ? curriclum : '',
        lesson_review: Boolean(lesson_review) ? lesson_review : '',
        faq: Boolean(faq) ? faq : '',
        st_date: new Date(st_date),
        ed_date: new Date(ed_date),
        expire_date: new Date(expire_time),
        expire_time: new Date(expire_time),
        period_yn: period_yn,
        confirm_st: confirm_st,
        premium_confirm_st: premium_confirm_st,
      });
      // 지역 리스트 불러오기
      this.getArea(area1, area2);
      this.getReviews(productId);
      this.getPremiumReviews(productId);
      //
      setTimeout(() => {
        this.resetState();
      }, 100);
      setTimeout(() => {
        this.resetState();
      }, 200);
    };
    const checkUpdatedValue = (org_data) => {
      // updated된 값을 비교
      requestPost('getProductDetailTmp', { productId }, (res) => {
        if (Boolean(res)) {
          let hiData = {};
          let arrObjs = [
            'title',
            'thumb',
            'st_date',
            'ed_date',
            'expire_time',
            'no_limit_time1',
            'no_limit_time2',
            'price_type',
            'price',
            'cond_txt',
            'support_price',
            'method',
            'area1',
            'area2',
            'schedule',
            'req_url',
            'comment',
            'note',
            'jobtype',
            'tech_stack',
            'difficulty',
            'interest',
            'customer_type',
            'curriclum',
            'curriclum_time',
            'lesson_review',
            'faq',
            'intro',
            'confirm_st',
            'period_yn',
            'premium_confirm_st',
          ];

          for (let i in arrObjs) {
            let key = arrObjs[i];
            if (res[key] != org_data[key]) {
              // 수정된 데이터 있음
              hiData[key] = 1;
            }
          }
          this.setState({ hiData });

          initDatas(res);
        } else {
          initDatas(org_data);
        }
      });
    };
    if (productId > 0) {
      requestPost('getProductDetail', { productId }, (res) => {
        if (Boolean(res)) {
          if (Boolean(checkEdit)) {
            checkUpdatedValue(res);
          } else {
            initDatas(res);
          }
        } else {
          alert('삭제된 내역입니다.');
          this.gotoPage(-1);
        }
      });
    } else {
      // register
      this.getArea();
      //
      setTimeout(() => {
        this.resetState();
      }, 100);
      setTimeout(() => {
        this.resetState();
      }, 200);
    }
  };
  resetState = () => {
    org_state = this.state;
    this.setState({ dummy: 1 });
    this.setState({ dummy: 0 });
  };

  openfileSelect = () => {
    if (this.fileref != null) {
      this.fileref.click();
    }
  };
  handleFileChange = async (event) => {
    let file = event.target.files[0];
    // get dementions
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    let width = img.width;
    let height = img.height;

    if (width != 720 || height != 378) {
      toastMsg('이미지를 720px X 378px로 권장 드립니다.');
    }

    const formData = new FormData();
    formData.append('file', file);
    requestFile('uploadImage', formData, (res) => {
      this.setState({ thumb: res.file_path });
    });
  };

  handleImgFileChange = (event) => {
    let file = event.target.files[0];
    if(!file) {
      return;
    }
    const formData = new FormData(); 
    formData.append( "file",  file);
    requestFile('uploadImage', formData, (res) => {
      const {file_path, originalname} = res;

  let imgHTML = `<img src="${consts.resourcUrl + file_path}" style="width: 100%">`;
  this.editorref.addContext(imgHTML);
    });
  }
  handleInputChange = (event) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({ [name]: value });
    //
    if (name == 'price_type' && value != 3) {
      //가격 유형 유료가 아니면
      this.setState({ price: '' });
    }
    if (name == 'area1') {
      // 지역 변경
      nonAuthedReqPost('getSubAreaInfo', { area_id: value }, (res) => {
        if (Boolean(res)) {
          this.setState({ areaSubList: res, area2: res[0]?.id });
        }
      });
    }
  };

  openReqUrl = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  };
  renderKeyword = ({ data }) => {
    if (!data) return '';
    data = data.split(',');

    return data.map((item, index) => (
      <div key={index} className="btn_option">
        {item}
      </div>
    ));
  };

  // 커리큘럼 삭제
  removeCurriclum = (index) => {
    let { curriclum } = this.state;
    let curriclumList = Boolean(curriclum) ? JSON.parse(curriclum) : [];
    curriclumList.splice(index, 1);
    this.setState({ curriclum: JSON.stringify(curriclumList) });
  };

  update = () => {
    const {
      expire_date,
      expire_time,
      set_status,
      productId,
      checkEdit,
      partner_id,
      user_name,
      title,
      thumb,
      st_date,
      ed_date,
      no_limit_time1,
      no_limit_time2,
      price_type,
      price,
      cond_txt,
      support_price,
      method,
      area1,
      area2,
      schedule,
      req_url,
      comment,
      note,
      jobtype,
      tech_stack,
      difficulty,
      interest,
      customer_type,
      curriclum,
      curriclum_time,
      lesson_review,
      faq,
      return_txt,
      status,
      reg_time,
      period_yn,
      confirm_st,
      normal_review_list,
      premium_review_list,
      premium_confirm_st,
      totalReviewCnt,
      totalPremiumReviewCnt,
    } = this.state;
    let intro = this.editorref.getContext();
    console.log(curriclum);
    let hiData = {};
    if (set_status == 2 || (!Boolean(productId) && set_status != 6)) {
      // 노출상태를 ‘노출’로 설정시에만 필수 입력 항목의 입력 여부를 체크
      if (!title) {
        hiData.title = 1;
      }
      if (!thumb) {
        hiData.thumb = 1;
      }
      if (!partner_id) {
        hiData.partner_id = 1;
      }
      if (price_type == 3 && price == 0) {
        // 가격유형 - 유료
        hiData.price = 1;
      }
      if (price_type == 2 && !cond_txt) {
        // 조건부 무료
        hiData.cond_txt = 1;
      }
      if (!support_price) {
        hiData.support_price = 1;
      }
      if (!schedule) {
        hiData.schedule = 1;
      }
      if (!req_url) {
        hiData.req_url = 1;
      }
      if (!comment) {
        hiData.comment = 1;
      }
      if (!jobtype) {
        hiData.jobtype = 1;
      }
      if (!difficulty) {
        hiData.difficulty = 1;
      }
      if (!interest) {
        hiData.interest = 1;
      }
      if (!customer_type) {
        hiData.customer_type = 1;
      }
      if (!curriclum) {
        hiData.curriclum = 1;
      }
      //
      this.setState({ hiData });
      if (!isEmptyObj(hiData)) {
        // 필수 항목 미 입력
        return toastMsg('필수 입력 항목들을 입력하세요.');
      }
    } else if (!Boolean(productId) && set_status == 6) {
      if (!title) {
        hiData.title = 1;
      }
      this.setState({ hiData });
      if (!isEmptyObj(hiData)) {
        // 필수 항목 미 입력
        return toastMsg('제목을 입력하세요.');
      }
    } else {
      this.setState({ hiData });
    }

    let stringCurriculum = JSON.stringify([]);

    if (curriclum) {
      const filteredCurriclum = JSON.parse(curriclum).filter((item) => {
        const filteredData = item.data
          ? item.data.filter(
              (subitem) =>
                !Object.values(subitem).every((value) => value === '')
            )
          : [];

        // Remove the "data" key if all its subitems were empty
        if (filteredData.length === 0) {
          delete item.data;
        } else {
          item.data = filteredData;
        }

        return item;
      });
      console.log(filteredCurriclum);
      stringCurriculum = JSON.stringify(filteredCurriclum);
    }

    //
    const params = {
      productId,
      checkEdit,
      data: {
        partner_id,
        title,
        thumb,
        no_limit_time1,
        no_limit_time2,
        price_type,
        price: String(price),
        cond_txt,
        support_price,
        method,
        area1,
        area2,
        schedule,
        req_url,
        comment,
        note,
        jobtype,
        tech_stack,
        difficulty,
        interest,
        customer_type,
        curriclum_time,
        return_txt,
        intro,
        set_status,
        status,
        period_yn,
        confirm_st,
        premium_confirm_st,
        curriclum: Boolean(stringCurriculum)
          ? JSON.stringify(stringCurriculum)
          : '',
        lesson_review: Boolean(lesson_review)
          ? JSON.stringify(lesson_review)
          : '',
        faq: Boolean(faq) ? JSON.stringify(faq) : '',
        st_date: formatDate2(st_date),
        ed_date: formatDate2(ed_date),
        expire_time:
          formatDate2(expire_date) + ' ' + formatTime(expire_time) + ':00',
      },
    };

    requestPost('updateProductDetail', params, (res) => {
      if (Boolean(res)) {
        saved = true;
        this.setState({ dummy: 1 });
        if (!productId) {
          setTimeout(() => {
            this.props.router.navigate('/education/leasson');
          }, 100);
        } else {
          toastMsg('저장되었습니다.', 'success');
          this.setState({ status: set_status });
        }
      } else {
        toastMsg('저장에 실패하였습니다.');
      }
    });
  };
  confirmChanged = () => {
    const { productId } = this.state;
    this.openReqUrl('/education/leasson/detail?check=1&id=' + productId);
  };
  agreeExpo = () => {
    this.setState({
      confirmTitle: '승인 게시',
      confirmTxt:
        '수정 사항을 승인하시겠습니까? \n운영자가 임의로 추가 수정한 정보까지 수정 사항에 반영됩니다.',
      modalIdx: 11,
    });
  };
  // 상품 삭제
  deleteProduct = () => {
    const { productId } = this.state;
    this.setState({ confirmTxt: '', confirmTitle: '', modalIdx: 0 });
    requestPost('deleteProduct', { productId }, (res) => {
      if (Boolean(res)) {
        this.props.router.navigate('/education/leasson');
      }
    });
  };
  // 상품 반려
  returnProduct = (return_txt) => {
    const { productId, partner_id, title } = this.state;
    this.setState({ modalIdx: 0, return_txt });

    const params = {
      productId,
      partner_id,
      title,
      return_txt,
      type: 'return',
    };
    requestPost('updateProductStatus', params, (res) => {
      if (Boolean(res)) {
        toastMsg('반려되었습니다.', 'success');
        this.setState({ status: 3, set_status: 3 });
      } else {
        toastMsg('저장에 실패하였습니다.');
      }
    });
  };
  onConfirm = () => {
    const { modalIdx } = this.state;
    this.setState({ modalIdx: 0, confirmTxt: '' });

    if (modalIdx == 10) {
      // 상품 삭제
      this.deleteProduct();
    } else if (modalIdx == 11) {
      // 수정 사항을 승인
      this.update();
    }
  };

  render() {
    const {
      productId,
      expire_date,
      expire_time,
      set_status,
      modalIdx,
      areaList,
      areaSubList,
      confirmTitle,
      confirmTxt,
      hiData,
      checkEdit,
      review_input,
      review_title,
      review_link,
    } = this.state;
    const {
      partner_id,
      user_name,
      title,
      thumb,
      st_date,
      ed_date,
      no_limit_time1,
      no_limit_time2,
      price_type,
      price,
      cond_txt,
      support_price,
      method,
      area1,
      area2,
      schedule,
      req_url,
      comment,
      note,
      jobtype,
      tech_stack,
      difficulty,
      interest,
      customer_type,
      curriclum,
      curriclum_time,
      lesson_review,
      faq,
      intro,
      return_txt,
      status,
      period_yn,
      confirm_st,
      normal_review_list,
      premium_review_list,
      premium_confirm_st,
      selectedReview,
      reviewType,
      matchingYn,
      totalReviewCnt,
      totalPremiumReviewCnt,
      reviewPage,
      premiumReviewPage,
    } = this.state;

    const cur_time = getCurrentTime();
    const exp_time =
      formatDate2(expire_date) + ' ' + formatTime(expire_time) + ':00'; // 모집마감일시
    //
    let curriclumList = [];
    let faqList = [];
    let reviewList = [];
    try {
      curriclumList = Boolean(curriclum) ? JSON.parse(curriclum) : [];
      faqList = Boolean(faq) ? JSON.parse(faq) : [];
      reviewList = Boolean(lesson_review) ? JSON.parse(lesson_review) : [];
    } catch (error) {
      console.error(error);
    }

    let state1 = JSON.parse(JSON.stringify(this.state));

    return (
      <Leftmenu>
        <div className="page_contents detailleasson">
          {/* <UsePrompt isDirty={JSON.stringify(org_state) != JSON.stringify(state1) && !saved} /> */}
          <div className="card">
            <div className="card_head">
              <div className="card_head_title">
                교육 상품 {Boolean(productId) ? '상세' : '등록'}
              </div>
              <div className="fralign_center" style={{ gap: 10 }}>
                {Boolean(productId) || (
                  <div
                    className="btn_normal cancel"
                    onClick={() => this.gotoPage(-1)}
                  >
                    취소
                  </div>
                )}
                {Boolean(productId) || (
                  <div className="btn_normal" onClick={() => this.update()}>
                    등록
                  </div>
                )}
                {status == 4 && checkEdit == 0 && (
                  <div
                    className="btn_link"
                    onClick={() => this.confirmChanged()}
                  >
                    수정 요청 확인
                  </div>
                )}
                {status == 4 && checkEdit == 1 ? (
                  <>
                    <div
                      className="btn_link"
                      onClick={() => this.setState({ modalIdx: 9 })}
                    >
                      반려
                    </div>
                    <div
                      className="btn_normal"
                      onClick={() => this.agreeExpo()}
                    >
                      승인 게시
                    </div>
                  </>
                ) : (
                  Boolean(productId) && (
                    <>
                      <div
                        className="btn_link"
                        onClick={() =>
                          window.open(
                            consts.domain + 'edudetail?type=1&id=' + productId
                          )
                        }
                      >
                        미리보기
                      </div>
                      <div className="btn_normal" onClick={this.update}>
                        저장
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
            <div className="card_body">
              <div
                className="frjustify_between splite_head"
                style={{ width: '100%' }}
              >
                <div className="font_D700_16">기본 정보</div>
                <div className="field_label">
                  <span>*</span> 필수 입력 항목입니다.
                </div>
              </div>
              {
                <>
                  <div className={`card_row`} style={{ alignItems: 'stretch' }}>
                    <div style={{ flex: 1 }}>
                      {productId > 0 && (
                        <div className={`card_row`} style={{ borderTop: 0 }}>
                          <label className="field_label">상품ID</label>
                          <div className="card_row_val">{productId}</div>
                        </div>
                      )}
                      <div className={`card_row`}>
                        <label className="field_label">노출상태</label>
                        <div className="card_row_val">
                          <label
                            className="radio_label"
                            onClick={() => {
                              this.setState({ set_status: 2 });
                            }}
                          >
                            <input
                              type="radio"
                              name="set_status"
                              checked={set_status === 2}
                              onChange={() => {}}
                            />
                            노출
                          </label>
                          <label
                            className="radio_label"
                            onClick={() => {
                              this.setState({ set_status: 1 });
                            }}
                          >
                            <input
                              type="radio"
                              name="set_status"
                              checked={set_status === 1}
                              onChange={() => {}}
                            />
                            승인대기
                          </label>
                          <label
                            className="radio_label"
                            onClick={() => {
                              this.setState({ set_status: 4 });
                            }}
                          >
                            <input
                              type="radio"
                              name="set_status"
                              checked={set_status === 4}
                              onChange={() => {}}
                            />
                            수정요청
                          </label>
                          <label
                            className="radio_label"
                            onClick={() => {
                              this.setState({ set_status: 3 });
                            }}
                          >
                            <input
                              type="radio"
                              name="set_status"
                              checked={set_status === 3}
                              onChange={() => {}}
                            />
                            비노출
                          </label>
                          <label
                            className="radio_label"
                            onClick={() => {
                              this.setState({ set_status: 6 });
                            }}
                          >
                            <input
                              type="radio"
                              name="set_status"
                              checked={set_status === 6}
                              onChange={() => {}}
                            />
                            임시저장
                          </label>
                        </div>
                      </div>
                      <div
                        className={`card_row ${hiData.title ? 'hilight' : ''}`}
                      >
                        <label className="field_label">
                          교육상품명<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <input
                            type="text"
                            className="input_box"
                            placeholder="상품명을 입력하세요."
                            value={title}
                            style={{ width: 330 }}
                            onChange={this.handleInputChange}
                            name="title"
                          />
                        </div>
                      </div>
                      <div
                        className={`card_row ${
                          hiData.partner_id ? 'hilight' : ''
                        }`}
                      >
                        <label className="field_label">
                          교육사<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <input
                            type="text"
                            className="input_box"
                            placeholder="교육사를 입력하세요."
                            value={user_name}
                            style={{ width: 200 }}
                            readOnly
                          />
                          <div
                            className="btn_normal"
                            onClick={() => this.setState({ modalIdx: 1 })}
                          >
                            교육사 입력
                          </div>
                          {Boolean(partner_id) && (
                            <div
                              className="btn_normal"
                              onClick={() =>
                                this.gotoPage(
                                  '/partner/company/detail?id=' + partner_id
                                )
                              }
                            >
                              교육사 상세보기
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`card_row ${
                          hiData.st_date || hiData.ed_date ? 'hilight' : ''
                        }`}
                      >
                        <label className="field_label">
                          교육시작/종료일<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <Datepicker
                            selected={st_date}
                            disabled={no_limit_time1 == 1}
                            onChange={(date) => {
                              if (date > ed_date) {
                                this.setState({ st_date: date, ed_date: date });
                              } else this.setState({ st_date: date });
                            }}
                          />
                          <Datepicker
                            selected={ed_date}
                            disabled={no_limit_time1 == 1}
                            onChange={(date) =>
                              this.setState({ ed_date: date })
                            }
                            minDate={st_date}
                          />
                          <span>{getEduDays(st_date, ed_date)}</span>
                          <label className="radio_label" style={{ width: 150 }}>
                            <input
                              type="checkbox"
                              checked={no_limit_time1}
                              onChange={() => {}}
                              onClick={() =>
                                this.setState({
                                  no_limit_time1: no_limit_time1 == 1 ? 0 : 1,
                                })
                              }
                            />
                            제한 없음
                          </label>
                        </div>
                      </div>
                      <div
                        className={`card_row ${
                          hiData.expire_time ? 'hilight' : ''
                        }`}
                      >
                        <label className="field_label">
                          모집마감일시<span>*</span>
                        </label>
                        <div className="card_row_val">
                          <Datepicker
                            selected={expire_date}
                            onChange={(date) =>
                              this.setState({ expire_date: date })
                            }
                            disabled={no_limit_time2 == 1}
                          />
                          <Timepicker
                            selected={expire_time}
                            onChange={(val) =>
                              this.setState({ expire_time: val })
                            }
                            disabled={no_limit_time2 == 1}
                          />
                          <span>
                            {cur_time < exp_time ? '모집중' : '모집종료'}
                          </span>
                          <label className="radio_label" style={{ width: 150 }}>
                            <input
                              type="checkbox"
                              checked={no_limit_time2}
                              onChange={() => {}}
                              onClick={() =>
                                this.setState({
                                  no_limit_time2: no_limit_time2 == 1 ? 0 : 1,
                                })
                              }
                            />
                            상시
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fcjust_center pt-0"
                      style={{
                        width: 300,
                        alignItems: 'center',
                        borderLeft: '1px solid #EEEEEE',
                        padding: 10,
                        gap: 6,
                        boxSizing: 'border-box',
                      }}
                    >
                      <label
                        className={`field_label w-100 pt-3 ${
                          hiData.thumb ? 'hilight' : ''
                        }`}
                      >
                        상품이미지<span>*</span>{' '}
                        <span className="gray-text fs-11 ms-2 mt--2">
                          png, jpg 파일, 720px X 378px 권장
                        </span>
                      </label>
                      <input
                        type="file"
                        ref={(val) => (this.fileref = val)}
                        name="thumb"
                        onChange={this.handleFileChange}
                        className="d-none"
                        accept="image/*"
                      />
                      {/* <div className='prod_img_bg' style={{ backgroundImage: 'url(' + consts.resourcUrl + thumb + ')' }}></div> */}
                      {Boolean(thumb) ? (
                        <img
                          src={consts.resourcUrl + thumb}
                          className="prod_img_bg"
                        />
                      ) : (
                        <div className="prod_img_bg" />
                      )}
                      <div className="btn_normal" onClick={this.openfileSelect}>
                        이미지등록
                      </div>
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.price_type ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      가격유형<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <select
                        value={price_type}
                        onChange={this.handleInputChange}
                        name="price_type"
                        placeholder="가격 유형을 선택하세요"
                      >
                        <option value={1}>무료</option>
                        <option value={2}>조건부 무료</option>
                        <option value={3}>유료</option>
                      </select>
                    </div>
                  </div>
                  {price_type == 3 && (
                    <div
                      className={`card_row ${hiData.price ? 'hilight' : ''}`}
                    >
                      <label className="field_label">
                        가격<span>*</span>
                      </label>
                      <div className="card_row_val">
                        <input
                          type="number"
                          disabled={price_type != 3}
                          value={price == 0 ? '' : price}
                          onChange={this.handleInputChange}
                          name="price"
                        />
                      </div>
                    </div>
                  )}
                  {price_type == 2 && (
                    <div
                      className={`card_row ${hiData.cond_txt ? 'hilight' : ''}`}
                    >
                      <label className="field_label">
                        조건입력<span>*</span>
                      </label>
                      <div className="card_row_val">
                        <input
                          type="text"
                          style={{ width: 800 }}
                          value={cond_txt}
                          onChange={this.handleInputChange}
                          name="cond_txt"
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className={`card_row ${
                      hiData.support_price ? 'hilight' : ''
                    }`}
                  >
                    <label className="field_label">
                      지원료<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ support_price: -1 })}
                      >
                        <input
                          type="radio"
                          name="support_price"
                          checked={support_price === -1}
                          onChange={() => {}}
                        />
                        없음
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ support_price: 0 })}
                      >
                        <input
                          type="radio"
                          name="support_price"
                          checked={support_price >= 0}
                          onChange={() => {}}
                        />
                        있음
                      </label>
                      <input
                        type="number"
                        disabled={support_price == -1}
                        value={support_price > 0 ? support_price : ''}
                        onChange={this.handleInputChange}
                        name="support_price"
                      />
                    </div>
                  </div>
                  <div className={`card_row ${hiData.method ? 'hilight' : ''}`}>
                    <label className="field_label">
                      학습방식<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <select
                        value={method}
                        onChange={this.handleInputChange}
                        name="method"
                        placeholder="학습 방식을 선택하세요"
                      >
                        <option value={1}>실시간 온라인</option>
                        <option value={2}>오프라인</option>
                        <option value={3}>이러닝</option>
                        <option value={4}>블렌디드</option>
                      </select>
                    </div>
                  </div>
                  {(method == 2 || method == 4) && (
                    <div
                      className={`card_row ${
                        hiData.area1 || hiData.area2 ? 'hilight' : ''
                      }`}
                    >
                      <label className="field_label">지역</label>
                      <div className="card_row_val">
                        <select
                          value={area1}
                          onChange={this.handleInputChange}
                          name="area1"
                        >
                          {areaList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.short_name}
                            </option>
                          ))}
                        </select>
                        <select
                          value={area2}
                          onChange={this.handleInputChange}
                          name="area2"
                        >
                          {areaSubList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {`${item.sigungu} ${item.dong}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div
                    className={`card_row ${hiData.schedule ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      수업일정<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <input
                        type="text"
                        style={{ width: 400 }}
                        value={schedule}
                        onChange={this.handleInputChange}
                        name="schedule"
                        placeholder="수업 일정을 입력하세요. ex) 월~금, 1일 평균 8시간"
                      />
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.req_url ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      교육신청URL<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <input
                        type="text"
                        style={{ width: 500 }}
                        value={req_url}
                        name="req_url"
                        onChange={this.handleInputChange}
                      />
                      {Boolean(req_url) && (
                        <div
                          className="btn_link"
                          onClick={() => this.openReqUrl(req_url)}
                        >
                          새 창으로 열기
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.comment ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      에디터스코멘트<span>*</span>
                    </label>
                    <div className="card_row_val">
                      <textarea
                        style={{ width: '100%' }}
                        placeholder="에디터스코멘트을 입력하세요."
                        value={comment}
                        onChange={this.handleInputChange}
                        name="comment"
                      />
                    </div>
                  </div>
                  <div className={`card_row ${hiData.note ? 'hilight' : ''}`}>
                    <label className="field_label">유의사항</label>
                    <div className="card_row_val">
                      <textarea
                        style={{ width: '100%' }}
                        placeholder="유의사항을 입력하세요."
                        value={note}
                        onChange={this.handleInputChange}
                        name="note"
                      />
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.period_yn ? 'hilight' : ''}`}
                  >
                    <label className="field_label">기수</label>
                    <div className="card_row_val">
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ period_yn: 'N' })}
                      >
                        <input
                          type="radio"
                          name="period_yn"
                          checked={period_yn === 'N'}
                          onChange={() => {}}
                        />
                        없음
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ period_yn: 'Y' })}
                      >
                        <input
                          type="radio"
                          name="period_yn"
                          checked={period_yn === 'Y'}
                          onChange={() => {}}
                        />
                        있음
                      </label>
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.period_yn ? 'hilight' : ''}`}
                  >
                    <label className="field_label">수강후기 자동검수</label>
                    <div className="card_row_val">
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ confirm_st: 1 })}
                      >
                        <input
                          type="radio"
                          name="confirm_st"
                          checked={confirm_st === 1}
                          onChange={() => {}}
                        />
                        수강후기만 승인
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ confirm_st: 2 })}
                      >
                        <input
                          type="radio"
                          name="confirm_st"
                          checked={confirm_st === 2}
                          onChange={() => {}}
                        />
                        모두 자동승인
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ confirm_st: 3 })}
                      >
                        <input
                          type="radio"
                          name="confirm_st"
                          checked={confirm_st === 3}
                          onChange={() => {}}
                        />
                        모두 수동승인
                      </label>
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.period_yn ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      프리미엄수강후기 자동검수
                    </label>
                    <div className="card_row_val">
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ premium_confirm_st: 1 })}
                      >
                        <input
                          type="radio"
                          name="premium_confirm_st"
                          checked={premium_confirm_st === 1}
                          onChange={() => {}}
                        />
                        수강후기만 승인
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ premium_confirm_st: 2 })}
                      >
                        <input
                          type="radio"
                          name="premium_confirm_st"
                          checked={premium_confirm_st === 2}
                          onChange={() => {}}
                        />
                        모두 자동승인
                      </label>
                      <label
                        className="radio_label"
                        onClick={() => this.setState({ premium_confirm_st: 3 })}
                      >
                        <input
                          type="radio"
                          name="premium_confirm_st"
                          checked={premium_confirm_st === 3}
                          onChange={() => {}}
                        />
                        모두 수동승인
                      </label>
                    </div>
                  </div>
                </>
              }

              <div
                className="frjustify_between splite_block"
                style={{ width: '100%', paddingTop: 20, paddingBottom: 10 }}
              >
                <div className="font_D700_16">상품 키워드 입력</div>
              </div>
              {
                <>
                  <div
                    className={`card_row ${hiData.jobtype ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      직무<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 2 })}
                      ></div>
                      <this.renderKeyword data={jobtype} />
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.tech_stack ? 'hilight' : ''}`}
                  >
                    <label className="field_label">기술스택</label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 3 })}
                      ></div>
                      <this.renderKeyword data={tech_stack} />
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.difficulty ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      난이도<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 4 })}
                      ></div>
                      <this.renderKeyword data={difficulty} />
                    </div>
                  </div>
                  <div
                    className={`card_row ${hiData.interest ? 'hilight' : ''}`}
                  >
                    <label className="field_label">
                      관심정보<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 5 })}
                      ></div>
                      <this.renderKeyword data={interest} />
                    </div>
                  </div>
                  <div
                    className={`card_row ${
                      hiData.customer_type ? 'hilight' : ''
                    }`}
                  >
                    <label className="field_label">
                      고객유형<span>*</span>
                    </label>
                    <div className="card_row_val flex-wrap">
                      <div
                        className="btn_edit"
                        onClick={() => this.setState({ modalIdx: 6 })}
                      ></div>
                      <this.renderKeyword data={customer_type} />
                    </div>
                  </div>
                </>
              }

              <div
                className={`frjustify_between splite_block w-100 py-3 ${
                  hiData.curriclum ? 'hilight' : ''
                }`}
              >
                <div className="font_D700_16">
                  커리큘럼<span className="danger-text">*</span>
                </div>
                <div
                  className="btn_link"
                  onClick={() => this.setState({ modalIdx: 7 })}
                >
                  미리보기
                </div>
              </div>
              <div
                className={`card_row ${hiData.curriclum_time ? 'hilight' : ''}`}
              >
                <label className="field_label">총 교육시간</label>
                <div className="card_row_val">
                  <input
                    type="number"
                    style={{ width: 100 }}
                    value={curriclum_time}
                    onChange={this.handleInputChange}
                    name="curriclum_time"
                    placeholder="50"
                  />
                  <span>시간</span>
                </div>
              </div>
              <div className="fcjust_center w-100 gap-10">
                {curriclumList.map((item, index) => (
                  <div className="query_block" key={index}>
                    <div className="fr_c_end w-100">
                      <div
                        className="close_btn"
                        onClick={() => this.removeCurriclum(index)}
                      />
                    </div>
                    <div className="fralign_center w-100 gap-20">
                      <input
                        type="text"
                        style={{ width: 800 }}
                        placeholder="대주제를 입력하세요.(필수입력)"
                        value={item.title}
                        onChange={(e) => {
                          item.title = e.target.value;
                          curriclumList[index] = item;
                          this.setState({
                            curriclum: JSON.stringify(curriclumList),
                          });
                        }}
                      />
                      <input
                        type="text"
                        style={{ flex: 1 }}
                        placeholder="시간 또는 주차 설명을 입력하세요.(선택입력)"
                        value={item.time}
                        onChange={(e) => {
                          item.time = e.target.value;
                          curriclumList[index] = item;
                          this.setState({
                            curriclum: JSON.stringify(curriclumList),
                          });
                        }}
                      />
                      <div style={{ width: 32 }}></div>
                    </div>
                    {item.data?.map((data, idx) => (
                      <div className="fralign_center w-100 gap-20" key={idx}>
                        <div className="fralign_center">
                          <div className="sub_query_mark"></div>
                          <input
                            type="text"
                            style={{ width: 776 }}
                            placeholder="소주제를 입력하세요. (선택입력)"
                            value={data.title}
                            onChange={(e) => {
                              data.title = e.target.value;
                              item.data[idx] = data;
                              curriclumList[index] = item;
                              this.setState({
                                curriclum: JSON.stringify(curriclumList),
                              });
                            }}
                          />
                        </div>
                        <input
                          type="text"
                          style={{ flex: 1 }}
                          placeholder="시간 또는 주차 설명을 입력하세요.(선택입력)"
                          value={data.time}
                          onChange={(e) => {
                            data.time = e.target.value;
                            item.data[idx] = data;
                            curriclumList[index] = item;
                            this.setState({
                              curriclum: JSON.stringify(curriclumList),
                            });
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === 'Tab' &&
                              item.data.length === idx + 1
                            ) {
                              item.data.push({ title: '', time: '' });
                              curriclumList[index] = item;
                              this.setState({
                                curriclum: JSON.stringify(curriclumList),
                              });
                            }
                          }}
                        />

                        {item.data.length == idx + 1 ? (
                          <div
                            className="plus_btn"
                            onClick={() => {
                              item.data.push({ title: '', time: '' });
                              curriclumList[index] = item;
                              this.setState({
                                curriclum: JSON.stringify(curriclumList),
                              });
                            }}
                          />
                        ) : (
                          <div
                            className="remove_btn"
                            onClick={() => {
                              item.data.splice(idx, 1);
                              curriclumList[index] = item;
                              this.setState({
                                curriclum: JSON.stringify(curriclumList),
                              });
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
                <div
                  className="add_query"
                  onClick={() => {
                    curriclumList.push({
                      title: '',
                      time: '',
                      data: [{ title: '', time: '' }],
                    });
                    this.setState({ curriclum: JSON.stringify(curriclumList) });
                  }}
                >
                  <div className="plus_btn" />
                  대주제 필드 추가
                </div>
              </div>
              <div
                className={`frjustify_between splite_block w-100 py-3 ${
                  hiData.lesson_review ? 'hilight' : ''
                }`}
              >
                <div className="font_D700_16">수강후기</div>
                <div className="fralign_center" style={{ gap: 20 }}>
                  <div
                    className="btn_normal"
                    onClick={() =>
                      this.setState({
                        modalIdx: -2,
                        matchingYn: 'N',
                        reviewType: 'N',
                      })
                    }
                  >
                    승인대기 리뷰목록
                  </div>
                  <div
                    className="btn_normal"
                    onClick={() =>
                      this.setState({
                        modalIdx: -2,
                        matchingYn: 'Y',
                        reviewType: 'N',
                      })
                    }
                  >
                    리스트에서 선택
                  </div>
                </div>
              </div>
              {review_input && (
                <div className={`card_row`}>
                  <div className="card_row_val">
                    <input
                      type="text"
                      className="w-100"
                      value={review_title}
                      onChange={({ target }) =>
                        this.setState({ review_title: target.value })
                      }
                      placeholder={'제목을 입력하세요.'}
                    />
                    <input
                      type="text"
                      className="w-100"
                      value={review_link}
                      onChange={({ target }) =>
                        this.setState({ review_link: target.value })
                      }
                      placeholder={'URL을 입력하세요.'}
                    />
                  </div>
                  <span>
                    <div
                      className="remove_btn"
                      onClick={() =>
                        this.setState({
                          review_input: false,
                          review_title: '',
                          review_link: '',
                        })
                      }
                    />
                  </span>
                </div>
              )}
              {/* {
                reviewList.map((item, index) =>
                <div className={`card_row`} key={index}>
                  <div className='card_row_val'>
                    <div>
                      <div className='mb-1'>{item.title}</div>
                      <div className='gray-text fs-12'>{item.link}</div>
                    </div>
                  </div>
                  <span>
                    <div className='remove_btn' onClick={() => {
                      reviewList.splice(index, 1);
                      this.setState({lesson_review: JSON.stringify(reviewList)})
                    }} />
                  </span>
                </div>
                )
              } */}
              <table style={{ textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <th width={'5%'}>총점</th>
                    <th width={'5%'}>기수</th>
                    <th width={'10%'}>수강상태</th>
                    <th width={'19%'}>좋은점</th>
                    <th width={'19%'}>아쉬운점</th>
                    <th width={'16%'}>작성자</th>
                    <th width={'16%'}>작성일</th>
                    <th width={'10%'}>인증상태</th>
                  </tr>
                  {normal_review_list?.map((item, index) => (
                    <tr
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          modalIdx: -1,
                          selectedReview: item,
                          reviewType: 'N',
                        })
                      }
                    >
                      <td>{item.score}</td>
                      <td>{item.period || '-'}</td>
                      <td>
                        {item.edu_st === 1
                          ? '면접통과'
                          : item.edu_st === 2
                          ? '수강중'
                          : item.edu_st === 3
                          ? '수강중단'
                          : '수료'}
                      </td>
                      <td style={{ textAlign: 'left' }}>{item.pros}</td>
                      <td style={{ textAlign: 'left' }}>{item.cons}</td>
                      <td>{item.user_nickname + '(' + item.email + ')'}</td>
                      <td>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{item.cert_st === 1 ? '인증' : '미인증'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalCnt={totalReviewCnt}
                onClick={(pageIdx) => {
                  this.setState({ reviewPage: pageIdx });
                  this.getReviews(productId, pageIdx);
                }}
                currentPage={reviewPage}
                showLast={false}
                perPage={5}
              />

              <div
                className={`frjustify_between splite_block w-100 py-3 ${
                  hiData.lesson_review ? 'hilight' : ''
                }`}
              >
                <div className="font_D700_16">프리미엄수강후기</div>
                <div className="fralign_center" style={{ gap: 20 }}>
                  <div
                    className="btn_normal"
                    onClick={() =>
                      this.setState({
                        modalIdx: -2,
                        matchingYn: 'N',
                        reviewType: 'P',
                      })
                    }
                  >
                    승인대기 프리미엄 리뷰목록
                  </div>
                  <div
                    className="btn_normal"
                    onClick={() =>
                      this.setState({
                        modalIdx: -2,
                        matchingYn: 'Y',
                        reviewType: 'P',
                      })
                    }
                  >
                    리스트에서 선택
                  </div>
                </div>
              </div>
              {review_input && (
                <div className={`card_row`}>
                  <div className="card_row_val">
                    <input
                      type="text"
                      className="w-100"
                      value={review_title}
                      onChange={({ target }) =>
                        this.setState({ review_title: target.value })
                      }
                      placeholder={'제목을 입력하세요.'}
                    />
                    <input
                      type="text"
                      className="w-100"
                      value={review_link}
                      onChange={({ target }) =>
                        this.setState({ review_link: target.value })
                      }
                      placeholder={'URL을 입력하세요.'}
                    />
                  </div>
                  <span>
                    <div
                      className="remove_btn"
                      onClick={() =>
                        this.setState({
                          review_input: false,
                          review_title: '',
                          review_link: '',
                        })
                      }
                    />
                  </span>
                </div>
              )}
              {/* {
                reviewList.map((item, index) =>
                <div className={`card_row`} key={index}>
                  <div className='card_row_val'>
                    <div>
                      <div className='mb-1'>{item.title}</div>
                      <div className='gray-text fs-12'>{item.link}</div>
                    </div>
                  </div>
                  <span>
                    <div className='remove_btn' onClick={() => {
                      reviewList.splice(index, 1);
                      this.setState({lesson_review: JSON.stringify(reviewList)})
                    }} />
                  </span>
                </div>
                )
              } */}
              <table style={{ textAlign: 'center' }}>
                <tbody>
                  <tr>
                    <th width={'5%'}>총점</th>
                    <th width={'5%'}>기수</th>
                    <th>수강상태</th>
                    <th>종합평가</th>
                    <th>성별</th>
                    <th>연령</th>
                    <th>교육경험</th>
                    <th>재직여부</th>
                    <th>작성자</th>
                    <th>작성일</th>
                    <th>인증상태</th>
                  </tr>
                  {premium_review_list?.map((item, index) => (
                    <tr
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          modalIdx: -1,
                          selectedReview: item,
                          reviewType: 'P',
                        })
                      }
                    >
                      <td>{item.score_total}</td>
                      <td>{item.period || '-'}</td>
                      <td>
                        {item.edu_st === 1
                          ? '면접통과'
                          : item.edu_st === 2
                          ? '수강중'
                          : item.edu_st === 3
                          ? '수강중단'
                          : '수료'}
                      </td>
                      <td
                        style={{
                          textAlign: 'left',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {item.text_total}
                      </td>
                      <td>{item.sex === 'M' ? '남' : '여'}</td>
                      <td>{item.age}</td>
                      <td>{item.similar_yn}</td>
                      <td>{item.on_job_yn}</td>
                      <td>{item.user_nickname + '(' + item.email + ')'}</td>
                      <td>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{item.cert_st === 1 ? '인증' : '미인증'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                totalCnt={totalPremiumReviewCnt}
                onClick={(pageIdx) => {
                  this.setState({ premiumReviewPage: pageIdx });
                  this.getPremiumReviews(productId, pageIdx);
                }}
                currentPage={premiumReviewPage}
                showLast={false}
                perPage={5}
              />

              <div
                className={`frjustify_between splite_block w-100 py-3 ${
                  hiData.faq ? 'hilight' : ''
                }`}
              >
                <div className="font_D700_16">FAQ</div>
              </div>
              <div className="fcjust_center w-100 gap-10">
                {faqList.map((item, index) => (
                  <div className="query_block" key={index}>
                    <div className="fr_c_end w-100">
                      <div
                        className="close_btn"
                        onClick={() => {
                          let tmpList = faqList;
                          tmpList.splice(index, 1);
                          this.setState({ faq: JSON.stringify(tmpList) });
                        }}
                      />
                    </div>
                    <div className="fralign_center w-100 gap-20">
                      <input
                        type="text"
                        className="flex-1"
                        placeholder="질문을 입력하세요."
                        value={item.question}
                        onChange={(e) => {
                          item.question = e.target.value;
                          faqList[index] = item;
                          this.setState({ faq: JSON.stringify(faqList) });
                        }}
                      />
                    </div>
                    <div className="fralign_start w-100">
                      <div className="sub_query_mark"></div>
                      <textarea
                        className="flex-1"
                        placeholder={'답변을 입력하세요.'}
                        value={item.answer}
                        onChange={(e) => {
                          item.answer = e.target.value;
                          faqList[index] = item;
                          this.setState({ faq: JSON.stringify(faqList) });
                        }}
                      />
                    </div>
                  </div>
                ))}
                <div
                  className="add_query"
                  onClick={() => {
                    faqList.push({ question: '', answer: '' });
                    this.setState({ faq: JSON.stringify(faqList) });
                  }}
                >
                  <div className="plus_btn" />
                  필드 추가
                </div>
              </div>
              <div
                className={`frjustify_between splite_block w-100 py-3 ${
                  hiData.intro ? 'hilight' : ''
                }`}
              >
                <div className="font_D700_16">클래스 소개</div>
              </div>
              <div className='card_row_val'>
                                            <div className='btn_normal' onClick={() => $('input[name=thumbinfo]').trigger('click')}>이미지 추가</div>
                                            <span className='gray-text fs-11 ms-2 mt--2'>png, jpg 파일, 1280px X 672px 권장</span>
                                            <input type="file" name="thumbinfo" onChange={(e) => this.handleImgFileChange(e)} className="d-none" accept="image/*" />
                                        </div>
              <SmartEditor
                ref={(val) => (this.editorref = val)}
                editorid={'class_intro'}
                value={intro}
                onChange={(val) => {
                  // console.log(" SmartEditor : ", val);
                }}
              />
              {productId > 0 && (
                <div className="fr_c_end w-100 py-3">
                  <div
                    className="btn_remove"
                    style={{ width: 120 }}
                    onClick={() =>
                      this.setState({
                        confirmTitle: '삭제하기',
                        confirmTxt: '등록된 상품 정보를 \n삭제하시겠습니까?',
                        modalIdx: 10,
                      })
                    }
                  >
                    상품 삭제
                  </div>
                </div>
              )}
              {modalIdx == -2 && (
                <ReviewListModal
                  confirm={() => {
                    this.setState({
                      modalIdx: 0,
                      matchingYn: '',
                      reviewType: '',
                    });
                    this.refetchData();
                  }}
                  closeModal={() => {
                    this.setState({
                      modalIdx: 0,
                      matchingYn: '',
                      reviewType: '',
                    });
                  }}
                  edu_id={productId}
                  type={reviewType}
                  matchingYn={matchingYn}
                />
              )}
              {modalIdx == -1 && (
                <ReviewModal
                  confirm={() => {
                    this.setState({
                      modalIdx: 0,
                      matchingYn: '',
                      reviewType: '',
                    });
                    this.refetchData();
                  }}
                  closeModal={() => {
                    this.setState({
                      modalIdx: 0,
                      selectedReview: null,
                      reviewType: '',
                    });
                  }}
                  review={selectedReview}
                  type={reviewType}
                />
              )}
              {modalIdx == 1 && (
                <EduKeyword
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={({ id, user_name }) =>
                    this.setState({ partner_id: id, user_name })
                  }
                  partnerId={partner_id}
                  user_name={user_name}
                />
              )}
              {modalIdx == 2 && (
                <JobKeyword
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(jobtype) => this.setState({ jobtype })}
                  category={jobtype}
                />
              )}
              {modalIdx == 3 && (
                <TechKeyword
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(tech_stack) => this.setState({ tech_stack })}
                  category={tech_stack}
                />
              )}
              {modalIdx == 4 && (
                <KeywordsModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(difficulty) => this.setState({ difficulty })}
                  category={difficulty}
                  type={'D'}
                />
              )}
              {modalIdx == 5 && (
                <KeywordsModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(interest) => this.setState({ interest })}
                  category={interest}
                  type={'I'}
                />
              )}
              {modalIdx == 6 && (
                <KeywordsModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  selectDatas={(customer_type) =>
                    this.setState({ customer_type })
                  }
                  category={customer_type}
                  type={'C'}
                />
              )}
              {modalIdx == 7 && (
                <QueryPreView
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  curriclumList={curriclumList}
                />
              )}
              {modalIdx == 8 && (
                <LessonReviewModal
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  title={title}
                  selectDatas={(selectedList) => {
                    // let selList = [...reviewList, ...selectedList];
                    // selList = selList.filter( (value,index) => {   // 중복값 제거
                    //   return index === selList.findIndex( (obj) => {
                    //     return JSON.stringify(obj) === JSON.stringify(value);
                    //   })
                    // });
                    // this.setState({lesson_review: JSON.stringify(selList)})
                    this.setState({
                      lesson_review: JSON.stringify(selectedList),
                    });
                  }}
                  selectedList={reviewList}
                />
              )}
              {modalIdx == 9 && (
                <EditAnswer
                  closeModal={() => this.setState({ modalIdx: 0 })}
                  title={'반려 사유 입력'}
                  text={return_txt}
                  selectDatas={this.returnProduct}
                />
              )}

              {modalIdx >= 10 && (
                <ConformModal
                  closeModal={() =>
                    this.setState({ confirmTxt: '', modalIdx: 0 })
                  }
                  onConfirm={this.onConfirm}
                  title={confirmTitle}
                >
                  {confirmTxt}
                </ConformModal>
              )}
            </div>
          </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(DetailLeasson);
