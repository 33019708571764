import './manager.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import Pagination from '../../components/Pagination';

import { toastMsg, formatDate, checkPerm, formatNum } from '../../libs/utils';
import { requestPost } from '../../libs/request';

const permList = [
  { label: '교육상품', perms: ['교육상품', '키워드'] },
  {
    label: '파트너사',
    perms: ['교육사', '파트너신청목록', '파트너계정관리', '한줄톡'],
  },
  { label: '회원', perms: ['회원정보', '탈퇴회원정보'] },
  {
    label: 'CRM',
    perms: ['상품문의', '서비스의견', '평가 및 리뷰', '게시글/사용자신고'],
  },
  { label: '커뮤니티', perms: ['게시글', '댓글/대댓글'] },
  { label: '배너와 광고', perms: ['메인배너', '광고배너', '광고상품설정'] },
  { label: '기타 콘텐츠', perms: ['직무백서', '이벤트'] },
  { label: '통계와 리포트', perms: ['서비스이용통계', '광고성과리포트'] },
  { label: '관리자', perms: ['관리자계정관리', '권한그룹관리'] },
];
class DetailPermission extends React.Component {
  constructor() {
    super();
    this.state = {
      permId: 0,

      name: '',
      perms: '',
      totalCnt: 0,
      currentPage: 1,
      dataList: [],
    };
  }
  componentDidMount() {
    const { searchParams } = this.props.router;
    const permId = searchParams.get('id');
    //
    this.getData(permId);
  }

  getData = (permId) => {
    window.scrollTo(0, 0);
    this.setState({ permId });

    if (permId > 0) {
      requestPost('getPermDetail', { permId }, (res) => {
        if (Boolean(res)) {
          const { name, perms } = res;
          this.setState({ name, perms });
          this.getUserList();
          //
        } else {
          alert('삭제된 내역입니다.');
          this.gotoPage(-1);
        }
      });
    }
  };
  getUserList = (currentPage = this.state.currentPage) => {
    this.setState({ currentPage });
    const { permId } = this.state;
    const params = { currentPage, permId };
    requestPost('getPermUserList', params, (res) => {
      if (Boolean(res)) {
        let totalCnt = 0;
        if (Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({ dataList: res, totalCnt });
      }
    });
  };
  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  };
  handleInputChange = (event) => {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    this.setState({ [name]: value });
    //
  };

  update = () => {
    const { permId, name, perms } = this.state;

    if (!name) {
      return toastMsg('그룹 명칭을 입력하세요.');
    }
    const params = {
      permId,
      name,
      perms,
    };
    requestPost('updatePerm', params, (res) => {
      if (Boolean(res)) {
        if (!permId) {
          setTimeout(() => {
            this.gotoPage(-1);
          }, 100);
        } else {
          toastMsg('저장되었습니다.', 'success');
        }
      } else {
        toastMsg('저장에 실패하였습니다.');
      }
    });
  };

  changePerm = (idx) => {
    const { perms } = this.state;
    const permList = Boolean(perms) ? perms.split(',') : [];

    let checked = false;
    for (let i in permList) {
      let tmp = permList[i];
      if (tmp == idx) {
        permList.splice(i, 1);
        checked = true;
        break;
      }
    }
    if (!checked) {
      permList.push(idx);
    }
    this.setState({ perms: permList.join() });
  };

  render() {
    const { permId, name, perms, totalCnt, currentPage, dataList } = this.state;

    return (
      <Leftmenu>
        <div className="page_contents detailpermission">
          <div className="card">
            <div className="card_head">
              <div className="card_head_title">
                {!Boolean(permId) ? '권한 그룹 등록' : '권한 그룹 상세'}
              </div>
              <div className="fralign_center" style={{ gap: 20 }}>
                {Boolean(permId) && (
                  <div className="btn_normal" onClick={this.update}>
                    저장
                  </div>
                )}
                {Boolean(permId) || (
                  <div
                    className="btn_normal cancel"
                    onClick={() => this.gotoPage(-1)}
                  >
                    취소
                  </div>
                )}
                {Boolean(permId) || (
                  <div className="btn_normal" onClick={this.update}>
                    등록
                  </div>
                )}
              </div>
            </div>
            <div className="card_body">
              <div className="card_row">
                <label className="field_label">그룹명</label>
                <div className="card_row_val">
                  <input
                    type={'text'}
                    placeholder="그룹 명칭을 입력하세요."
                    value={name}
                    name="name"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="card_row" style={{ alignItems: 'flex-start' }}>
                <label className="field_label">권한 선택</label>
                <div className="card_row_val">
                  <div className="fcjust_center w-100">
                    {permList.map((item, index) => (
                      <div key={index}>
                        <div className="field_row bg-E">{item.label}</div>
                        <div className="field_row">
                          {item.perms.map((perm, idx) => (
                            <label
                              key={index + '-' + idx}
                              className="radio_label"
                            >
                              <input
                                checked={checkPerm(perms, index + '' + idx)}
                                type="checkbox"
                                onChange={() => {}}
                                onClick={() =>
                                  this.changePerm(index + '' + idx)
                                }
                              />
                              {perm}
                            </label>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {Boolean(permId) && (
              <div className="card_body">
                <div
                  className="frjustify_between"
                  style={{ width: '100%', padding: '10px 0px' }}
                >
                  <div className="font_D700_16">그룹이 적용된 계정</div>
                  <div className="field_label">
                    {formatNum(totalCnt)} 개의 계정
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>계정ID</th>
                      <th>이메일(로그인ID)</th>
                      <th>이름</th>
                      <th>생성일시</th>
                      <th>활성상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.length > 0 ? (
                      dataList.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => this.openDetail(item.id)}
                        >
                          <td>{item.id}</td>
                          <td>{item.email}</td>
                          <td>{item.user_name}</td>
                          <td>{formatDate(item.reg_time)}</td>
                          <td>{item.status == 1 ? '활성' : '비활성'}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>데이터가 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  totalCnt={totalCnt}
                  onClick={(pageIdx) => this.getUserList(pageIdx)}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(DetailPermission);
