import { Fragment, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateComponent from './privatecomponent';
import PublicElement from './publicelement';
import Login from '../screen/auth/Login';
import FindPassword from '../screen/auth/findpassword';
import ResetPassword from '../screen/auth/ResetPassword';
//대시보드
import Dashboard from '../screen/dashboard/Dashboard';
import Mypage from '../screen/mypage';
import EditMyInfo from '../screen/mypage/editmyinf';
import ChangePassword from '../screen/mypage/changepassword';
import AlermList from '../screen/mypage/alermlist';
//교육상품
// import Education from '../screen/education';
import EduLeasson from '../screen/education/eduleasson';//교육 상품
import NewLeasson from '../screen/education/newLeasson';//신규 교육 등록
import DetailLeasson from '../screen/education/detailLeasson';// 교육 상품 상세
import EduKeyword from '../screen/education/edukeyword';
import Curation from '../screen/education/curation';
import CurationDetail from '../screen/education/curationDetail'
import BootCamp from '../screen/education/bootCamp';
import BootCampDetail from '../screen/education/bootCampDetail';
//파트너사
// import Partner from '../screen/partner';
import PartnerCompany from '../screen/partner/prtcompany';
import DetailCompany from '../screen/partner/detailCompany';
import PartnerRequest from '../screen/partner/prtrequest';
import DetailRequest from '../screen/partner/detailRequest';
import PartnerManager from '../screen/partner/prtmanager';
import DetailPartnerManager from '../screen/partner/detailManager';
import PartnerLinetok from '../screen/partner/prtlinetok';
import DetailLinetok from '../screen/partner/detailLinetok';
//회원
// import Member from '../screen/member';
import MemberList from '../screen/member/memlist';
import DetailMember from '../screen/member/detailMember';
import MemberOutList from '../screen/member/outlist';
//CRM
// import Crm from '../screen/crm';
import CrmQueryProd from '../screen/crm/crmqueryprod';
import DetailCrmProd from '../screen/crm/detailCrmProd';
import CrmQuerySvc from '../screen/crm/crmquerysvc';
import DetailCrmSvc from '../screen/crm/detailCrmSvc';
import CrmReviewer from '../screen/crm/crmreviewer';
import DetailReviewer from '../screen/crm/detailReviewer';
import CrmReport from '../screen/crm/crmreport';
import DetailReport from '../screen/crm/detailReport';
import Reviews from '../screen/crm/reviews';
import PremiumReviews from '../screen/crm/premiumReviews';
import UnmatchedReviews from '../screen/crm/unmatchedReviews';
//커뮤니티
// import Community from '../screen/community';
import CommunityList from '../screen/community/cmtlist';
import DetailCommunity from '../screen/community/detailCommunity';
import CommunityComment from '../screen/community/comment';
import DetailComment from '../screen/community/detailComment';
// 배너와 광고
// import Banner from '../screen/banner';
import BannerMain from '../screen/banner/bnnmain';
import DetailMain from '../screen/banner/detailMain';
import BannerMainList from '../screen/banner/mainlist';
import BannerAdvert from '../screen/banner/bnnadvert';
import DetailAdvert from '../screen/banner/detailAdvert';
import BannerAdvertList from '../screen/banner/advertlist';
import BannerSetting from '../screen/banner/bnnsetting';
import DetailSetting from '../screen/banner/detailSetting';
// 기타 콘텐츠
// import Other from '../screen/other';
import OtherJobpaper from '../screen/other/othjobpaper';
import DetailJobpaper from '../screen/other/detailJobpaper';
import OtherEvent from '../screen/other/othevent';
import DetailEvent from '../screen/other/detailEvent';
import OtherNotice from '../screen/other/othnotice';
import DetailNotice from '../screen/other/detailNotice';
// 통계와 리포트
// import Report from '../screen/report';
import ReportService from '../screen/report/rptservice';
import ReportAdvert from '../screen/report/rptadvert';
import DetailReportAdvert from '../screen/report/detailAdvert';
import PdfViewAdvert from '../screen/report/pdfviewAdvert';
//관리자
// import Manager from '../screen/manager';
import ManagerList from '../screen/manager/mnglist';
import DetailManager from '../screen/manager/detailManager';
import ManagerPermission from '../screen/manager/mngpermission';
import DetailPermission from '../screen/manager/detailPermission';
import { checkPerm } from '../libs/utils';

export default function Router() {
    let url = useLocation();
    let paths = [
        "/dashboard",//대시보드
        "/mypage",//마이페이지
        "/mypage/editmyinf",//내 정보 수정
        "/mypage/changepassword",//비밀번호 변경
        "/mypage/alermlist",//비밀번호 변경
        "/education",// 교육상품
        "/education/leasson",// 교육상품 / 교육상품
        "/education/keyword",// 교육상품 / 키워드
        "/partner",// 파트너사
        "/partner/leasson",// 파트너사 / 교육사
        "/partner/request",// 파트너사 / 파트터신청목록
        "/partner/manager",// 파트너사 / 파트터계정관리
        "/partner/linetok",// 파트너사 / 한줄톡
        "/member",//회원
        "/member/list",//회원 / 회원정보
        "/member/outlist",//회원 / 탈퇴회원정보
        "/crm",//CRM
        "/crm/queryprod",//CRM/상품문의
        "/crm/querysvc",//CRM/서비스 의견
        "/crm/reviewer",//CRM/평가및 리뷰
        "/crm/report",//CRM/게시글 사용자신고
        "/crm/reviews", // 수강후기
        "/crm/premiumReviews", // 프리미엄수강후기
        "/crm/unmatchedReviews", // 매칭필요수강후기
        "/community",//커뮤니티
        "/community/list",//커뮤니티 / 게시글
        "/community/comment",//커뮤니티 / 댓글/대댓글
        "/banner",//배너와 광고
        "/banner/main",//배너와 광고 / 메인 배너
        "/banner/advert",//배너와 광고 / 광고 배너
        "/banner/setting",//배너와 광고 / 광고 상품 설정
        "/other",//기타 콘텐츠
        "/other/jobpaper",//기타 콘텐츠 / 직무 백서
        "/other/event",//기타 콘텐츠 / 이벤트
        "/other/notice",//기타 콘텐츠 / 공지사항
        "/report",//통계와 리포트
        "/report/service",//통계와 리포트 / 서비스이용통계
        "/report/advert",//통계와 리포트 / 광고성과리포트
        // "/report/logger",//통계와 리포트 / 유저로그수집
        "/manager",//관리자
        "/manager/list",//관리자 / 관리자계정관리
        "/manager/permission",//관리자 / 권한 그룹 관리
    ];

    const authacc = useSelector(state => state.authacc);
    const {perms} = authacc;
    
    return (
        <Routes >
            <Fragment>
                <Route path="/" element={<Navigate to="/login" />} exact/>

                {/* Public Block */}
                <Route path="/login" exact element={<PublicElement element={<Login />}/>} />
                <Route path="/findpsw" exact element={<PublicElement element={<FindPassword />}/>} />
                <Route path="/reset-password" exact element={<PublicElement element={<ResetPassword />}/>} />

                {/* Private Block */}
                {/* 나의 페이지 */}
                <Route path="/mypage" exact >
                    <Route path="" exact element={<PrivateComponent element={<Mypage />}/>} />
                    <Route path="editmyinf" exact element={<PrivateComponent element={<EditMyInfo />}/>} />
                    <Route path="changepassword" exact element={<PrivateComponent element={<ChangePassword />}/>} />
                    <Route path="alermlist" exact element={<PrivateComponent element={<AlermList />}/>} />
                    {url.pathname.indexOf("/mypage") === 0 && (<Route path={url.pathname} exact element={<Navigate to="/mypage" />} />)}
                </Route>
                {/* 대시보드 */}
                <Route path="/dashboard" exact element={<PrivateComponent element={<Dashboard />}/>} />
                {/* 교육상품 */}
                <Route path="/education" exact >
                    {checkPerm(perms, '00') && <Route path="leasson" exact element={<PrivateComponent element={<EduLeasson />}/>} />}
                    {checkPerm(perms, '00') && <Route path="leasson/new" exact element={<PrivateComponent element={<NewLeasson />}/>} />}
                    {checkPerm(perms, '00') && <Route path="leasson/detail" exact element={<PrivateComponent element={<DetailLeasson />}/>} />}
                    {checkPerm(perms, '01') && <Route path="keyword" exact element={<PrivateComponent element={<EduKeyword />}/>} />}
                    {checkPerm(perms, '00') && <Route path="curation" exact element={<PrivateComponent element={<Curation />}/>} />}
                    {checkPerm(perms, '00') && <Route path="curation/detail" exact element={<PrivateComponent element={<CurationDetail />}/>} />}
                    {checkPerm(perms, '00') && <Route path="camp" exact element={<PrivateComponent element={<BootCamp />}/>} />}
                    {checkPerm(perms, '00') && <Route path="camp/detail" exact element={<PrivateComponent element={<BootCampDetail/>}/>} />}
                    {url.pathname.indexOf("/education") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '00') ? "/education/leasson" : "/education/keyword"} />} />)}
                </Route>
                {/* 파트너사 */}
                <Route path="/partner" exact >
                    {checkPerm(perms, '10') && <Route path="company" exact element={<PrivateComponent element={<PartnerCompany />}/>} />}
                    {checkPerm(perms, '10') && <Route path="company/detail" exact element={<PrivateComponent element={<DetailCompany />}/>} />}
                    {checkPerm(perms, '11') && <Route path="request" exact element={<PrivateComponent element={<PartnerRequest />}/>} />}
                    {checkPerm(perms, '11') && <Route path="request/detail" exact element={<PrivateComponent element={<DetailRequest />}/>} />}
                    {checkPerm(perms, '12') && <Route path="manager" exact element={<PrivateComponent element={<PartnerManager />}/>} />}
                    {checkPerm(perms, '12') && <Route path="manager/detail" exact element={<PrivateComponent element={<DetailPartnerManager />}/>} />}
                    {checkPerm(perms, '13') && <Route path="linetok" exact element={<PrivateComponent element={<PartnerLinetok />}/>} />}
                    {checkPerm(perms, '13') && <Route path="linetok/detail" exact element={<PrivateComponent element={<DetailLinetok />}/>} />}
                    {url.pathname.indexOf("/partner") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '10') ? "/partner/company" : (checkPerm(perms, '11') ? "/partner/request" : (checkPerm(perms, '12') ? "/partner/manager" : "/partner/linetok"))} />} />)}
                </Route>
                {/* 회원 */}
                <Route path="/member" exact >
                    {checkPerm(perms, '20') && <Route path="list" exact element={<PrivateComponent element={<MemberList />}/>} />}
                    {checkPerm(perms, '20') && <Route path="list/detail" exact element={<PrivateComponent element={<DetailMember />}/>} />}
                    {checkPerm(perms, '21') && <Route path="outlist" exact element={<PrivateComponent element={<MemberOutList />}/>} />}
                    {checkPerm(perms, '21') && <Route path="outlist/detail" exact element={<PrivateComponent element={<DetailMember />}/>} />}
                    {url.pathname.indexOf("/member") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '20') ? "/member/list" : "/member/outlist"} />} />)}
                </Route>
                {/* CRM */}
                <Route path="/crm" exact >
                    {checkPerm(perms, '30') && <Route path="queryprod" exact element={<PrivateComponent element={<CrmQueryProd />}/>} />}
                    {checkPerm(perms, '30') && <Route path="queryprod/detail" exact element={<PrivateComponent element={<DetailCrmProd />}/>} />}
                    {checkPerm(perms, '31') && <Route path="querysvc" exact element={<PrivateComponent element={<CrmQuerySvc />}/>} />}
                    {checkPerm(perms, '31') && <Route path="querysvc/detail" exact element={<PrivateComponent element={<DetailCrmSvc />}/>} />}
                    {checkPerm(perms, '32') && <Route path="reviewer" exact element={<PrivateComponent element={<CrmReviewer />}/>} />}
                    {checkPerm(perms, '32') && <Route path="reviewer/detail" exact element={<PrivateComponent element={<DetailReviewer />}/>} />}
                    {checkPerm(perms, '33') && <Route path="report" exact element={<PrivateComponent element={<CrmReport />}/>} />}
                    {checkPerm(perms, '33') && <Route path="report/detail" exact element={<PrivateComponent element={<DetailReport />}/>} />}
                    {checkPerm(perms, '32') && <Route path="reviews" exact element={<PrivateComponent element={<Reviews />}/>} />}
                    {checkPerm(perms, '32') && <Route path="premiumReviews" exact element={<PrivateComponent element={<PremiumReviews />}/>} />}
                    {checkPerm(perms, '32') && <Route path="unmatchedReviews" exact element={<PrivateComponent element={<UnmatchedReviews />}/>} />}
                    {url.pathname.indexOf("/crm") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '30') ? "/crm/queryprod" : (checkPerm(perms, '31') ? "/crm/querysvc" : (checkPerm(perms, '32') ? "/crm/reviewer" : "/crm/report"))} />} />)}
                </Route>
                {/* 커뮤니티 */}
                <Route path="/community" >
                    {checkPerm(perms, '40') && <Route path="list" exact element={<PrivateComponent element={<CommunityList />}/>} />}
                    {checkPerm(perms, '40') && <Route path="list/detail" exact element={<PrivateComponent element={<DetailCommunity />}/>} />}
                    {checkPerm(perms, '41') && <Route path="comment" exact element={<PrivateComponent element={<CommunityComment />}/>} />}
                    {checkPerm(perms, '41') && <Route path="comment/detail" exact element={<PrivateComponent element={<DetailComment />}/>} />}
                    {url.pathname.indexOf("/community") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '40') ? "/community/list" : "/community/comment"} />} />)}
                </Route>
                {/* 배너와 광고 */}
                <Route path="/banner" exact >
                    {checkPerm(perms, '50') && <Route path="main" exact element={<PrivateComponent element={<BannerMain />}/>} />}
                    {checkPerm(perms, '50') && <Route path="main/detail" exact element={<PrivateComponent element={<DetailMain />}/>} />}
                    {checkPerm(perms, '50') && <Route path="main/list" exact element={<PrivateComponent element={<BannerMainList />}/>} />}
                    {checkPerm(perms, '51') && <Route path="advert" exact element={<PrivateComponent element={<BannerAdvert />}/>} />}
                    {checkPerm(perms, '51') && <Route path="advert/detail" exact element={<PrivateComponent element={<DetailAdvert />}/>} />}
                    {checkPerm(perms, '51') && <Route path="advert/list" exact element={<PrivateComponent element={<BannerAdvertList />}/>} />}
                    {checkPerm(perms, '52') && <Route path="setting" exact element={<PrivateComponent element={<BannerSetting />}/>} />}
                    {checkPerm(perms, '52') && <Route path="setting/detail" exact element={<PrivateComponent element={<DetailSetting />}/>} />}
                    {url.pathname.indexOf("/banner") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '50') ? "/banner/main" : (checkPerm(perms, '51') ? "/banner/advert" : "/banner/setting")} />} />)}
                </Route>
                {/* 기타 콘텐츠 */}
                <Route path="/other" exact >
                    {checkPerm(perms, '60') && <Route path="jobpaper" exact element={<PrivateComponent element={<OtherJobpaper />}/>} />}
                    {checkPerm(perms, '60') && <Route path="jobpaper/detail" exact element={<PrivateComponent element={<DetailJobpaper />}/>} />}
                    {checkPerm(perms, '61') && <Route path="event" exact element={<PrivateComponent element={<OtherEvent />}/>} />}
                    {checkPerm(perms, '61') && <Route path="event/detail" exact element={<PrivateComponent element={<DetailEvent />}/>} />}
                    {checkPerm(perms, '62') && <Route path="notice" exact element={<PrivateComponent element={<OtherNotice />}/>} />}
                    {checkPerm(perms, '62') && <Route path="notice/detail" exact element={<PrivateComponent element={<DetailNotice />}/>} />}
                    {url.pathname.indexOf("/other") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '60') ? "/other/jobpaper" : (checkPerm(perms, '61') ? "/other/event" : "/other/notice")} />} />)}
                </Route>
                {/* 통계와 리포트 */}
                <Route path="/report" exact >
                    {checkPerm(perms, '70') && <Route path="service" exact element={<PrivateComponent element={<ReportService />}/>} />}
                    {checkPerm(perms, '71') && <Route path="advert" exact element={<PrivateComponent element={<ReportAdvert />}/>} />}
                    {checkPerm(perms, '71') && <Route path="advert/detail" exact element={<PrivateComponent element={<DetailReportAdvert />}/>} />}
                    {checkPerm(perms, '71') && <Route path="advert/pdfview" exact element={<PrivateComponent element={<PdfViewAdvert />}/>} />}
                    {url.pathname.indexOf("/report") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '70') ? "/report/service" : "/report/advert"} />} />)}
                </Route>
                {/* 관리자 */}
                <Route path="/manager" exact >
                    {checkPerm(perms, '80') && <Route path="list" exact element={<PrivateComponent element={<ManagerList />}/>} />}
                    {checkPerm(perms, '80') && <Route path="list/detail" exact element={<PrivateComponent element={<DetailManager />}/>} />}
                    {checkPerm(perms, '81') && <Route path="permission" exact element={<PrivateComponent element={<ManagerPermission />}/>} />}
                    {checkPerm(perms, '81') && <Route path="permission/detail" exact element={<PrivateComponent element={<DetailPermission />}/>} />}
                    {url.pathname.indexOf("/manager") === 0 && (<Route path={url.pathname} exact element={<Navigate to={checkPerm(perms, '80') ? "/manager/list" : "/manager/permission"} />} />)}
                </Route>
                {/* Other Block */}
                {/* {paths.includes(url.pathname) ? <></> : <Route path={url.pathname} exact element={<Navigate to="/dashboard" />} />} */}
                {!paths.includes(url.pathname) && (<Route path={url.pathname} exact element={authacc.user_id > 0 ? <Navigate to="/dashboard" />: <Navigate to="/login" />} />)}
            </Fragment>
        </Routes>
    );
}
