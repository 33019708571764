
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './foot.css';

function Foot() {
    return (
        <>
        <div className="foot_section">
            Copyright {new Date().getFullYear()} All rights reserved.
        </div>
        <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} />
        </>
    );
}

export default Foot;