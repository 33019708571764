
import React from 'react';
import './education.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';

import {nonAuthedReqPost, requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import Nodata from '../../components/Nodata';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, renderProductStatus, toastMsg } from '../../libs/utils';
import moment from 'moment';
import EduProductModal from '../../components/modal/EduProductModal';

class Curation extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      set_state:    0,
      visible_state:0,
      totalCnt:     0,
      currentPage:  1,
      jobList: [],
      currentItem: {},
      modalIdx: 0,
      currentIdx: 0,
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = () => {
    requestPost('getJobtypeByKeyword', {keyword: ''}, (res) => {
        if(Boolean(res)) {
            this.setState({jobList: JSON.parse(res[0].data)})
        }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeCollect = (set_state) => {
    this.setState({set_state})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }
  changeStatus = (visible_state) => {
    this.setState({visible_state})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoDetail = (id) => {
    const { router } = this.props;
    // router.navigate('/education/camp/detail?id=' + id);
  }

  goToJobTypeList = () => {
    const { router } = this.props;
    router.navigate('/education/keyword');
  }

  setBootcamp = (bootcamp, idx) => {
    const { jobList, currentItem } = this.state;

    let jobIdx = jobList?.findIndex(item => item.title === currentItem?.title);
    if (jobIdx > -1) {
      let jobLists = [...jobList];
      let temp = jobLists[jobIdx]?.bootcamps;

      if (!temp) {
        temp = [];
      };

      temp[idx] = {id: bootcamp.id, title: bootcamp.title};

      jobLists[jobIdx].bootcamps = temp;

      this.setState({jobList: jobLists, modalIdx: 0});

    }
  }

  saveJob = () => {
    const { jobList, currentItem } = this.state;
    console.log(jobList);

    let jobIdx = jobList?.findIndex(item => item.title === currentItem?.title);
    if (jobIdx > -1) {
      if (!jobList[jobIdx].bootcamps || jobList[jobIdx].bootcamps.length < 3) {
        return toastMsg('필수로 3개의 부트캠프를 선택해야 합니다.')
      }

       const params = { jobtypeId: 6, title: '직무', data: JSON.stringify(jobList) };
       console.log(JSON.parse(params.data));
       requestPost('updateJobtypeById', params, (res) => {
            if(Boolean(res)) {
                this.getData();
                toastMsg('저장되었습니다.', 'success');
                this.setState({currentItem: {}});
            }
        });
    }
  }

  render() {
    const { currentIdx, modalIdx, currentItem, keyword, set_state, visible_state, totalCnt, currentPage, jobList } = this.state;
    
    return (
      <Leftmenu>
        {
          !currentItem.title ?
          <div className='page_contents eduleasson'>
            <div className='card'>
              <div className='card_head'>
                <div className='card_head_title'>직무별 부트캠프</div>
                <div className='card_head_tool'>
                  <div className='btn_normal' onClick={() => this.goToJobTypeList('')}>새로운 직무 생성</div>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>직무명</th>
                    <th>부트캠프 수</th>
                    <th>대표부트캠프</th>
                    <th>최종편집일</th>
                    <th>편집자</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                    jobList?.length > 0 ? jobList?.map((item, index) =>
                    <tr key={index} onClick={() => this.setState({currentItem: item})}>
                      <td>{item.title}</td>
                      <td>{item.bootcamps?.length || 0}</td>
                      <td>{item.bootcamps?.length > 0 ? item.bootcamps[0].title : '-'}</td>
                      <td>{Boolean(item.upd_dtm) ? moment(item.upd_dtm).format('YYYY-MM-DD HH:mm') : '-'}</td>
                    </tr>
                    )
                    :
                    <Nodata colSpan={8} />
                  }
                </tbody>
              </table>
              <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
            </div>
          </div>
          : 
          <div className='page_contents eduleasson'>
            <div className='card'>
              <div className='card_head'>
                <div className='card_head_title'>{currentItem?.title}</div>
                <div className='card_head_tool'>
                  <div className='btn_normal cancel' onClick={() => this.setState({currentItem: {}})}>취소</div>
                  <div className='btn_normal' onClick={() => this.saveJob()}>저장</div>
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>부트캠프1</label>
                <div className='card_row_val'>
                  <input type="text" disabled style={{width: '100%'}} value={currentItem?.bootcamps?.length > 0 ? currentItem.bootcamps[0].title : ''}/>
                  {
                    <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentIdx: 0})}>선택</div>
                  }
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>부트캠프2</label>
                <div className='card_row_val'>
                  <input type="text" disabled style={{width: '100%'}} value={currentItem?.bootcamps?.length > 1 ? currentItem.bootcamps[1].title : ''}/>
                  {
                    <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentIdx: 1})}>선택</div>
                  }
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>부트캠프3</label>
                <div className='card_row_val'>
                  <input type="text" disabled style={{width: '100%'}} value={currentItem?.bootcamps?.length > 2 ? currentItem.bootcamps[2].title : ''}/>
                  {
                    <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentIdx: 2})}>선택</div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {modalIdx == 1 && <EduProductModal closeModal={() => this.setState({modalIdx: 0, currentItem: {}})} confirm={(item) => this.setBootcamp(item, currentIdx)}/>}
      </Leftmenu>
    );
  }
}

export default withRouter(Curation);