
import React from 'react';

	const Pagination = ({onClick, totalCnt, currentPage, perPage=20, showLast=true}) => {
	
		const Items = ({page, index}) => {
			return (
				<div key={index} className='page_nav_btn' onClick={() => onClick(page)}>{page}</div>
			)
		}
	///////
	return (
		<>
		{
			(function() {
				
				let records_per_page = perPage;   // pagenation render count
				let total = Math.ceil(totalCnt / records_per_page);
				let first = null;
				let middle = [];
				let next = null;

				
				
				// let start_page 	= ( ( parseInt( (currentPage - 1 ) / records_per_page ) ) * records_per_page ) + 1;
				// let end_page 	= start_page + records_per_page - 1;
				
				// if (end_page >= total) end_page = total;
				var start_page 	= currentPage - currentPage % 10;
				var end_page 	= start_page + 10;
				if (start_page == 0) start_page = 1;
				if (end_page >= total) end_page = total;

				if (total > 1) {
					if (currentPage > 1) {
						first = <>
						{showLast && <div className='page_nav_btn page_first_btn' onClick={() => onClick(1) } />}
						<div className='page_nav_btn page_prev_btn' onClick={() => onClick(currentPage-1)} />
						</>
					}
					// 
					let index = 0;
					for (var k=start_page; k<=end_page; k++) {
						index++;
						if (currentPage != k) {
							middle.push(<Items page={k} index={index} />)
						}
						else {
							middle.push(<div key={index} className='page_act_btn' onClick={() => {}}>{k}</div>)
						}
					}
					// 
					if (currentPage < total) {
						next = <>
						<div className='page_nav_btn page_next_btn' onClick={() => onClick(currentPage+1)} />
						{showLast && <div className='page_nav_btn page_last_btn' onClick={() => onClick(total) } />}
						</>
					}
				}
				
				return (<div className='pagenation'>
									{first}{
										middle.map((item, index) => <span key={index}>{item}</span>)
									}{next}
								</div>)
			}
			)()
		}
		</>
	);
};


export default Pagination;