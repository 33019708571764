import { leftMenuActionType } from './LeftMenuActions';

const showmenu = true;
const leftmenu = (state = showmenu, action) => {
    switch (action.type) {
        case leftMenuActionType.showleftmenu:
            return action.value;
        default:
            return state;
    }
};

export default leftmenu;