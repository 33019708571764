import './banner.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {nonAuthedReqPost, requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';

class BannerSetting extends React.Component {
  constructor() {
    super();
    this.state = {
      status:       '',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { status } = this.state;
    const params = { status }
    nonAuthedReqPost('getEditorsPickList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeStatus = (status) => {
    this.setState({status})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  openDetail = (id) => {
    const { router } = this.props;
    router.navigate('/banner/setting/detail?id=' + id);
  }

  render() {
    const { status, totalCnt, currentPage, dataList, checkAll } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents bannermainlist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>광고 상품 설정</div>
              <div className='card_head_tool'>
                <div className='btn_normal' onClick={() => this.openDetail('')}>에디터스 픽 등록</div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>상품ID</th>
                  <th>노출여부</th>
                  <th>에디터 픽 타이틀</th>
                  <th>게시기간</th>
                  <th>생성일</th>
                  <th>등록자</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={()=>{this.openDetail(item.id)}}>
                    <td>{item.id}</td>
                    <td>{item.status == 1 ? '게시' : '미게시'}</td>
                    <td>{item.title}</td>
                    <td>{Boolean(item.st_time) ? formatDate(item.st_time) : '게시 즉시'} ~ {Boolean(item.ed_time) ? formatDate(item.ed_time) : '무제한'}</td>
                    <td>{formatDate(item.reg_dtm)}</td>
                    <td>{item.reg_nm + '(' + item.email + ')'}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(BannerSetting);