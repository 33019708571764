import './banner.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime, renderMemberStatus } from '../../libs/utils';
import consts from '../../libs/consts';

class BannerMain extends React.Component {
  constructor() {
    super();
    this.state = {
      editsort: false,
      dataList: [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = () => {
    window.scrollTo(0, 0);

    requestPost('getBanners', {type: 'M'}, (res) => {
      if(Boolean(res)) {
        this.setState({dataList: res, renderTiming: res[0].render_timing});
      }
    });
  }

  goBannerlist = ()=>{
    const { router } = this.props;
    router.navigate('/banner/main/list');
  }

  changeOrder = () => {
    const { editsort, dataList } = this.state;
    this.setState({editsort:!editsort});
    
    if(editsort) {  // 변경 완료
      const listIds = dataList.map(dt => dt.id);
      requestPost('updateBannerSort', {listIds}, (res) => {
        if(Boolean(res)) {
            this.getData();
            toastMsg('저장되었습니다.', 'success');
        }
    });
    }
  }
  swapData = (indexA, indexB) =>  {
    let {dataList} = this.state;
    let temp = dataList[indexA];
    dataList[indexA] = dataList[indexB];
    dataList[indexB] = temp;
    // 
    this.setState({dataList});
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  setTiming = () => {
    const { dataList, renderTiming } = this.state;
    console.log(renderTiming);

    const listIds = dataList.map(dt => dt.id);
    requestPost('updateBannerRenderTiming', {listIds, renderTiming}, (res) => {
      if(Boolean(res)) {
        this.getData();
        toastMsg('저장되었습니다.', 'success');
      }
    })
  }

  render() {
    const { editsort, dataList, renderTiming } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents bannerMain'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>홈 케러셀 (구 메인배너)</div>
            </div>
            <div className='card_head'>
              <div className='card_head_tool'>
                <div className='card_head_indicator'>노출중인 배너
                <input type='text' disabled value={`${dataList?.length}/10`}/></div>
                <div className='card_head_indicator'>전환속도 (초)
                <input 
                  type='text' 
                  value={renderTiming} 
                  onChange={(e) => {
                    if (isNaN(Number(e.target.value))) {
                      toastMsg('숫자만 입력하세요.', 'warning')
                      this.setState({renderTiming: e.target.value})
                    } else if (Number(e.target.value) < 5) {
                      toastMsg('5초 이상만 입력가능합니다.', 'warning')
                    } else {
                      this.setState({renderTiming: e.target.value})
                    }
                  }}/>
                </div>
                <div className='btn_normal' style={{marginTop: '15px'}} onClick={() => this.setTiming()}>{'적용'}</div>
              </div>
              <div className='card_head_tool'>
                <div className='btn_normal' onClick={() => this.changeOrder()}>{editsort ? "변경 완료" : "게시 순서 변경"}</div>
                <div className='btn_normal' onClick={() => {if (dataList.length >= 10) {toastMsg('배너는 최대 10개입니다. 다른 배너를 삭제하고 다시 등록해주세요.', 'warning')} else {this.gotoPage('/banner/main/detail?type=M')}}}>신규 배너 등록</div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  {editsort && (<th></th>)}
                  <th>배너ID</th>
                  <th>게시여부</th>
                  <th>게시 순서</th>
                  <th>배너명</th>
                  <th>부제</th>
                  <th>이미지</th>
                  <th>게시기간</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index}>
                    {editsort && (<td>
                      <div className='sortrow'>
                        <div className='up' onClick={() => this.swapData(index, index==0 ? dataList.length-1 : index-1)} />
                        <div className='down' onClick={() => this.swapData(index, index==dataList.length-1 ? 0 : index+1)} />
                      </div>
                    </td>)}
                    <td>{item.id}</td>
                    <td><input type="checkbox" checked={item.expo == '1'}/></td>
                    <td>{index + 1}</td>
                    <td className='cursor_pointer' onClick={()=>{this.gotoPage('/banner/main/detail?id=' + item.id)}}>{item.title}</td>
                    <td className='cursor_pointer' onClick={()=>{this.gotoPage('/banner/main/detail?id=' + item.id)}}>{item.subtitle}</td>
                    <td>
                      <img src={consts.resourcUrl + item.pc} className='banner_img' />
                    </td>
                    <td>{Boolean(item.st_time) ? formatDate(item.st_time) : '게시 즉시'} ~ {Boolean(item.ed_time) ? formatDate(item.ed_time) : '무제한'}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={5}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <div className='card_head'>
              <div style={{flex:1}}></div>
              <div className='btn_normal' onClick={this.goBannerlist}>등록된 배너 전체 보기</div>
            </div>
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(BannerMain);