import React from 'react';
import ModalImage from "react-modal-image";

import './community.css';
import Leftmenu from '../../layout/leftmenu';
import withRouter from '../../components/withRouter';
import EditAnswer from '../../components/modal/edit_answer';

import consts from '../../libs/consts';
import Pagination from '../../components/Pagination';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum, renderPostCategory, renderPostOpen } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailCommunity extends React.Component {
    constructor() {
        super();
        this.state = {
            postId:    0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
            totalCnt:     0,
            currentPage:  1,
            dataList:     [],
            dataList2:    [],
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const postId  = searchParams.get("id");
        // 
        this.getData(postId);
    }

    getData = (postId) => {
        window.scrollTo(0, 0);
        this.setState({postId});
    
        if(postId > 0) {
            requestPost('getPostDetail', {postId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, category, title, content, img, tag, open, status, memo, hits, reg_time, nickname, like_cnt, bookmark_cnt, cmt_cnt, report_cnt } = res;
        
                    this.setState({ user_id, category, title, content, img, tag, open, status, memo, hits, reg_time, nickname, like_cnt, bookmark_cnt, cmt_cnt, report_cnt });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
            // 
            this.getComments(1, postId);
            this.getReComments(postId);
        }
    }
    getComments = (currentPage=this.state.currentPage, postId) => {
      this.setState({currentPage});
  
      const params = { postId, currentPage }
      requestPost('getCommentsByPostId', params, (res) => {
        if(Boolean(res)) {
          let totalCnt = 0;
          if(Boolean(res[0])) {
            totalCnt = res[0].total_cnt;
          }
          this.setState({dataList: res, totalCnt});
        }
      });
    }
    getReComments = (postId) => {
      requestPost('getReCommentsByPostId', {postId}, (res) => {
        if(Boolean(res)) {
          this.setState({dataList2: res});
        }
      });
    }

    updateMemo = (memo) => {
        const { postId } = this.state;
        this.setState({modalIdx: 0});

        if(!memo) {
            return toastMsg('사유를 입력하세요.');
        }
        const params = {
            postId, memo
        }
        requestPost('updatePost', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.getData(postId);
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { postId, modalIdx, totalCnt, currentPage, dataList, dataList2,
            user_id, category, title, content, img, tag, open, status, memo, hits, reg_time, nickname, like_cnt, bookmark_cnt, cmt_cnt, report_cnt } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailcommunity'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>게시글 상세</div>
                            <div className='fralign_center' style={{ gap: 20 }}>
                                <div className='btn_normal' onClick={() => this.setState({modalIdx: 1})}>블라인드 처리</div>
                            </div>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                            </div>
                            <table className='alignleft'>
                                <tbody>
                                    <tr>
                                        <th>게시글ID</th>
                                        <td colSpan={3}>{postId}</td>
                                    </tr>
                                    <tr>
                                        <th>카테고리</th>
                                        <td colSpan={3}>{renderPostCategory(category)}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자</th>
                                        <td colSpan={3} className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td colSpan={3}>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>게시글 대상</th>
                                        <td colSpan={3}>{renderPostOpen(open)}</td>
                                    </tr>
                                    <tr>
                                        <th>제목</th>
                                        <td colSpan={3}>{title}</td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td className='pre-wrap' colSpan={3}>{content}</td>
                                    </tr>
                                    <tr>
                                        <th>첨부이미지</th>
                                        <td colSpan={3}>
                                            <div className='fralign_start' style={{gap:10, flexWrap:'wrap'}}>
                                                {
                                                    Boolean(img) && img.split(',').map((item, index) => 
                                                        // <img key={index} src={consts.resourcUrl + item} className='imgbg' />
                                                        <ModalImage
                                                            key={index}
                                                            className='imgbg'
                                                            small={consts.resourcUrl + item}
                                                            large={consts.resourcUrl + item}
                                                            hideDownload
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>태그</th>
                                        <td colSpan={3}>{tag}</td>
                                    </tr>
                                    <tr>
                                        <th>노출상태</th>
                                        <td>{status == 1 ? '공개' : (status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                                        <th>블라인드 사유</th>
                                        <td className='pre-wrap'>{memo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>조회수</th>
                                        <th>공감</th>
                                        <th>북마크</th>
                                        <th>댓글</th>
                                        <th>신고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatNum(hits)}</td>
                                        <td>{formatNum(like_cnt)}</td>
                                        <td>{formatNum(bookmark_cnt)}</td>
                                        <td>{formatNum(cmt_cnt)}</td>
                                        <td className='cursor_pointer' onClick={() => report_cnt > 0 && this.gotoPage('/crm/report?post_id=' + postId)}>{formatNum(report_cnt)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>댓글/대댓글</div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>댓글ID</th>
                                        <th>대댓글ID</th>
                                        <th>내용</th>
                                        <th>작성자</th>
                                        <th>노출상태</th>
                                        <th>작성일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList.length > 0 ?
                                    dataList.map((item, index) => 
                                    <>
                                        <tr key={index} onClick={() => this.gotoPage('/community/comment/detail?id=' + item.id)}>
                                            <td>{item.id}</td>
                                            <td>-</td>
                                            <td className='ellipsis1'>{Boolean(item.comment) && item.comment.length > consts.showMaxLen ? item.comment.substr(0, consts.showMaxLen)+'...' : item.comment}</td>
                                            <td>{item.nickname}</td>
                                            <td>{item.status == 1 ? '공개' : (item.status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                                            <td>{formatDate(item.reg_time)}</td>
                                        </tr>
                                        {dataList2.map((item2, idx) => 
                                            item.id == item2.comment_id &&
                                            <tr key={index+ '-' + idx} onClick={() => this.gotoPage('/community/comment/detail?id=' + item.id)}>
                                                <td>{item.id}</td>
                                                <td>{item2.id}</td>
                                                <td className='ellipsis1'>{Boolean(item2.comment) && item2.comment.length > consts.showMaxLen ? item.comment.substr(0, consts.showMaxLen)+'...' : item2.comment}</td>
                                                <td>{item2.nickname}</td>
                                                <td>{item2.status == 1 ? '공개' : (item.status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                                                <td>{formatDate(item2.reg_time)}</td>
                                            </tr>
                                        )}
                                    </>
                                    )
                                    :
                                    <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                                    }
                                </tbody>
                            </table>
                            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getComments(pageIdx)} currentPage={currentPage} perPage={7} />
                        </div>
                    </div>
                </div>
                {modalIdx == 1 && <EditAnswer closeModal={() => this.setState({modalIdx: 0})} title={"사유 작성"} text={memo} selectDatas={this.updateMemo} />}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailCommunity);