import React from 'react';
import $ from 'jquery';

import './banner.css';
import Leftmenu from '../../layout/leftmenu';

import withRouter from '../../components/withRouter';
import Datepicker from '../../components/datepicker';
import Timepicker from '../../components/datepicker/timepicker';
import SelectProdModal from '../../components/modal/selprod_modal';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, isEmptyObj, formatDate, formatDate2, formatTime } from '../../libs/utils';
import {requestPost, requestFile} from '../../libs/request';

class BannerDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            bannerId:     0,
            product_id:   '',
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',

            type:    'M', 
            title:   '', 
            subtitle: '',
            url:     '', 
            pc:      '', 
            mobile:  '',
            position: [],
            expo:    1,
            st_sel:  1,
            ed_sel:  1,
            st_time: null, 
            ed_time: null, 
            infinite_time: 'Y',
            cta_yn: 'N',
            stdate: new Date(), 
            eddate: new Date(), 
            sttime: new Date(), 
            edtime: new Date(),

            prod_expire_time: '', 
            company_name: ''
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const bannerId  = searchParams.get("id");
        const type      = searchParams.get("type");
        this.setState({type});
        // 
        this.getData(bannerId);
    }

    getData = (bannerId) => {
        window.scrollTo(0, 0);
        this.setState({bannerId});
    
        if(bannerId > 0) {
            requestPost('getBannerDetail', {bannerId}, (res) => {
                if(Boolean(res)) {
                    const { type, product_id, title, url, expo, st_time, ed_time, pc, mobile, position, sort, reg_time, email, enable, disable, product_title, prod_expire_time, subtitle, cta_yn, cta_btn_nm, cta_btn_color } = res;
                    console.log(st_time, ed_time)
                    this.setState({ 
                        type, product_id, title, url, expo, pc, mobile, sort, reg_time, email, enable, disable, product_title, st_time, ed_time, subtitle, cta_yn, cta_btn_nm, cta_btn_color,
                        position: Boolean(position) ? position.split(',') : [], 
                        st_sel: Boolean(st_time) ? 1: 2,
                        ed_sel: Boolean(ed_time) ? 1: 2,
                        stdate: Boolean(st_time) ? new Date( st_time ) : new Date(),
                        eddate: Boolean(ed_time) ? new Date( ed_time ) : new Date(), 
                        sttime: Boolean(st_time) ? new Date( st_time ) : new Date(), 
                        edtime: Boolean(ed_time) ? new Date( ed_time ) : new Date(),
                        infinite_time: Boolean(st_time) && st_time.startsWith('1899') && Boolean(ed_time) && ed_time.startsWith('9999') ? 'Y' : 'N',

                        prod_expire_time: Boolean(prod_expire_time) ? new Date( prod_expire_time ) : new Date(),
                    });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }
    handleFileChange = (event, type) => {
      let file = event.target.files[0];
      if(!file) {
        return;
      }
      const formData = new FormData(); 
      formData.append( "file",  file);
      requestFile('uploadImage', formData, (res) => {
        const {file_path, originalname} = res;
        this.setState({[type]: file_path});
      });
    }
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
    
        this.setState({[name]: value});

        if (name === 'infinite_time' && value === 'N') {
            this.setState({stdate: new Date(), sttime: new Date(), eddate: new Date(), edtime: new Date()})
        }
        // 
        let { type, prod_expire_time } = this.state;
        if(type = 'P' && name == 'ed_sel' && value == 2) {  // 광고상품 / 교육 모집 종료 일시 선택
            this.setState({
                eddate: new Date(prod_expire_time),
                edtime: new Date(prod_expire_time)
            });
        }

    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    updatePos = (idx) => {
        let { position } = this.state;

        if (idx < 0) {
            if (position.length < 3) {
                position = [3, 4, 5]
            } else {
                position = [];
            }

            this.setState({position})
        } else {
            let checked = false;
            for (let i in position) {
                let tmp = position[i];
                if(tmp == idx) {
                    position.splice(i, 1);
                    checked = true;
                    break;
                }
            }
            if(!checked) {
                position.push(idx);
            }
            this.setState({position});
        }

        
    }
    checkPos = (pos) => {
        let { position } = this.state;
        if (pos < 0) {
            if (position?.length === 3) {
                return true;
            } else {
                return false;
            }
        } else {
            if(!position) {
            return false;
            }
            for(let i in position) {
            let data = position[i];
            if(data == pos) {  // checked
                return true;
            }
            }
        
            return false;
        }
    }
    // 
    onConfirm = () => {
        const {bannerId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        requestPost('deleteBanner', {bannerId}, (res) => {
            if(Boolean(res)) {
                this.gotoPage(-1);
            }
            else {
                toastMsg('삭제에 실패하였습니다.');
            }
        });
    }
    update = () => {
        const {bannerId, product_id, type, title, url, expo, pc, mobile, position, st_sel, ed_sel, stdate, eddate, sttime, edtime, prod_expire_time, infinite_time, cta_yn, cta_btn_nm, cta_btn_color, subtitle} = this.state;

        if(type != 'P') {
            if(!bannerId && !title) {
                return toastMsg('배너명을 입력하세요.');
            }
            if((!pc || !mobile)) {   // 광고 상품는 이미지 없음
                return toastMsg('이미지를 등록하세요.');
            }
        }
        if(type == 'P') {
            if(!product_id) {
                return toastMsg('상품명을 입력하세요.');
            }
            
            if(formatDate2(eddate) > formatDate2(prod_expire_time)) {
                return toastMsg('교육의 모집 종료 일시 이전 시점으로 지정해야 합니다.');
            }
            if(formatDate2(eddate) < formatDate2(new Date())) {
                return toastMsg('종료일시는 오늘 이후 시점으로 지정해야 합니다.');
            }
        }
        // 
        let st_time = infinite_time === 'Y' ? '1900-01-01 00:00:00' : st_sel == 1 ? formatDate2(stdate) + ' ' + formatTime(sttime) + ':00' : null;
        let ed_time = infinite_time === 'Y' ? '9999-12-31 00:00:00' : ed_sel == 1 ? formatDate2(eddate) + ' ' + formatTime(edtime) + ':00' : null;
        
        if(type == 'P') {
            ed_time = formatDate2(eddate) + ' ' + formatTime(edtime) + ':00';
        }

        if(!st_time && !ed_time && st_time > ed_time) {
            return toastMsg('종료 일시는 시작 일시 이후로 설정하세요.');
        }

        const params = {
            bannerId, product_id, type, title, url, expo, pc, mobile, position: position.join(),
            st_time,
            subtitle, cta_yn, cta_btn_color, cta_btn_nm, 
            ed_time:  infinite_time === 'Y' ? ed_time : ed_sel == 1 ? formatDate2(eddate) + ' ' + formatTime(edtime) + ':00' : null
        }
        requestPost('updateBanner', params, (res) => {
            if(Boolean(res)) {
                if(!bannerId) {
                  setTimeout(() => {
                    this.gotoPage(-1);
                  }, 100);
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    render() {
        const { bannerId, confirmTitle, confirmTxt, modalIdx, 
            type, product_id, title, url, expo, pc, mobile, position, sort, reg_time, email, enable, disable, product_title, prod_expire_time, company_name,
            st_sel, ed_sel, stdate, eddate, sttime, edtime, infinite_time, subtitle,cta_yn, cta_btn_nm, cta_btn_color } = this.state;

        let page_title = '';
        switch (type) {
            case 'M': page_title = !Boolean(bannerId) ? "새로운 케러셀 배너 등록" : "홈 케러셀 상세"; break;
            case 'A': page_title = !Boolean(bannerId) ? "신규 광고 배너 등록" : "광고 배너 상세"; break;
            case 'P': page_title = !Boolean(bannerId) ? "광고 상품 게시 등록" : "광고 상품 게시 정보"; break;
        }
        
        return (
            <Leftmenu>
                <div className='page_contents detailMain'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{page_title}</div>
                            {Boolean(bannerId) || (<div className='fralign_center' style={{gap:20}}>
                                <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>
                                <div className='btn_normal' onClick={this.update}>등록</div>
                            </div>)}
                            {Boolean(bannerId) && (<div className='fralign_center' style={{gap:20}}>
                                <div className='btn_normal cancel' onClick={() => this.gotoPage('/report/advert/detail?id=' + bannerId)}>리포트 보기</div>
                                <div className='btn_normal' onClick={this.update}>저장</div>
                            </div>)}
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            {Boolean(bannerId) ||
                            <>
                            {type == 'P' ?
                            <>
                            <div className='card_row'>
                                <label className='field_label'>상품명</label>
                                <div className='card_row_val'>
                                    <input type={"text"} style={{width:400}} readOnly placeholder={"상품명을 입력하세요"} value={product_title} />
                                    <div className='btn_normal' onClick={() => this.setState({modalIdx: 2})}>상품 입력</div>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>교육사명</label>
                                <div className='card_row_val'>{company_name}</div>
                            </div>
                            </>
                            :
                            <>
                            <div className='card_row'>
                                <label className='field_label'>배너명</label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder={"배너명을 입력하세요"} value={title} onChange={({target}) => this.setState({title: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>부제</label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder={"부제를 입력하세요"} value={subtitle} onChange={({target}) => this.setState({subtitle: target.value})} />
                                </div>
                            </div>
                            </>
                            }
                            </>}
                            {Boolean(bannerId) && (<table className='alignleft'>
                                <tbody>
                                    <tr>
                                        <th>배너ID</th>
                                        <td>{bannerId}</td>
                                    </tr>
                                    <tr>
                                        <th>게시 상태</th>
                                        <td>{enable ? '게시중' : (disable ? '게시종료' : '') }</td>
                                    </tr>
                                    <tr>
                                        <th>등록자</th>
                                        <td>{email}</td>
                                    </tr>
                                    <tr>
                                        <th>등록일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                    {type == 'P' ?
                                    <tr>
                                        <th>상품명</th>
                                        <td>{product_title}</td>
                                        <th>상품ID</th>
                                        <td>{product_id}</td>
                                    </tr>
                                    :
                                    <>
                                    <tr>
                                        <th>배너명</th>
                                        <td>{title}</td>
                                    </tr>
                                     <tr>
                                        <th>부제</th>
                                        <td>{subtitle}</td>
                                    </tr>
                                    </>}
                                </tbody>
                            </table>)}
                            {type != 'P' &&
                            <>
                            <div className='card_row'>
                                <label className='field_label'>연결 URL</label>
                                <div className='card_row_val'>
                                    <input type="text" placeholder="배너 클릭 시 연결할 페이지 URL을 입력하세요" className='w-50' value={url} onChange={({target}) => this.setState({url: target.value})} />
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>CTA</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({cta_yn:'Y'})}><input type="radio" name="cta_yn" checked={cta_yn == 'Y'} onChange={()=>{}}/>사용</label>
                                    <label className='radio_label' onClick={() => this.setState({cta_yn:'N'})}><input type="radio" name="cta_yn" checked={cta_yn == 'N'} onChange={()=>{}}/>사용안함</label>
                                </div>
                            </div>
                            {
                                cta_yn === 'Y' ?
                                <>
                                <div className='card_row'>
                                    <label className='field_label'>버튼명</label>
                                    <div className='card_row_val'>
                                        <div className='card_row_col'>
                                            <input type={"text"} placeholder={"버튼명을 입력하세요"} value={cta_btn_nm} onChange={({target}) => this.setState({cta_btn_nm: target.value})} />
                                            <div className='card_row_item'>
                                                <input type={"text"} placeholder={"배너명을 입력하세요"} value={cta_btn_color} onChange={({target}) => this.setState({cta_btn_color: target.value})} maxLength={6} pattern='[0-9A-Za-z]*' />
                                                <div style={{width: '20px', height: '20px', borderRadius: '5px', backgroundColor: `#${cta_btn_color}`, border: '1px solid #efefef'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                :
                                null
                            }
                            </>}
                            <div className='card_row'>
                                <label className='field_label'>게시 상태</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({expo:1})}><input type="radio" name="expo" checked={expo == 1} onChange={()=>{}}/>게시</label>
                                    <label className='radio_label' onClick={() => this.setState({expo:2})}><input type="radio" name="expo" checked={expo == 2} onChange={()=>{}}/>게시종료</label>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>게시 기간 설정</label>
                                <div className='card_row_val'>
                                    <select value={infinite_time} onChange={this.handleInputChange} name="infinite_time">
                                        <option value={'Y'}>무제한</option>
                                        <option value={'N'}>게시일 지정</option>
                                    </select>
                                </div>
                                </div>
                                {
                                    infinite_time === 'N' ?
                                    <div className='card_row'>
                                        <div className='card_row_val'>
                                            <div className='fcjust_center' style={{gap:10}}>
                                                <div className='fralign_center' style={{gap:10}}>
                                                    <select value={st_sel} onChange={this.handleInputChange} name="st_sel">
                                                        <option value={1}>시작일시 지정</option>
                                                        <option value={2}>게시 즉시</option>
                                                    </select>
                                                    {st_sel == 1 &&
                                                    <>
                                                    <Datepicker selected={stdate} onChange={(date) => this.setState({ stdate: date })} maxDate={eddate} />
                                                    <Timepicker selected={sttime} onChange={(val) => this.setState({ sttime: val })} />
                                                    </>}
                                                </div>
                                                <div className='fralign_center' style={{gap:10}}>
                                                    <select value={ed_sel} onChange={this.handleInputChange} name="ed_sel">
                                                        {type == 'P' ?
                                                        <>
                                                        <option value={1}>종료일시 지정</option>
                                                        <option value={2}>교육 모집 종료 일시</option>
                                                        </>
                                                        :
                                                        <>
                                                        <option value={1}>종료일시 지정</option>
                                                        <option value={2}>무제한</option>
                                                        </>
                                                        }
                                                    </select>
                                                    {(ed_sel == 1 || type == 'P') &&
                                                    <>
                                                    <Datepicker selected={eddate} onChange={(date) => this.setState({ eddate: date })} 
                                                        minDate={stdate} 
                                                        // maxDate={type == 'P' ? prod_expire_time : null} 
                                                    />
                                                    <Timepicker selected={edtime} onChange={(val) => this.setState({ edtime: val })} />
                                                    {(type == 'P' && ed_sel == 1 && Boolean(prod_expire_time)) && <label>교육의 모집 종료 일시 이전 시점으로 지정해야 합니다. [모집종료일시: {formatDate2(prod_expire_time)}]</label>}
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : 
                                    null
                                }
                            {type == 'A' &&
                            <div className='card_row'>
                                <label className='field_label'>게시 위치 설정</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(-1)} onChange={() => {}} onClick={() => this.updatePos(-1)} />전체</label>
                                    {/* <label className='radio_label' ><input type="checkbox" checked={this.checkPos(0)} onChange={() => {}} onClick={() => this.updatePos(0)} />영역1(교육찾기)</label>
                                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(1)} onChange={() => {}} onClick={() => this.updatePos(1)} />영역2(교육상품상세)</label>
                                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(2)} onChange={() => {}} onClick={() => this.updatePos(2)} />영역3(직무백서)</label> */}
                                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(5)} onChange={() => {}} onClick={() => this.updatePos(5)} />홈</label>
                                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(4)} onChange={() => {}} onClick={() => this.updatePos(4)} />마이페이지</label>
                                    <label className='radio_label' ><input type="checkbox" checked={this.checkPos(3)} onChange={() => {}} onClick={() => this.updatePos(3)} />검색결과</label>
                                    
                                </div>
                            </div>}
                        </div>
                        {type != 'P' &&
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box', gap:20 }}>
                            <div className='fralign_center' style={{gap:20}}>
                                <div className='btn_normal' onClick={() => $('input[name=pc]').trigger('click')}>PC용 이미지 등록</div>
                                <span className='gray-text fs-11 mx-2 mt--2'>{type === 'M' ? 'png, jpg 파일, 1250px X 470px 권장' : 'png, jpg 파일, 1250px X 380px 권장'}</span>
                                <div className='btn_normal' onClick={() => $('input[name=mobile]').trigger('click')}>모바일용 이미지 등록</div>
                                <span className='gray-text fs-11 ms-2 mt--2'>png, jpg 파일, 525px X 124px 권장</span>

                                <input type="file" name="pc" onChange={(e) => this.handleFileChange(e, 'pc')} className="d-none" accept="image/*" />
                                <input type="file" name="mobile" onChange={(e) => this.handleFileChange(e, 'mobile')} className="d-none" accept="image/*" />
                            </div>
                            {Boolean(pc) &&
                            <div>
                                <label className='font_D400_14'>PC 이미지 미리보기</label>
                                <br />
                                <img src={consts.resourcUrl + pc} className='pc_img_bg' />
                            </div>}
                            {Boolean(mobile) &&
                            <div>
                                <label className='font_D400_14'>모바일 이미지 미리보기</label>
                                <br />
                                <img src={consts.resourcUrl + mobile} className='mobile_img_bg' />
                            </div>}
                        </div>}
                        {Boolean(bannerId) && (<div className='fr_c_end w-100' style={{ paddingBottom:20 }}>
                            <div className='btn_remove' onClick={() => this.setState({confirmTitle: page_title, confirmTxt: type == 'P' ? "광고 상품 게시 설정을 삭제하시겠습니까?" : "배너 정보를 삭제하시겠습니까?", modalIdx: 1})}>{type == 'P' ? '광고 설정 정보 삭제' : '배너 삭제'}</div>
                        </div>)}
                    </div>
                </div>
                {modalIdx == 1 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
                {modalIdx == 2 && 
                    <SelectProdModal productId={product_id} closeModal={() => this.setState({modalIdx: 0})} 
                        selectDatas={({id, title, user_name, expire_time}) => 
                            this.setState({
                                product_id: id, 
                                product_title: title, 
                                company_name: user_name, 
                                prod_expire_time: expire_time
                            })} 
                    />}
            </Leftmenu>
        );
    }
}

export default withRouter(BannerDetail);