import React from 'react';

import withRouter from '../../components/withRouter';
import BannerDetail from './BannerDetail';
import Leftmenu from '../../layout/leftmenu';
import Datepicker from '../../components/datepicker';
import Timepicker from '../../components/datepicker/timepicker';
import EduProductModal from '../../components/modal/EduProductModal';
import { formatDate2, formatTime, toastMsg } from '../../libs/utils';
import { nonAuthedReqPost, requestPost } from '../../libs/request';

let org_state = {};
let saved = false;
class detailSetting extends React.Component {
    constructor() {
        super();
        this.state = {
            title: '',
            content: '',
            infinite_time: 'Y',
            st_date: new Date(), 
            ed_date: new Date(), 
            id: 0,
            status: 1,
            st_sel:  1,
            ed_sel:  1,
            pick_1: {},
            pick_2: {},
            currentBootcamp: 0,
            modalIdx: 0,
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        let id = searchParams.get("id");   // 파트너에서 정보 수정 > 관리자가 "수정 요청 확인" 했을 때
        id = Boolean(id) ? id : '';
        // 
        org_state = {};
        saved = false;
        org_state = this.state;
        // 
        this.getData(id);
        
    }

    getData = (id) => {
    window.scrollTo(0, 0);
    this.setState({id: id});
    console.log(id);

    if (id < 1) {

    } else {
      nonAuthedReqPost('getEditorsPickDetail', {id}, (res) => {
        if (Boolean(res)) {

          this.setState({
            id: res.id,
            title: res.title,
            content: res.content,
            status: res.status,
            stdate: Boolean(res.st_time) ? new Date( res.st_time ) : new Date(),
            eddate: Boolean(res.ed_time) ? new Date( res.ed_time ) : new Date(), 
            sttime: Boolean(res.st_time) ? new Date( res.st_time ) : new Date(), 
            edtime: Boolean(res.ed_time) ? new Date( res.ed_time ) : new Date(),
            infinite_time: Boolean(res.st_time) && res.st_time.startsWith('1899') && Boolean(res.ed_time) && res.ed_time.startsWith('9999') ? 'Y' : 'N',
            pick_1: {
              id: res.pick_1,
              title: res.pick_1_title
            },
            pick_2: {
              id: res.pick_2,
              title: res.pick_2_title
            },
          })
        }
      })
    }
    // checkEdit 값이 설정되었을 때 updated된 값을 비교하여 하이라이트 및 수정 되어야 함.
  }

    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        this.setState({[name]: value});

        if (name === 'infinite_time' && value === 'N') {
            this.setState({stdate: new Date(), sttime: new Date(), eddate: new Date(), edtime: new Date()})
        }
    }

    setBootcamp = (bootcamp, id) => {
        switch(id) {
        case 1:
            this.setState({pick_1: bootcamp, modalIdx: 0})
        break;
        case 2:
            this.setState({pick_2: bootcamp, modalIdx: 0})
        break;
        default: break;
        }
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    update = () => {
    const { id, title,status, st_date, ed_date, infinite_time, st_sel, ed_sel, stdate, eddate, sttime, edtime, content, pick_1, pick_2   } = this.state;
    let st_time = infinite_time === 'Y' ? '1900-01-01 00:00:00' : st_sel == 1 ? formatDate2(stdate) + ' ' + formatTime(sttime) + ':00' : null;
    let ed_time = infinite_time === 'Y' ? '9999-12-31 00:00:00' : ed_sel == 1 ? formatDate2(eddate) + ' ' + formatTime(edtime) + ':00' : null;
    
    if (!title) {
      toastMsg('큐레이션명을 입력하세요.', 'warning');
      return;
    }

    if (!content) {
        return toastMsg('내용을 입력하세요.', 'warning')
    }

    if(!st_time && !ed_time && st_time > ed_time) {
        return toastMsg('종료 일시는 시작 일시 이후로 설정하세요.');
    }
    if (!pick_1.id) {
      return toastMsg('에디터스 픽 푸트캠프를 선택하세요.', 'warning');
    }
    if (!pick_2.id) {
      return toastMsg('비교 푸트캠프를 선택하세요.', 'warning');
    }

    let params = {
      id, title, content, status, st_time, ed_time, pick_1: pick_1.id, pick_2: pick_2.id
    };

    console.log(params);

    requestPost('upsertEditorsPick', params, (res) => {
      if (Boolean(res)) {
        this.gotoPage('/banner/setting')
      } else {
        toastMsg('저장에 실패했습니다.')
      }
    })

  }

  delete () {
    const { id } = this.state;
    requestPost('deleteEditorsPick', {id}, (res) => {
        if (Boolean(res)) {
        this.gotoPage('/banner/setting')
      } else {
        toastMsg('저장에 실패했습니다.')
      }
    })
  }

    render() {
        const { modalIdx, currentBootcamp, id , title, content, pick_1, pick_2, infinite_time, stdate, eddate, sttime, edtime, st_sel, ed_sel, status } = this.state;
        return (
        <Leftmenu>
            <div className='page_contents detailleasson'>
            {/* <UsePrompt isDirty={JSON.stringify(org_state) != JSON.stringify(state1) && !saved} /> */}
                <div className='card'>
                    <div className='card_head'>
                        <div className='card_head_title'>에디터스 픽 {Boolean(id) ? '상세' : '등록'}</div>
                        <div className='fralign_center' style={{ gap: 10 }}>
                           <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>
                           {id && <div className='btn_normal cancel' onClick={() => this.delete()}>삭제</div>}
                             <div className='btn_normal' onClick={() => this.update()}>등록</div>
                        </div>
                    </div>
                    <div className='card_body'>
                        <div className='frjustify_between splite_head' style={{ width: '100%' }}>
                            <div className='font_D700_16'>기본 정보</div>
                            <div className='field_label'><span>*</span> 필수 입력 항목입니다.</div>
                        </div>
                    </div>
                    <>
                        <div className={`card_row`} style={{ alignItems: 'stretch' }}>
                            <div style={{ flex: 1 }}>
                            {id > 0 && (<div className={`card_row`} style={{ borderTop: 0 }}>
                                <label className='field_label'>상품ID</label>
                                <div className='card_row_val'>{id}</div>
                            </div>)}
                            <div className={`card_row`}>
                                <label className='field_label'>에디터스픽 명<span>*</span></label>
                                <div className='card_row_val'>
                                <input type="text" className='input_box' placeholder='에디터스 픽 타이틀을 입력하세요.' value={title} style={{ width: 330 }} onChange={this.handleInputChange} name='title' />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='card_row'>
                            <label className='field_label'>부가설명</label>
                            <div className='card_row_val'>
                                <label className='radio_label' onClick={() => this.setState({status:1})}><input type="radio" name="status" checked={status == 1} onChange={()=>{}}/>게시</label>
                                <label className='radio_label' onClick={() => this.setState({status:2})}><input type="radio" name="status" checked={status == 2} onChange={()=>{}}/>게시안함</label>
                            </div>
                        </div>
                    </>
                    <div className='card_row'>
                        <label className='field_label'>게시 기간 설정</label>
                        <div className='card_row_val'>
                            <select value={infinite_time} onChange={this.handleInputChange} name="infinite_time">
                                <option value={'Y'}>무제한</option>
                                <option value={'N'}>게시일 지정</option>
                            </select>
                        </div>
                    </div>
                    {
                        infinite_time === 'N' ?
                        <div className='card_row'>
                            <div className='card_row_val'>
                                <div className='fcjust_center' style={{gap:10}}>
                                    <div className='fralign_center' style={{gap:10}}>
                                        <select value={st_sel} onChange={this.handleInputChange} name="st_sel">
                                            <option value={1}>시작일시 지정</option>
                                            <option value={2}>게시 즉시</option>
                                        </select>
                                        {st_sel == 1 &&
                                        <>
                                        <Datepicker selected={stdate} onChange={(date) => this.setState({ stdate: date })} maxDate={eddate} />
                                        <Timepicker selected={sttime} onChange={(val) => this.setState({ sttime: val })} />
                                        </>}
                                    </div>
                                    <div className='fralign_center' style={{gap:10}}>
                                        <select value={ed_sel} onChange={this.handleInputChange} name="ed_sel">
                                        
                                        <>
                                        <option value={1}>종료일시 지정</option>
                                        <option value={2}>무제한</option>
                                        </>
                                            
                                        </select>
                                        {(ed_sel == 1) &&
                                        <>
                                        <Datepicker selected={eddate} onChange={(date) => this.setState({ eddate: date })} 
                                            minDate={stdate} 
                                            // maxDate={type == 'P' ? prod_expire_time : null} 
                                        />
                                        <Timepicker selected={edtime} onChange={(val) => this.setState({ edtime: val })} />
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : 
                        null
                    }
                    <div className='card_row'>
                        <label className='field_label'>에디터스픽</label>
                        <div className='card_row_val'>
                            <input type="text" disabled style={{width: '100%'}} value={pick_1.id ? pick_1.title : ''}/>
                            {
                            pick_1.id ?
                            <div className="btn_normal cancel" onClick={() => this.setState({pick_1: {}})}>제거</div>
                            :
                            <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentBootcamp: 1})}>선택</div>
                            }
                        </div>
                    </div>
                    <div className='card_row'>
                        <label className='field_label'>비교강의</label>
                        <div className='card_row_val'>
                            <input type="text" disabled style={{width: '100%'}} value={pick_2.id ? pick_2.title : ''}/>
                            {
                            pick_2.id ?
                            <div className="btn_normal cancel" onClick={() => this.setState({pick_2: {}})}>제거</div>
                            :
                            <div className="btn_normal" onClick={() => this.setState({modalIdx: 1, currentBootcamp: 2})}>선택</div>
                            }
                        </div>
                    </div>
                    <div className={`card_row`}>
                        <label className='field_label'>에디터스픽 내용<span>*</span></label>
                        <div className='card_row_val'>
                        <textarea className='input_box' placeholder='내용을 입력하세요.' value={content} style={{width: '100%', height: 300}} onChange={this.handleInputChange} name='content' />
                        </div>
                    </div>
                </div>
            </div>
             {modalIdx == 1 && <EduProductModal closeModal={() => this.setState({modalIdx: 0, currentBootcamp: 0})} confirm={(item) => this.setBootcamp(item, currentBootcamp)}/>}
        </Leftmenu>
        );
    }
}

export default withRouter(detailSetting);