import './datepicker.css'
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko)

function DateBtnpicker({ selected, onChange, format  }) {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='date_btn' onClick={onClick} ref={ref}>{value}</div>
    ));
    return (
        <div style={{ width: 'min-content' }}>
            <DatePicker
                dateFormat={format===2?"yyyy년":(format===1?"yyyy년 MM월":"yyyy년 MM월 dd일")}
                selected={selected}
                onChange={onChange}
                locale="ko"
                customInput={<ExampleCustomInput />}
                showYearPicker={format === 2}
                yearItemNumber={3}
                showMonthYearPicker={format === 1}
            />
        </div>
    );
}

export default DateBtnpicker;