import './partner.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import EduKeyword from '../../components/modal/edu_keyword';
import ConformModal from '../../components/modal/ConformModal';

import consts from '../../libs/consts';
import { formatDate2, getEduDays, toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum } from '../../libs/utils';
import {requestPost, fileDownload} from '../../libs/request';

class DetailRequest extends React.Component {
    constructor() {
        super();
        this.state = {
            modalIdx: 0,
            confirmTitle: '',
            confirmTxt:   '',
            //  
            buss_num:   '', 
            company:    '', 
            owner:      '', 
            service:    '', 
            buss_file:  '', 
            communit_file:  '', 
            brand_file: '', 
            buss_path:  '', 
            communit_path:  '', 
            brand_path: '', 
            manager_name: '', 
            phone:      '', 
            m_email:    '', 
            admin_accept: 0, 
            status:     3, 
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        let partnerId = searchParams.get("id");
        this.getData(partnerId);
    }

    getData = (partnerId) => {
        window.scrollTo(0, 0);
        this.setState({partnerId});
    
        if(partnerId > 0) {
            requestPost('getEduCompanyDetail', {partnerId}, (res) => {
                if(Boolean(res)) {
                const { p_email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                    brand_path, manager_name, phone, m_email, status, admin_accept, accept_time, reg_time } = res;
        
                this.setState({ p_email, user_name, homepage, intro, thumb, expo, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, 
                    brand_path, manager_name, phone, m_email, status, admin_accept, accept_time, reg_time });
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }
    openReqUrl = (url) => {
        window.open(url, '_blank', 'noreferrer');
    }
    openCloseEdu = () => {
        const { showedu } = this.state;
        this.setState({ showedu: !showedu });
    }

    openCloseConfirm = () => {
        this.setState({ modalIdx: 10, confirmTitle: "파트너사 신청", confirmTxt: "처리완료 상태로 변경하시겠습니까?" });
    }
    onConfirm = () => {
        const {modalIdx, partnerId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
    
        if(modalIdx == 10) {  // 처리완료 상태로 변경
            requestPost('updateCompanyStatus', {partnerId, type: 'accept' }, (res) => {
                if(Boolean(res)) {
                    toastMsg('처리되었습니다.', 'success');
                    this.setState({admin_accept: 1});
                }
            });
        }
        else if(modalIdx == 11) {  // delete
            requestPost('updateCompanyStatus', {partnerId, type: 'del' }, (res) => {
                if(Boolean(res)) {
                    this.gotoPage('/partner/request');
                }
            });
        }
        else if(modalIdx > 1000) {  // 교육사 선택
            let link = `/partner/company/detail?partner_id=${modalIdx - 1000}`;
            this.gotoPage(link);
        }
    }

    openNewCompany = () => {
        const { router } = this.props;
        const {partnerId} = this.state;
        router.navigate('/partner/company/detail?partner_id=' + partnerId);
    }

    selectCompany = (id, buss_num) => {
        console.log('buss_num', buss_num)
        if(Boolean(buss_num)) {
            setTimeout(() => {
                this.setState({ modalIdx: 1000 + id, confirmTitle: "교육사 선택", confirmTxt: "선택한 교육사에 이미 회사정보가 등록되어 있습니다. \n기존 입력 정보를 삭제하고 입력하시겠습니까?" });
            }, 300);
            return;
        }
        let link = `/partner/company/detail?partner_id=${id}`;
        this.gotoPage(link);
    }

    render() {
        const { modalIdx, confirmTitle, confirmTxt,
                p_email, buss_num, company, owner, service, buss_file, communit_file, brand_file, buss_path, communit_path, brand_path, manager_name, phone, m_email, admin_accept, homepage, accept_time, reg_time } = this.state;
                
        return (
            <Leftmenu>
                <div className='page_contents detailrequest'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>파트너사 신청 상세</div>
                            <div className='d-flex'>
                                <div className='btn_remove' onClick={() => this.setState({confirmTitle: "삭제하기", confirmTxt: "교육사 정보를 삭제하시겠습니까? \n해당 교육사 정보 및 등록된 모든 교육 상품 정보가 삭제됩니다.", modalIdx: 11})}>파트너사 신청 삭제</div>
                                {admin_accept == 0 &&
                                <div className='btn_normal ms-3' onClick={this.openCloseConfirm}>처리완료 상태로 변경</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{ border: '1px solid #EEEEEE', padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between' style={{ width: '100%', padding: '10px 0px' }}>
                                <div className='font_D700_16'>기본 정보</div>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>접수 일시</th>
                                        <td>{formatDate(accept_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>처리 상태</th>
                                        <td>{admin_accept == 0 ? '신청접수' : '처리완료'}</td>
                                    </tr>
                                    <tr>
                                        <th>처리 담당자</th>
                                        <td>{manager_name}({m_email})</td>
                                    </tr>
                                    <tr>
                                        <th>처리 완료 일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='card_body border-1' style={{ padding: 10, boxSizing: 'border-box' }}>
                            <div className='frjustify_between w-100' style={{ padding: '10px 0px' }}>
                                <div className='font_D700_16'>신청 정보</div>
                                <div className='fralign_center' style={{gap:10}}>
                                    <div className='btn_normal' onClick={this.openNewCompany}>신규 교육사 생성</div>
                                    <div className='btn_normal' onClick={() => this.setState({modalIdx: 1})}>기존 교육사 정보로 넣기</div>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>사업자등록번호</th>
                                        <td>{buss_num}</td>
                                        <th>담당자명</th>
                                        <td>{manager_name}</td>
                                    </tr>
                                    <tr>
                                        <th>회사(법인)명</th>
                                        <td>{company}</td>
                                        <th>담당자 휴대폰번호</th>
                                        <td>{phone}</td>
                                    </tr>
                                    <tr>
                                        <th>담당자 이메일</th>
                                        <td>{m_email}</td>
                                        <th>파트너센터 계정용 이메일</th>
                                        <td>{Boolean(p_email) ? p_email : '-'}</td>
                                    </tr>
                                    <tr>
                                        <th>교육사 홈페이지</th>
                                        <td colSpan={3}><a href={(homepage) ? (homepage.indexOf('http') >= 0 ? homepage : 'http://'+homepage) : ''} target="_blank" rel="noreferrer">{homepage}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='card_row' style={{marginTop:30}}>
                                <label className='field_label'>사업자등록증</label>
                                <div className='card_row_val'>
                                    {Boolean(buss_file) && <div className='btn_normal cancel' onClick={() => this.openReqUrl(consts.resourcUrl + buss_path)}>보기</div>}
                                    {Boolean(buss_file) && <div className='btn_link' onClick={() => fileDownload(consts.resourcUrl + buss_path, buss_file)}>다운로드</div>}
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>브랜드 로고</label>
                                <div className='card_row_val'>
                                    {Boolean(brand_file) && <div className='btn_normal cancel' onClick={() => this.openReqUrl(consts.resourcUrl + brand_path)}>보기</div>}
                                    {Boolean(brand_file) && <div className='btn_link' onClick={() => fileDownload(consts.resourcUrl + brand_path, brand_file)}>다운로드</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modalIdx == 1 && <EduKeyword closeModal={() => this.setState({modalIdx: 0})} selectDatas={({id, buss_num}) => this.selectCompany(id, buss_num)} />}
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailRequest);