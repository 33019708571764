import ico_check from '../assets/ico_check.png';
import ico_page_first from '../assets/ico_page_first.png';
import ico_page_last from '../assets/ico_page_last.png';
import ico_page_next from '../assets/ico_page_next.png';
import ico_page_prev from '../assets/ico_page_prev.png';
import ico_x from '../assets/ico_x.png';

export default {
    ico_check,
    ico_page_first,
    ico_page_last,
    ico_page_next,
    ico_page_prev,
    ico_x,
};