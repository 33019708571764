import './modal.css'
import Modal from 'react-modal';
import { useState } from "react";
import {requestPost} from '../../libs/request';
import { toastMsg } from '../../libs/utils';

Modal.setAppElement('#root');
function MemberPause({ memberId, status, afterOpenModal, closeModal, onConfirm }) {
    const [pausetype, setPausetype] = useState((status == 'T' || status == 'S') ? status : 'T');
    const [stopDay, setStopDay] = useState(0);

    const changeMemStatus = () => {
        if(pausetype == 'T' && stopDay == 0) {
            return toastMsg('일시정지일을 입력하세요.');
        }
        requestPost('updateMemberStatus', {memberId, status: pausetype, stop_day: stopDay, type: 'status'}, (res) => {
            if(Boolean(res)) {
                toastMsg('처리되었습니다.', 'success');
                onConfirm(pausetype)
                closeModal();
            }
        });
    }

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 500, padding:0 }
            }}
        >
            <div className='card memberpause'>
                <div className='card_head'>
                    <div className='card_head_title'>계정 이용 정지 설정</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='fcjust_center' style={{alignItems: 'flex-start', margin: 'auto'}}>
                    <div className='fralign_center'>
                        <label className='radio_label' onClick={()=>{setPausetype('T')}}><input type="radio" name="pausetype" checked={pausetype == 'T'} onChange={()=>{}}/>일시정지</label>
                        <input type="text" style={{width:80, marginLeft:30}} value={stopDay} 
                            onChange={(e) => {
                                const check = /[a-z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
                                if (e.nativeEvent.data && check.test(e.nativeEvent.data)) {
                                    return null
                                }
                                setStopDay(e.target.value.replace(/[^0-9]/g, ''))
                                }} disabled={pausetype == 'S'} />
                        <label style={{width:80, marginLeft:10, fontSize: 13}}>일</label>
                    </div>
                    <div className='fralign_center'>
                        <label className='radio_label' onClick={()=>{setPausetype('S')}}><input type="radio" name="pausetype" checked={pausetype == 'S'} onChange={()=>{}}/>영구정지</label>
                    </div>
                </div>
                
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={()=>{
                        changeMemStatus();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
}

export default MemberPause;