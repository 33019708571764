import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { fileDownload, requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';
import moment from 'moment';
import consts from '../../libs/consts';
import { nonAuthedReqPost } from '../../libs/request';
import Pagination from '../Pagination';

Modal.setAppElement('#root');
function ReviewListModal({ edu_id, closeModal,type, matchingYn, confirm }) {

  const [reviewList, setReviewList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    fetchData();
    setCheckedAll(false);
  }, [page])

  useEffect(() => {
    if (reviewList.length > 0 && reviewList.filter(item => item.checked === true).length === reviewList.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [reviewList]);

  const checkAll = () => {
    if (checkedAll) {
      let temp = [...reviewList];
    temp = temp.map(item => ({...item, checked: false}));
    setReviewList([...temp])
    } else {
      let temp = [...reviewList];
      temp = temp.map(item => ({...item, checked: true}));
      setReviewList([...temp])
    }
    
    setCheckedAll(!checkedAll);
  }

  const fetchData = () => {
    if (type === 'P') {
      if (matchingYn === 'Y') {
        nonAuthedReqPost('getUnmatchedPremiumReviews', {offset: 5, page: page}, (res) => {
          if (Boolean(res)) {
            console.log(res);
            setReviewList(res.rows);
            setTotalCount(res.totalCount);
          }
        })
      } else {
        nonAuthedReqPost('getPremiumReviewList', {edu_id: edu_id, offset: 5, page: page, review_st: 2}, (res) => {
          if (Boolean(res)) {
            console.log(res);
            setReviewList(res.rows);
            setTotalCount(res.totalCount);
          }
        })
      }
    } else {
      if (matchingYn === 'Y') {
        nonAuthedReqPost('getUnmatchedReviews', {offset: 5, page: page}, (res) => {
          if (Boolean(res)) {
            console.log(res);
            setReviewList(res.rows);
            setTotalCount(res.totalCount);
          }
        })
      } else {
        nonAuthedReqPost('getReviewList', {edu_id: edu_id, offset: 5, page: page, review_st: 2}, (res) => {
          if (Boolean(res)) {
            console.log(res);
            setReviewList(res.rows);
            setTotalCount(res.totalCount);
          }
        })
      }
    }
  }

  const confirmData = () => {
    let lists = reviewList.filter(item => item.checked === true);
    console.log(lists);

    if (type === 'P') {
      if (matchingYn === 'Y') {
        let sussList = [];
        let failList = [];
        for (let i = 0; i < lists.length; i++) {
          let review = lists[i];
          requestPost('matchPremiumReview', {review_id: review.review_id, edu_id: edu_id}, (res) => {
            if(Boolean(res)) {
              sussList.push(review);
            } else {
              failList.push(review);
            }
          });
        }

        if (failList.length === 0) {
          toastMsg('저장되었습니다.', 'success');
          confirm();
        } else {
          toastMsg('저장에 실패한 목록이 있습니다.', 'warning');
          setCheckedAll(false);
          fetchData();
        }
      } else {
        let sussList = [];
        let failList = [];
        for (let i = 0; i < lists.length; i++) {
          let review = lists[i];
          requestPost('updatePremiumReviewCertSt', {review_id: review.review_id, review_st: 1}, (res) => {
            if(Boolean(res)) {
              sussList.push(review);
            } else {
              failList.push(review);
            }
          });
        }

        if (failList.length === 0) {
          toastMsg('저장되었습니다.', 'success');
          confirm();
        } else {
          toastMsg('저장에 실패한 목록이 있습니다.', 'warning');
          setCheckedAll(false);
          fetchData();
        }
      }
    } else {
      if (matchingYn === 'Y') {
        let sussList = [];
        let failList = [];
        for (let i = 0; i < lists.length; i++) {
          let review = lists[i];
          requestPost('matchReview', {review_id: review.review_id, edu_id: edu_id}, (res) => {
            if(Boolean(res)) {
              sussList.push(review);
            } else {
              failList.push(review);
            }
          });
        }

        if (failList.length === 0) {
          toastMsg('저장되었습니다.', 'success');
          confirm();
        } else {
          toastMsg('저장에 실패한 목록이 있습니다.', 'warning');
          setCheckedAll(false);
          fetchData();
        }
      } else {
        let sussList = [];
        let failList = [];
        for (let i = 0; i < lists.length; i++) {
          let review = lists[i];
          requestPost('updateReviewCertSt', {review_id: review.review_id, review_st: 1}, (res) => {
            if(Boolean(res)) {
              sussList.push(review);
            } else {
              failList.push(review);
            }
          });
        }

        if (failList.length === 0) {
          toastMsg('저장되었습니다.', 'success');
          confirm();
        } else {
          toastMsg('저장에 실패한 목록이 있습니다.', 'warning');
          setCheckedAll(false);
          fetchData();
        }
        
      }
    }
  }

    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 1000, height: 600, padding:0 }
            }}
        >
            <div className='card diffkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>{matchingYn === 'Y' ? '매칭필요' : '승인대기'} 리뷰목록</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='card_body' style={{border: 0}}>
                  <table>
                    
                      {
                        type === 'P' ?
                        <>
                          <tbody>
                            <tr>
                              <th><input type="checkbox" checked={checkedAll} onClick={() => checkAll()}/></th>
                              <th width={'5%'}>총점</th>
                              <th width={'5%'}>기수</th>
                              <th >수강상태</th>
                              <th >종합평가</th>
                              <th>성별</th>
                              <th>연령</th>
                              <th>교육경험</th>
                              <th >재직여부</th>
                              <th >작성자</th>
                              <th >작성일</th>
                              <th >인증상태</th>
                            </tr>
                            {
                              reviewList?.map((item, index) => (
                                <tr>
                                  <td><input type="checkbox" checked={item.checked} onChange={(e) => {
                                    let temp = [...reviewList];
                                    temp[index].checked = !item.checked
                                    setReviewList([...temp]);
                                  }}/></td>
                                  <td>{item.score_total}</td>
                                  <td>{item.period || '-'}</td>
                                  <td>{item.edu_st === 1 ? '면접통과' : item.edu_st === 2 ? '수강중' : item.edu_st === 3 ? '수강중단' : '수료'}</td>
                                  <td style={{textAlign: 'left', textOverflow:'ellipsis', overflow:'hidden'}}>{item.text_total}</td>
                                  <td>{item.sex === 'M' ? '남' : '여'}</td>
                                  <td>{item.age}</td>
                                  <td>{item.similar_yn}</td>
                                  <td>{item.on_job_yn}</td>
                                  <td>{item.user_nickname + '(' + item.email + ')'}</td>
                                  <td>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                                  <td>{item.cert_st === 1 ? '인증' : '미인증'}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                          </>
                        : <>
                          <tbody>
                            <tr>
                              <th><input type="checkbox" checked={checkedAll} onClick={() => checkAll()}/></th>
                              <th width={'5%'}>총점</th>
                              <th width={'5%'}>기수</th>
                              <th width={'10%'}>수강상태</th>
                              <th width={'19%'}>좋은점</th>
                              <th width={'19%'}>아쉬운점</th>
                              <th width={'16%'}>작성자</th>
                              <th width={'16%'}>작성일</th>
                              <th width={'10%'}>인증상태</th>
                            </tr>
                            {
                              reviewList?.map((item, index) => (
                                <tr>
                                  <td><input type="checkbox" checked={item.checked} onChange={(e) => {
                                    let temp = [...reviewList];
                                    temp[index].checked = !item.checked
                                    setReviewList([...temp]);
                                  }}/></td>
                                  <td>{item.score}</td>
                                  <td>{item.period || '-'}</td>
                                  <td>{item.edu_st === 1 ? '면접통과' : item.edu_st === 2 ? '수강중' : item.edu_st === 3 ? '수강중단' : '수료'}</td>
                                  <td style={{textAlign: 'left', }}>{item.pros}</td>
                                  <td style={{textAlign: 'left', }}>{item.cons}</td>
                                  <td>{item.user_nickname + '(' + item.email + ')'}</td>
                                  <td>{moment(item.reg_dtm).format('YYYY-MM-DD HH:mm')}</td>
                                  <td>{item.cert_st === 1 ? '인증' : '미인증'}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </>
                      }
                  </table>
                </div>

                <Pagination totalCnt={totalCount} onClick={(pageIdx) => setPage(pageIdx)} currentPage={page} perPage={5} />
                
                <div className='modal_bottom'>
                    <div className='btn_normal' onClick={()=>{
                      confirmData();
                    }}>{matchingYn === 'Y' ? '매칭' : '승인'}</div>
                    <div className='btn_normal cancel' onClick={closeModal}>닫기</div>
                </div>
            </div>
        </Modal>
    );
}

export default ReviewListModal;