import './banner.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime } from '../../libs/utils';

class BannerAdvertList extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      status:       '',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, status } = this.state;
    const params = { keyword, status, currentPage, type: 'A' }
    requestPost('getBannerList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeStatus = (status) => {
    this.setState({status})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  openDetail = (id) => {
    const { router } = this.props;
    router.navigate('/banner/advert/detail?id=' + id);
  }

  renderPosition = (pos) => {
    let posList = ["영역1(교육찾기)", "영역2(교육상품상세)", "영역3(직무백서)", "영역4(검색결과)", "영역5(마이페이지)"];
    if(!pos) {
      return '';
    }

    let tmpList = [];
    pos.split(',').map(dt => {
      tmpList.push(posList[dt]);
    });

    return tmpList.join();
  }

  render() {
    const { keyword, status, totalCnt, currentPage, dataList, checkAll } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents bannermainlist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>광고 배너 전체보기</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='배너ID, 배너명, 등록자명으로 검색'
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
                <div className='btn_normal' onClick={() => this.gotoPage('/banner/advert/detail?type=A')}>신규 배너 등록</div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
                <span>{formatNum(totalCnt)}</span>개의 배너
              </div>
            <div className='fralign_center' style={{ gap: 20 }}>
                <div className='font_D700_14'>게시상태</div>
                <label className='radio_label' onClick={() => this.changeStatus(0)}><input type="radio" name="status" checked={status == 0} onChange={()=>{}} />전체</label>
                <label className='radio_label' onClick={() => this.changeStatus(1)}><input type="radio" name="status" checked={status == 1} onChange={()=>{}} />게시중</label>
                <label className='radio_label' onClick={() => this.changeStatus(2)}><input type="radio" name="status" checked={status == 2} onChange={()=>{}} />게시종료</label>
            </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>배너ID</th>
                  <th>게시 위치</th>
                  <th>배너명</th>
                  <th>게시상태</th>
                  <th>등록자</th>
                  <th>등록일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={()=>{this.openDetail(item.id)}}>
                    <td>{item.id}</td>
                    <td>{this.renderPosition(item.position)}</td>
                    <td>{item.title}</td>
                    <td>{item.enable ? '게시중' : (item.disable ? '게시종료' : '') }</td>
                    <td>{item.user_name}({item.email})</td>
                    <td>{formatDate(item.reg_time)}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(BannerAdvertList);