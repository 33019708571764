import React from 'react';
import './login.css';
import { connect } from "react-redux";
import withRouter from '../../components/withRouter';
import { setauthinf } from '../../redux/authacc/AuthaccActions';
import {validationEmail, verifyPassword} from '../../libs/utils';
import {nonAuthedReqPost} from '../../libs/request';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email:      "",
      password:   "",
      email_msg:  '',
      pass_msg:   ''
    };
  }

  onClickLogin = ()=>{
    const {email, password} = this.state;

    let pass_msg = '';
    if(email === '') {
      return this.setState({email_msg: '아이디를 입력하세요.'});
    }
    else if(!validationEmail(email)) {
      this.setState({email_msg: '이메일 형식이 올바르지 않습니다.'}); return;
    }
    else 
      this.setState({email_msg: ''});
    
    switch(verifyPassword(password)) {
      case -10:  pass_msg = '비밀번호를 입력하세요.'; break;
      // case -1: pass_msg = '8~12자리 영문,숫자,특수문자 혼합으로 입력해주세요.'; break;
      // case -2: pass_msg = '숫자와 영문자를 혼용하여야 합니다.'; break;
      // case -3: pass_msg = '같은 문자를 4번 이상 사용하실 수 없습니다.'; break;
    }
    if(Boolean(pass_msg)) {
      this.setState({pass_msg});
      return;
    }
    else {
      this.setState({pass_msg: ''});
    }

    this.fnLogin(email, password);
    
  }
  fnLogin = (email, password) => {
    let param = {email, password}
    nonAuthedReqPost('login/admin', param, (res) => {
      if(res == -1) {
        this.setState({email_msg: '등록되지 않은 계정 정보입니다.'}); return;
      }
      else if(res == -2) {
        this.setState({email_msg: '', pass_msg: '아이디와 비밀번호가 일치하지 않습니다. \n입력 정보를 다시 한번 확인해주세요.'}); return;
      }
      else if(res == -10) {
        return alert('비활성화된 계정으로 로그인이 불가합니다.');
      }
      else if(res) {
        // window.location.href ='/';
        const {auth_token, id, email, user_name, perm} = res;
        localStorage.setItem('user_id', id);
        localStorage.setItem('auth_token', auth_token);
        const { authacc, setauthinf } = this.props;
        setauthinf({...authacc, auth_token, user_id: id, email, user_name, perm});
      }
    });
    
  }
  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.onClickLogin();
    }
  }

  onClickFindPassword = ()=>{
    const { router } = this.props;
    router.navigate("/findpsw");
  }

  render() {
    const { email, password, email_msg, pass_msg } = this.state;
    return (
      <div className="loginpage">
        <div className='loginform'>
          <div className='formtitle'><img src='/images/logo.png' style={{width: 147, position: 'relative', top: 5}} /> 관리자</div>
          <div className='login_inp_area'>
            <input type="text" className='input_box' placeholder='아이디' onChange={(e) => this.setState({email:e.target.value}) } value={email} onKeyUp={this.handleKeyPress} />
            {Boolean(email_msg) && <div className="invalid-feedback d-block">{email_msg}</div>}
            <input type="password" className='input_box mt-3' placeholder='비밀번호' onChange={(e) => this.setState({password:e.target.value}) } value={password} onKeyUp={this.handleKeyPress} />
            {Boolean(pass_msg) && <div className="invalid-feedback d-block">{pass_msg}</div>}
          </div>
          <div className='login_tool'>
            <div className='btn_rectangle' onClick={() => this.onClickLogin()}>로그인</div>
            <div className='btn_findpsw' onClick={this.onClickFindPassword}>비밀번호 찾기</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authacc:state.authacc,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    setauthinf: (val) => dispatch(setauthinf(val)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));