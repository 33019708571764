import './manager.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';

import { toastMsg, formatDate } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailManager extends React.Component {
    constructor() {
        super();
        this.state = {
            managerId:    0,

            type:       1,
            perm_id:    '',
            email:      '',
            user_name:  '',
            department: '',
            phone:      '',
            position:   '',
            status:      1,
            managerData: {},
            permList:    []
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const managerId  = searchParams.get("id");
        // 
        this.getData(managerId);
    }

    getData = (managerId) => {
        window.scrollTo(0, 0);
        this.setState({managerId});
    
        if(managerId > 0) {
            requestPost('getManagerDetail', {managerId}, (res) => {
                if(Boolean(res)) {
                    const { type, perm_id, status, email, user_name, department, phone, position, reg_time } = res;
                    this.setState({ type, perm_id, status, email, user_name, department, phone, position, reg_time });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
        requestPost('getManagerPerms', {}, (res) => {
            if(Boolean(res)) {
                this.setState({ permList:res });
                // 
            }
        });
    }
    gotoPage = (link) => {
      const { router } = this.props;
      router.navigate(link);
    }
    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
    
        this.setState({[name]: value});
        // 
    }
    
    update = () => {
        const {managerId, email, user_name, department, phone, position, perm_id, status, type} = this.state;

        if(!managerId) {
            if(!email) {
                return toastMsg('이메일을 입력하세요.');
            }
            if(!user_name) {
                return toastMsg('이름을 입력하세요.');
            }
            if(!phone) {
                return toastMsg('휴대전화번호를 입력하세요.');
            }
        }
        if(!perm_id) {
            return toastMsg('권한그룹을 선택하세요.');
        }
        const params = {
            managerId, email, user_name, department, phone, position, perm_id, status, type
        }
        requestPost('updateManager', params, (res) => {
            if(Boolean(res)) {
                if(!managerId) {
                  setTimeout(() => {
                    this.gotoPage(-1);
                  }, 100);
                }
                else {
                    toastMsg('저장되었습니다.', 'success');
                }
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    render() {
        const { managerId, type, perm_id, status, email, user_name, department, phone, position, reg_time, permList } = this.state;
        
        return (
            <Leftmenu>
                <div className='page_contents detailmanager'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>{!Boolean(managerId) ? "관리자 계정 등록" : "관리자 계정 상세"}</div>
                            <div className='fralign_center' style={{gap:20}}>
                                {Boolean(managerId) && <div className='btn_normal' onClick={this.update}>저장</div>}
                                {Boolean(managerId) || <div className='btn_normal cancel' onClick={() => this.gotoPage(-1)}>취소</div>}
                                {Boolean(managerId) || <div className='btn_normal' onClick={this.update}>등록</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{gap:10}}>
                            <div className='frjustify_between splite_head w-100' >
                                <div className='font_D700_16'></div>
                                {Boolean(managerId) || <div className='field_label'><span>*</span> 필수 입력 항목입니다.</div>}
                            </div>
                            {Boolean(managerId) > 0 && (<table>
                                <tbody>
                                    <tr>
                                        <th>계정ID</th>
                                        <td>{managerId}</td>
                                        <th>생성일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>이메일(로그인ID)</th>
                                        <td>{email}</td>
                                        <th>휴대전화번호</th>
                                        <td>{phone}</td>
                                    </tr>
                                    <tr>
                                        <th>이름</th>
                                        <td colSpan={3}>{user_name}</td>
                                    </tr>
                                    <tr>
                                        <th>소속부서</th>
                                        <td>{department}</td>
                                        <th>직책</th>
                                        <td>{position}</td>
                                    </tr>
                                </tbody>
                            </table>)}
                            {Boolean(managerId) || (<div className='card_row'>
                                <label className='field_label'>이메일(ID)<span>*</span></label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder="이메일을 입력하세요." value={email} name='email' onChange={this.handleInputChange}/>
                                </div>
                            </div>)}
                            {Boolean(managerId) || (<div className='card_row'>
                                <label className='field_label'>이름<span>*</span></label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder="이름을 입력하세요." value={user_name} name='user_name' onChange={this.handleInputChange}/>
                                </div>
                            </div>)}
                            {Boolean(managerId) || (<div className='card_row'>
                                <label className='field_label'>휴대전화번호<span>*</span></label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder="휴대전화번호를 입력하세요." value={phone} name='phone' onChange={this.handleInputChange}/>
                                </div>
                            </div>)}
                            {Boolean(managerId) || (<div className='card_row'>
                                <label className='field_label'>소속부서</label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder="소속부서를 입력하세요." value={department} name='department' onChange={this.handleInputChange}/>
                                </div>
                            </div>)}
                            {Boolean(managerId) || (<div className='card_row'>
                                <label className='field_label'>직책</label>
                                <div className='card_row_val'>
                                    <input type={"text"} placeholder="직책을 입력하세요." value={position} name='position' onChange={this.handleInputChange}/>
                                </div>
                            </div>)}
                            {Boolean(managerId) && (<div className='card_row'>
                                <label className='field_label'>계정 활성 상태</label>
                                <div className='card_row_val'>
                                    <label className='radio_label' onClick={() => this.setState({ status: 1 }) }><input type="radio" name="status" checked={status == 1} onChange={() => {}} />활성(로그인가능)</label>
                                    <label className='radio_label' onClick={() => this.setState({ status: 2 }) }><input type="radio" name="status" checked={status == 2} onChange={() => {}} />비활성(로그인불가)</label>
                                </div>
                            </div>)}
                            <div className='card_row'>
                                <label className='field_label'>계정유형<span>*</span></label>
                                <div className='card_row_val'>
                                    <select value={type} name='type' onChange={this.handleInputChange}>
                                        <option value={1}>일반관리자</option>
                                        <option value={2}>슈퍼관리자</option>
                                    </select>
                                </div>
                            </div>
                            <div className='card_row'>
                                <label className='field_label'>권한그룹<span>*</span></label>
                                <div className='card_row_val'>
                                    <select value={perm_id} name='perm_id' onChange={this.handleInputChange}>
                                        <option value=''>권한 그룹을 선택하세요.</option>
                                        {permList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Leftmenu>
        );
    }
}

export default withRouter(DetailManager);