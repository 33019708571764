import './edukeyword.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import JobKeyList from './jobkey_list';
import TechKeyList from './techkey_list';
import KeywordList from './KeywordList';

import { updateAdminPerm, updateMyAlarm } from '../../libs/utils';

class EduKeyword extends React.Component {
  constructor() {
    super();
    this.state = {
      tabidx:0,
    };
  }
  
  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

  }

  render() {
    const { tabidx } = this.state;
    return (
    <Leftmenu>
      <div className='page_contents edukeyword'>
        <div className='card'>
          <div className='card_head'>
            <div className='card_head_title'>키워드</div>
          </div>
          <div className='tab_row'>
            <div className={tabidx === 0?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({tabidx:0})}}>직무</div>
            <div className={tabidx === 1?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({tabidx:1})}}>기술스택</div>
            <div className={tabidx === 2?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({tabidx:2})}}>난이도</div>
            <div className={tabidx === 3?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({tabidx:3})}}>관심정보</div>
            <div className={tabidx === 4?'tab_act_item':'tab_def_item'} onClick={()=>{this.setState({tabidx:4})}}>고객유형</div>
          </div>
        </div>
        {tabidx === 0 && (<JobKeyList />)}
        {tabidx === 1 && (<TechKeyList />)}
        {tabidx === 2 && (<KeywordList type='D' />)}
        {tabidx === 3 && (<KeywordList type='I' />)}
        {tabidx === 4 && (<KeywordList type='C' />)}
      </div>
    </Leftmenu>
    );
  }
}

export default withRouter(EduKeyword);