import './partner.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum } from '../../libs/utils';
import consts from '../../libs/consts';

class PartnerLinetok extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      expo:       0,
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, expo } = this.state;
    const params = { keyword, expo, currentPage }
    requestPost('getOneTalkList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeExpo = (expo) => {
    this.setState({expo})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  openDetail = (id) => {
    const { router } = this.props;
    router.navigate('/partner/linetok/detail?id=' + id);
  }

  render() {
    const { keyword, expo, totalCnt, currentPage, dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents prtlinetok'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>한줄톡 관리</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='작성자닉네임, 교육사명으로 검색'
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
                <span>{formatNum(totalCnt)}</span>개의 게시글
              </div>
              <div className='fralign_center' style={{ gap: 20 }}>
                <div className='font_D700_14'>노출상태</div>
                  <label className='radio_label' onClick={() => this.changeExpo(0)}><input type="radio" name="expo" checked={expo === 0} onChange={()=>{}} />전체</label>
                  <label className='radio_label' onClick={() => this.changeExpo(1)}><input type="radio" name="expo" checked={expo === 1} onChange={()=>{}} />노출</label>
                  <label className='radio_label' onClick={() => this.changeExpo(2)}><input type="radio" name="expo" checked={expo === 2} onChange={()=>{}} />비노출</label>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>한줄톡ID</th>
                  <th>내용</th>
                  <th>교육사명</th>
                  <th>작성자</th>
                  <th>노출상태</th>
                  <th>작성일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr onClick={()=>{this.openDetail(item.id)}} key={index}>
                    <td>{item.id}</td>
                    <td className='ellipsis1'>{Boolean(item.contents) && item.contents.length > consts.showMaxLen ? item.contents.substr(0, consts.showMaxLen)+'...' : item.contents}</td>
                    <td>{item.user_name}</td>
                    <td>{item.nickname}</td>
                    <td>{item.expo == 1 ? '노출' : '비노출'}</td>
                    <td>{formatDate(item.reg_time)}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(PartnerLinetok);