import './edukeyword.css';
import React from 'react';
import withRouter from '../../components/withRouter';
import Modal from 'react-modal';
import ConformModal from '../../components/modal/ConformModal';
import { requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';

Modal.setAppElement('#root');
class TechKeyList extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIdx:     0,
      confirmTitle: '',
      confirmTxt:   '',
      groupList:    [],
      techStackList: [],
      techId:       -1,
      big_group_id: -1,
      small_group:  '',
      data:         '',
      showLgroup:   false,
      group_name:  '',
      group_id:     -1,
    };
  }

  componentDidMount = () => {
    // 
    this.getData();
  }
  getData = () => {
		requestPost('getTechStackByKeyword', {keyword: ''}, (res) => {
      if(Boolean(res)) {
        const {groupAllList, techStackList} = res;
        this.setState({groupList: groupAllList, techStackList});
      }
		});
	}

  swapGroup = (indexA, indexB) =>  {
    let {techStackList} = this.state;
    let temp = techStackList[indexA];
    techStackList[indexA] = techStackList[indexB];
    techStackList[indexB] = temp;
    // 
    this.setState({techStackList})
    requestPost('updateTechStack', {techStackList}, (res) => { });
  }
  swapData = (indexA, indexB) =>  {
      let {data} = this.state;
      let dataList = [];
      try {
          dataList = Boolean(data) ? JSON.parse(data) : [];
      } catch (error) { }

      let temp = dataList[indexA];
      dataList[indexA] = dataList[indexB];
      dataList[indexB] = temp;
      // 
      this.setState({data: JSON.stringify(dataList)});
  }


  onConfirm = () => {
      const {modalIdx} = this.state;

      if(modalIdx < 1000) {  // 그룹 삭제
          this.deleteGroup();
      }
      else if(modalIdx < 10000) {  // 기술스택을 삭제  (data)
          this.deleteTechData();
      }
      else {  // 직무 삭제  (tbl_tech)
          this.deleteTechStack();
      }
  }
  deleteGroup = () => {
    const { modalIdx } = this.state;

    requestPost('deleteTechGroupById', {group_id: modalIdx}, (res) => {
      if(Boolean(res)) {
        this.getData();
        toastMsg('삭제되었습니다.', 'success');
        this.setState({modalIdx: 0, group_name: '', techId: -1});
      }
    });
  }
  deleteTechData = () => {
    let {data, modalIdx} = this.state;
    let dataList = [];
    try {
        dataList = Boolean(data) ? JSON.parse(data) : [];
    } catch (error) { }

    dataList.splice(modalIdx - 1000, 1);
    // 
    this.setState({data: JSON.stringify(dataList)});
    this.setState({modalIdx: 0, confirmTxt: ''});
  }
  deleteTechStack = () => {
    const { modalIdx } = this.state;

    requestPost('deleteTechStackById', {techId: modalIdx - 10000}, (res) => {
      if(Boolean(res)) {
        this.getData();
        toastMsg('삭제되었습니다.', 'success');
        this.setState({modalIdx: 0, group_name: '', techId: -1, big_group_id: -1});
      }
    });
  }

  update = () => {
      const { techId, big_group_id, small_group, data } = this.state;

      if(big_group_id == -1) {
        return toastMsg('대그룹을 선택하세요.');
      }
      if(!small_group) {
          return toastMsg('소그룹을 입력하세요.');
      }
      const params = {
          techId, data, 
          group_id: big_group_id, 
          group_name: small_group
      }
      requestPost('updateTechStackById', params, (res) => {
          if(Boolean(res)) {
            this.getData();
            if(techId > 0) {
                toastMsg('저장되었습니다.', 'success');
            }
            else {
              toastMsg('추가되었습니다.', 'success');
              this.setState({techId: -1, small_group: "", data: '', big_group_id: -1});
            }
          }
      });
  }

  // big group
  openLargeGroup = ()=>{
    const { showLgroup } = this.state;
    this.setState({showLgroup:!showLgroup});
  }
  updateGroup = (group_name, group_id) => {

    if(!group_name) {
      return toastMsg('대그룹을 입력하세요.');
    }
    requestPost('updateTechGroup', {group_name, group_id}, (res) => {
      if(Boolean(res)) {
          this.getData();
            this.setState({group_name: "", group_id: -1});
          if(group_id > 0) {
            toastMsg('저장되었습니다.', 'success');
          }
          else {
            toastMsg('추가되었습니다.', 'success');
          }
      }
    });
  }

  render() {
    const { modalIdx, confirmTxt, groupList, techStackList, techId, big_group_id, small_group, data, showLgroup, group_name, group_id } = this.state;
    
    let dataList = [];
    if(techId >= 0) {
        try {
            dataList = Boolean(data) ? JSON.parse(data) : [];
        } 
        catch (error) { }
    }

    return (<>
      <div className='techkey_list my-3'>
        <div className='flex-1' />
        <div className='btn_normal' onClick={this.openLargeGroup}>대그룹 관리</div>
      </div>
      <div className='techkey_list'>
        <div className='tech_grouplist'>
          <table>
            <thead>
              <tr>
                <th>순서변경</th>
                <th>대그룹</th>
                <th>소그룹</th>
              </tr>
            </thead>
            <tbody>
              {
                techStackList.map((item, index) =>
                <tr key={index}>
                  <td>
                    <div className='sortrow'>
                      <div className='up' onClick={() => this.swapGroup(index, index==0 ? techStackList.length-1 : index-1)} />
                      <div className='down' onClick={() => this.swapGroup(index, index==techStackList.length-1 ? 0 : index+1)} />
                    </div>
                  </td>
                  <td>{item.big_group_name}</td>
                  <td className='techgroupname' onClick={() => 
                      this.setState({
                        techId: item.id, 
                        data:   item.data, 
                        big_group_id: item.group_id,
                        small_group:  item.group_name
                      })}>{item.group_name}</td>
                </tr>
                )
              }
            </tbody>
          </table>
          <div className='btn_normal mb-3' onClick={() => this.setState({techId: 0, small_group: "", data: '', big_group_id: -1})}>그룹  추가</div>
        </div>

        {techId >= 0 &&
        <div className='job_detail'>
          <div className='job_detail_head'>
            <div className='detail_label'>그룹 {techId == 0 ? '등록' : '수정'}</div>
            <div className='detail_tool'>
              {techId > 0 && <div className='btn_normal cancel' onClick={() => this.setState({confirmTxt: "그룹을 삭제하시겠습니까?\n그룹에 등록된 모든 키워드가 삭제되며,\n키워드가 적용된 모든 영역에서\n해당 값이 삭제됩니다.", modalIdx: techId + 10000})}>삭제</div>}
              <div className='btn_normal' onClick={() => this.update()}>저장</div>
            </div>
          </div>
          <div className='job_detail_row'>
            <div className='job_row_label'>그룹명</div>
            <select value={big_group_id} onChange={({target}) => this.setState({big_group_id: target.value})} >
              <option>대그룹 선택</option>
              {
                groupList.map((item, index) =>
                <option key={index} value={item.id}>{item.group_name}</option>
                )
              }
            </select>
            <input type="text" style={{ width: 300 }} placeholder="소그룹 입력" value={small_group} onChange={({target}) => this.setState({small_group: target.value})} />
          </div>
          <div className='job_detail_row'>
            <div className='job_row_label'>직무</div>
            <div className='flex-1' />
            <div className='btn_normal' onClick={() => {
              dataList.push({"title":"","descript":""});
              this.setState({data: JSON.stringify(dataList)});
            }}>기술스택 추가</div>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{width: '5%'}}>순서</th>
                <th style={{width: '20%'}}>기술스택명</th>
                <th style={{width: '40%'}}>기술스택 설명</th>
                <th style={{width: '5%'}}></th>
              </tr>
            </thead>
            <tbody>
              {
                dataList.map((item, index) => 
                <tr key={index}>
                  <td>
                    <div className='sortrow'>
                      <div className='up' onClick={() => this.swapData(index, index==0 ? dataList.length-1 : index-1)} />
                      <div className='down' onClick={() => this.swapData(index, index==dataList.length-1 ? 0 : index+1)} />
                    </div>
                  </td>
                  <td>
                      <input type="text" value={item.title} onChange={(e) => {
                          item.title = e.target.value;
                          dataList[index] = item;
                          this.setState({data: JSON.stringify(dataList)})
                      }} />
                  </td>
                  <td>
                    <input type="text" value={item.descript} onChange={(e) => {
                      item.descript = e.target.value;
                      dataList[index] = item;
                      this.setState({data: JSON.stringify(dataList)})
                    }} 
                      />
                  </td>
                  <td><div className='rmvtxtbtn' onClick={() => this.setState({confirmTxt: "기술스택을 삭제하시겠습니까?\n키워드가 적용된 모든 영역에서 \n해당 값이 삭제됩니다.", modalIdx: index + 1000})}>삭제</div></td>
                </tr>
                )
              }
            </tbody>
          </table>
        </div>}
        
        {modalIdx >= 1 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={"삭제하기"}>{confirmTxt}</ConformModal>}
        {/* <ConformModal modalIsOpen={showrmvgroup} closeModal={this.openCloseGroup} onConfirm={this.doRemoveGroup}>
          그룹을 삭제하시겠습니까?<br />그룹에 등록된 모든 키워드가 삭제되며,<br />키워드가 적용된 모든 영역에서<br />해당 값이 삭제됩니다.
        </ConformModal>
        <ConformModal modalIsOpen={showrmvjob} closeModal={this.openCloseJob} onConfirm={this.doRemoveTech}>
          기술스택을 삭제하시겠습니까?<br />키워드가 적용된 모든 영역에서 <br />해당 값이 삭제됩니다.</ConformModal> */}
      </div>

      <Modal
          isOpen={showLgroup}
          onRequestClose={this.openLargeGroup}
          style={{
              overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  display: 'flex', justifyContent: 'center', alignItems: 'center'
              },
              content: { position: 'unset', width: 600, padding:0 }
          }}
        >
          <div className='card largetgroup'>
            <div className='card_head'>
                <div className='card_head_title'>대그룹 관리</div>
                <div className='card_head_tool'>
                    <div className='close_modal' onClick={this.openLargeGroup}/>
                </div>
            </div>
            <div className='fralign_start w-100' style={{gap: 6}}>
              <input type="text" className='flex-1' value={group_name} onChange={({target}) => this.setState({group_name: target.value})} />
              <div className='btn_normal' onClick={() => this.updateGroup(group_name, '')}>추가</div>
            </div>
            <div className='card_body'>
              {
                groupList.map((item, index) =>
                <div key={index}>
                  <input type="text" className='flex-1' value={item.group_name} onChange={({target}) => {
                    item.group_name = target.value;
                    let tmpList = groupList;
                    tmpList[index] = item;
                    this.setState({groupList: tmpList})
                  }} />
                  {group_id != item.id ?
                  <>
                  <div className='remove' onClick={() => this.setState({confirmTxt: "그룹을 삭제하시겠습니까?\n그룹에 등록된 모든 키워드가 삭제되며,\n키워드가 적용된 모든 영역에서\n해당 값이 삭제됩니다.", modalIdx: item.id})}>삭제</div>
                  <div className='modify' onClick={() => this.setState({group_id: item.id})}>수정</div>
                  </>
                  :
                  <div className='modify' onClick={() => this.updateGroup(item.group_name, item.id)}>저장</div>}
                </div>
                )
              }
              </div>
          </div>
      </Modal>
    </>
    );
  }
}

export default withRouter(TechKeyList);