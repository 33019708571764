import './menuitem.css'
import { useNavigate, useLocation } from 'react-router-dom';
function Menuitem({title, linkurl}) {
    let navigate = useNavigate();
    let url = useLocation();
    return (
        <div className={url.pathname.indexOf(linkurl) === 0?"menu_item_open":"menu_item_close" }
            onClick={()=>{ navigate(linkurl); }}>
            {title}
        </div>
    );
}

export default Menuitem;