import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authacc from "./authacc/AuthaccReducer";
import other from "./other/OtherReducer";
import leftmenu from './leftmenu/LeftMenuReducer';

const persistConfig = {
    key: "root",
    // localStorage에 저장합니다.
    storage,
    // authacc reducer만 localstorage에 저장합니다.
    whitelist: ["authacc"]
    // blacklist -> 그것만 제외합니다
};

export const rootReducer = combineReducers({
    authacc,
    other,
    leftmenu
});

const persisted = persistReducer(persistConfig, rootReducer);

const store = createStore(persisted, applyMiddleware(thunk));

export default store;