import { authaccActionType } from './AuthaccActions';

const initAuthAcc = {
    user_id:    0,
    type:       1,    // 일반 관리자
    perms:       "",
    email:      "",   // 이메일(로그인ID)
    user_name:  "",   //이름
    alarm:      {alarmList: [], alarmCnt: 0},   //alarm
};

const authacc = (state = initAuthAcc, action) => {
    switch (action.type) {
        case authaccActionType.setauthinf:
            return {
                ...state,
                ...action.value,
            };
        case authaccActionType.updatePerm:
            return {
                ...state,
                perms: action.perms
            };
        case authaccActionType.updateAlarm:
            return {
                ...state,
                alarm: action.alarm
            };
        case authaccActionType.clearauthinf:
            return {
                ...initAuthAcc,
            };

        default:
            return state;
    }
};

export default authacc;