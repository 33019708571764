import React from 'react';
import { usePrompt } from '../libs/usePrompt';

function UsePrompt ({isDirty}) {
	usePrompt( '저장하지 않은 입력 정보는 사라집니다. \n계속 진행하시겠습니까?', isDirty );
	
	return (
			<div />
	)
	
}

export default UsePrompt;