import './crm.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';
import { utils, writeFileXLSX } from 'xlsx';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime, renderMemberStatus } from '../../libs/utils';
import consts from '../../libs/consts';

class CrmReviewer extends React.Component {
  constructor() {
    super();
    this.state = {
      markList:     [1,1,1,1,1],
      type:         '',
      member:       '',
      answer:       '',
      memberId:     '',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
      checkAll:     false,
    };
  }


  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    const { searchParams } = this.props.router;
    const keyword  = searchParams.get("key");
    if(keyword) {
      this.setState({memberId: keyword});
    }
    setTimeout(() => {
      this.getData();
    }, 10);
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { markList, type, member, answer, memberId } = this.state;
    const params = { markList, type, member, answer, memberId, currentPage, kind: 'list' }
    requestPost('getSurveyList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeStatus = (value, type) => {
    this.setState({[type]: value});
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  checkAll = () => {
    let { dataList, checkAll } = this.state;
    dataList = dataList.map(dt => {
      dt.checked = !checkAll;
      return dt;
    } );
    this.setState({checkAll: !checkAll, dataList})
  }

  allDownloadExcel = () => {
    requestPost('getSurveyList', {kind: 'all'}, (res) => {
      if(Boolean(res) && res.length > 0) {
        this.downloadExcel(res);
      }
      else {
        toastMsg("데이터가 없습니다.");
      }
    });
  }
  selDownloadExcel = () => {
    const { dataList } = this.state;
    const selectedList = dataList.filter(dt => dt.checked);
    if(selectedList.length == 0) {
      return toastMsg('항목을 선택하세요.');
    }
    this.downloadExcel(selectedList);
  }
  downloadExcel = (dataList) => {
    
    const data = [];
    dataList.map(item => {
      data.push({ "ID": item.id, "구분": item.type == 1 ? '서비스평가' : '추천평가', "별점": item.mark, "리뷰 내용": item.review, "회원여부": Boolean(item.user_id) ? '회원' : '비회원', "답변여부": item.status == 2 ? '답변완료' : '미답변', "작성일시": formatDate(item.reg_time) })
    });

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `평가 및 리뷰_${getCurrentTime()}.xlsx`);
  }
  
  checkMark = (idx) => {
    let { markList } = this.state;

    markList[idx] = markList[idx] == 1 ? 0 : 1;
    this.setState({markList});
    this.getData();
  }

  openDetail = (val) => {
    const { router } = this.props;
    router.navigate('/crm/reviewer/detail?id=' + val);
  }

  render() {
    const { markList, type, member, answer, totalCnt, currentPage, dataList, checkAll } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents crmreviwer'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>평가 및 리뷰</div>
              <div className='card_head_tool'>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>별점</div>
                  <label className='radio_label' ><input type="checkbox" checked={markList[0] == 1} onChange={() => {}} onClick={() => this.checkMark(0)} />1</label>
                  <label className='radio_label' ><input type="checkbox" checked={markList[1] == 1} onChange={() => {}} onClick={() => this.checkMark(1)} />2</label>
                  <label className='radio_label' ><input type="checkbox" checked={markList[2] == 1} onChange={() => {}} onClick={() => this.checkMark(2)} />3</label>
                  <label className='radio_label' ><input type="checkbox" checked={markList[3] == 1} onChange={() => {}} onClick={() => this.checkMark(3)} />4</label>
                  <label className='radio_label' ><input type="checkbox" checked={markList[4] == 1} onChange={() => {}} onClick={() => this.checkMark(4)} />5</label>
                </div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
                <span>{formatNum(totalCnt)}</span>개의 평가
              </div>
              <div className='fralign_center' style={{ gap: 30 }}>
                <div className='fralign_center' style={{ gap: 10 }}>
                  <div className='btn_link' onClick={this.selDownloadExcel}>선택 정보 다운로드</div>
                  <div className='btn_link' onClick={this.allDownloadExcel}>전체 다운로드</div>
                </div>
                <div className='fralign_center' style={{ gap: 10 }}>
                  <div className='font_D700_14'>구분</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0, 'type')}><input type="radio" name="type" checked={type == 0} onChange={()=>{}} />전체</label>
                  {/* <label className='radio_label' onClick={() => this.changeStatus(2, 'type')}><input type="radio" name="type" checked={type == 2} onChange={()=>{}} />추천평가</label> */}
                  <label className='radio_label' onClick={() => this.changeStatus(1, 'type')}><input type="radio" name="type" checked={type == 1} onChange={()=>{}} />서비스평가</label>
                </div>
                <div className='fralign_center' style={{ gap: 10 }}>
                  <div className='font_D700_14'>회원여부</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0, 'member')}><input type="radio" name="member" checked={member == 0} onChange={()=>{}} />전체</label>
                  <label className='radio_label' onClick={() => this.changeStatus(1, 'member')}><input type="radio" name="member" checked={member == 1} onChange={()=>{}} />회원</label>
                  <label className='radio_label' onClick={() => this.changeStatus(2, 'member')}><input type="radio" name="member" checked={member == 2} onChange={()=>{}} />비회원</label>
                </div>
                <div className='fralign_center' style={{ gap: 10 }}>
                  <div className='font_D700_14'>답변여부</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0, 'answer')}><input type="radio" name="answer" checked={answer == 0} onChange={()=>{}} />전체</label>
                  <label className='radio_label' onClick={() => this.changeStatus(1, 'answer')}><input type="radio" name="answer" checked={answer == 1} onChange={()=>{}} />미답변</label>
                  <label className='radio_label' onClick={() => this.changeStatus(2, 'answer')}><input type="radio" name="answer" checked={answer == 2} onChange={()=>{}} />답변완료</label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>
                    <input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} />
                  </th>
                  <th>ID</th>
                  <th>구분</th>
                  <th>별점</th>
                  <th>리뷰 내용</th>
                  <th>회원여부</th>
                  <th>답변여부</th>
                  <th>작성일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index}>
                    <td>
                      <input type='checkbox' onChange={() => {}} onClick={() => {
                        let tmpList = dataList;
                        tmpList[index].checked = !item.checked;
                        // 
                        let checkedCnt = tmpList.filter(dt => dt.checked).length
                        this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})
                      }} checked={Boolean(item.checked)} />
                    </td>
                    <td>{item.id}</td>
                    <td>{item.type == 1 ? '서비스평가' : '추천평가' }</td>
                    <td>{item.mark}</td>
                    <td className='cursor_pointer ellipsis1' onClick={()=>{this.openDetail(item.id)}}>{Boolean(item.review) && item.review.length > consts.showMaxLen ? item.review.substr(0, consts.showMaxLen)+'...' : item.review}</td>
                    <td>{Boolean(item.user_id) ? '회원' : '비회원'}</td>
                    <td>{item.status == 2 ? '답변완료' : '미답변' }</td>
                    <td>{formatDate(item.reg_time)}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={7}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(CrmReviewer);