import React from 'react';

import './mypage.css';
import withRouter from '../../components/withRouter';
import images from '../../libs/images';
import { verifyPassword } from '../../libs/utils';
import Leftmenu from '../../layout/leftmenu';

import {requestPost} from '../../libs/request';
import { toastMsg } from '../../libs/utils';

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      currentpw:  "",
      password:   "",
      repassword: "",
      curpw_msg:  null,
      pass_msg:   null,
      repass_msg: null,
    };
  }
  gotoPage = () => {
    const { router } = this.props;
    router.navigate('/mypage');
  }

  update = () => {
    const {currentpw, repassword, password} = this.state;

    if(!Boolean(currentpw)) {
      return this.setState({curpw_msg: 1});
    }
    else {
      this.setState({curpw_msg: null});
    }

    let pass_msg = '';
    switch(verifyPassword(password)) {
      case -10:  pass_msg = '비밀번호를 입력해주세요.'; break;
      case -1: pass_msg = '8~16자리 영문,숫자,특수문자 혼합으로 입력해주세요.'; break;
      // case -2: pass_msg = '숫자와 영문자를 혼용하여야 합니다.'; break;
      // case -3: pass_msg = '같은 문자를 4번 이상 사용하실 수 없습니다.'; break;
    }
    if(Boolean(pass_msg)) {
      this.setState({pass_msg:"8~16자리 영문,숫자,특수문자 혼합으로 입력해주세요."});
      return;
    }
    else {
      this.setState({pass_msg: ''});
    }
    
    if(password != repassword) {
      this.setState({repass_msg: '비밀번호가 일치하지 않습니다.'});
      return;
    }
    else {
      this.setState({repass_msg: ''});
    }

    requestPost('checkpw', {password: currentpw}, (res) => {
      if(!res){
        this.setState({curpw_msg: 1});
      }
      else {
        this.fnChangePw();
      }
    });
  }
  fnChangePw = () => {
    const {password} = this.state;
    requestPost('updatepw', {password}, (res) => {
      if(res) {
        toastMsg('비밀번호가 변경되었습니다.');
        this.setState({currentpw: "", password: "", repassword: "", curpw_msg: null, pass_msg: null, repass_msg: null});
      }
      else {
        toastMsg('비밀번호 변경이 실패하였습니다.');
      }
    });
    
  }

  onChangeCurPassword = (val)=>{
    this.setState({currentpw:val, curpw_msg: null})
  }

  onBlurCurPassword = (password) => {
    if(!password) return;
    requestPost('checkpw', {password}, (res) => {
      if(!res){
        this.setState({curpw_msg: 1});
      }
      else{
        this.setState({curpw_msg: 2})
      }
    });
  }

  onBlurNewPassword = (val) => {
    if(!val) {
      return;
    }
    if(verifyPassword(val) != 1){
      this.setState({pass_msg:"8~16자리 영문,숫자,특수문자 혼합으로 입력해주세요."});
    }
    else{
      this.setState({pass_msg:""});
    }
  }

  onBlurCnfPassword = (val) => {
    if(!val) {
      return;
    }
    const { password } = this.state;
    if(password !== val){
      this.setState({repass_msg:"비밀번호가 일치하지 않습니다."});
    }
    else{
      this.setState({repass_msg:""});
    }
  }
  render() {
    const { currentpw, curpw_msg, password, pass_msg, repassword, repass_msg } = this.state;
    
    return (
      <Leftmenu>
        <div className='page_contents'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>비밀번호 변경</div>
              <div className='card_head_tool'>
                <div className='btn_normal cancel' onClick={this.gotoPage}>취소</div>
                <div className='btn_normal' onClick={this.update}>확인</div>
              </div>
            </div>
            <div className='card_body'>
              <div className='card_row'>
                <label className='field_label'>현재 비밀번호</label>
                <div className='card_row_val'>
                  <div className='passwordbox'>
                    <input onChange={({target}) => { this.onChangeCurPassword(target.value) }} onBlur={({target}) => this.onBlurCurPassword(target.value)} value={currentpw} type="password" className='input_box' placeholder='현재 비밀번호를 입력하세요.' />
                    {curpw_msg == 1 && (<div className='err_msg'>비밀번호가 일치하지 않습니다.</div>)}
                    {curpw_msg >= 1 && (<img src={curpw_msg == 1 ? images.ico_x : images.ico_check} alt="tip" />)}
                  </div>
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>새로운 비밀번호</label>
                <div className='card_row_val'>
                  <div className='passwordbox'>
                    <input onChange={({target}) => { this.setState({password:target.value, pass_msg:null}) }} onBlur={({target}) => { this.onBlurNewPassword(target.value); }} value={password} type="password" className='input_box' placeholder='영문, 숫자, 특수문자 포함 8~16자' style={{ width: 300 }} />
                    {pass_msg !== null && pass_msg!=="" && (<div className='err_msg'>8~16자리 영문,숫자,특수문자 혼합으로 입력해주세요.</div>)}
                    {pass_msg !== null && (<img src={pass_msg !== ""?images.ico_x:images.ico_check} alt="tip" />)}
                  </div>
                </div>
              </div>
              <div className='card_row'>
                <label className='field_label'>새로운 비밀번호 확인</label>
                <div className='card_row_val'>
                  <div className='passwordbox'>
                    <input onChange={({target}) => { this.setState({repassword:target.value, repass_msg:null}) }} onBlur={({target}) => { this.onBlurCnfPassword(target.value); }} value={repassword} type="password" className='input_box' placeholder='비밀번호를 다시 한번 입력하세요' style={{ width: 300 }} />
                    {repass_msg !== null && repass_msg !=="" && (<div className='err_msg'>비밀번호가 일치하지 않습니다.</div>)}
                    {repass_msg !== null && (<img src={repass_msg !== ""?images.ico_x:images.ico_check} alt="tip" />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Leftmenu>
    );
  }
}

export default withRouter(ChangePassword);