import './crm.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import ModalImage from "react-modal-image";
import withRouter from '../../components/withRouter';

import ConformModal from '../../components/modal/ConformModal';
import consts from '../../libs/consts';
import { toastMsg, renderProductStatus, isEmptyObj, formatDate, formatNum } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailCrmSvc extends React.Component {
    constructor() {
        super();
        this.state = {
            questionId:    0,
            modalIdx:      0,
            confirmTitle:  '',
            confirmTxt:    '',
        };
    }
    componentDidMount() {

        const { searchParams } = this.props.router;
        const questionId  = searchParams.get("id");
        // 
        this.getData(questionId);
    }

    getData = (questionId) => {
        window.scrollTo(0, 0);
        this.setState({questionId});
    
        if(questionId > 0) {
            requestPost('getQuestionDetail', {questionId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, contents, thumb, email, answer, reg_time, nickname, user_email, status } = res;
        
                    this.setState({ user_id, contents, thumb, email, answer, reg_time, nickname, user_email, status });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }

    onConfirm = () => {
        const {modalIdx, questionId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        const status = modalIdx == 10 ? 2 : 1
        const params = {
            questionId, status
        }
        requestPost('updateQuestion', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.setState({status})
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { questionId, confirmTitle, confirmTxt, modalIdx,
            user_id, contents, thumb, email, answer, reg_time, nickname, user_email, status } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailcrmprod'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>서비스 의견 상세</div>
                            <div className='fralign_center' style={{ gap: 20 }}>
                                {status == 1 && <div className='btn_normal' onClick={() => this.setState({confirmTitle: "서비스 의견 상세", confirmTxt: "답변완료 상태로 변경하시겠습니까?", modalIdx: 10})}>답변완료로 처리</div>}
                                {status == 2 && <div className='btn_normal' onClick={() => this.setState({confirmTitle: "서비스 의견 상세", confirmTxt: "미답변 상태로 변경하시겠습니까?", modalIdx: 11})}>미답변으로 처리</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{ padding: 10 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>의견ID</th>
                                        <td>{questionId}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자</th>
                                        {!Boolean(nickname) ?
                                            <td>{email}</td> :
                                            <td className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{(user_id) ? `${nickname}(${user_email})` : email}</td>}
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>답변여부</th>
                                        <td>{status == 2 ? '답변완료' : '미답변'}</td>
                                    </tr>
                                    <tr>
                                        <th>의견 내용</th>
                                        <td className='pre-wrap'>{contents}</td>
                                    </tr>
                                    <tr>
                                        <th>첨부이미지</th>
                                        <td>
                                            <div className='fralign_start' style={{gap:10, flexWrap:'wrap'}}>
                                                {
                                                    Boolean(thumb) && thumb.split(',').map((item, index) => 
                                                        item.indexOf('.pdf') > 0 ?
                                                        <a key={index} href={consts.resourcUrl + item} target="_blank">첨부파일_{index}.pdf</a>
                                                        :
                                                        <ModalImage
                                                            key={index}
                                                            className='imgbg'
                                                            small={consts.resourcUrl + item}
                                                            large={consts.resourcUrl + item}
                                                            hideDownload
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailCrmSvc);