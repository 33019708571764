import React from 'react';

import withRouter from '../../components/withRouter';
import BannerDetail from './BannerDetail';

class detailAdvert extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    render() {
        
        return (
            <BannerDetail />
        );
    }
}

export default withRouter(detailAdvert);