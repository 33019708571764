
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import { removeItem, itemKey } from "./preference";
import store from '../redux/store';
import {requestPost} from './request';
import { updatePerm, updateAlarm } from '../redux/authacc/AuthaccActions';

export const formatMsToHMS = ms => {
  let seconds = ms / 1000;
  let hours = parseInt(seconds / 3600);
  seconds = seconds % 3600;
  let minutes = parseInt(seconds / 60);
  seconds = seconds % 60;

  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (hours) {
    return hours + ':' + minutes + ':' + seconds;
  }
  return minutes + ':' + seconds;
};

export const formatPhone = phone => {
  phone = phone + '';
  let formatNum = '';
  if (phone.length === 11) {
    formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phone.length === 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (phone.indexOf('02') === 0) {
      formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }

  return formatNum;
};

export const formatNum = number => {
  if(!number) return '0';
  number = number + '';
  let formatNum = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formatNum;
};

export const getCurrentTime = (allShow=true) => {
  let now_date = new Date();
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();
  let second = now_date.getSeconds();
  month = month < 10 ? '0'+month : month;
  day = day < 10 ? '0'+day : day;
  hour = hour < 10 ? '0'+hour : hour;
  minute = minute < 10 ? '0'+minute : minute;
  second = second < 10 ? '0'+second : second;
  let date = year+"-"+month+"-"+day;
  return allShow ? (date+" "+hour+":"+minute+":"+second) : date;
};

export const formatDate = (str_date, allShow=true, type='dash') => {
  if(str_date == "" || !Boolean(str_date))
    return "-";

  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate(); 
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();    

  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day;    
  let date = '';
  if(type === 'dot') {
    date = year ? (year+"."+t_month+"."+t_day) : '';
  }
  else if(type === 'dash') {
    date = year ? (year+"-"+t_month+"-"+t_day) : '';
  }
  else {
    date = year ? (year+""+t_month+""+t_day) : '';
  }
  let t_hour = hour < 10 ? '0'+hour : hour;
  let t_minute = minute < 10 ? '0'+minute : minute;  
  let time = t_hour+":"+t_minute;

  return allShow ? (date + ' ' + time) : date;
};
export const formatDate1 = (str_date, allShow=true) => {
  if(str_date == "" || !Boolean(str_date))
    return "-";

  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate(); 
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();    

  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day;    
  let date = year ? (year+"년 "+t_month+"월 "+t_day+"일 ") : '일';
  let t_hour = hour < 10 ? '0'+hour : hour;
  let t_minute = minute < 10 ? '0'+minute : minute;  
  let time = t_hour+"시"+t_minute+"분";

  return allShow ? (date + ' ' + time) : date;
};
export const formatDate2 = (str_date) => {
  if(str_date == "" || !Boolean(str_date))
    return "-";

  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate(); 

  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day;    
  let date  = year ? (year+"-"+t_month+"-"+t_day) : '';

  return date;
};
export const formatTime = (date) => {
  if(date == "" || !Boolean(date))
    return "";

  let now_date = new Date(date);
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();    

  let t_hour = hour < 10 ? '0'+hour : hour;
  let t_minute = minute < 10 ? '0'+minute : minute;  
  let time = t_hour+":"+t_minute;

  return time;
};

export const formatYMD = (str_date) => {
  if(!str_date)
    return "-";

  return `${str_date.substr(0,4)}년 ${str_date.substr(4,2)}월 ${str_date.substr(6,2)}일`;
};
export const formatYMD1 = (str_date, type='-') => {
  if(!str_date)
    return "-";

  return `${str_date.substr(0,4)}${type}${str_date.substr(4,2)}${type}${str_date.substr(6,2)}`;
};
export const string2Date = (dateString) => { 
  // dateString *HAS* to be in this format "YYYY-MM-DD HH:MM:SS"  
  let dateParam = dateString.split(/[\s-:]/)  
  dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString()  
  return new Date(...dateParam)  
}
export const formatChatTime = (str_date) => {
  if(!Boolean(str_date))
    return "";

  let today_date = new Date();
  let today_year = today_date.getFullYear();
  let today_month = today_date.getMonth() + 1;
  let today_day = today_date.getDate();

  let now_date = new Date(str_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let hour = now_date.getHours();
  let minute = now_date.getMinutes(); 
  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day;
  let t_minute = minute < 10 ? '0'+minute : minute;

  let r_str = "";

  if(today_year == year && today_month == month && today_day == day){    
    let gubun = '오전';
    if(hour > 11){
        gubun = '오후';
        if(hour > 12)
          hour = hour - 12;        
    }
    let t_hour = hour < 10 ? '0'+hour : hour;
    r_str = gubun+" "+t_hour+":"+t_minute;      
  }
  else {
    r_str = year+"."+t_month+"."+t_day;
  }
  
  return r_str;
};

export const formatChatTime1 = (str_date) => {
  if(!Boolean(str_date))
    return "";

  let now_date = new Date(str_date);
  let hour = now_date.getHours();
  let minute = now_date.getMinutes(); 
  let t_minute = minute < 10 ? '0'+minute : minute;

  let gubun = '오전';
  if(hour > 11){
      gubun = '오후';
      if(hour > 12)
        hour = hour - 12;        
  }
  let t_hour = hour < 10 ? '0'+hour : hour;
  let r_str = gubun+" "+t_hour+":"+t_minute;      
    
  return r_str;
};
export const formatDateWeekdayTime = (str_date) => {  
  if(!Boolean(str_date))
    return "";
  var week = new Array('일', '월', '화', '수', '목', '금', '토');

  let now_date = new Date(str_date);  
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();
  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day; 
  let t_hour = hour < 10 ? '0'+hour : hour;
  let t_minute = minute < 10 ? '0'+minute : minute;   
  var week_index = now_date.getDay();

  let returnStr = year+"년"+t_month+"월"+t_day+"일("+week[week_index]+")";
  
  return returnStr;
};
export const formatDateWeekday = (str_date) => {  
  if(!Boolean(str_date))
    return "";
  var week = new Array('일', '월', '화', '수', '목', '금', '토');

  let now_date = new Date(str_date);  
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day;   
  var week_index = now_date.getDay();

  let returnStr = year+"."+t_month+"."+t_day+"("+week[week_index]+")";
  
  return returnStr;
};

export const dateAdd = (date1, days) => {
  let result = new Date(date1);
  result.setDate(result.getDate() + days);
  return result;
}
export const objTimeToDate = (obj_date) => {
  if(obj_date == "")
    return "";
    
  let now_date = new Date(obj_date);
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();    
  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day; 
  
  let hour = now_date.getHours();
  let minute = now_date.getMinutes();    
  let second = now_date.getSeconds();
  let t_hour = hour < 10 ? '0'+hour : hour;
  let t_minute = minute < 10 ? '0'+minute : minute;  
  let t_second = second < 10 ? '0'+second : second;  
  let time = year+"-"+t_month+"-"+t_day +' '+ t_hour+":"+t_minute+":"+t_second;
  return time;
};

export const relativeTime = (date) => {
  if(!Boolean(date)) return  '';

  date = new Date(date);
  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;
  const MONTH = DAY * 30;
  const YEAR = DAY * 365;

  const secondsAgo = Math.round((Date.now() - Number(date)) / 1000);

  if (secondsAgo < MINUTE) {
    return secondsAgo + ` 초`;
  }

  let divisor;
  let unit = "";

  if (secondsAgo < HOUR) {
    [divisor, unit] = [MINUTE, "분"];
  } else if (secondsAgo < DAY) {
    [divisor, unit] = [HOUR, "시간"];
  } else if (secondsAgo < WEEK) {
    [divisor, unit] = [DAY, "일"];
  } else if (secondsAgo < MONTH) {
    [divisor, unit] = [WEEK, "주"];
  } else if (secondsAgo < YEAR) {
    [divisor, unit] = [MONTH, "달"];
  } else {
    [divisor, unit] = [YEAR, "년"];
  }

  const count = Math.floor(secondsAgo / divisor);
  return `${count} ${unit} 전`;
};
export const getTwoDateToMinutes = (st_time, ed_time) => {
  if(!st_time || !ed_time) {
    return 0
  }
  let dateOne = new Date(st_time);
  let dateTwo = new Date(ed_time);

  let msDifference =  dateTwo - dateOne;
  let minutes = Math.floor(msDifference/1000/60);
  return minutes;
};
export const secondsTwoDayToMHHMMSS = (st_time, ed_time) => {
  if(!st_time || !ed_time) {
    return 0
  }
  let dateOne = new Date(st_time);
  let dateTwo = new Date(ed_time);

  let msDifference =  dateTwo - dateOne;
  let seconds = Math.floor(msDifference/1000);

  const M = `${Math.floor(seconds / 86400)}`.padStart(2, '0');
  const HH = `${Math.floor(seconds / 3600) % 24}`.padStart(2, '0');
  const MM = `${Math.floor(seconds / 60) % 60}`.padStart(2, '0');
  const SS = `${Math.floor(seconds % 60)}`.padStart(2, '0');
  
  let ret_val = '';
  ret_val += M == '00' ? '' : M+'일 ';
  ret_val += HH == '00' ? '' : HH+'시간 ';
  ret_val += MM == '00' ? '' : MM+'분 ';
  ret_val += SS == '00' ? '' : SS+'초';
  return ret_val;
};
export const changeAge = (birthday) => {    // birthday = "750127"
  let y=parseInt(birthday.substring(0,1));
  let preYear = (y === 2) ? 20 : 19;
  birthday = preYear + birthday;

  let now_date = new Date();
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate();
  month = month < 10 ? '0'+month : month;
  day = day < 10 ? '0'+day : day;
  let now = year+""+month+""+day;

  let offset = parseInt(now) - parseInt(birthday) + '';
  return offset.length === 5 ? '0'+offset : offset;
};

export const changeFullAge = (birthday) => {    // birthday = "19910817"
  if(!birthday) return '-';
  birthday = birthday.replace(/-/gi, '');
  birthday= birthday.substr(0,4) + '-' + birthday.substr(4,2) + '-' + birthday.substr(6,2)
  const today = new Date();
  const birthDate = new Date(birthday);

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age
};

export const getBirthday = (birthday) => {    // birthday = "750127"
  if(!birthday) return '-';
  let y=parseInt(birthday.substring(0,2));
  let preYear = (y > 20) ? 19 : 20;
  birthday = preYear + birthday;
  birthday = birthday.substr(0,4) +'-'+ birthday.substr(4,2) +'-'+ birthday.substr(6,2);

  let now_date = new Date(parseInt(birthday));
  let year = now_date.getFullYear();
  let month = now_date.getMonth() + 1;
  let day = now_date.getDate(); 
  
  let t_month = month < 10 ? '0'+month : month;
  let t_day = day < 10 ? '0'+day : day;    
  let date = year+"."+t_month+"."+t_day;
  return date;
};

export const getBirthday1 = (birthday) => {    // birthday = "750127"
  if(!birthday) return '-';
  let y=parseInt(birthday.substring(0,2));
  let preYear = (y > 20) ? 19 : 20;
  birthday = preYear + birthday;
  birthday = birthday.substr(0,4) +'.'+ birthday.substr(4,2) +'.'+ birthday.substr(6,2);
  return birthday;
};

export const verifyName = name => {   // ok: true
  var reg = /^[가-힣]{2,4}$/;
  return reg.test(name);
};
export const validationNickName = nickName => {
  var regExp = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\*]{2,6}$/;
  // var regExp = /^[가-힣A-Za-z]{1,6}$/;

  if(regExp.test(nickName))  
    return true;
  else
    return false;  
};

export const verifyUserId = userId => {
  var letterNumber = /^[0-9a-z]+$/;
  if(!userId) return false; 
  if (userId.length < 4 || userId.length > 10) {
    return false;
  } 
  else if(userId.match(letterNumber))  {
    return true;
  }
  return true;
};

export const verifyPhone = phone => {
  var letterNumber = /^[0-9]+$/;
  if(!phone) return false; 
  if (phone.length < 11 || phone.length > 11) {
    return false;
  } 
  else if(phone.match(letterNumber))  {
    return true;
  }
  return false;
};

export const validationEmail = email => {
  return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
};

export const verifyPassword = pw => {
  let checkNumber  = pw.search(/[0-9]/g);
  let checkEnglish = pw.search(/[a-z]/ig);

  if(!pw) {
    return -10;
  }
  else if(!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/.test(pw)){
    return -1;
  }
  else if(checkNumber <0 || checkEnglish <0) {
    return -2;
  }
  else if(/(\w)\1\1\1/.test(pw)) {
    return -3;
  }
  return 1;
};

export const replaceComma = (str) => {
  return str.toString().replace(/\b,\b/gi,'');
}

export const isImagefile = (file_name) => {
  return file_name.match(/\.(jpg|jpeg|png|gif)$/i);
}
export const delay = (delay) => {
  return new Promise( res => setTimeout(res, delay) );
}

export const isEmptyObj = (myObj) => {  // if myObj = {} Then true
  console.log(Object.keys(myObj))
  return !Object.keys(myObj).length;
}

export const transUnit = (number) => {
  var inputNumber  = number < 0 ? false : number;
  var unitWords    = ['', '만', '억', '조', '경'];
  var splitUnit    = 10000;
  var splitCount   = unitWords.length;
  var resultArray  = [];
  var resultString = '';

  for (var i = 0; i < splitCount; i++){
       var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0){
          resultArray[i] = unitResult;
      }
  }

  for (i = 0; i < resultArray.length; i++){
      if(!resultArray[i]) continue;
      resultString = String(formatNum(resultArray[i])) + unitWords[i] + resultString;
  }

  return resultString;
}
export const convertUnit = (value) => {

    if(value > 1000000) {
        return (value / 1000000).toFixed(1) + 'M';
    }
    else if(value > 1000) {
        return (value / 1000).toFixed(1) + 'K';
    }
    else {
        return formatNum(value);
    }
}

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength)
  }
}

export const roundToHour = (date) => {    // 시간 반올림
  date.setHours(date.getHours() + 1);
  return date
}

export const chunkMaxLength = (arr, chunkSize, maxLength) => {
  return Array.from({length: maxLength}, () => arr.splice(0,chunkSize));
}
export const isIos = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const removeAllItem = () => {
  localStorage.clear();
}

export const nl2br = (str, is_xhtml) => {
  var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
}
export const br2nl = (str) => {
  return str.replace(/<br\s*\/?>/mg,"\n");
}

export const alert2 = (message) => {
  confirmAlert({
    message,
    buttons: [{ label: '확인' }]
  });
}
export const toastMsg = (message, type='warning') => {
  type == 'success' ? 
  toast.success(message, {autoClose: 2000}) : 
  toast.info(message, {autoClose: 2000});
}

export const renderProductStatus = (status) => {
  let html = '';
  switch (status) {
    case 1: html = <span className='blue-text'>승인대기</span>; break;
    case 2: html = '노출'; break;
    case 3: html = '비노출'; break;
    case 4: html = '수정요청'; break;
    case 6: html = <span className='danger-text'>임시저장</span>; break;
  }
  return html;
}
export const renderMemberStatus = (status) => {
  let html = '';
  switch (status) {
    case 'N': html = '정상'; break;
    case 'E': html = '탈퇴'; break;
    case 'T': html = '일시정지'; break;
    case 'S': html = '영구정지'; break;
  }
  return html;
}
export const renderMethod = (method) => {
  let html = '';
  
  switch (method) {
    case 1: html = '실시간 온라인 교육'; break;
    case 2: html = '오프라인 교육'; break;
    case 3: html = '이러닝 교육'; break;
    case 4: html = '블렌디드 교육'; break;
  }
  return html;
}
export const renderMemExitReason = (exit_reason, exit_txt) => {
  let html = '';
  
  switch (exit_reason) {
    case 1: html = "원하는 정보를 찾지 못했어요."; break;
    case 2: html = "교육 추천이 유용하지 않아요."; break;
    case 3: html = "더이상 IT 교육 정보가 필요하지 않아요."; break;
    case 4: html = "자주 사용하지 않아요."; break;
    case 5: html = "서비스의 UI/UX가 불편해요."; break;
    case 6: html = "광고가 많아요"; break;
    case 7: html = exit_txt; break;
  }
  return html;
}
export const renderReportReason = (resone, type, reason_txt) => {
  let html = '';
  
  switch (resone) {
    case 1: html = type == 1 ? '비방, 욕설, 차별 등 혐오 표현을 사용했습니다.' : '비방, 욕설, 차별 등 혐오 표현입니다.'; break;
    case 2: html = '스팸, 홍보, 도배글입니다.'; break;
    case 3: html = '개인 정보 노출 게시물입니다.'; break;
    case 4: html = type == 1 ? '음란, 도박, 범죄 등 불법 정보를 포함한 게시글을 작성했습니다.' : '음란, 도박, 범죄 등 불법 정보를 포함합니다.'; break;
    case 5: html = reason_txt; break;
  }
  return html;
}
export const renderPostCategory = (category) => {
  let html = '';
  
  switch (category) {
    case 1: html = '교육정보'; break;
    case 2: html = '채용정보'; break;
    case 3: html = '직무Q&A'; break;
    case 4: html = '잡담'; break;
  }
  return html;
}
export const renderPostOpen = (open) => {
  let html = '';
  
  switch (open) {
    case 1: html = '모든 사람'; break;
    case 2: html = '재직자'; break;
    case 3: html = '구직자'; break;
    case 4: html = '학생'; break;
  }
  return html;
}
export const checkPerm = (perms, perm) => {
  if(!perms) {
    return false;
  }
  perms = perms.toString().split(',');
  for(let i in perms) {
    let data = perms[i];
    if(data == perm) {  // 권한 있음
      return true;
    }
  }

  return false;
}
export const updateMyAlarm = () => {
  requestPost('getAlarmData', {}, (res) => {
    if(Boolean(res)) {
      store.dispatch(updateAlarm(res));
      //
    }
  });
}
export const updateAdminPerm = () => {
  requestPost('getMyPermission', {}, (res) => {
    if(Boolean(res)) {
      const {perms} = res;
      store.dispatch(updatePerm(perms));
      //
    }
  });
}
// 
export const getEduDays = (st_date, ed_date) => {
  if(!st_date || !ed_date) {
    return '';
  }
  const diffInMs      = new Date(ed_date) - new Date(st_date)
  const diffInDays    = diffInMs / (1000 * 60 * 60 * 24) + 1;
  let diffInMonths  = diffInMs / (1000 * 60 * 60 * 24 * 30);
  
  diffInMonths  = Math.floor(diffInMonths);
  diffInMonths = diffInDays > 30 ? `, ${diffInMonths}개월 이상 ~ ${diffInMonths+1}개월 미만` : '';
  return `${Math.round(diffInDays)}일${diffInMonths}`;
}
export const copyToClipboard = (textToCopy) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
    });
  }
}
export const replaceQuoteAll = function(str) {
  if(!str) return "";
  return str.replace(/["']/g, '');
};
export const replaceSpaceAll = function(str) {
  if(!str) return "";
  return str.replace(/[ ]/g, '');
};