import './partner.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import ConformModal from '../../components/modal/ConformModal';

import { toastMsg, formatDate } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailLinetok extends React.Component {
    constructor() {
        super();
        this.state = {
            modalIdx: 0,
            confirmTitle: '',
            confirmTxt:   '',
            oneTalkId:    0,
            
            user_id:    '', 
            partner_id: '', 
            contents:   '', 
            expo:       '', 
            reg_time:   '', 
            user_name:  '', 
            nickname:   '',
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        let oneTalkId = searchParams.get("id");
        this.getData(oneTalkId);
    }

    getData = (oneTalkId) => {
        window.scrollTo(0, 0);
        this.setState({oneTalkId});
    
        requestPost('getOneTalkDetail', {oneTalkId}, (res) => {
            if(Boolean(res)) {
                const { user_id, partner_id, contents, expo, reg_time, user_name, nickname } = res;
        
                this.setState({ user_id, partner_id, contents, expo, reg_time, user_name, nickname });
            }
            else {
                alert('삭제된 내역입니다.');
                this.gotoPage(-1);
            }
        });
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    openMember = ()=>{
        const { router } = this.props;
        const { user_id } = this.state;
        router.navigate('/member/list/detail?id=' + user_id);
    }

    openCompany = () => {
        const { router } = this.props;
        const { partner_id } = this.state;
        router.navigate('/partner/company/detail?id=' + partner_id);
    }

    openCloseVisible = () => {
        this.setState({ modalIdx: 2, confirmTitle: "한줄톡 노출", confirmTxt: "해당 게시물을 비노출 상태로 변경하시겠습니까?" });
    }

    openCloseHide = () => {
        this.setState({ modalIdx: 1, confirmTitle: "한줄톡 노출", confirmTxt: "해당 게시물을 노출 상태로 변경하시겠습니까?" });
    }

    onConfirm = () => {
        const {modalIdx, oneTalkId} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
    
        requestPost('updateOneTalkStatus', {oneTalkId, expo: modalIdx }, (res) => {
            if(Boolean(res)) {
                toastMsg('처리되었습니다.', 'success');
                this.setState({expo: modalIdx});
            }
        });
    }
    render() {
        const { modalIdx, confirmTitle, confirmTxt, oneTalkId, user_id, partner_id, contents, expo, reg_time, user_name, nickname } = this.state;
        
        return (
            <Leftmenu>
                <div className='page_contents detaillinetok'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>한줄톡 상세</div>
                            <div className='fralign_center' style={{gap:20}}>
                                {expo == 1 && <div className='btn_normal' onClick={this.openCloseVisible}>비노출로 변경</div>}
                                {expo == 2 && <div className='btn_normal' onClick={this.openCloseHide}>노출로 변경</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{gap:10}}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>한줄톡ID</th>
                                        <td>{oneTalkId}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자</th>
                                        <td className='cursor_pointer' onClick={this.openMember}>{nickname}</td>
                                    </tr>
                                    <tr>
                                        <th>교육사명</th>
                                        <td className='cursor_pointer' onClick={this.openCompany}>{user_name}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>노출상태</th>
                                        <td>{expo == 1 ? '노출' : '비노출'}</td>
                                    </tr>
                                    <tr>
                                        <th>내용</th>
                                        <td className='pre-wrap'>{contents}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                {modalIdx > 0 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailLinetok);