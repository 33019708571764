import './banner.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime, renderMemberStatus } from '../../libs/utils';
import consts from '../../libs/consts';

class BannerAdvert extends React.Component {
  constructor() {
    super();
    this.state = {
      dataList: [],
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = () => {
    window.scrollTo(0, 0);

    requestPost('getBanners', {type: 'A'}, (res) => {
      if(Boolean(res)) {
        this.setState({dataList: res});
      }
    });
  }

  changeOrder = () => {
    const { editsort, dataList } = this.state;
    this.setState({editsort:!editsort});
    
    if(editsort) {  // 변경 완료
      const listIds = dataList.map(dt => dt.id);
      requestPost('updateBannerSort', {listIds}, (res) => {
        if(Boolean(res)) {
            this.getData();
            toastMsg('저장되었습니다.', 'success');
        }
    });
    }
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }
  goBannerlist = ()=>{
    const { router } = this.props;
    router.navigate('/banner/advert/list');
  }

  render() {
    const { dataList } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents banneradvert'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>광고 배너 게시 설정</div>
            </div>
            <div className='card_head'>
              <div />
              <div className='btn_normal' onClick={() => this.gotoPage('/banner/advert/detail?type=A')}>신규 배너 등록</div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>배너ID</th>
                  <th>노출여부</th>
                  <th>게시 위치</th>
                  <th>배너명</th>
                  <th>이미지</th>
                  <th>게시기간</th>
                  <th>게시자</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index} onClick={()=>{this.gotoPage('/banner/advert/detail?id=' + item.id)}}>
                    <td>{item.id}</td>
                    <td><input type="checkbox" checked={item.expo == 1}/></td>
                    <td>{index + 1}</td>
                    <td className='cursor_pointer'>{item.title}</td>
                    <td>
                      <img src={consts.resourcUrl + item.pc} className='banner_img' />
                    </td>
                    <td>{Boolean(item.st_time) ? formatDate(item.st_time) : '게시 즉시'} ~ {Boolean(item.ed_time) ? formatDate(item.ed_time) : '무제한'}</td>
                    <td>{item.user_name}({item.email})</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={6}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <div className='card_head'>
              <div style={{flex:1}}></div>
              <div className='btn_normal' onClick={this.goBannerlist}>등록된 배너 전체 보기</div>
            </div>
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(BannerAdvert);