import './member.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';
import { utils, writeFileXLSX } from 'xlsx';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime, renderMemberStatus } from '../../libs/utils';

class MemberList extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      status:       '',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
      checkAll:     false,
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    this.getData();
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    const { keyword, status } = this.state;
    const params = { keyword, status, currentPage, type: 'list' }
    requestPost('getMemberList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeMemStatus = (status) => {
    this.setState({status})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  checkAll = () => {
    let { dataList, checkAll } = this.state;
    dataList = dataList.map(dt => {
      dt.checked = !checkAll;
      return dt;
    } );
    this.setState({checkAll: !checkAll, dataList})
  }

  allDownloadExcel = () => {
    requestPost('getMemberList', {type: 'all', status: 'N'}, (res) => {
      if(Boolean(res) && res.length > 0) {
        this.downloadExcel(res);
      }
      else {
        toastMsg("데이터가 없습니다.");
      }
    });
  }
  selDownloadExcel = () => {
    const { dataList } = this.state;
    const selectedList = dataList.filter(dt => dt.checked);
    if(selectedList.length == 0) {
      return toastMsg('항목을 선택하세요.');
    }
    this.downloadExcel(selectedList);
  }
  downloadExcel = (dataList) => {
    
    const data = [];
    dataList.map(item => {
      data.push({ "회원 ID": item.id, "닉네임": item.nickname, "휴대폰번호": item.phone, "이메일": item.email, "가입유형": item.reg_type == 'E' ? '이메일' : '카카오', "계정상태": renderMemberStatus(item.status), "가입일시": formatDate(item.reg_time) })
    });

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `회원_${getCurrentTime()}.xlsx`);
  }

  openDetail = (id) => {
    const { router } = this.props;
    router.navigate('/member/list/detail?id='+id);
  }

  render() {
    const { keyword, status, totalCnt, currentPage, dataList, checkAll } = this.state;

    return (
      <Leftmenu>
        <div className='page_contents memlist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>회원</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='회원ID, 닉네임, 휴대폰번호로 검색'
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
                <span>{formatNum(totalCnt)}</span>명의 회원
              </div>
              <div className='fralign_center' style={{ gap: 60 }}>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='btn_link' onClick={this.selDownloadExcel}>선택 정보 다운로드</div>
                  <div className='btn_link' onClick={this.allDownloadExcel}>전체 다운로드</div>
                </div>
                <div className='fralign_center' style={{ gap: 20 }}>
                  <div className='font_D700_14'>계정상태</div>
                  <label className='radio_label' onClick={() => this.changeMemStatus('')}><input type="radio" name="status" checked={status == ''} onChange={()=>{}} />전체</label>
                  <label className='radio_label' onClick={() => this.changeMemStatus('N')}><input type="radio" name="status" checked={status == 'N'} onChange={()=>{}} />활성</label>
                  <label className='radio_label' onClick={() => this.changeMemStatus('T')}><input type="radio" name="status" checked={status == 'T'} onChange={()=>{}} />일시정지</label>
                  <label className='radio_label' onClick={() => this.changeMemStatus('S')}><input type="radio" name="status" checked={status == 'S'} onChange={()=>{}} />영구정지</label>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>
                    <input type='checkbox' onChange={() => {}} onClick={() => this.checkAll()} checked={checkAll} />
                  </th>
                  <th>회원 ID</th>
                  <th>닉네임</th>
                  <th>휴대폰번호</th>
                  <th>이메일</th>
                  <th>가입유형</th>
                  <th>계정상태</th>
                  <th>가입일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) =>
                  <tr key={index}>
                    <td>
                      <input type='checkbox' onChange={() => {}} onClick={() => {
                        let tmpList = dataList;
                        tmpList[index].checked = !item.checked;
                        // 
                        let checkedCnt = tmpList.filter(dt => dt.checked).length
                        this.setState({dataList: tmpList, checkAll: dataList.length == checkedCnt})
                      }} checked={Boolean(item.checked)} />
                    </td>
                    <td>{item.id}</td>
                    <td className='cursor_pointer' onClick={()=>{this.openDetail(item.id)}}>{item.nickname}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.reg_type == 'E' ? '이메일' : '카카오' }</td>
                    <td>{renderMemberStatus(item.status)}</td>
                    <td>{formatDate(item.reg_time)}</td>
                  </tr>
                  )
                  :
                  <tr><td colSpan={8}>데이터가 없습니다.</td></tr>
                }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div>
      </Leftmenu>
    );
  }
}
export default withRouter(MemberList);