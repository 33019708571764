import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import './modal.css'
import { requestPost } from '../../libs/request';
import { toastMsg } from '../../libs/utils';
import Nodata from '../Nodata';

Modal.setAppElement('#root');
function KeywordsModal({ category, type, afterOpenModal, closeModal, selectDatas }) {
	useEffect( () => {
		getData();
        category = Boolean(category) ? category.split(',') : []
        setDataArr(category);
	}, []);
  
  	const [dataArr, setDataArr] = useState([]);       // 상품에 등록된 직무
  	const [dataList, setDataList] = useState([]);

  	const getData = () => {
		requestPost('getProductKeywords', {type}, (res) => {
		  if(Boolean(res)) {
            let data = res.data
            data = Boolean(data) ? JSON.parse(data) : []
			setDataList(data)
		  }
		});
	}

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 700, height: 500, padding:0 }
            }}
        >
            <div className='card diffkeyword'>
                <div className='card_head'>
                    <div className='card_head_title'>{type == 'D' ? '난이도' : (type == 'I' ? '관심정보' : '고객유형')}</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <div className='card_body'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{width: '5%'}}></th>
                                <th style={{width: '5%'}}>ID</th>
                                <th style={{width: '20%'}}>{type == 'D' ? '난이도명' : (type == 'I' ? '관심정보' : '고객유형')}</th>
                                <th style={{width: '60%'}}>설명</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataList.map((data, idx) => {
                                    let checked = dataArr?.find((dt) => dt == data.title);
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <input type="checkbox" 
                                                    value={data.title} 
                                                    checked={Boolean(checked)} 
                                                    onChange={() => {
                                                        let dataArr_ = dataArr;
                                                        if(checked) {
                                                            dataArr_ = dataArr?.filter((dt) => dt != data.title);
                                                        }
                                                        else {
                                                            dataArr_.push(data.title);
                                                        }
                                                        setDataArr([...dataArr_]);
                                                    }}
                                                />
                                            </td>
                                            <td>{idx + 1}</td>
                                            <td>{data.title}</td>
                                            <td>{data.descript}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='fralign_center' style={{gap:10, flexWrap:'wrap'}}>
                    {
                        dataArr.map((item, index) => <div key={index} className='key_button'>{item}</div>)
                    }
                </div>
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={()=>{
						if(dataArr.length == 0) {
							return toastMsg((type == 'D' ? '난이도를' : (type == 'I' ? '관심정보를' : '고객유형을')) + ' 선택하세요.', 'warning');
						}
                        selectDatas && selectDatas(dataArr.join());
                        closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
}

export default KeywordsModal;