import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import { toastMsg } from '../../libs/utils';
import './modal.css'
Modal.setAppElement('#root');

function EditAnswer({ afterOpenModal, closeModal, selectDatas, title, text }) {
    const [txt, setTxt] = useState(text ? text: '');

    return (
        <Modal
            isOpen={true}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                },
                content: { position: 'unset', width: 500, padding:0 }
            }}
        >
            <div className='card memberpause'>
                <div className='card_head'>
                    <div className='card_head_title'>{title}</div>
                    <div className='card_head_tool'>
                        <div className='close_modal' onClick={closeModal}/>
                    </div>
                </div>
                <textarea style={{width:'100%', height:200}} value={txt} onChange={({target}) => setTxt(target.value)} />
                
                <div className='modal_bottom'>
                    <div className='btn_normal cancel' onClick={closeModal}>취소</div>
                    <div className='btn_normal' onClick={() => {
                        if(!txt) {
                            return toastMsg('내용을 입력하세요.');
                        }
                        selectDatas && selectDatas(txt);
                        closeModal();
                    }}>확인</div>
                </div>
            </div>
        </Modal>
    );
}

export default EditAnswer;