import './crm.css';
import Leftmenu from '../../layout/leftmenu';

import React from 'react';
import withRouter from '../../components/withRouter';
import EditAnswer from '../../components/modal/edit_answer';

import ConformModal from '../../components/modal/ConformModal';
import { toastMsg, formatDate } from '../../libs/utils';
import {requestPost} from '../../libs/request';

class DetailCrmProd extends React.Component {
    constructor() {
        super();
        this.state = {
            prodReqId:    0,
            modalIdx:     0,
            confirmTitle: '',
            confirmTxt:   '',
        };
    }

    componentDidMount() {

        const { searchParams } = this.props.router;
        const prodReqId  = searchParams.get("id");
        // 
        this.getData(prodReqId);
    }

    getData = (prodReqId) => {
        window.scrollTo(0, 0);
        this.setState({prodReqId});
    
        if(prodReqId > 0) {
            requestPost('getProdRequestDetail', {prodReqId}, (res) => {
                if(Boolean(res)) {
                    const { user_id, product_id, contents, expo, answer, answer_time, reg_time, nickname, prod_title } = res;
        
                    this.setState({ user_id, product_id, contents, expo, answer, answer_time, reg_time, nickname, prod_title });
                    // 
                }
                else {
                    alert('삭제된 내역입니다.');
                    this.gotoPage(-1);
                }
            });
        }
    }
    
    updateAnswer = (answer) => {
        const { prodReqId } = this.state;
        this.setState({modalIdx: 0});

        if(!answer) {
            return toastMsg('내용을 입력하세요.');
        }
        const params = {
            prodReqId, answer, type: 'answer'
        }
        requestPost('updateProdRequest', params, (res) => {
            if(Boolean(res)) {
                toastMsg('수정되었습니다.', 'success');
                this.setState({answer, answer_time: 'now'});
            }
            else {
                toastMsg('저장에 실패하였습니다.');
            }
        });
    }
    onConfirm = () => {
        const {modalIdx, prodReqId, answer} = this.state;
        this.setState({modalIdx: 0, confirmTxt: ''});
  
        if(modalIdx == 12) {
            if(!answer) {
                return toastMsg('답변 내용을 작성하세요.');
            }
            this.updateAnswer(answer);
        }
        else {
            const expo = modalIdx == 10 ? 2 : 1
            const params = {
                prodReqId, expo, type: 'expo'
            }
            requestPost('updateProdRequest', params, (res) => {
                if(Boolean(res)) {
                    toastMsg('수정되었습니다.', 'success');
                    this.setState({expo})
                }
                else {
                    toastMsg('저장에 실패하였습니다.');
                }
            });
        }
    }

    gotoPage = (link) => {
        const { router } = this.props;
        router.navigate(link);
    }

    render() {
        const { prodReqId, confirmTitle, confirmTxt, modalIdx,
            user_id, product_id, contents, expo, answer, answer_time, reg_time, nickname, prod_title } = this.state;

        return (
            <Leftmenu>
                <div className='page_contents detailcrmprod'>
                    <div className='card'>
                        <div className='card_head'>
                            <div className='card_head_title'>상품 문의 상세</div>
                            <div className='fralign_center' style={{gap:20}}>
                                {expo == 1 && (<div className='btn_normal' onClick={() => this.setState({confirmTitle: "상품 문의 상세", confirmTxt: "해당 게시물을 비노출 상태로 변경하시겠습니까?", modalIdx: 10})}>비노출로 변경</div>)}
                                {expo == 2 && (<div className='btn_normal' onClick={() => this.setState({confirmTitle: "상품 문의 상세", confirmTxt: "해당 게시물을 노출 상태로 변경하시겠습니까?[", modalIdx: 11})}>노출로 변경</div>)}
                                {Boolean(answer_time) && <div className='btn_normal' onClick={() => this.setState({modalIdx: 1})}>답변 수정</div>}
                            </div>
                        </div>
                        <div className='card_body' style={{ padding: 10 }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>상품문의ID</th>
                                        <td>{prodReqId}</td>
                                        <th>노출상태</th>
                                        <td>{expo == 1 ? '노출' : '비노출'}</td>
                                    </tr>
                                    <tr>
                                        <th>작성자 닉네임</th>
                                        <td className='cursor_pointer' onClick={() => this.gotoPage('/member/list/detail?id=' + user_id)}>{nickname}</td>
                                        <th>회원 ID</th>
                                        <td>{user_id}</td>
                                    </tr>
                                    <tr>
                                        <th>상품명</th>
                                        <td className='cursor_pointer' onClick={() => this.gotoPage('/education/leasson/detail?id=' + product_id)}>{prod_title}</td>
                                        <th>상품 ID</th>
                                        <td>{product_id}</td>
                                    </tr>
                                    <tr>
                                        <th>작성일시</th>
                                        <td colSpan={3}>{formatDate(reg_time)}</td>
                                    </tr>
                                    <tr>
                                        <th>답변여부</th>
                                        <td colSpan={3}>{Boolean(answer_time) ? '답변완료' : '미답변'}</td>
                                    </tr>
                                    <tr>
                                        <th>문의 내용</th>
                                        <td className='pre-wrap' colSpan={3}>{contents}</td>
                                    </tr>
                                    {Boolean(answer_time) &&
                                    <tr>
                                        <th>답변 내용</th>
                                        <td className='pre-wrap' colSpan={3}>{answer}</td>
                                    </tr>}
                                </tbody>
                            </table>
                            {Boolean(answer_time) ||
                            <>
                            <div className='fr_c_end w-100' style={{ padding: '20px 0px 10px 0px' }}>
                                <div className='btn_normal' style={{ width: 120 }} onClick={() => this.setState({confirmTitle: "상품 문의 상세", confirmTxt: "상품 문의 답변을 게시하시겠습니까?", modalIdx: 12})}>답변 게시</div>
                            </div>
                            <textarea style={{ width: '100%', height: 200 }} placeholder={"답변 내용을 작성하세요."} value={answer} onChange={({target}) => this.setState({answer: target.value})} />
                            </>}
                        </div>
                    </div>
                </div>
                {modalIdx >= 10 && <ConformModal closeModal={() => this.setState({confirmTxt: '', modalIdx: 0})} onConfirm={this.onConfirm} title={confirmTitle}>{confirmTxt}</ConformModal>}
                {modalIdx == 1 && <EditAnswer closeModal={() => this.setState({modalIdx: 0})} title={"답변 수정"} text={answer} selectDatas={this.updateAnswer} />}
            </Leftmenu>
        );
    }
}

export default withRouter(DetailCrmProd);